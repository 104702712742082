import React from 'react'
import { Formik } from 'formik'
import ApiFetcher from 'lib/api_fetcher'
import TextInput from 'ui/forms/text_input'
import {Button} from 'reactstrap'
import {useToasts} from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'

const LoginForm = ({endpoint}) => {
  const {addToast} = useToasts()
  const {i18n} = useTranslation()

  return (
    <Formik
      initialValues={{ username: '', password: '' }}
      validate={values => {
        const errors = {}
        if (!values.username) {
          errors.username = i18n.t('login_form.username_required')
        }
        if (!values.password) {
          errors.password = i18n.t('login_form.password_required')
        }
        return errors
      }}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        new ApiFetcher({
          key: 'login',
          endpoint: endpoint,
          headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
          method: 'POST',
          body: JSON.stringify(values)
        }).call()
          .then((res) => {
            if (res.success == true) {
              if (res.destination_bg) {
                localStorage.setItem('sign_in_bg', res.destination_bg)
              } else {
                localStorage.removeItem('sign_in_bg')
              }
              window.location.href = res.redirect_to
            } else {
              setFieldError('password', res.message)
              addToast(res.message, {appearance: 'error', autoDismiss: true})
            }
          })
          .catch(() => {
            setFieldError('password', i18n.t('login_form.login_problem'))
          })
          .finally(() => setSubmitting(false))
      }}
    >
      {({
        errors,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <TextInput name="username" placeholder="Usuario" hideError={true}/>
          <TextInput name="password" placeholder="Contraseña" hideError={true} type="password"/>
          <Button type="submit" className="btn btn-info btn-block" disabled={isSubmitting}>
            Acceder
          </Button>
        </form>
      )}
    </Formik>
  )
}

export default LoginForm
