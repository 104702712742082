import currency from 'i18n/currency'

export default class PriceSummaryCalculator {
  constructor(props) {
    this.ticket_types = props.ticket_types
    this.currency = props.currency
  }

  call() {
    let total_tickets_qty = 0
    let total_base_price = currency(0, this.currency)
    let total_discount = currency(0, this.currency)
    let total_additional_fee = currency(0, this.currency)
    let total_final_price = currency(0, this.currency)

    Object.values(this.ticket_types).map((ticket_type) => {
      total_base_price = currency(ticket_type.base_price, this.currency).add(ticket_type.supplements_price).multiply(ticket_type.qty).add(total_base_price)
      total_discount = currency(ticket_type.discount, this.currency).multiply(ticket_type.qty).add(total_discount)
      total_additional_fee = currency(ticket_type.additional_fee, this.currency).multiply(ticket_type.qty).add(total_additional_fee)
      total_final_price = currency(ticket_type.total_price, this.currency).add(ticket_type.supplements_price).multiply(ticket_type.qty).add(total_final_price)
      total_tickets_qty += ticket_type.qty
    })

    return {
      total_base_price: total_base_price,
      total_discount: total_discount,
      total_additional_fee: total_additional_fee,
      total_final_price: total_final_price,
      total_tickets_qty: total_tickets_qty
    }
  }
}
