import React, {useState} from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import {Card as CardBase, CardImg, CardBody} from 'reactstrap'
import thumbnailNotAvailable from 'img/image-not-available.png'

const Wrapper = styled.div.attrs({
  className: 'card-wrapper p-2'
})`
  max-width: 300px;
`

const Card = styled(CardBase)`
  transition: 1s;
`

const Badge = styled.div.attrs({
  className: 'px-2 shadow rounded'
})`
  position: absolute;
  top: 0em;
  left: 0em;
  opacity: 1;
  z-index: 1;
  font-weight: bold;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
`

const BestSellerBadge = styled(Badge).attrs({
  className: 'text-white bg-success'
})``

const Thumbnail = styled(CardImg).attrs({
  className: ''
})`
  ${props => props.status == 'active' &&`
    cursor: pointer;
    transition: 0.3s;
    opacity: 0.88;

    &:hover {
      opacity: 1;
    }
  `}
`

const ProductTitle = styled.h5.attrs({
  className: 'mb-2'
})`
  font-size: 1.05rem;

  a {
    text-decoration: none;
    color: var(--secondary);
    transition: .5s ease;

    &:hover {
      color: var(--primary);
    }
  }
`

const FeaturedPrice = styled.span.attrs()`
  cursor: pointer;
  transition: .3s ease;
  border: 0px;
  border-radius: 0.15em;

  strong {
    font-size: 1.15em;
  }

  &:hover {
    color: var(--primary);
  }
`

const ProductCard = (props) => {
  const {id, thumbnail, title, state, state_label, city, city_label, status, featured_price, notification, evoucher, url, template_link, best_seller, height, newWindow} = props
  const {i18n} = useTranslation()

  const CardContent = () => (
    <Card className={cn(
      ['h-100', 'shadow-sm']
    )}>
      {best_seller &&
        <BestSellerBadge>{i18n.t('product_card.best_seller_badge')}</BestSellerBadge>
      }
      <Thumbnail
        top
        width="100%"
        height={height ? height : '190'}
        status={status}
        src={thumbnail ? thumbnail : thumbnailNotAvailable}
        onClick={() => newWindow ? window.open(url) : window.location.href = url}
      />
      <CardBody className="px-3">
        <ProductTitle>
          <a href={url} target={newWindow ? 'blank' : '_self'}>{title}</a>
        </ProductTitle>
        <small>
          {featured_price ? (
            <FeaturedPrice onClick={() => newWindow ? window.open(url) : window.location.href = url}>
              {i18n.t('product_card.from')} <strong className="h3">{featured_price}€</strong>
            </FeaturedPrice>
          ) : (
            <strong className="h3">{i18n.t('product_card.no_feature_price_label')}</strong>
          )}
        </small>
      </CardBody>
    </Card>
  )

  if (props.hideWrapper) {
    return <CardContent/>
  }

  return  (
    <Wrapper>
      <CardContent/>
    </Wrapper>
  )
}

export default ProductCard
