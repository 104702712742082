import React, {useState} from 'react'
import Page from 'ui/page'
import { useTranslation } from 'react-i18next'
import AsyncTable from 'ui/async_table'
import {ButtonGroup, Button} from 'reactstrap'
import useTableFetch from 'utils/hooks/use_table_fetch'
import tableOptions from './cash_balance_grid_options'
import {currency} from 'i18n/config'
import time from 'i18n/time'
import {assign} from 'lodash'

const CashBalance = (props) => {
  const {urls} = props
  const {i18n} = useTranslation()

  const tableFetch = useTableFetch(urls.grid)
  const {data, doFetch, loading} = tableFetch

  return (
    <Page {...props}>
      <h1 className="mb-3">{i18n.t('cash_balance.title')}</h1>
      <AsyncTable
        {...tableFetch}
        tableOptions={tableOptions}
      />
    </Page>
  )
}

export default CashBalance
