import React, {useEffect} from 'react'
import {ModulePanel, ModuleHeader, ModuleBody, ModuleFooter} from 'containers/purchase/modules/module_panel'
import { useTranslation } from 'react-i18next'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'
import 'react-datepicker/dist/react-datepicker.css'
import {Row, Col, ListGroup, ListGroupItem} from 'reactstrap'
import Select from 'react-select'
import moment from 'moment'

export const AccessModule = (props) => {
  const {config, machine, status} = props
  const { t, i18n } = useTranslation()
  const [current, send] = machine
  const { days_available } = config
  const { selection_groups } = current.context

  useEffect(() => {
    registerLocale('es', es)
  }, [])

  const SelectionGroup = (data) => {
    const {id, name, event_groups, access_date, session, event_group} = data

    // const [eventGroupActive, setEventGroupActive] = useState(event_groups[0].id)

    const SessionSelector = ({events}) => {
      const eventsList = events.map((e) => ({value: e.id, label: moment(e.date).format('LT')}))

      return (
        <Select
          id={eventsList.find((e) => e.value == session)}
          className="text-dark"
          options={eventsList}
          value={eventsList.find((e) => e.value == session)}
          onChange={(event) => {
            send({type: 'SESSION_CHANGE', selection_group_id: id, session_id: event.value})
          }}
        />
      )
    }

    const SessionGroupSelector = () => {
      const selection_group_id = id

      return (
        <ListGroup>
          <ListGroupItem color="info">
            <strong>Seleccione una sesión y hora</strong>
          </ListGroupItem>
          {event_groups.map(({id, name, events}) => (
            <ListGroupItem
              key={id}
              color={event_group == id ? 'warning' : ''}
              style={{cursor: 'pointer'}}
              onClick={() => {
                if (event_group == id) {return null}
                send({type: 'EVENT_GROUP_CHANGE', selection_group_id: selection_group_id, event_group: id})}
              }
            >
              <div className="d-flex justify-content-between">
                <div className="p-2">
                  {name}
                </div>
                {event_group == id &&
                  <div className="w-25">
                    <SessionSelector events={events}/>
                  </div>
                }
              </div>
            </ListGroupItem>
          ))}
        </ListGroup>
      )
    }

    return (
      <div className="card">
        <div className="card-body">
          <Row>
            <Col>
              <h5 className="card-title">{name}</h5>
              <DatePicker
                className={'form-control'}
                locale="es"
                selected={access_date}
                filterDate={(date) => days_available ? days_available.includes(moment(date).format('YYYY-MM-DD')) : true}
                onChange={(date) => {
                  send('ACCESS_DATE_CHANGE', {id: id, day: date})
                  // setAccessDate(date)
                }}
                dateFormat="P"
              />
              <div className="mt-2">
                {moment(access_date).format('LL')}
              </div>
            </Col>
            <Col>
              {event_groups.length &&
                <SessionGroupSelector/>
              }
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  return (
    <ModulePanel>
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send('REOPEN_ACCESSSSTEP') : null}>
        <span>
          <i className="fa fa-question-circle mr-2" aria-hidden="true"></i>
          {i18n.t('access_module.title')}
        </span>
      </ModuleHeader>
      <ModuleBody status={status}>
        {current.matches({accessStep: 'waitingEventsPick'}) && selection_groups.length == 0 &&
          <div className="alert alert-info" role="alert">
            {i18n.t('event_module.events_not_available')}
          </div>
        }

        {selection_groups.map((selectionGroup) => (
          <SelectionGroup key={selectionGroup.id} {...selectionGroup}/>
        ))}
      </ModuleBody>
      <ModuleFooter {...props}/>
    </ModulePanel>
  )
}

export default AccessModule
