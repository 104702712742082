import currency from 'currency.js'

const EUR = value => currency(value, {symbol: '€', pattern: '#!', formatWithSymbol: true })
const MXN = value => currency(value, {symbol: 'MXN', pattern: '#!', formatWithSymbol: true })

const currencyDefinition = (cur) => {
  if (cur == 'EUR') {
    return EUR
  } else if (cur == 'MXN') {
    return MXN
  } else {
    return EUR
  }
}

export default currencyDefinition
export {EUR, MXN}
