import {assign} from 'xstate'
import moment from 'moment'
import {nextStep, isLastStep} from 'utils/step_utils'
import i18n from 'i18n/config'

const stepName  = 'visitorStep'

const updateForm = assign({visitorsForm: (ctx, e) => e.form})
const cleanError = assign({error: null})
const setError = (msg) => assign({error: i18n.t(msg)})
const setVisitorsValues = assign({visitors: (ctx, e) => e.data.visitors})

const submitForm = (ctx) => {
  return new Promise((resolve, reject) => {
    ctx.visitorsForm.submitForm()
      .then(ctx.visitorsForm.validateForm)
      .then(errors => {
        const isValid = Object.keys(errors).length === 0
        if (isValid) {
          resolve(ctx.visitorsForm.values)
        } else {
          reject(errors)
        }
      })
  })
}

const visitorsState = (config, params) => {
  const prepareVisitors = assign({visitors: (ctx, e) => {
    const ticketTypesWithVisitors = params.external_api_config.visitorsRequired

    const visitorApplicable = (ticket_id) => {
      if (Array.isArray(ticketTypesWithVisitors)) {
        return ticketTypesWithVisitors.includes(ticket_id)
      } else {
        return ticketTypesWithVisitors == true || ticketTypesWithVisitors == 'true'
      }
    }

    return ctx.tickets
      .filter(({id, qty}) => qty >= 0 && visitorApplicable(id))
      .filter((ticket) => !(ticket.params && ticket.params.disable_visitor == true))
      .map((t) => {
        let group = []
        for (let i = 0; i < t.qty; i++) {
          group.push({
            name: '',
            card_id: '',
            ticket_type_id: t.id,
            ticket_type_name: t.name,
            total_price: t.total_price
          })
        }

        return group
      })
      .flat()
  }})

  const formRequired = (ctx) => ctx.visitors.length > 0

  return {
    initial: 'init',
    states: {
      init: {
        on: {
          '': {
            actions: prepareVisitors,
            target: 'determinatingFormRequired',
          }
        },
      },
      determinatingFormRequired: {
        on: {
          '': [
            {
              target: 'waitingVisitorsResponses',
              actions: [cleanError],
              cond: formRequired,
            },
            {
              target: isLastStep(config, 'visitorsStep') ? '#purchase.reserving' : '#purchase.' + nextStep(config, 'visitorsStep')
            }
          ],
        },
      },
      waitingVisitorsResponses: {},
      formValidation: {
        invoke: {
          src: submitForm,
          onDone: {
            target: isLastStep(config, 'visitorsStep') ? '#purchase.reserving' : '#purchase.' + nextStep(config, 'visitorsStep'),
            actions: [setVisitorsValues]
          },
          onError: {
            target: 'waitingVisitorsResponses',
            actions: [setError(i18n.t('contact_state.submiting_contact_form')), 'scrollToError']
          }
        }
      }
    },
    on: {
      VISITORS_FORM_CHANGE: {
        actions: [updateForm, cleanError]
      },
      NEXT_STEP_CLICK: [
        {
          target: '.formValidation'
        }
      ],
      RESERVATION_REQUEST: [
        {
          target: '.formValidation'
        }
      ]
    }
  }
}

export default visitorsState
