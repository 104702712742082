import React from 'react'
import {Row} from 'reactstrap'
import ProductCard from 'containers/list/products/product_card'
import {useTranslation} from 'react-i18next'

const RelatedProducts = (props) => {
  const {products, url, rowProps, limitProducts = 5} = props
  const {i18n} = useTranslation()

  if (!products || products.length <= 0) return ''

  return (
    <React.Fragment>
      <hr className="mt-4"/>
      <h4 className="mb-4">{i18n.t('related_products.title')}</h4>
      <div className="mx-2">
        <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 mt-3" {...rowProps}>
          {products.slice(0, limitProducts).map((product, key) =>
            <ProductCard
              {...product}
              key={key}
              url={url.replace(/ *\:[^)]*\: */g, product.slug)}
            />
          )}
        </Row>
      </div>

    </React.Fragment>
  )
}

export default RelatedProducts
