import React from 'react'
import {usePartial} from 'i18n/config'
import PageUnlogged from 'ui/page_unlogged'

const LegalWarning = (props) => {
  const {site_name, client_params} = props

  const LegalWarningPartial = usePartial('auth/legal_warning', {supportTelephone: client_params.support_telephone_label, site_name: site_name})

  return (
    <PageUnlogged {...props}>
      <LegalWarningPartial/>
    </PageUnlogged>
  )
}

export default LegalWarning
