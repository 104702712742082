import React from 'react'
import { FormGroup, Label, FormFeedback } from 'reactstrap'
import { useField, useFormikContext } from 'formik'
import ReactSelect from 'react-select'
import cn from 'classnames'
import moment from 'moment'
import {get} from 'lodash'

const TimePicker = (props) => {
  const name = (props.field && props.field.name) || props.name
  const {label, params, machine} = props
  const {start_time, end_time, interval, duration, offset, disabled_times} = params
  const [current, send] = machine
  const [field, meta] = useField(name)
  const {setFieldValue} = useFormikContext()

  const offset_date = moment().add(offset, 'minutes')
  const start_date = moment(moment(current.context.day).format('YYYY-MM-DD') + ' ' + start_time)
  const end_date = moment(moment(current.context.day).format('YYYY-MM-DD') + ' ' + end_time)

  const generateOptions = () => {
    let current_time = start_date
    let times = []

    const available_times = get(params, 'available_times', [])
      .map((t) => moment(t))
      .filter((at) => at.format('YYYY-MM-DD') == current.context.day)

    while (current_time <= end_date) {
      times.push(moment(current_time.toDate()))
      current_time = current_time.clone().add(interval, 'minutes')
    }

    times = times.concat(available_times)

    return times
      .sort((a, b) => a - b)
      .filter((t) => !offset || t > offset_date)
      .filter((t) => {
        const disabled_time = disabled_times.some((d) => {
          return moment(d).format('YYYY-MM-DD HH:mm') == t.format('YYYY-MM-DD HH:mm')
        })

        return !disabled_time
      })
      .map((t) => {
        let label = t.format('HH:mm')
        if (duration) {
          label = `${t.format('HH:mm')} - ${moment(t).add(duration, 'minutes').format('HH:mm')}`
        }

        return {
          label: label,
          value: label
        }
      })
  }

  return (
    <FormGroup className={ meta.touched && meta.error ? 'has-error' : '' }>
      <Label className='control-label' htmlFor={props.id || props.name}>
        {label}
        {props.required &&
          <span className="text-danger ml-1">*</span>
        }
      </Label>
      <div
        style={{display: 'none'}}
        className={
          cn([
            'form-control',
            meta.touched && meta.error ? 'is-invalid' : '',
            meta.touched && !meta.error ? 'is-valid' : ''
          ])
        }
      >
      </div>
      <ReactSelect
        isClearable={props.required ? false : true}
        onChange={(selected) => {setFieldValue(field.name, selected ? selected.value  : '')}}
        options={generateOptions()}
        {...props}
      />
      {meta.touched && meta.error ? (
        <FormFeedback className={'help-block'}>{meta.error}</FormFeedback>
      ) : null}
    </FormGroup>
  )
}

export default TimePicker
