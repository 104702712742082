import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import Page from 'ui/page'
import {ListGroup, ListGroupItem, Card, CardHeader, CardBody} from 'reactstrap'
import AsyncTable from 'ui/async_table'
import useTableFetch from 'utils/hooks/use_table_fetch'
import tableOptions from './products_catalog_grid_options'
import parse from 'react-html-parser'
import cn from 'classnames'

const Faq = (props) => {
  const {records} = props
  const {i18n} = useTranslation()

  const FaqItem = (props) => {
    const {title, content} = props
    const [active, setActive] = useState(false)

    return (
      <Card className="rounded-0">
        <CardHeader>
          <div className="d-flex justify-content-between" style={{cursor: 'pointer'}} onClick={() => setActive(!active)}>
            <h5 className="m-0" >{title}</h5>
            <i className={cn(['fa', active ? 'fa-caret-up' : 'fa-caret-down'])}/>
          </div>
        </CardHeader>
        {active &&
          <CardBody>
            {parse(content)}
          </CardBody>
        }
      </Card>

    )
  }

  return (
    <Page {...props}>
      <h1 className="mb-4">{i18n.t('faq.title')}</h1>
      <ListGroup>
        {records.map((i) => <FaqItem key={i.id} {...i}/>)}
      </ListGroup>
    </Page>
  )
}

export default Faq
