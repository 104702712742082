import React from 'react'
import Pagination from 'react-js-pagination'
import styled from 'styled-components'
import {isMobile} from 'react-device-detect'
import { useTranslation } from 'react-i18next'

const Styles = styled.div`
  ${isMobile &&`
    .page-item:not(.default-item) {
      display: none;
    }
  `}
`

const ProductsGridPaginator = (props) => {
  const {current_page, per_page, total_items, onPageSelected} = props
  const { t, i18n } = useTranslation()

  if (per_page > total_items ) {
    return null
  }

  return (
    <Styles>
      <Pagination
        activePage={current_page}
        itemsCountPerPage={parseInt(per_page)}
        totalItemsCount={total_items ? total_items : 0}
        pageRangeDisplayed={5}
        onChange={onPageSelected}
        innerClass="pagination pagination m-0"
        itemClass="page-item"
        linkClass="page-link"
        itemClassPrev="prev default-item"
        itemClassFirst="first default-item"
        itemClassNext="next default-item"
        itemClassLast="last default-item"
        prevPageText={i18n.t('paginator.prev')}
        nextPageText={i18n.t('paginator.next')}
      />
    </Styles>
  )
}

export default ProductsGridPaginator
