import i18n, {currency} from 'i18n/config'

const getColumns = (data, params) => [
  {
    Header: '',
    accessor: 'row',
    Cell: ({state, row}) => state.pageSize * (state.pageIndex) + (row.index + 1),
    sortable: false,
    justifyContent: 'center',
    width: 20
  },
  {
    Header: i18n.t('cash_balance_grid_options.operation_date'),
    id: 'cash_balances.operation_date',
    accessor: 'operation_date',
    justifyContent: 'center',
    width: 100,
  },
  {
    Header: i18n.t('cash_balance_grid_options.operation_type'),
    id: 'cash_balances.operation_type',
    accessor: 'operation_type_label',
    justifyContent: 'center',
    width: 150,
    style: ({row}) => ({
      fontWeight: 'bold',
      color: row.original.operation_type == 'withdraw' ? 'red' : ''
    })
  },
  {
    Header: i18n.t('cash_balance_grid_options.description'),
    id: 'cash_balances.description',
    accessor: 'description',
    justifyContent: 'left',
    width: 250,
  },
  {
    Header: i18n.t('cash_balance_grid_options.amount'),
    id: 'cash_balances.amount',
    accessor: 'amount',
    justifyContent: 'flex-end',
    width: 100,
    Cell: ({value}) => currency(value)
  },
  {
    Header: 'Total',
    id: i18n.t('cash_balance_grid_options.total_cash'),
    accessor: 'total_cash',
    justifyContent: 'flex-end',
    width: 100,
    Cell: ({value}) => currency(value)
  }
]

const tableOptions = (data, params) => {
  return {
    columns: getColumns(data, params)
  }
}

export default tableOptions
