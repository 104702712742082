import cssVariables from 'lib/css_variables'

const mediaBreakpoints = cssVariables
  .filter((v) => v.name.includes('breakpoint'))
  .map((v) => ({
    name: v.name.replace('breakpoint-', ''),
    value: Number.parseInt(v.value.replace('px', ''))
  }))

export default mediaBreakpoints
