import React, {useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import parse from 'react-html-parser'
import Page from 'ui/page'
import cartFailed from 'img/cart-failed'
import gear from 'img/gear-loading'

const Vouchers = (props) => {
  const {status} = props

  const VouchersNotFound = () => {
    const { t, i18n } = useTranslation()

    return (
      <div className="alert alert-danger mb-4 d-flex justify-content-left" role="alert">
        <div>
          <img width="110" height="110" className="mr-4" src={cartFailed}></img>
        </div>
        <div className="w-100">
          <h4 className="alert-heading d-flex justify-content-between m-0 p-0">
            <div className="align-self-center">
              {i18n.t('vouchers_not_found.title')}
            </div>
          </h4>
          <hr/>
          <p className="m-0">{parse(i18n.t('vouchers_not_found.description'))}</p>
        </div>
      </div>
    )
  }

  const VouchersNotReady = () => {
    useEffect(() => {
      const interval = setInterval(() => {
        location.reload()
      }, 8000)
      return () => clearInterval(interval)
    }, [])

    const { t, i18n } = useTranslation()

    return (
      <div className="alert alert-info mb-4 d-flex justify-content-left" role="alert">
        <div>
          <img width="110" height="110" className="mr-4" src={gear}></img>
        </div>
        <div className="w-100">
          <h4 className="alert-heading d-flex justify-content-between m-0 p-0">
            <div className="align-self-center">
              {i18n.t('vouchers_not_ready.title')}
            </div>
          </h4>
          <hr/>
          <p className="m-0">{parse(i18n.t('vouchers_not_ready.description'))}</p>
          <p className="mt-2">{parse(i18n.t('vouchers_not_ready.reminder'))}</p>
        </div>
      </div>
    )
  }

  return (
    <Page {...props}>
      {status == '404' &&
        <VouchersNotFound/>
      }
      {status == '202' &&
        <VouchersNotReady/>
      }
    </Page>
  )
}

export default Vouchers
