import React from 'react'
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import { useField, useFormikContext } from 'formik'
import cn from 'classnames'

const Checkbox = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  const {setFieldValue} = useFormikContext()

  return (
    <FormGroup check className={ meta.touched && meta.error ? 'has-error' : '' }>
      <Input
        {...field}
        {...props}
        type='checkbox'
        className={
          cn([
            meta.touched && meta.error ? 'is-invalid' : '',
            meta.touched && !meta.error ? 'is-valid' : ''
          ])
        }
        onChange={(e) => setFieldValue(field.name, e.target.checked)}
        checked={field.value}
      />
      <Label check className='control-label' htmlFor={props.id || props.name}>{label}</Label>

      {meta.touched && meta.error ? (
        <FormFeedback className={'help-block'}>{meta.error}</FormFeedback>
      ) : null}
    </FormGroup>
  )
}

export default Checkbox
