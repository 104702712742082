import React, {useState, useEffect} from 'react'
import PubSub from 'pubsub-js'
import useLayout from 'utils/hooks/use_layout'
import styled, {keyframes} from 'styled-components'

const spinnerAnimation = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
  }

  /* :not(:required) hides these rules from IE9 and below */
  &:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }

  &:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: ${spinnerAnimation} 1500ms infinite linear;
    -moz-animation: ${spinnerAnimation} 1500ms infinite linear;
    -ms-animation: ${spinnerAnimation} 1500ms infinite linear;
    -o-animation: ${spinnerAnimation} 1500ms infinite linear;
    animation: ${spinnerAnimation} 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  }
`

const LoaderSpinner = () => {
  const [active, setActive] = useState(false)
  const mode = useLayout()

  useEffect(() => {
    PubSub.subscribe('loading-page', () => setActive(true))
    PubSub.subscribe('loaded-page', () => setActive(false))
  }, [])

  if (active) {
    window.parent.postMessage(['enableSpinner', ''], '*')
    if (mode == 'b2c') {
      return <Spinner>Loading&#8230;</Spinner>
    } else {
      return null
    }
  } else {
    window.parent.postMessage(['disableSpinner', ''], '*')
    return ''
  }
}

export default LoaderSpinner
