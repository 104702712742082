import ApiFetcher from 'lib/api_fetcher'
import PubSub from 'pubsub-js'

const tableFetch = (endpoint, query, setData, setLoading, cacheKey) => {
  setLoading(true)

  PubSub.publish('loading-page')

  new ApiFetcher({
    endpoint: endpoint,
    query: query
  })
    .call()
    .then(data => {
      setLoading(false)
      PubSub.publish('loaded-page')
      setData(data)
    }).catch(() => {
      localStorage.setItem(cacheKey, null)
      alert('Error obteniendo datos...')
      PubSub.publish('loaded-page')
      setLoading(false)
    })
}

export default tableFetch
