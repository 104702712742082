import CarLocationModule from 'containers/purchase/modules/car_location_module/car_location_module'
import carLocationState from 'containers/purchase/modules/car_location_module/car_location_state'
import VehiclesModule from 'containers/purchase/modules/vehicles_module/vehicles_module'
import vehiclesState from 'containers/purchase/modules/vehicles_module/vehicles_state'
import VehicleRateModule from 'containers/purchase/modules/vehicle_rate_module/vehicle_rate_module'
import vehicleRateState from 'containers/purchase/modules/vehicle_rate_module/vehicle_rate_state'
import ContactModule from 'containers/purchase/modules/contact_module/contact_module'
import contactState from 'containers/purchase/modules/contact_module/contact_state'
import i18n from 'i18n/config'

const reservationMapper = (ctx) => {
  return {
    event_id: 'SV20220720',
    ticket_types: [],
    params: {
      quotation: ctx.quotation,
      contact_values: ctx.contactValues,
      pickup: ctx.pickup_values,
      dropoff: ctx.dropoff_values,
      transfer_service: ctx.transfer_service_values
    }
  }
}

const config = (params) => {
  const {external_api_config} = params

  let steps = {}

  steps.carLocationStep = {
    component: CarLocationModule,
    componentProps: {
      title: i18n.t('avis_machine_config.car_location_module_title'),
      formName: 'car_location_form',
    },
    currentStep: 'carLocationStep',
    target: '#purchase.contactRequired',
    autoNext: false,
    states: carLocationState,
    context: {
      tickets: []
    }
  }

  steps.vehiclesStep = {
    component: VehiclesModule,
    componentProps: {
      title: i18n.t('avis_machine_config.vehicles_module_title')
    },
    autoNext: false,
    states: vehiclesState,
    context: {
      vehicles: []
    }
  }

  steps.vehicleRateStep = {
    component: VehicleRateModule,
    componentProps: {
      title: i18n.t('avis_machine_config.vehicle_rate_module_title')
    },
    autoNext: false,
    states: vehicleRateState,
    context: {}
  }

  steps.contactStep = {
    component: ContactModule,
    autoNext: false,
    states: contactState,
    context: {
      contactValues: []
    }
  }

  return {
    reservationMapper: reservationMapper,
    steps: steps,
    params: params,
  }
}

export default config
