import EventModule from 'containers/purchase/modules/event_module/event_module'
import MixentradasIframeModule from 'containers/purchase/modules/mixentradas_iframe_module/mixentradas_iframe_module'
import eventState from 'containers/purchase/modules/event_module/event_state'
import iframeState from 'containers/purchase/modules/mixentradas_iframe_module/iframe_state'

const reservationMapper = (ctx, e) => {
  return {
    event_id: `${ctx.event.id}_${ctx.reservation.ReferenceCode}`,
    ticket_types: ctx.tickets,
    params: {
      reservation_code: ctx.reservation.ReferenceCode
    }
  }
}

const config = (params) => {
  return {
    reservationMapper: reservationMapper,
    hideReservatioButton: true,
    steps: {
      eventStep: {
        component: EventModule,
        autoNext: true,
        states: eventState,
        context: {
          year_month: params.initialYearMonth,
          event_groups: [],
          event: null
        }
      },
      iframeStep: {
        component: MixentradasIframeModule,
        autoNext: false,
        states: iframeState,
        context: {
          tickets: [],
          iframeEndpoint: params.external_api_config.widget_endpoint,
          iframeToken: params.external_api_config.widget_token
        }
      }
    }
  }
}

export default config
