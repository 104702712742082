import spainFlag from 'img/flags/Spain'
import ukFlag from 'img/flags/UnitedKingdom'
import ApiFetcher from 'lib/api_fetcher'
import AppProvider from 'utils/app_provider'
import React, { useState, useEffect } from 'react'
import {isBrowser, isMobile} from 'react-device-detect'
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarText,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap'
import Modal from 'ui/modal'
import { useTranslation } from 'react-i18next'
import ContactForm from './contact_form'
import DestinationSelector from './destination_selector'
import SearchBox from './search_box'
import LanguageSwitcher from 'ui/language_switcher'
import styled from 'styled-components'

const HeaderIframe = (props) => {
  const {show_menu, root, links, user, language_links, destination_id, destination_link, change_destination_link, product_link, logout_link, back_link} = props.menu_props
  const {i18n} = useTranslation()

  const BackLink = styled.a`
    cursor: pointer;
    color: black;

    &:hover {
      color: var(--primary);
    }
  `

  if(!show_menu) {
    return ''
  }

  return (
    <AppProvider user={user}>
      <div className="container">
        <div className="mt-2 d-flex flex-column flex-md-row justify-content-md-between">
          <div className="align-self-center d-flex flex-column flex-sm-row justify-content-center">
            {back_link &&
              <div className="justify-content-center text-center mb-2 mb-sm-0">
                <BackLink href={back_link} className="mr-0 mr-sm-5 text-center"><i className="fa fa-arrow-left mr-2"/>{i18n.t('header_iframe.back_to_home')}</BackLink>
              </div>
            }
            <div>
              <DestinationSelector url={destination_link} destination_id={destination_id} change_destination_link={change_destination_link} iframe className="mr-4 align-self-center"/>
              <LanguageSwitcher en es fr urls={language_links}/>
            </div>
          </div>
          <div className="justify-content-center pt-2 pt-md-0 mt-md-0 search-box-content">
            <SearchBox productLink={product_link} user={user}/>
          </div>
        </div>
      </div>
    </AppProvider>
  )
}

export default HeaderIframe
