import EventModule from 'containers/purchase/modules/event_module/event_module'
import DayModule from 'containers/purchase/modules/day_module/day_module'
import TicketTypesModule from 'containers/purchase/modules/ticket_types_module/ticket_types_module'
import TicketTypesSelectorModule from 'containers/purchase/modules/ticket_types_module/ticket_types_selector_module'
import CustomFieldsModule from 'containers/purchase/modules/fh_custom_fields_module/custom_fields_module'
import ContactModule from 'containers/purchase/modules/contact_module/contact_module'
import eventState from 'containers/purchase/modules/event_module/event_state'
import dayState from 'containers/purchase/modules/day_module/day_state'
import ticketTypesState from 'containers/purchase/modules/ticket_types_module/ticket_types_state'
import customFieldsState from 'containers/purchase/modules/fh_custom_fields_module/custom_fields_state'
import contactState from 'containers/purchase/modules/contact_module/contact_state'
import VisitorsModule from 'containers/purchase/modules/visitors_module/visitors_module'
import visitorsState from 'containers/purchase/modules/visitors_module/visitors_state'
import {omit} from 'lodash'

const reservationMapper = (ctx) => {
  return {
    event_id: ctx.event.id,
    ticket_types: ctx.tickets,
    customers: ctx.visitors,
    params: {
      custom_fields: ctx.customFields,
      custom_field_values: ctx.customFieldValues,
      contact_values: ctx.contactValues
    }
  }
}

const config = (params) => {
  const {external_api_config} = params
  const eventPickMode = external_api_config && external_api_config.event_pick_mode
  const visitorsRequired = external_api_config && external_api_config.visitorsRequired
  const mode = external_api_config && external_api_config.mode

  let steps = {}

  if (eventPickMode == 'day') {
    steps.dayStep = {
      component: DayModule,
      autoNext: true,
      states: dayState,
      context: {
        year_month: params.initialYearMonth,
        event_groups: [],
        event: null
      }
    }
  } else {
    steps.eventStep = {
      component: EventModule,
      autoNext: true,
      states: eventState,
      context: {
        year_month: params.initialYearMonth,
        // day: params.initialDate,
        event_groups: [],
        event: null
      }
    }
  }

  steps.ticketsStep = {
    component: mode == 'only_with_extended_options' ? TicketTypesSelectorModule : TicketTypesModule,
    autoNext: false,
    states: ticketTypesState,
    context: {
      tickets: []
    }
  }

  steps.customFieldsStep = {
    component: CustomFieldsModule,
    autoNext: false,
    states: customFieldsState,
    context: {
      customFields: [],
      customFieldValues: []
    }
  }

  if (visitorsRequired) {
    steps.visitorsStep = {
      component: VisitorsModule,
      autoNext: false,
      states: visitorsState,
      context: {
        visitors: []
      }
    }
  }

  steps.contactStep = {
    component: ContactModule,
    autoNext: false,
    states: contactState,
    context: {
      contactValues: []
    }
  }

  if (params.external_api_config && params.external_api_config.disable_custom_fields == true) {
    steps = omit(steps, 'customFieldsStep')
  }

  return  {
    reservationMapper: reservationMapper,
    steps: steps,
    params: params
  }
}

export default config
