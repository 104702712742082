import URI from 'urijs'

export default class ApiFetcher {
  constructor(options) {
    const defaults = {
      key: '',
      base_url: '',
      endpoint: '/',
      method: 'GET',
      query: {},
      headers: {},
      body: null,
      credentials: 'include',
      cache: 'default'
    }

    let opts = Object.assign({}, defaults, options)

    Object.keys(defaults).forEach(prop => {
      this[prop] = opts[prop]
    })

    this.addMandatoryHeaders()
  }

  call() {
    let url = new URI(this.endpoint).search(this.query)
    let request = new Request(url, this)

    return fetch(request)
      .then((response) => {
        return response.json()
          .then((json) => {
            if (response.ok) {
              return Promise.resolve(json)
            }
            return Promise.reject(json)
          })
      })
  }

  addMandatoryHeaders() {
    const token = document.querySelector('meta[name=csrf-token]').getAttribute('content')
    this.headers['X-CSRF-Token'] = token
    this.headers['Content-Type'] = 'application/json'
  }
}
