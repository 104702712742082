import React from 'react'
import {ModulePanel, ModuleHeader, ModuleBody, ModuleFooter} from 'containers/purchase/modules/module_panel'
import { useTranslation } from 'react-i18next'
import TransferServiceForm from './transfer_service_form'

export const TransferServiceModule = (props) => {
  const {config, machine, status} = props
  const [current, send] = machine
  const { t, i18n } = useTranslation()

  const setForm = (form) => {
    send({type: 'TRANSFER_SERVICE_FORM_CHANGE', form: form})
  }

  return (
    <ModulePanel>
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send('REOPEN_TRANSFERSERVICESTEP') : null}>
        <span>
          <i className="fa fa-users mr-2" aria-hidden="true"/>
          {props.title}
        </span>
      </ModuleHeader>
      <ModuleBody status={status}>
        <TransferServiceForm step={props.formName} setForm={setForm} config={config} machine={machine}/>
      </ModuleBody>
      <ModuleFooter {...props}/>
    </ModulePanel>
  )
}

export default TransferServiceModule
