import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import {useToasts} from 'react-toast-notifications'
import {Row, Col, Card as CardBase, CardImg, CardBody, CardSubtitle, CardFooter} from 'reactstrap'
import addFavorite from 'lib/actions/add_favorite'
import removeFavorite from 'lib/actions/remove_favorite'
import thumbnailNotAvailable from 'img/image-not-available.png'
import Modal from 'ui/modal'

const Wrapper = styled.div.attrs({
  className: 'col card-wrapper px-2 mb-3'
})``

const Card = styled(CardBase)`
  transition: 1s;
`

const Badge = styled.div.attrs({
  className: 'px-2 shadow rounded'
})`
  position: absolute;
  top: 0em;
  left: 0em;
  opacity: 1;
  z-index: 1;
  font-weight: bold;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
`

const BestSellerBadge = styled(Badge).attrs({
  className: 'text-white bg-success'
})``

const Thumbnail = styled(CardImg).attrs({
  className: ''
})`
  ${props => props.status == 'active' &&`
    cursor: pointer;
    transition: 0.3s;
    opacity: 1;

    &:hover {
      -webkit-transform: scale(1.01);
      transform: scale(1.01);
    }
  `}

  ${props => props.status != 'active' &&`
    filter: grayscale(100%);
    opacity: 0.8;
  `}
`

const ProductTitle = styled.h5.attrs({
  className: 'mb-2'
})`
  font-size: 1.05rem;

  a {
    text-decoration: none;
    color: var(--secondary);
    transition: .5s ease;

    &:hover {
      color: var(--primary);
    }
  }
`

const ProductFooter = styled(CardFooter).attrs({
  className: 'pt-1 pb-2 px-3 border border-0'
})`
  transition: .5s ease;
`

const Icon = styled.i.attrs()`
  cursor: pointer;
  color: var(--primary);
  font-size: 1.05rem;
  transition: .5s ease;

  &:hover {
    color: var(--secondary)
  }
`

const FeaturedPrice = styled.span.attrs()`
  cursor: pointer;
  transition: .3s ease;
  border: 0px;
  border-radius: 0.15em;

  strong {
    font-size: 1.27em;
  }

  &:hover {
    color: var(--primary);
  }
`

const FavButton = styled(Icon).attrs({
  className: 'fa fa-heart mr-1'
})`
  ${props => props.active &&`
    color: var(--red);
  `}
`

const TemplateButton = styled(Icon).attrs({
  className: 'fa fa-info-circle'
})`
`

const NotificationButton = styled(Icon).attrs({
  className: 'fa fa-exclamation-triangle mr-1'
})``

const EvoucherButton = styled(Icon).attrs({
  className: 'fa fa-mobile'
})`
  padding: 0 0.27em;
  font-size: 1.1em;
`

const VariantsModal = (props) => {
  const {product, modal, toggle, urls} = props
  const {variants} = product
  const {i18n} = useTranslation()

  if (!variants) { return '' }

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      header={i18n.t('variants_modal.title')}
      closeOverlay={true}
    >
      <Row className={cn(['row row-cols-1 row-cols-md-2 row-cols-lg-3 mx-0', variants.length > 3 ? 'justify-content-start' : 'justify-content-center'])}>
        {variants.map((variant) => {
          return (
            <Col>
              <ProductCard {...variant} url={urls.service.replace(/ *\:[^)]*\: */g, variant.slug)}/>
            </Col>
          )
        })}
      </Row>
    </Modal>
  )
}

const ProductCard = (props) => {
  const {id, has_variants, thumbnail, title, state, state_label, city, city_label, status, featured_price, featured_price_label, notification, evoucher, url, template_link, best_seller, height, urls} = props
  const [isHover, setIsHover] = useState(false)
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const {i18n} = useTranslation()
  const {addToast} = useToasts()
  // const productInfoLink = template_link + '&language=' + languageWithCountry(i18n.language)

  useEffect(() => {
    if (modal != true) { return }

    console.log('Carga datos')
  }, [modal])

  const onProductClick = (e) => {
    e.preventDefault()

    if (status != 'active') { return '' }

    if (has_variants) {
      setModal(true)
    } else {
      window.location.href = url;
    }
  }

  const ProductLink = () => {
    if (status != 'active') {
      return <a>{title}</a>
    }

    if (has_variants) {
      return <a onClick={(e) => {
        e.preventDefault()
        setModal(true)
      }} href={''}>{title}</a>
    }

    return <a href={url}>{title}</a>
  }

  const [favorite, setFavorite] = useState(props.favorite)

  const onAddFavorite = () => {
    setFavorite(true)
    addToast(i18n.t('product_card.added_to_favorites_toast'), {appearance: 'success', autoDismiss: true})
  }

  const onRemoveFavorite = () => {
    setFavorite(false)
    addToast(i18n.t('product_card.removed_from_favorites_toast'), {appearance: 'info', autoDismiss: true})
  }

  const CardContent = () => (
    <Card onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} className={cn(
      ['h-100', isHover ? 'shadow' : 'shadow-sm']
    )}>
      {best_seller &&
        <BestSellerBadge>{i18n.t('product_card.best_seller_badge')}</BestSellerBadge>
      }
      <Thumbnail
        top
        width="100%"
        status={status}
        src={thumbnail ? thumbnail : thumbnailNotAvailable}
        onClick={onProductClick}
      />
      <CardBody className="px-3 pt-3 pb-1">
        <ProductTitle>
          <ProductLink/>
        </ProductTitle>
        <CardSubtitle className="h6 mb-2 text-muted"><small>{city_label}</small></CardSubtitle>
      </CardBody>
      <ProductFooter className={isHover ? 'bg-light': 'bg-white'}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="btn-group">
            <FavButton
              active={favorite}
              title={favorite ? i18n.t('product_card.remove_fav') : i18n.t('product_card.add_tooltip')}
              onClick={() => favorite ? removeFavorite(id, onRemoveFavorite) : addFavorite(id, onAddFavorite)}
            />
            {template_link &&
              <TemplateButton
                title={i18n.t('product_card.template_link_tooltip')}
                onClick={() => window.open(template_link)}
              />
            }
            {notification &&
              <NotificationButton title="notification"/>
            }
            {evoucher &&
              <EvoucherButton title={ i18n.t('product_card.evoucher_tooltip') }/>
            }
          </div>
          <div className={isHover ? 'text-primary' : 'text-secondary'}>
            {status == 'active' ? (
              featured_price ? (
                <FeaturedPrice onClick={() => status == 'active' ? (window.location = url) : ('')}>
                  {i18n.t('product_card.from')} <strong className="h3">{featured_price_label}</strong>
                </FeaturedPrice>
              ) : (
                <strong className="h3">{i18n.t('product_card.no_feature_price_label')}</strong>
              )
            ) : (
              <div className="py-2">
                <i className="text-muted">
                  {i18n.t('product_card.inactive')}
                </i>
              </div>
            )}
          </div>
        </div>
      </ProductFooter>
    </Card>
  )

  if (props.hideWrapper) {
    return <CardContent/>
  }

  return  (
    <Wrapper>
      <VariantsModal product={props} modal={modal} toggle={toggle} urls={urls}/>
      <CardContent/>
    </Wrapper>
  )
}

export default ProductCard
