import React, {useState, useEffect} from 'react'
import {FormGroup, Label, FormFeedback, Button, ButtonGroup } from 'reactstrap'
import {useField, useFormikContext } from 'formik'
import {uniq} from 'lodash'

const RadioButtons = ({ label, ...props }) => {
  const name = (props.field && props.field.name) || props.name
  const [field, meta] = useField(name)
  const {setFieldValue} = useFormikContext()

  const onClick = (e) => {
    const tag = e.target.value

    let values = field.value

    if (field.value.includes(tag)) {
      values = field.value.filter((c) => c != tag)
    } else {
      values = uniq(field.value.concat([tag]))
    }

    setFieldValue(field.name, values)
  }

  // useEffect(() => {
  //   setFieldValue(field.name, [''])
  // }, [])

  return (
    <FormGroup className={ meta.touched && meta.error ? 'has-error' : '' }>
      <Label className="control-label text-center w-100" htmlFor={props.id || props.name}>{label}</Label>
      <div className='clearfix'></div>

      <ButtonGroup className="btn-block">
        {props.values.map(({label, value}) =>
          <Button
            color="primary text-white"
            active={field.value.includes(value) ? true : false}
            key={value}
            value={value}
            onClick={onClick}
          >
            {field.value.includes(value) &&
              <i className="fa fa-check-square mr-1" aria-hidden="true"></i>
            }
            {label}
          </Button>
        )}
      </ButtonGroup>

      {meta.touched && meta.error ? (
        <FormFeedback className={'invalid-feedback d-block'}>{meta.error}</FormFeedback>
      ) : null}
    </FormGroup>
  )
}

export default RadioButtons
