import {useState, useEffect} from 'react'

const useLayout = () => {
  const [layout, setLayout] = useState('b2c')

  useEffect(() => {
    const receiveMessage = (e) => {
      if (e.data[0] == 'layout') {
        setLayout(e.data[1])
      }
    }

    window.addEventListener('message', receiveMessage, false)
  }, [])

  return layout
}

export default useLayout
