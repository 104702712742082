import RouteModule from 'containers/purchase/modules/route_module/route_module'
import routeState from 'containers/purchase/modules/route_module/route_state'
import TransferServiceModule from 'containers/purchase/modules/transfer_service_module/transfer_service_module'
import transferServiceState from 'containers/purchase/modules/transfer_service_module/transfer_service_state'
import QuotationsModule from 'containers/purchase/modules/quotations_module/quotations_module'
import quotationsState from 'containers/purchase/modules/quotations_module/quotations_state'
import ContactModule from 'containers/purchase/modules/contact_module/contact_module'
import contactState from 'containers/purchase/modules/contact_module/contact_state'
import i18n from 'i18n/config'

const reservationMapper = (ctx) => {
  return {
    event_id: 'SV20220720',
    ticket_types: [],
    params: {
      quotation: ctx.quotation,
      contact_values: ctx.contactValues,
      pickup: ctx.pickup_values,
      dropoff: ctx.dropoff_values,
      transfer_service: ctx.transfer_service_values
    }
  }
}

const config = (params) => {
  const {external_api_config} = params

  let steps = {}

  steps.pickupStep = {
    component: RouteModule,
    componentProps: {
      title: i18n.t('servantrip_machine_config.pickup_step_module_title'),
      formName: 'pickup_form',
    },
    currentStep: 'pickup',
    target: '#purchase.dropoffStep',
    autoNext: false,
    states: routeState,
    context: {
      tickets: []
    }
  }

  steps.dropoffStep = {
    component: RouteModule,
    componentProps: {
      title: i18n.t('servantrip_machine_config.dropoff_step_module_title'),
      formName: 'dropoff_form',
    },
    autoNext: false,
    currentStep: 'dropoff',
    target: '#purchase.transferServiceStep',
    states: routeState,
    context: {
      tickets: []
    }
  }

  steps.transferServiceStep = {
    component: TransferServiceModule,
    componentProps: {
      title: i18n.t('servantrip_machine_config.transfer_service_step_module_title'),
    },
    currentStep: 'transferService',
    target: '#purchase.quotationsStep',
    autoNext: false,
    states: transferServiceState,
    context: {
      tickets: []
    }
  }

  steps.quotationsStep = {
    component: QuotationsModule,
    autoNext: false,
    states: quotationsState,
    context: {
      quotation: null
    }
  }

  steps.contactRequired = {
    component: ContactModule,
    autoNext: false,
    states: contactState,
    context: {
      contactValues: []
    }
  }

  return {
    reservationMapper: reservationMapper,
    steps: steps,
    params: params,
  }
}

export default config
