import React from 'react'
import { FormGroup, Label, FormFeedback, Button, ButtonGroup } from 'reactstrap'
import { useField, useFormikContext } from 'formik'
import cn from 'classnames'

const RadioButtons = ({ label, ...props }) => {
  const name = (props.field && props.field.name) || props.name
  const [field, meta] = useField(name)
  const {setFieldValue} = useFormikContext()

  return (
    <FormGroup className={ meta.touched && meta.error ? 'has-error' : '' }>
      <Label className='control-label' htmlFor={props.id || props.name}>{label}</Label>
      <div className='clearfix'></div>

      <ButtonGroup className={props.btnBlock ? 'btn-block' : ''}>
        {props.values.map(({label, value}) =>
          <Button
            color="info"
            active={value == field.value}
            key={value}
            onClick={() => setFieldValue(field.name, value)}
          >
            <i className={cn('fa', 'mr-2', value == field.value ? 'fa-dot-circle' : 'fa-circle')}/>{label}
          </Button>
        )}
      </ButtonGroup>

      {meta.touched && meta.error ? (
        <FormFeedback className={'invalid-feedback d-block'}>{meta.error}</FormFeedback>
      ) : null}
    </FormGroup>
  )
}

export default RadioButtons
