// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'i18n/config'
import 'bootstrap/dist/js/bootstrap'
import 'stylesheets/application'

// function getMeta(metaName) {
//   const metas = document.getElementsByTagName('meta')
//
//   for (let i = 0; i < metas.length; i++) {
//     if (metas[i].getAttribute('name') === metaName) {
//       return metas[i].getAttribute('content')
//     }
//   }
//
//   return ''
// }
//
// var theme = getMeta('theme-slug')

// if (theme && theme != 'ticketinhotel') {
//   import(`stylesheets/themes/${theme}/bootstrap.scss`).catch(() => console.log(theme + ': THEME NOT INSTALLED'))
// }

require('@rails/ujs').start()
// require("turbolinks").start()
require('@rails/activestorage').start()
require('channels')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context('containers', true)
var ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)
