import React from 'react'
import {ModulePanel, ModuleHeader, ModuleBody, ModuleFooter} from 'containers/purchase/modules/module_panel'
import {ListGroup, ListGroupItem} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import {currency} from 'i18n/config'
import {get} from 'lodash'
import parse from 'react-html-parser'
import styled from 'styled-components'
import cn from 'classnames'

const Styles = styled.div`
  align-self: center !important;

  input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: white;
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: var(--primary);
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid var(--primary);
    border-radius: 50%;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
  }

  input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--primary);
    /* Windows High Contrast Mode */
    background-color: red;
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }

  input[type="radio"]:focus {
    outline: max(2px, 0.15em) solid var(--primary);
    outline-offset: max(2px, 0.15em);
  }
`

const TourcmsComponentModule = (props) => {
  const {machine, status} = props
  const { t, i18n } = useTranslation()
  const [current, send] = machine
  const { tickets, components, component } = current.context

  const isFetching = current.matches({tourcmsComponentStep: 'fetchingComponents'}) ? true : false

  const onComponentSeleted = (component) => send({type: 'COMPONENT_SELECTION', component: component})

  const ComponentSelect = () => {
    const componentsList = Array.isArray(components) ? components : [components]

    if (!components || components.length <= 0) {
      return (
        <div className="alert alert-warning">
          {i18n.t('tourcms_component_module.no_availability')}
        </div>
      )
    }

    return (
      <ListGroup>
        {componentsList.map((c) => {
          const {component_key, date_code, note, special_offer_note, total_price, price_breakdown} = c

          const price = get(price_breakdown, 'price_row')

          let priceList = []
          if (Array.isArray(price)) {
            priceList = price
          } else {
            priceList.push(price)
          }

          return (
            <ListGroupItem
              key={component_key}
              className={cn(
                'rounded-0 d-flex justify-content-between flex-column flex-md-row',

              )}
              style={{cursor: 'pointer', background: component.component_key == component_key ? '#ffeeba' : ''}}
              onClick={() => onComponentSeleted(c)}
            >
              <div className={'p-2 align-self-center text-center text-md-left'}>
                <div className="font-weight-bold">
                  {date_code || note ? (
                    <div>{date_code ? `${date_code} - ` : ''} {note}</div>
                  ) : (
                    <div>{special_offer_note}</div>
                  )}
                </div>
                <div>
                  {priceList.map((p) => (
                    <span key={p['rate-id']} className="mr-3">{parse(p.text)}</span>
                  ))}
                </div>
              </div>
              <div className={'d-flex justify-content-end align-self-center flex-column flex-sm-row'}>
                <div className="price text-right p-2 mr-5" style={{fontSize: '18px', width: '200'}}>
                  {i18n.t('tourcms_component_module.total_price', {price: currency(total_price)})}
                </div>
                <Styles>
                  <input
                    type="radio"
                    checked={component.component_key == component_key}
                    name="component_key"
                    value={component_key}
                  />
                </Styles>
              </div>
            </ListGroupItem>
          )})}
      </ListGroup>
    )
  }

  return (
    <ModulePanel>
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send('REOPEN_SUPPLEMENTSSTEP') : null}>
        <span>
          <i className="fa fa-star mr-2" aria-hidden="true"></i>
          {i18n.t('tourcms_component_module.select_a_modality')}
        </span>
      </ModuleHeader>
      <ModuleBody status={status}>
        {isFetching ? (
          <div className="alert alert-info" role="alert">
            <i className="far fa-clock mr-2" aria-hidden="true"></i>
            {i18n.t('tourcms_component_module.searching_modalities')}
          </div>
        ) : (
          <React.Fragment>
            {components &&
              <ComponentSelect/>
            }

            {!components &&
              <div className="alert alert-info" role="alert">
                <i className="fa fa-warning mr-2" aria-hidden="true"></i>
                {i18n.t('tourcms_component_module.no_components')}
              </div>
            }
          </React.Fragment>
        )}
      </ModuleBody>
      <ModuleFooter {...props}/>
    </ModulePanel>
  )
}

export default TourcmsComponentModule
