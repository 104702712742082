import { assign } from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import {nextStep} from 'utils/step_utils'
import {EUR} from 'utils/currency'
import i18n from 'i18n/config'

const cleanOutbound = assign({outbound: (ctx, e) => null})
const cleanInbound = assign({inbound: (ctx, e) => null})
const setOutboundTab = assign({activeTab: (ctx, e) => 'outbound'})
const setActiveTab = assign({activeTab: (ctx, e) => e.tab})
const setOutbound = assign({outbound: (ctx, e) => e.outbound})
const setInbound = assign({inbound: (ctx, e) => e.inbound})
const setTickets = assign({tickets: (ctx, e) => {
  const {availability, outbound, inbound} = ctx

  let ticketTypes = []

  const mapTickets = (route, direction) => {
    return availability.offer.passengers.map((passenger, i) => {
      const passenger_type = availability.passenger_types.find((pt) => pt.code == passenger.type)
      const fare = route.product.items[0].passenger_fares[i]
      const product = availability.products.find((p) => p.code == fare.product_code)

      let ticket = {
        id: `${passenger.id}-${passenger_type.code}-${fare.product_code}-${direction}`,
        name: `${passenger_type.description} ${product.name}`,
        base_price: fare.price,
        discount: 0,
        additional_fee: 0,
        total_price: fare.price,
        qty: 1
      }

      if (direction == 'inbound') {
        ticket.params = {
          disable_visitor: true
        }
      }

      return ticket
    })
  }

  if (outbound) {
    ticketTypes = [...ticketTypes, ...mapTickets(outbound, 'outbound')]
  }

  if (inbound) {
    ticketTypes = [...ticketTypes, ...mapTickets(inbound, 'inbound')]
  }

  return ticketTypes
}})

const trainState = (steps, params, step) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {'': {
          target: 'waitingPick',
          actions: [setOutboundTab, cleanOutbound, cleanInbound]
        }},
      },
      waitingPick: {
      }
    },
    on: {
      OUTBOUND_CHANGE: {
        actions: [setOutbound]
      },
      INBOUND_CHANGE: {
        actions: [setInbound]
      },
      TAB_CHANGE: {
        actions: [setActiveTab]
      },
      CONFIRMATION: {
        target: '#purchase.visitorsStep',
        actions: [setTickets]
      }
    }
  }
}

export default trainState
