import React from 'react'
import {ModulePanel, ModuleHeader, ModuleBody, ModuleFooter} from 'containers/purchase/modules/module_panel'
import CustomFieldsForm from 'containers/purchase/modules/custom_fields_module/custom_fields_form'
import { useTranslation } from 'react-i18next'
import {get} from 'lodash'

export const CustomFieldsModule = (props) => {
  const {config, machine, status} = props
  const { t, i18n } = useTranslation()
  const [current, send] = machine
  const event = current.context.event

  const eventCustomFields = (customFields) => {
    if (!event) { return customFields }

    const eventCustomFieldIds = get(config, 'external_api_config.event_custom_fields.' + event.id)
    if (!eventCustomFieldIds) { return customFields }

    return customFields.filter((customField) => eventCustomFieldIds.some((id) => id == customField.id))
  }

  const customFields = eventCustomFields(current.context.customFields)

  const capitalize = (s) => s.substr(0, 1).toUpperCase() + s.substr(1).toLowerCase()
  const moduleTitle = capitalize(config.product.custom_field_block)

  const setForm = (form) => {
    send({type: 'FAREHARBOR_FORM_CHANGE', form: form})
  }

  return (
    <ModulePanel>
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send('REOPEN_CUSTOMFIELDSSTEP') : null}>
        <span>
          <i className="fa fa-question-circle mr-2" aria-hidden="true"></i>
          {moduleTitle}
        </span>
      </ModuleHeader>
      <ModuleBody status={status}>
        <CustomFieldsForm customFields={customFields} setForm={setForm} machine={machine}/>
      </ModuleBody>
      <ModuleFooter {...props}/>
    </ModulePanel>
  )
}

export default CustomFieldsModule
