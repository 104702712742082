import EventModule from 'containers/purchase/modules/event_module/event_module'
import JantoIframeModule from 'containers/purchase/modules/janto_iframe_module/janto_iframe_module'
import eventState from 'containers/purchase/modules/event_module/event_state'
import iframeState from 'containers/purchase/modules/janto_iframe_module/iframe_state'

const reservationMapper = (ctx) => {
  return {
    event_id: ctx.event.id,
    ticket_types: ctx.tickets,
  }
}

const config = (params) => {
  return {
    reservationMapper: reservationMapper,
    steps: {
      eventStep: {
        component: EventModule,
        autoNext: true,
        states: eventState,
        context: {
          year_month: params.initialYearMonth,
          event_groups: [],
          event: null
        }
      },
      iframeStep: {
        component: JantoIframeModule,
        autoNext: false,
        states: iframeState,
        context: {
          iframeLink: null
        }
      }
    }
  }
}

export default config
