const currentStep = (state) => {
  if (typeof state === 'object' && state !== null) {
    return Object.keys(state)[0]
  } else {
    return state
  }
}

const stepConfig = (steps, state) => steps[currentStep(state)]

const nextStep = (steps, stepName) => {
  const stepNames = Object.keys(steps)
  const nextIndex = stepNames.findIndex((item) => item == stepName) + 1
  return stepNames[nextIndex]
}

const isLastStep = (steps, state) => Object.keys(steps).slice(-1)[0] == currentStep(state)

const stepStatus = (steps, needleStep, machineStep) => {
  const stepNames = Object.keys(steps)
  const needleIndex = stepNames.findIndex((item) => item == needleStep)
  const machineIndex = stepNames.findIndex((item) => item == machineStep)

  if (machineIndex == -1) { return 'completed' }
  if (needleIndex == machineIndex) { return 'active' }
  if (needleIndex > machineIndex) { return 'pending' }
  if (needleIndex < machineIndex) { return 'completed' }
}

export {currentStep, stepConfig, isLastStep, nextStep, stepStatus}
