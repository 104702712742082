import React from 'react'

const LegalWarning = (props) => {
  const {supportTelephone, site_name} = props
  const site_url = `www.${site_name.toLowerCase()}.es`

  return (
    <div>
      <p>
        Al utilizar esta página web, el Usuario reconoce haber leído y entendido todos y cada una de las condiciones de este contrato y acepta las mismas en todos sus términos. El uso del Servicio por parte del Usuario debe ser justo aceptando las condiciones del contrato. Ocio y Tickets, S.L. como propietario de esta web, se reserva el derecho de cambiar cualquier término o condición cuando le parezca conveniente. Estos cambios se considerarán aceptados por el Usuario si éste continua haciendo uso del Servicio pasadas las dos semanas desde la fecha de publicación de dichos cambios. Ocio y Tickets, S.L. no será responsable bajo ningún concepto y en ningún momento de los contenidos, fotografías y gráficos que aparecen en la Web, siendo su función informativa y la de un mero canal de comunicación.
      </p>

      <ul>
        <li>PROPIEDAD</li>
        <li>USO Y RESTRICCIONES</li>
        <li>RENUNCIA</li>
        <li>LIMITACIÓN DE RESPONSABILIDAD</li>
        <li>MODIFICACIONES</li>
        <li>RESERVA DE COOKIES</li>
        <li>PROTECCIÓN DE DATOS</li>
        <li>ACCIONES LEGALES, LEGISLACIÓN APLICABLE Y JURISDICCIÓN</li>
        <li>MISCELÁNEA</li>
      </ul>

      <h4>PROPIEDAD</h4>
      <p>
        En cumplimiento de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico, Ocio y Tickets, S.L. le informa de que es titular del website {site_url}. De acuerdo con la exigencia del artículo 10 de la citada Ley, Ocio y Tickets, S.L. informa de los siguientes datos: El titular de esta página Web es Ocio y Tickets, S.L., con CIF B-66349986 y domicilio social en Carrer de Trafalgar, 6, 08010 (Planta 3º - Despacho 34)
        Barcelona, inscrita en el R.M. de Barcelona, Folio 0015, Tomo 44450, Sección GENERAL. La dirección de correo electrónico de contacto con la empresa es info@ociobusiness.es . {site_url} es una marca registrada y propiedad de Ocio y Tickets, S.L. Esta página web es la única página web oficial de {site_url}, la cual está diseñada, administrada y gestionada por el departamento de marketing de Ocio y Tickets, S.L. Todo el contenido que aparece en esta web, así como la información, imágenes, diseño gráfico, contenidos creativos y estéticos, índices y códigos fuente en cualesquiera de los tipos de lenguaje de programación utilizados, son una obra cuya propiedad intelectual pertenece a sus autores y está protegido por los derechos de autor o copyright ©. Asimismo, todas las descripciones, noticias, e informes de esta web, con la información que en ellos aparece, y que han sido cedidos por terceros, son propiedad de sus autores y también están protegidos por los derechos de autor y copyright ©. Queda prohibida la reproducción, imitación, modificación, distribución y/o transformación de los contenidos de esta web y cualesquiera otros actos que contravinieren los derechos de autor y de propiedad industrial. La información de esta web sólo podrá ser utilizada por aquellas personas expresamente autorizadas para ello por sus autores. Toda vulneración de los derechos de autor y de propiedad industrial contenidos en esta web, así como la infracción de la normativa que los protege establecida en el Texto Refundido de la Ley de Propiedad Intelectual, legislación referente a la protección de patentes y marcas, y cualesquiera otras normas nacionales y comunitarias, conllevará las responsabilidades civiles y/o penales que en cada caso correspondan.
      </p>

      <h4>USO Y RESTRICCIONES</h4>
      <p>
        Usted tendrá derecho a descargarse los materiales que contenga esta página web a un ordenador personal individual y a hacer impresiones de los materiales que se contengan en esta página web, únicamente para su uso personal. El uso en otras direcciones de Internet de los materiales incluidos en esta página web queda terminantemente prohibido, salvo por autorización expresa de {site_url}.
      </p>

      <h4>RENUNCIA</h4>
      <p>
        Esta página web y sus contenidos son proporcionados sin ninguna declaración o garantías de cualquier tipo, ya sean expresas o implícitas. {site_url} rechaza cualesquiera declaraciones o garantías, incluyendo a modo de ejemplo pero no limitándose a la aptitud para un determinado propósito, en la medida permitida por las leyes aplicables. Además, {site_url} no declara o garantiza que la información y/o los recursos accesibles a través de la página web son exactos, completos o actuales, o que esta página web está libre de defectos, incluyendo pero no limitándose a virus u otros elementos perjudiciales ni se hace responsable del contenido de las páginas web a las que el usuario pueda acceder a través de los enlaces establecidos en su website y declara que en ningún caso procederá a examinar o ejercitar ningún tipo de control sobre el contenido de otras páginas de la red. {site_url} declara haber adoptado todas las medidas necesarias para evitar cualesquiera daños que, a los usuarios de su website, pudieran derivarse de la navegación por sus páginas web. En consecuencia, Ocio y Tickets, S.L. no se hace responsable, en ningún caso, de los eventuales daños que por la navegación por Internet pudiera sufrir el usuario. Usted soportará todos los costes que resulten de la utilización de esta página web.
      </p>

      <h4>LIMITACION DE RESPONSABILIDAD</h4>
      <p>
        En la medida permitida por las leyes aplicables, ni Ocio y Tickets, S.L. ni cualquiera de sus respectivos directores, empleados, afiliados u otros representantes o distribuidores, serán responsables por pérdidas o daños derivados de o en relación con el uso de, o imposibilidad de uso, de los materiales y/o recursos o servicios ofrecidos a través de esta página web, incluyendo pero no limitándose a pérdidas o daños indirectos, especiales o derivados, pérdida de información, ingresos, beneficios u oportunidades, pérdidas o daños a la propiedad y demandas de terceros. Si esta cláusula no se puede ejecutar en todo o en parte en cualquier jurisdicción debido a sus respectivas leyes, entonces y en la medida permitida por dichas leyes la responsabilidad de Ocio y Tickets, S.L. por las pérdidas, daños y demandas (ya fueren contractuales, extracontractuales -incluyendo pero no limitándose a negligencia- o cualquier otro) no excederá en ningún caso de la cantidad pagada por usted para el acceso a la página web.
      </p>

      <h4>MODIFICACIONES</h4>
      <p>
        Ocio y Tickets, S.L.. se reserva el derecho a realizar las modificaciones que considere oportunas sin aviso previo, en el contenido de su website. Tanto en lo referente a los contenidos de la website, como en las condiciones de usos de las misma o en las condiciones generales de contratación. Dichas modificaciones podrán realizarse, a través de su website, de cualquier forma admisible en derecho y serán de obligado cumplimiento durante el tiempo en que se encuentren publicadas en la web y hasta que no sean modificadas válidamente por otras posteriores.
      </p>

      <h4>RESERVA DE COOKIES</h4>
      <p>
        Ocio y Tickets, S.L. se reserva el derecho de utilizar cookies en la navegación del usuario por su website para facilitar la personalización y comodidad de la navegación. Siguiendo la política de protección de datos de la empresa, Ocio y Tickets, S.L. informa que las cookies se asocian al usuario anónimo y a su ordenador, y no proporcionan por sí el nombre y apellidos del usuario. El usuario tiene la posibilidad de configurar su navegador de modo que se le informe de la recepción de cookies, pudiendo, si así lo desea, impedir que sean instaladas en su disco duro. No obstante, para el acceso a la website de Ocio y Tickets, S.L. no será preceptiva la instalación de cookies.
      </p>

      <h4>PROTECCIÓN DE DATOS</h4>
      <p>
        {site_url} garantiza la seguridad y confidencialidad de los datos facilitados por el Usuario. Estos datos serán incorporados a un fichero automatizado propiedad de Ocio y Tickets, S.L. con el fin de facilitar la prestación del Servicio y enviarle en el futuro ofertas comerciales sobre productos y servicios que puedan resultar de su interés. Si el Usuario es una persona física podrá ejercitar los derechos de acceso, rectificación y cancelación de los datos contenidos en dicho fichero en los términos reconocidos en la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal y demás normativa vigente en España, escribiendo a nuestra dirección de correo electrónico info@ociobusiness.es . Mientras no nos comunique lo contrario, entendemos que sus datos no han sido modificados, que usted se compromete a notificarnos cualquier variación y que tenemos el consentimiento para tratarlos para las finalidades establecidas en estás Condiciones de Uso.
      </p>

      <h4>ACCIONES LEGALES, LEGISLACIÓN APLICABLE Y JURISDICCIÓN</h4>
      <p>
        Ocio y Tickets, S.L. se reserva asimismo la facultad de presentar las acciones civiles o penales que considere oportunas por la utilización indebida de sus páginas web y contenidos o por el incumplimiento de las presentes condiciones. La relación entre el usuario y Ocio y Tickets, S.L. se regirá por la normativa española vigente y serán competentes para decidir sobre cualquier controversia que se pudiera suscitar entre el usuario y Ocio y Tickets, S.L., los juzgados o tribunales propios de la localidad de Barcelona.
      </p>

      <h4>MISCELÁNEA</h4>
      <p>
        Cualquier renuncia a una disposición de estas condiciones deberá ser por escrita y firmada por Ocio y Tickets, S.L. para ser válida. Dicha renuncia no significará una renuncia continuada a la misma disposición en el futuro. Si alguno de los tribunales competentes encuentra alguna disposición de estas condiciones inválida o inejecutable por cualquier razón, la misma será ineficaz en los términos estimados por los tribunales pero sin afectar a la validez y ejecutabilidad de cualquiera de las restantes disposiciones. Estas condiciones constituyen el acuerdo total y completo entre las partes con relación al objeto de las presentes condiciones y prevalece sobre cualquier previa declaración, consenso o acuerdos ya fueren orales o escritos y serán únicamente modificados por escrito, firmados por Usted y Ocio y Tickets, S.L.
      </p>


      <p><strong>Ocio y Tickets, S.L.</strong></p>
      <p>Carrer de Trafalgar, 6</p>
      <p>08010 Barcelona</p>
      <p>Planta 3º - Despacho 34)</p>

      <p><strong>Tel. Atención al cliente:</strong></p>
      <p>{supportTelephone}</p>
      <p>
        Esta página web es la única página web oficial de {site_url} y es propiedad de Ocio y Tickets, S.L. Todos los derechos quedan reservados. Ninguna reproducción, adaptación, distribución o transmisión comercial de cualquier parte o partes de esta página web o de cualquier información contenida en la misma por cualquier medio está permitida sin el previo consentimiento por escrito de Ocio y Tickets, S.L.
      </p>

      <p><strong>{site_url}</strong></p>
      <p><strong>Todos los derechos quedan reservados.</strong></p>
    </div>
  )
}

export default LegalWarning
