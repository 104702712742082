import React from 'react'

const WeatherWidget = (props) => {
  const {destination, locale} = props

  const destinationsMap = {
    barcelona: 'barcelona-id08019',
    'costa-dorada': 'vila-seca-id43171',
    'costa-brava': 'figueres-id17066',
    madrid: 'madrid-id28079',
    sevilla: 'sevilla-id41091',
    tenerife: 'santa-cruz-de-tenerife-id38038',
    valencia: 'valencia-id46250',
    malaga: 'malaga-id29067',
    granada: 'granada-id18087'
  }

  if (!destinationsMap[destination]) {
    return ''
  }

  const iframeLink = 'https://www.aemet.es/'+ locale +'/eltiempo/prediccion/municipios/mostrarwidget/'+ destinationsMap[destination] +'?w=g3p01110000ohmffffffw384z161x4f86d9t95b6e9r1s4n1'

  return (
    <iframe width="100%" height="170" tabIndex="0" src={iframeLink} frameBorder="0" scrolling="no"></iframe>
  )
}

export default WeatherWidget
