import React, {useState, useEffect} from 'react'
import { FormGroup, Label, FormFeedback } from 'reactstrap'
import { useField, useFormikContext } from 'formik'
import cn from 'classnames'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'
import moment from 'moment'

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    display: block;
  }
`

const DatetimePicker = ({label, ...props }) => {
  const {name, machine, timezone} = props
  const [current] = machine
  const {event} = current.context

  const [field, meta] = useField(name || props.field.name)
  const {setFieldValue} = useFormikContext()

  // TODO: Solo usado en servantrip? Unificar con date_picker

  const releaseDate = () => {
    const now = moment()
    const remainder = 5 - (now.minute() % 5)
    return now.add(props.release || 0, 'hours').add(remainder, 'minutes').set('second', 0)
  }

  const [selected, setSelected] = useState(props.initialDate || releaseDate().toDate())

  const filterPassedTime = (time) => {
    let selectedCopy = new Date(selected.valueOf())
    selectedCopy.setHours(time.getHours(), time.getMinutes(), time.getSeconds())

    return releaseDate().toDate() < selectedCopy
  }

  useEffect(() => {
    registerLocale('es', es)
  }, [])

  useEffect(() => {
    setFieldValue(field.name, moment.tz(moment(selected).format('YYYY-MM-DD HH:mm'), timezone).format())
  }, [selected])

  return (
    <FormGroup>
      <DatePickerWrapper>
        <Label className='control-label' htmlFor={props.id || props.name}>
          {label}
          {props.required &&
            <span className="text-danger ml-1">*</span>
          }
        </Label>
        <ReactDatePicker
          className={
            cn([
              'form-control',
              meta.touched && meta.error ? 'is-invalid' : '',
              meta.touched && !meta.error ? 'is-valid' : ''
            ])
          }
          locale="es"
          selected={ selected }
          onChange={(date) => setSelected(date)}
          dateFormat="Pp"
          timeIntervals={5}
          filterTime={filterPassedTime}
          minDate={releaseDate().toDate()}
          showTimeSelect
          autoComplete={false}
          {...props}
        />
      </DatePickerWrapper>
    </FormGroup>
  )
}

export default DatetimePicker
