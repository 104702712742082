import React from 'react'
import {ModulePanel, ModuleHeader, ModuleBody, ModuleFooter} from 'containers/purchase/modules/module_panel'
import LodginsList from './lodgins_list'
import { useTranslation } from 'react-i18next'
import time from 'i18n/time'

export const LodginModule = (props) => {
  const {machine, status} = props
  const [current, send] = machine
  const { event, lodgins, lodgin, pickup } = current.context
  const { t, i18n } = useTranslation()

  return (
    <ModulePanel>
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send('REOPEN_LCTPICKUPSTEP') : null}>
        <span>
          <i className="fa fa-map-marker mr-2" aria-hidden="true"></i>
          {status == 'completed' ? (
            i18n.t('lodgin_module.lodgin_selected', {lodgin: lodgin.name})
          ) : (
            i18n.t('lodgin_module.select_lodgin')
          )}
        </span>
      </ModuleHeader>
      <ModuleBody status={status}>
        <div className="alert alert-info">
          {i18n.t('lodgin_module.instructions')}
        </div>
        {lodgins && lodgins.length > 0 &&
          <LodginsList
            data={lodgins}
            onLodginSelected={(lodgin) => send({type: 'LODGIN_SELECTION', lodgin: lodgin})}
          />
        }
        {pickup &&
          <div className="mt-2 text-center">
            <strong className="mr-2">{i18n.t('lodgin_module.pickup_label')}:</strong>
            <span>{i18n.t('lodgin_module.pickup_info', {name: pickup.name, time: time(pickup.pickup_time).tz(event.local_timezone).format('HH:mm')})}</span>
          </div>
        }
      </ModuleBody>
      <ModuleFooter {...props}/>
    </ModulePanel>
  )
}

export default LodginModule
