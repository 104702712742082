import React, {useState} from 'react'
import Page from 'ui/page'
import {useTranslation} from 'react-i18next'
import {Card, CardHeader, CardBody, FormGroup, Input, Label, Button} from 'reactstrap'
import OrderModal from 'containers/reports/order_modal'
import ApiFetcher from 'lib/api_fetcher'
import {useToasts} from 'react-toast-notifications'

const RetrieveOrder = (props) => {
  const {urls} = props
  const {i18n} = useTranslation()

  const OrderForm = () => {
    const {addToast} = useToasts()
    const [orderId, setOrderId] = useState('')
    const [order, setOrder] = useState()
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)

    const orderFetcher = (code) => {
      PubSub.publish('loading-page')

      return (
        new ApiFetcher({
          key: 'cart',
          method: 'GET',
          endpoint: urls.order.replace(/ *\:[^)]*\: */g, code),
          query: {mode: 'json'}
        }).call()
          .then((res) => {
            if (res.success != false) {
              setOrder(res)
              toggle()
            } else {
              PubSub.publish('loaded-page')
              addToast(i18n.t('retrieve_order.incorrect_code'), {appearance: 'error', autoDismiss: true})
            }
          })
      )
    }

    return (
      <>
        <FormGroup>
          <Input placeholder={i18n.t('retrieve_order.placeholder')} value={orderId} onChange={(e) => setOrderId(e.target.value)}/>
        </FormGroup>
        <Button color="primary text-white" className="btn-block" disabled={orderId.length == 0} onClick={() => orderFetcher(orderId)}>{i18n.t('retrieve_order.button')}</Button>
        {order &&
          <OrderModal order={order} urls={urls} modal={modal} toggle={toggle}/>
        }
      </>
    )
  }

  return (
    <Page {...props}>
      <h1>{i18n.t('retrieve_order.title')}</h1>

      <p>{i18n.t('retrieve_order.instructions')}</p>

      <Card>
        <CardHeader>{i18n.t('retrieve_order.operation_code')}</CardHeader>
        <CardBody>
          <OrderForm/>
        </CardBody>
      </Card>
    </Page>
  )
}

export default RetrieveOrder
