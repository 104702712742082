import DayModule from 'containers/purchase/modules/day_module/day_module'
import TicketTypesModule from 'containers/purchase/modules/ticket_types_module/ticket_types_module'
import TourcmsComponentModule from 'containers/purchase/modules/tourcms_component_module'
import dayState from 'containers/purchase/modules/day_module/day_state'
import tourcmsRatesState from 'containers/purchase/modules/ticket_types_module/tourcms_rates_state'
import tourcmsComponentState from 'containers/purchase/modules/tourcms_component_module/tourcms_component_state'
import PickupModule from 'containers/purchase/modules/lct_pickup_module/pickup_module'
import pickupState from 'containers/purchase/modules/lct_pickup_module/lct_pickup_state'
import ContactModule from 'containers/purchase/modules/contact_module/contact_module'
import contactState from 'containers/purchase/modules/contact_module/contact_state'

const reservationMapper = (ctx) => {
  return {
    event_id: ctx.event.id,
    ticket_types: ctx.tickets,
    params: {
      contact_values: ctx.contactValues,
      component: ctx.component,
      pickup: ctx.pickup
    }
  }
}

const config = (params) => {
  const {external_api_config} = params
  const pickupRequired = external_api_config && external_api_config.pickup_required
  const contactRequired = external_api_config && external_api_config.contact_required

  let steps = {}

  steps['dayStep'] = {
    component: DayModule,
    autoNext: true,
    states: dayState,
    context: {
      year_month: params.initialYearMonth,
      event_groups: [],
      event: null
    }
  }

  steps['ticketsStep'] = {
    component: TicketTypesModule,
    autoNext: false,
    states: tourcmsRatesState,
    context: {
      tickets: []
    }
  }

  steps['tourcmsComponentStep'] = {
    component: TourcmsComponentModule,
    autoNext: false,
    states: tourcmsComponentState,
    context: {
      components: [],
      component: null
    }
  }

  if (pickupRequired) {
    steps.pickupRequired = {
      component: PickupModule,
      autoNext: false,
      states: pickupState,
      context: {
        pickups: [],
        pickup: null
      }
    }
  }

  if (contactRequired) {
    steps.contactRequired = {
      component: ContactModule,
      autoNext: false,
      states: contactState,
      context: {
        contactValues: []
      }
    }
  }  

  return {
    reservationMapper: reservationMapper,
    steps: steps
  }
}

export default config
