import React, {useState} from 'react'
import ApiFetcher from 'lib/api_fetcher'
import {Row, Col, Button, ListGroup, ListGroupItem} from 'reactstrap'
import time from 'i18n/time'
import SummaryModule from 'containers/purchase/modules/summary_module/summary_module'
import {ModulePanel, ModuleHeader, ModuleBody} from 'containers/purchase/modules/module_panel'
import CartNotReadyCallout from 'containers/purchase/cart_not_ready_callout'
import { useTranslation } from 'react-i18next'
import parse from 'react-html-parser'
import cartWorking from 'img/cart-working'
import cartConfirmed from 'img/cart-confirmed'
import cartCancelled from 'img/cart-cancelled'
import cartFailed from 'img/cart-failed'
import cartCancellationPending from 'img/cart-cancellation-pending'
import ReactInterval from 'react-interval'
import Page from 'ui/page'
import useLayout from 'utils/hooks/use_layout'
import DataList from 'ui/data_list'
import RelatedProducts from './partials/related_products'

const poolingTimes = (status) => {
  if (status == 'payment_pending') {
    return 3000
  } else if(status == 'human_processing_pending') {
    return 10000
  }

  return 65000
}

const PrintCallout = ({cart}) => {
  const { t, i18n } = useTranslation()

  const reservations = cart.items.filter((item) => item.status == 'confirmed')

  if (reservations.length <= 0) { return '' }

  return (
    <div className="alert alert-success mb-4 d-flex justify-content-left" role="alert">
      <div>
        <img width="110" height="110" className="mr-4" src={cartConfirmed}></img>
      </div>
      <div className="w-100">
        <h4 className="alert-heading d-flex justify-content-between m-0 p-0">
          <div className="align-self-center">
            {i18n.t('print_callout.tickets_ready')}
          </div>
        </h4>
        <hr/>
        <p className="m-0">{i18n.t('print_callout.can_be_downloaded')}</p>
        <p className="m-0">{i18n.t('print_callout.email_will_be_received')}</p>

        <ListGroup className="mt-3">
          {reservations.map((item) => {
            return (
              <ListGroupItem key={item.id} className="d-flex justify-content-between align-items-center">
                <div>
                  {item.order_code} - {item.product.title}
                </div>
                <Button color="success" href={item.vouchers} rel="noreferrer noopener" target="_blank">
                  <i className="fa fa-print mr-1"/>
                  {i18n.t('summary.print_vouchers')}
                </Button>
              </ListGroupItem>
            )
          })}
        </ListGroup>
      </div>
    </div>
  )
}

const OnRequestCallout = ({cart}) => {
  const { t, i18n } = useTranslation()

  const reservations = cart.items.filter((item) => item.status == 'human_processing_pending')

  if (reservations.length <= 0) { return '' }

  return (
    <div className="alert alert-warning mb-4 d-flex justify-content-left" role="alert">
      <div>
        <img width="110" height="110" className="mr-4" src={cartWorking}></img>
      </div>
      <div>
        <h4 className="alert-heading">{i18n.t('onrequest_callout.title')}...</h4>
        <hr/>
        <p className="m-0">{parse(i18n.t('onrequest_callout.description'))}</p>

        <ListGroup className="mt-3">
          {reservations.map((item) => {
            return (
              <ListGroupItem key={item.id} className="d-flex justify-content-between align-items-center">
                <div>
                  {item.order_code} - {item.product.title}
                </div>
              </ListGroupItem>
            )
          })}
        </ListGroup>
      </div>
    </div>
  )
}

const CancellationPendingCallout = ({cart}) => {
  const { t, i18n } = useTranslation()

  const reservations = cart.items.filter((item) => item.status == 'cancellation_pending')

  if (reservations.length <= 0) { return '' }

  return (
    <div className="alert alert-danger mb-4 d-flex justify-content-left" role="alert">
      <div>
        <img width="110" height="110" className="mr-4" src={cartCancellationPending}></img>
      </div>
      <div className="w-100">
        <h4 className="alert-heading d-flex justify-content-between m-0 p-0">
          <div className="align-self-center">
            {i18n.t('cancellation_pending_callout.title')}
          </div>
        </h4>
        <hr/>
        <p className="m-0">{parse(i18n.t('cancellation_pending_callout.description'))}</p>

        <ListGroup className="mt-3">
          {reservations.map((item) => {
            return (
              <ListGroupItem key={item.id} className="d-flex justify-content-between align-items-center">
                <div>
                  {item.order_code} - {item.product.title}
                </div>
              </ListGroupItem>
            )
          })}
        </ListGroup>
      </div>
    </div>
  )
}

const CancelledCallout = ({cart}) => {
  const { t, i18n } = useTranslation()

  const reservations = cart.items.filter((item) => item.status == 'cancelled')

  if (reservations.length <= 0) { return '' }

  return (
    <div className="alert alert-danger mb-4 d-flex justify-content-left" role="alert">
      <div>
        <img width="110" height="110" className="mr-4" src={cartCancelled}></img>
      </div>
      <div className="w-100">
        <h4 className="alert-heading d-flex justify-content-between m-0 p-0">
          <div className="align-self-center">
            {i18n.t('cancelled_callout.title')}
          </div>
        </h4>
        <hr/>
        <p className="m-0">{parse(i18n.t('cancelled_callout.description'))}</p>

        <ListGroup className="mt-3">
          {reservations.map((item) => {
            return (
              <ListGroupItem key={item.id} className="d-flex justify-content-between align-items-center">
                <div>
                  {item.order_code} - {item.product.title}
                </div>
              </ListGroupItem>
            )
          })}
        </ListGroup>
      </div>
    </div>
  )
}

const DiscardedCallout = () => {
  const { t, i18n } = useTranslation()

  return (
    <div className="alert alert-warning mb-4 d-flex justify-content-left" role="alert">
      <div>
        <img width="110" height="110" className="mr-4" src={cartCancelled}></img>
      </div>
      <div>
        <h4 className="alert-heading">{i18n.t('discarded_callout.title')}</h4>
        <hr/>
        <p className="m-0">{parse(i18n.t('discarded_callout.description'))}</p>
      </div>
    </div>
  )
}

const FailedCallout = ({cart}) => {
  const { t, i18n } = useTranslation()

  const reservations = cart.items.filter((item) => item.status == 'failed')

  if (reservations.length <= 0) { return ''}

  return (
    <div className="alert alert-danger mb-4 d-flex justify-content-left" role="alert">
      <div>
        <img width="110" height="110" className="mr-4" src={cartFailed}></img>
      </div>
      <div className="w-100">
        <h4 className="alert-heading d-flex justify-content-between m-0 p-0">
          <div className="align-self-center">
            {i18n.t('failed_callout.title')}
          </div>
        </h4>
        <hr/>
        <p className="m-0">{i18n.t('failed_callout.description')}</p>

        <ListGroup className="mt-3">
          {reservations.map((item) => {
            return (
              <ListGroupItem key={item.id} className="d-flex justify-content-between align-items-center">
                <div>
                  {item.order_code} - {item.product.title}
                </div>
              </ListGroupItem>
            )
          })}
        </ListGroup>
      </div>
    </div>
  )
}

const ReservationData = ({status_label, order_date, attended_by, items, payment_method}) => {
  const { t, i18n } = useTranslation()

  return (
    <ModulePanel>
      <ModuleHeader status="active" showIcon={false}>
        <span>
          <i className="fa fa-bars mr-2" aria-hidden="true"></i>
          {i18n.t('summary.order_data')}
        </span>
      </ModuleHeader>
      <ModuleBody status="active">
        <Row className={'p-2'}>
          <DataList label={i18n.t('summary.order_date')} value={time(order_date).format('lll')}/>
          <DataList label={i18n.t('summary.payment_method')} value={payment_method.title}/>
          <DataList label={i18n.t('summary.attended_by')} value={attended_by}/>
        </Row>
      </ModuleBody>
    </ModulePanel>
  )
}

const BuyerData = ({name, surname, email, telephone, room}) => {
  const { t, i18n } = useTranslation()

  return (
    <ModulePanel>
      <ModuleHeader status="active" showIcon={false}>
        <span>
          <i className="fa fa-user mr-2"></i>
          {i18n.t('summary.contact_data')}
        </span>
      </ModuleHeader>
      <ModuleBody status="active">
        <Row className="p-2">
          <DataList label={i18n.t('summary.name')} value={`${name} ${surname}`}/>
          <DataList label={i18n.t('summary.email')} value={email}/>
          <DataList label={i18n.t('summary.telephone')} value={telephone}/>
          <DataList label={i18n.t('summary.room')} value={room}/>
        </Row>
      </ModuleBody>
    </ModulePanel>
  )
}

const Summary = (props) => {
  const {endpoints} = props

  const cartFetcher = () => (
    new ApiFetcher({
      key: 'cart',
      method: 'GET',
      endpoint: endpoints.reservation
    }).call()
  )

  const mode = useLayout()
  const [cart, setCart] = useState()
  const reservation = cart && cart.items[0]

  React.useEffect(() => {
    PubSub.publish('loading-page')

    cartFetcher().then((data) => {
      setCart(data.reservation)
      PubSub.publish('loaded-page')
    })
  }, [])

  if (!cart) { return '' }

  if (cart && (cart.status == 'payment_pending' || cart.status == 'payment_failed')) {
    window.location.href = endpoints.payment
    return ''
  }

  return (
    <Page {...props}>
      <ReactInterval
        enabled={true}
        timeout={poolingTimes(cart.status)}
        callback={() => {
          cartFetcher().then((data) => {
            setCart(data.reservation)
          })
        }}
      />
      {cart.status == 'confirming' &&
        <CartNotReadyCallout/>
      }

      <FailedCallout cart={cart}/>
      <PrintCallout cart={cart}/>
      <OnRequestCallout cart={cart}/>
      <CancellationPendingCallout cart={cart}/>
      <CancelledCallout cart={cart}/>

      {cart.status == 'discarded' &&
        <DiscardedCallout/>
      }
      {['confirmed', 'human_processing_pending', 'cancellation_pending', 'cancelled', 'failed'].includes(cart.status) &&
        <Row className="mx-0">
          <Col className="pl-0">
            <ReservationData {...cart}/>
            <BuyerData {...cart.main_customer}/>
          </Col>
          <Col className="pr-0">
            <SummaryModule cart={cart} showStatus={true}/>
          </Col>
        </Row>
      }
      {['confirmed', 'human_processing_pending'].includes(cart.status) &&
        <RelatedProducts products={reservation.product.related_products} url={endpoints.service}/>
      }
    </Page>
  )
}

export default Summary
