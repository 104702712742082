import { assign } from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import i18n from 'i18n/config'
import {groupBy} from 'lodash'
import moment from 'moment'

const stepName  = 'accessStep'

const fetchEvents = (ctx) => (
  new ApiFetcher({
    key: 'events',
    endpoint: ctx.endpoints.events,
    query: {date: ctx.day}
  }).call()
)

const fetchSessions = (ctx, e) => (
  new ApiFetcher({
    key: 'events',
    endpoint: ctx.endpoints.events,
    query: {date: moment(e.day).format('YYYY-MM-DD')}
  }).call()
)

const emptyEvents = (ctx) => ctx.pickup == null
const setError = assign({error: i18n.t('validations:pickups_fetch_error')})
const cleanError = assign({error: null})
const setSelectionGroups = assign({selection_groups: (ctx, e) => {
  const { tickets } = ctx
  const selectableProductIds = tickets && tickets.filter((ticket) => ticket.qty > 0).map((ticket) => ticket.id)
  const groups = groupBy(e.data, (eg) => eg.selection_group.id)

  const selectionGroups = Object.keys(groups).map((selectionGroupId) => {
    const filteredEventGroups = e.data.filter((eventGroup) => eventGroup.selection_group.id == selectionGroupId)
    return {
      id: selectionGroupId,
      name: filteredEventGroups[0].selection_group.name,
      event_groups: filteredEventGroups,
      access_date: new Date(ctx.day),
      session: filteredEventGroups[0].events[0].id,
      event_group: filteredEventGroups[0].id
    }
  })

  if (ctx.filterSessionGroups == true) {
    return selectionGroups
      .filter((sg) => selectableProductIds.includes(sg.event_groups.map((eg) => eg.events.map((event) => event.params && event.params.product_id )[0])[0]))  
  } else {
    return selectionGroups
  }
}})

const replaceSelectGroup = assign({selection_groups: (ctx, e) => {
  let newSelectionGroups = [...ctx.selection_groups]

  const index = newSelectionGroups.findIndex((selectionGroup) => selectionGroup.id == e.id )
  newSelectionGroups[index].access_date = e.day

  return newSelectionGroups
}})

const markGroupRefreshing = assign({refreshingGroup: (ctx, e) => e.id})

const replaceGroupSessions = assign({selection_groups: (ctx, e) => {
  const newEventGroups = e.data.filter((event_group) => event_group.selection_group.id == ctx.refreshingGroup)

  let newSelectionGroups = [...ctx.selection_groups]

  const index = newSelectionGroups.findIndex((selectionGroup) => selectionGroup.id == ctx.refreshingGroup )
  newSelectionGroups[index].event_groups = newEventGroups
  newSelectionGroups[index].session = newEventGroups[0].events[0].id
  newSelectionGroups[index].event_group = newEventGroups[0].id
  return ctx.selection_groups
}})

const replaceSession = assign({selection_groups: (ctx, e) => {
  let newSelectionGroups = [...ctx.selection_groups]
  const index = newSelectionGroups.findIndex((selectionGroup) => selectionGroup.id == e.selection_group_id )
  newSelectionGroups[index].session = e.session_id

  return newSelectionGroups
}})

const replaceEventGroupAndSession = assign({selection_groups: (ctx, e) => {
  let newSelectionGroups = [...ctx.selection_groups]
  const index = newSelectionGroups.findIndex((selectionGroup) => selectionGroup.id == e.selection_group_id )
  const newEventGorup = newSelectionGroups[index].event_groups.find((eventGroup) => eventGroup.id == e.event_group)
  newSelectionGroups[index].event_group = newEventGorup.id
  newSelectionGroups[index].session = newEventGorup.events[0].id

  return newSelectionGroups
}})

const state = (config) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {
          '': [
            {
              target: 'fetchingEvents',
              cond: emptyEvents,
              actions: [cleanError, 'startSpinner']
            },
            {
              target: 'waitingEventsPick'
            }
          ],
        },
      },
      fetchingEvents: {
        invoke: {
          src: fetchEvents,
          onDone: {
            target: 'waitingEventsPick',
            actions: [setSelectionGroups, 'stopSpinner']
          },
          onError: {
            target: '#purchase.error',
            actions: [setError, 'stopSpinner']
          }
        }
      },
      fetchingGroupSessions: {
        invoke: {
          src: fetchSessions,
          onDone: {
            target: 'waitingEventsPick',
            actions: [replaceGroupSessions, 'stopSpinner']
          },
          onError: {
            target: '#purchase.error',
            actions: [setError, 'stopSpinner']
          }
        }
      },
      waitingEventsPick: {
        on: {}
      }
    },
    on: {
      ACCESS_DATE_CHANGE: [
        {
          target: '.fetchingGroupSessions',
          actions: ['startSpinner', markGroupRefreshing, replaceSelectGroup]
        }
      ],
      SESSION_CHANGE: [
        {
          actions: [replaceSession]
        }
      ],
      EVENT_GROUP_CHANGE: [
        {
          actions: [replaceEventGroupAndSession]
        }
      ],
    }
  }
}

export default state
