import React, {useState} from 'react'
import {Button} from 'reactstrap'
import i18n, {currency} from 'i18n/config'

const getColumns = (data, params) => [
  {
    Header: '',
    accessor: 'row',
    Cell: ({state, row}) => state.pageSize * (state.pageIndex) + (row.index + 1),
    sortable: false,
    justifyContent: 'center',
    width: 20
  },
  {
    Header: i18n.t('products_catalog_grid_options.type'),
    accessor: 'type',
    sortable: false,
    justifyContent: 'center',
    width: 50
  },
  {
    Header: i18n.t('products_catalog_grid_options.state'),
    accessor: 'state_id',
    sortable: false,
    justifyContent: 'center',
    Cell: ({state, row}) => row.original.state_label,
    width: 80
  },
  {
    Header: i18n.t('products_catalog_grid_options.product'),
    accessor: 'title',
    sortable: false,
    width: 400
  },
  {
    Header: i18n.t('products_catalog_grid_options.featured_price'),
    accessor: 'featured_price_label',
    sortable: false,
    justifyContent: 'flex-end',
    width: 100
  },
  {
    Header: i18n.t('products_catalog_grid_options.type'),
    accessor: 'main_commission',
    sortable: false,
    justifyContent: 'center',
    Cell: ({value}) => {
      const [isHover, setIsHover] = useState(false)

      const taxLabel = value ? value : i18n.t('products_catalog_grid_options.pending_assignment')
      return (
        <Button
          color="primary"
          size="sm"
          className="text-white"
          style={{width: '150px'}}
          onMouseOver={() => setIsHover(true)}
          onMouseOut={() => setIsHover(false)}
        >
          {isHover ? taxLabel : i18n.t('products_catalog_grid_options.click_to_show')}
        </Button>
      )
    },
    width: 100
  }
]

const tableOptions = (data, params) => {
  return {
    columns: getColumns(data, params)
  }
}

export default tableOptions
