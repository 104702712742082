import React, {useEffect} from 'react'
import { Formik, Field } from 'formik'
import { Row, Col, Form, FormGroup, Alert, Card, CardHeader, CardBody, ButtonGroup, Button, ListGroupItem, ListGroup } from 'reactstrap'
import { ReactstrapInput } from 'reactstrap-formik'
import QtyInput from 'ui/forms/qty_input'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import {get} from 'lodash'
import Select from 'ui/forms/select'
import TextInput from 'ui/forms/text_input'
import DatePicker from 'ui/forms/datetime_picker'
import AddressSelector from 'ui/forms/address_selector'
import moment from 'moment'
import {uniq} from 'lodash'
import styled from 'styled-components'
import cn from 'classnames'

const Styles = styled.div`
  align-self: center !important;

  input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: white;
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: var(--primary);
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid var(--primary);
    border-radius: 50%;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
  }

  input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--primary);
    /* Windows High Contrast Mode */
    background-color: red;
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }

  input[type="radio"]:focus {
    outline: max(2px, 0.15em) solid var(--primary);
    outline-offset: max(2px, 0.15em);
  }
`

const RouteForm = (props) => {
  const { t, i18n } = useTranslation()

  const {step, config, machine} = props
  const {resources} = machine[0].context
  const release = get(config, 'product.external_api_config.release')

  return (
    <Formik
      initialValues={{
        step: step.replace('_form', ''),
        when: '',
        place_type: step == 'pickup_form' ? 'airport' : 'address',
        airport_iata: '',
        flight_number: '',
        address: ''
      }}
      validationSchema={
        Yup.object().shape({
          when: Yup.string().when('step', {
            is: 'pickup',
            then: Yup.string().required(i18n.t('validations:required'))
          }),
          place_type: Yup.string().oneOf(['airport', 'address']),
          airport_iata: Yup.string().when('place_type', {
            is: 'airport',
            then: Yup.string().required(i18n.t('validations:required'))
          }),
          flight_number: Yup.string().when('place_type', {
            is: 'airport',
            then: Yup.string().required(i18n.t('validations:required'))
          }),
          address: Yup.string().when('place_type', {
            is: 'address',
            then: Yup.string().required(i18n.t('validations:required'))
          }),
        })
      }
      onSubmit={() => {}}
    >
      {(form) => {
        const {values, errors, touched, setFieldValue} = form

        useEffect(() => {
          props.setForm(form)
        }, [values, errors, touched])

        const PlaceForm = ({type}) => {
          return (
            <React.Fragment>
              <label class="control-label mb-2">
                {props.step == 'pickup_form' ? (
                  i18n.t('route_form.place_type_pickup_label')
                ) : (
                  i18n.t('route_form.place_type_dropoff_label')
                )}

              </label>
              <ButtonGroup className="btn-block">
                <Button
                  color="primary text-white mr-3"
                  active={values.place_type == 'airport' ? true : false}
                  onClick={() => setFieldValue('place_type', 'airport')}
                >
                  {values.place_type == 'airport' ? (
                    <i className="fa fa-dot-circle mr-3" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-circle mr-3" aria-hidden="true"></i>
                  )}
                  <i className="fa fa-plane"/>
                  {i18n.t('route_form.airport')}
                </Button>
                <Button
                  color="primary text-white ml-2"
                  active={values.place_type == 'address' ? true : false}
                  onClick={() => setFieldValue('place_type', 'address')}
                >
                  {values.place_type == 'address' ? (
                    <i className="fa fa-dot-circle mr-3" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-circle mr-3" aria-hidden="true"></i>
                  )}
                  <i className="fa fa-map-marker"></i> {i18n.t('route_form.place')}
                </Button>
              </ButtonGroup>

              <Row className="mt-3">
                {resources && get(values, 'place_type') == 'airport' &&
                  <React.Fragment>
                    <Col xs="12" md="6">
                      <Select
                        label={i18n.t('route_form.airport_iata')}
                        placeholder={i18n.t('route_form.airport_iata_placeholder')}
                        name={'airport_iata'}
                        className="mt-2"
                        labelClass="m-0"
                        required
                        values={(resources && resources.airports) ? resources.airports.map((r) => ({label: r.name, value: r.code})) : []}
                      />
                    </Col>
                    <Col xs="12" md="6">
                      <TextInput
                        label={i18n.t('route_form.flight_number')}
                        name={'flight_number'}
                        className="mt-2"
                        uncontrolled
                        required
                      />
                    </Col>
                  </React.Fragment>
                }

                {get(values, 'place_type') == 'address' &&
                  <Col>
                    <AddressSelector
                      label={props.step == 'pickup_form' ? i18n.t('route_form.address_pickup') : i18n.t('route_form.address_dropoff')}
                      placeholder={i18n.t('route_form.address_placeholder')}
                      name="address"
                      className="mt-2"
                      value={{label: values.address, value: values.address}}
                      onChange={(selected) => {
                        setFieldValue('address', selected ? selected.value  : '')
                      }}
                      required
                    />
                  </Col>
                }
              </Row>
            </React.Fragment>
          )
        }

        return (
          <Form>
            <Row className="justify-content-md-center">
              <Col xs="12" md="6">
                <FormGroup>
                  <PlaceForm/>

                  {step == 'pickup_form' &&
                    <DatePicker
                      machine={machine}
                      name={'when'}
                      release={release}
                      label={i18n.t('route_form.pickup_date')}
                      timezone={config.product.local_timezone}
                    />
                  }
                </FormGroup>
              </Col>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}

export default RouteForm
