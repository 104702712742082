import React, {useEffect} from 'react'
import { Formik, Field } from 'formik'
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap'
import { ReactstrapInput, ReactstrapSelect, ReactstrapRadio } from 'reactstrap-formik'
import {keyBy, mapValues, mapKeys} from 'lodash'
import * as Yup from 'yup'
import i18n from 'i18n/config'

const fieldComponentsMap = (fieldType) => {
  const typesMap = {
    long: ReactstrapInput,
    short: ReactstrapInput,
    count: ReactstrapInput,
    ['extended-option']: ReactstrapSelect
  }

  return typesMap[fieldType]
}

const generateInputName = (id) => `custom_field_${id}`

const getCustomFields = (customFields) => {
  let newFields = [...customFields]
  newFields.map((customfield, i) => {
    customfield['inputName'] = generateInputName(customfield.pk)
  })
  return newFields
}

const initFormValues = (customFields) => customFields
  .map((customField) => ({[customField.inputName]: ''}))
  .reduce((accum, current) => ({...accum, ...current}))

const inputProps = (customField) => {
  let options = []
  if (customField.type == 'extended-option') {
    options = customField.extended_options.map((option) => {
      return {
        id: option.pk,
        name: option.offset == 0 ? option.name : `${option.name} (${option.offset})`
      }
    })
  }

  return {
    name: customField.inputName,
    id: customField.inputName,
    options: options,
    defaultOption: customField.description
  }
}

const buildSchema = (customFields) => {
  const validationTypes = {
    ['yes-no']: Yup.boolean(),
    short: Yup.string().max(20, i18n.t('validations:string.max')),
    long: Yup.string().max(20, i18n.t('validations:string.max')),
    count: Yup.number().integer().typeError(i18n.t('validations:integer')),
    ['extended-option']: Yup.number().integer()
  }

  let validationSchema = mapValues(keyBy(customFields, 'inputName'), (customField) => {
    let validation = validationTypes[customField.type]
    if (customField.is_required == true) {
      validation = validation.required(i18n.t('validations:required'))
    }
    return validation
  })

  return Yup.object().shape(validationSchema)
}

const CustomFieldsForm = (props) => {
  if (props.customFields.length <= 0) {
    return ''
  }

  const customFields = getCustomFields(props.customFields)

  return (
    <Formik
      validationSchema={buildSchema(customFields)}
      initialValues={initFormValues(customFields)}
      onSubmit={(values) => {}}
    >
      {(form) => {
        const {
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          submitForm,
          validateForm
        } = form

        useEffect(() => {
          buildSchema(customFields)
        }, [])

        useEffect(() => {
          props.setForm(form)
        }, [values])

        const selectedOption = (customField) => customField.extended_options.find(({pk}) => pk == values[customField.inputName])

        return (
          <Form>
            {customFields.map((customField) =>
              <FormGroup key={customField.pk}>
                <Field
                  required={customField.required ? true : false}
                  label={customField.required ? `${customField.name} *` : customField.name}
                  name={customField.inputName}
                  placeholder={customField.description}
                  component={fieldComponentsMap(customField.type)}
                  inputprops={inputProps(customField)}
                />
                {customField.type == 'extended-option' && selectedOption(customField) &&
                  <FormText color="muted" style={{marginTop: '-10px'}}>
                    {selectedOption(customField).description}
                  </FormText>
                }
              </FormGroup>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

export default CustomFieldsForm
