import React, {useState, useEffect} from 'react'
import Page from 'ui/page'
import StepWizard from 'react-step-wizard'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import styled from 'styled-components'
import { Row, Col, Button, ButtonGroup, Card, CardImg, CardHeader, CardBody, CardFooter, TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap'
import ApiFetcher from 'lib/api_fetcher'
import cn from 'classnames'
import QRCode from 'react-qr-code'
import imgLink from 'img/referrals/link'
import imgModal from 'img/referrals/modal'
import imgIframe from 'img/referrals/iframe'
import imgBanner from 'img/referrals/banner'
import imgGrid from 'img/referrals/grid'
import imgMasonry from 'img/referrals/masonry'
import imgProduct from 'img/referrals/product'
import CopyBlock from 'ui/copy_block'


const Referrals = (props) => {
  const {user, urls} = props
  const b2cAccounts = user.user_data.client.b2c_accounts
  const destinations = user.user_data.user.destinations

  let initialToken
  if (b2cAccounts && b2cAccounts.length == 1) {
    initialToken = b2cAccounts[0].token
  }

  const [token, setToken] = useState(initialToken)
  const [destination, setDestination] = useState()
  const [language, setLanguage] = useState('es')
  const [destinationProducts, setDestinationProducts] = useState()
  const [products, setProducts] = useState([])
  const [modality, setModality] = useState()
  const [format, setFormat] = useState()
  const [link, setLink] = useState('')
  const [modalJs, setModalJs] = useState()
  const {i18n} = useTranslation()

  const Step = (props) => {
    const {nextStep, previousStep, title, children, valid, showPrevious, showNext} = props

    return (
      <Card>
        <CardHeader className="bg-primary text-center text-white">
          {title}
        </CardHeader>
        <CardBody>
          {children}
        </CardBody>
        <CardFooter>
          <Row>
            {showPrevious &&
              <Col xs="6">
                <Button color="primary" className="btn-block text-white" disabled={false} onClick={() => previousStep()}>
                  {i18n.t('format:actions.previous')}
                </Button>
              </Col>
            }
            {showNext &&
              <Col xs="6" className={showPrevious ? '' : 'offset-6'}>
                <Button color="primary" className="btn-block text-white" disabled={valid ? false : true} onClick={() => nextStep()}>
                  {i18n.t('format:actions.next')}
                </Button>
              </Col>
            }
          </Row>
        </CardFooter>
      </Card>
    )
  }

  const TokenStep = (stepProps) => {
    const { t, i18n } = useTranslation()
    const options = b2cAccounts.map((a) => ({label: a.name, value: a.token}))

    return (
      <Step {...stepProps} title={i18n.t('referrals.token_step.title')} showNext={true}>
        <p>{i18n.t('referrals.token_step.instructions')}</p>
        <Select
          className="text-left"
          options={options}
          placeholder={i18n.t('referrals.token_step.select_placeholder')}
          onChange={(a) => setToken(a.value)}
          value={options.find((a) => a.value == token)}
        />
      </Step>
    )
  }

  const ProductStep = (props) => {
    const { t, i18n } = useTranslation()
    const {currentStep} = props
    const destinationOptions = destinations.map((d) => ({label: d.name, value: d.id}))
    const productOptions = destinationProducts && destinationProducts.map((p) => ({label: p.title, value: p.id})) || []

    const fetchProducts = (destination) => {
      PubSub.publish('loading-page')

      return new ApiFetcher({
        key: 'products',
        query: {
          per_page: 9999
        },
        endpoint: urls.products.replace(/ *\:[^)]*\: */g, destination),
      }).call()
        .then((res) => {
          setDestinationProducts(res.products)
          setDestination(destination)
        })
        .finally(() => PubSub.publish('loaded-page'))
    }

    return (
      <Step {...props} title={i18n.t('referrals.product_step.title')} showPrevious={initialToken ? false : true} showNext={true}>
        <h5>{i18n.t('referrals.product_step.select_a_language')}</h5>
        <ButtonGroup className="mb-3">
          <Button color="primary" className="text-white" active={language == 'es'} onClick={() => setLanguage('es')}>{i18n.t('format:languages.spanish')}</Button>
          <Button color="primary" className="text-white" active={language == 'en'} onClick={() => setLanguage('en')}>{i18n.t('format:languages.english')}</Button>
          <Button color="primary" className="text-white" active={language == 'fr'} onClick={() => setLanguage('fr')}>{i18n.t('format:languages.french')}</Button>
        </ButtonGroup>

        <h5>{i18n.t('referrals.product_step.select_a_destination')}</h5>
        <Select
          className="text-left"
          options={destinationOptions}
          placeholder={i18n.t('referrals.product_step.select_destination_placeholder')}
          onChange={(a) => fetchProducts(a.value)}
          value={destinationOptions.find((a) => a.value == destination)}
        />

        {destination &&
          <div>
            <hr/>
            <h5>{i18n.t('referrals.product_step.referral_type')}</h5>
            <Row className="row-cols-3">
              <Col>
                <Card onClick={() => setModality('grid')} style={{cursor: 'pointer'}}>
                  <CardImg top width="100%" src={imgGrid}/>
                  <CardHeader className={cn(modality == 'grid' ? 'bg-primary text-white' : '')}>{i18n.t('referrals.product_step.grid')}</CardHeader>
                </Card>
              </Col>
              <Col>
                <Card onClick={() => setModality('masonry')} style={{cursor: 'pointer'}}>
                  <CardImg top width="100%" src={imgMasonry}/>
                  <CardHeader className={cn(modality == 'masonry' ? 'bg-primary text-white' : '')}>{i18n.t('referrals.product_step.masonry')}</CardHeader>
                </Card>
              </Col>
              <Col>
                <Card onClick={() => setModality('product')} style={{cursor: 'pointer'}}>
                  <CardImg top width="100%" src={imgProduct}/>
                  <CardHeader className={cn(modality == 'product' ? 'bg-primary text-white' : '')}>{i18n.t('referrals.product_step.product')}</CardHeader>
                </Card>
              </Col>
            </Row>
          </div>
        }

        {destinations && modality == 'product' &&
          <div>
            <hr/>
            <h5>{i18n.t('referrals.product_step.select_a_product')}</h5>
            <Select
              className="text-left"
              options={productOptions}
              placeholder={i18n.t('referrals.product_step.select_product_placeholder')}
              onChange={(p) => setProducts([p.value])}
              value={productOptions.find((a) => a.value == products[0])}
            />
          </div>
        }

        {destinations && modality == 'masonry' &&
          <div>
            <hr/>
            <h5>{i18n.t('referrals.product_step.select_the_products')}</h5>
            <Select
              className="text-left"
              options={productOptions}
              placeholder={i18n.t('referrals.product_step.select_the_products_placeholder')}
              onChange={(p) => setProducts(p && p.map((product) => product.value) || [])}
              value={products.map((id) => productOptions.find((p) => p.value == id))}
              isMulti
            />
          </div>
        }
      </Step>
    )
  }

  const FormatStep = (props) => {
    const fetchReferral = () => {
      PubSub.publish('loading-page')

      return new ApiFetcher({
        key: 'retrieve_referral',
        body: JSON.stringify({
          user_token: token,
          modality: modality,
          destination: destination,
          products: products,
          language: language,
          product: products && products[0],
          format: format
        }),
        endpoint: urls.retrieve_referral,
        method: 'POST'
      }).call()
        .then((res) => {
          setLink(res.url)
          setModalJs(res.modal_js)
        })
        .finally(() => PubSub.publish('loaded-page'))
    }

    useEffect(() => {
      if (format) { fetchReferral() }
    }, [format])

    const LinkFormat = (props) => {
      const [linkLabel, setLinkLabel] = useState('Link')
      const [linkTarget, setLinkTarget] = useState('blank')

      const htmlLink = `<a href="${link}" target="${linkTarget}">${linkLabel}</a>`

      const onImageCownload = () => {
        const svg = document.getElementById('QRCode').childNodes[0]

        const svgData = new XMLSerializer().serializeToString(svg)
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const img = new Image()
        img.onload = () => {
          canvas.width = img.width
          canvas.height = img.height
          ctx.drawImage(img, 0, 0)
          const pngFile = canvas.toDataURL('image/png')
          const downloadLink = document.createElement('a')
          downloadLink.download = 'QRCode'
          downloadLink.href = `${pngFile}`
          downloadLink.click()
        }
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
      }

      return (
        <div>
          <Row>
            <Col>
              <Card className="text-center h-100">
                <CardHeader>
                  {i18n.t('referrals.link_format.title')}
                </CardHeader>
                <CardBody className="d-flex flex-column h-100 align-items-center">
                  <p>{i18n.t('referrals.link_format.instructions')}</p>
                  <CopyBlock value={link}/>
                </CardBody>
              </Card>
            </Col>

            <Col>
              <Card className="text-center h-100">
                <CardHeader>
                  {i18n.t('referrals.qr_generated')}
                </CardHeader>
                <CardBody>
                  <p>{i18n.t('referrals.link_format.qr_instructions')}</p>
                  <div id="QRCode"><QRCode size={170} value={link}/></div>
                  <Button className="mt-3" onClick={onImageCownload}>{i18n.t('referrals.link_format.download_qr')}</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card className="text-center mt-4">
            <CardHeader>
              {i18n.t('referrals.html_generated')}
            </CardHeader>
            <CardHeader>
              <form className="form-inline">
                <label className="mr-2">{i18n.t('format:html.label')}</label>
                <input value={linkLabel} className="form-control mr-3" onChange={(e) => setLinkLabel(e.target.value)}/>
                <label className="mr-2">{i18n.t('format:html.new_window')}</label>
                <ButtonGroup>
                  <Button color="primary text-white" onClick={() => setLinkTarget('blank')} active={linkTarget == 'blank'}>
                    {i18n.t('format:boolean.yes')}
                  </Button>
                  <Button color="primary text-white" onClick={() => setLinkTarget('_self')} active={linkTarget == '_self'}>
                    {i18n.t('format:boolean.no')}
                  </Button>
                </ButtonGroup>
              </form>
            </CardHeader>
            <CardBody>
              <p>{i18n.t('referrals.link_format.html_instructions')}</p>
              <CopyBlock value={htmlLink}/>
            </CardBody>
            <CardFooter className="bg-white">
              <h5>{i18n.t('referrals.preview')}</h5>
              <a href={link} target={linkTarget}>{linkLabel}</a>
            </CardFooter>
          </Card>
        </div>
      )
    }

    const BannerFormat = (props) => {
      const [iframeWidth, setIframeWidth] = useState('300')
      const [iframeHeight, setIframeHeight] = useState('280')
      const iframeHtml = `<iframe width="${iframeWidth}" height="${iframeHeight}" src="${link}"></iframe>`
      return (
        <div>
          <Card className="text-center">
            <CardHeader>
              {i18n.t('referrals.html_generated')}
            </CardHeader>
            <CardHeader>
              <form className="form-inline">
                <label className="mr-2">{i18n.t('referrals.width')}</label>
                <input value={iframeWidth} className="form-control mr-3" onChange={(e) => setIframeWidth(e.target.value)}/>
                <label className="mr-2">{i18n.t('referrals.height')}</label>
                <input value={iframeHeight} className="form-control mr-3" onChange={(e) => setIframeHeight(e.target.value)}/>
              </form>
            </CardHeader>
            <CardBody>
              <p>{i18n.t('referrals.banner_format.instructions')}</p>
              <CopyBlock value={iframeHtml}/>
            </CardBody>
            <CardFooter className="bg-white">
              <h5>{i18n.t('referrals.preview')}</h5>
              <iframe src={link} width={iframeWidth} height={iframeHeight} target={iframeHtml} frameBorder="0"/>
            </CardFooter>
          </Card>
        </div>
      )
    }

    const IframeFormat = (props) => {
      const [iframeWidth, setIframeWidth] = useState('100%')
      const [iframeHeight, setIframeHeight] = useState('1000')
      const iframeHtml = `<iframe width="${iframeWidth}" height="${iframeHeight}" src="${link}"></iframe>`
      return (
        <div>
          <Card className="text-center">
            <CardHeader>
              {i18n.t('referrals.html_generated')}
            </CardHeader>
            <CardHeader>
              <form className="form-inline">
                <label className="mr-2">{i18n.t('referrals.width')}</label>
                <input value={iframeWidth} className="form-control mr-3" onChange={(e) => setIframeWidth(e.target.value)}/>
                <label className="mr-2">{i18n.t('referrals.height')}</label>
                <input value={iframeHeight} className="form-control mr-3" onChange={(e) => setIframeHeight(e.target.value)}/>
              </form>
            </CardHeader>
            <CardBody>
              <p>{i18n.t('referrals.iframe_format.instructions')}</p>
              <CopyBlock value={iframeHtml}/>
            </CardBody>
            <CardFooter className="bg-white">
              <h5>{i18n.t('referrals.preview')}</h5>
              <iframe src={link} width={iframeWidth} height={iframeHeight} target={iframeHtml} frameBorder="0"/>
            </CardFooter>
          </Card>
        </div>
      )
    }

    const ModalFormat = (props) => {
      const step1 = `<script src="${modalJs}"></script>`
      const step2 = `<script>obmodal({buttonId: 'myButton', link: '${link}'})</script>`
      const step3 = '<a id="myButton" ">'+ i18n.t('referrals.modal_format.clickable_label') + '</a>'

      return (
        <div>
          <Card className="text-center">
            <CardHeader>
              {i18n.t('referrals.modal_format_instructions')}
            </CardHeader>
            <CardBody>
              <h5>{i18n.t('referrals.format_step.attach_external_js')}</h5>
              <CopyBlock value={step1}/>
              <hr/>
              <h5>{i18n.t('referrals.format_step.paste_script')}</h5>
              <CopyBlock value={step2}/>
              <hr/>
              <h5>{i18n.t('referrals.format_step.attach_event_to_html')}</h5>
              <CopyBlock value={step3}/>
            </CardBody>
          </Card>
        </div>
      )
    }

    return (
      <Step {...props} title={i18n.t('referrals.format_step.title')} showPrevious={true}>
        <h5>{i18n.t('referrals.format_step.select_a_format')}</h5>
        <Row className="row-cols-4">
          <Col>
            <Card onClick={() => setFormat('link')} style={{cursor: 'pointer'}}>
              <CardImg top width="100%" height="100%" src={imgLink}/>
              <CardHeader className={cn(format == 'link' ? 'bg-primary text-white' : '')}>{i18n.t('referrals.format_step.link')}</CardHeader>
            </Card>
          </Col>
          {modality == 'product' &&
            <Col>
              <Card onClick={() => setFormat('banner')} style={{cursor: 'pointer'}}>
                <CardImg top width="100%" height="100%" src={imgBanner}/>
                <CardHeader className={cn(format == 'banner' ? 'bg-primary text-white' : '')}>{i18n.t('referrals.format_step.banner')}</CardHeader>
              </Card>
            </Col>
          }
          <Col>
            <Card onClick={() => setFormat('iframe')} style={{cursor: 'pointer'}}>
              <CardImg top width="100%" height="100%" src={imgIframe}/>
              <CardHeader className={cn(format == 'iframe' ? 'bg-primary text-white' : '')}>{i18n.t('referrals.format_step.iframe')}</CardHeader>
            </Card>
          </Col>
          <Col>
            <Card onClick={() => setFormat('modal')} style={{cursor: 'pointer'}}>
              <CardImg top width="100%" height="100%" src={imgModal}/>
              <CardHeader className={cn(format == 'modal' ? 'bg-primary text-white' : '')}>{i18n.t('referrals.format_step.modal')}</CardHeader>
            </Card>
          </Col>
        </Row>

        {format &&
          <div>
            <hr/>
            <h3 className="text-center py-4">{i18n.t('referrals.format_step.referral_ready')}</h3>
            {format == 'link' &&
              <LinkFormat/>
            }
            {format == 'banner' &&
              <BannerFormat/>
            }
            {format == 'iframe' &&
              <IframeFormat/>
            }
            {format == 'modal' &&
              <ModalFormat/>
            }
          </div>
        }
      </Step>
    )
  }

  const productsStepValid = () => {
    if (!destination && !modality) { return false }

    if (modality == 'grid' || products.length > 0) {
      return true
    } else {
      return false
    }
  }

  return (
    <Page {...props}>
      {b2cAccounts.length > 0 ? (
        <div>
          <h1>{i18n.t('referrals.title')}</h1>
          <StepWizard
            transitions={{
              enterRight: '',
              enterLeft : '',
              exitRight : '',
              exitLeft  : ''
            }}
          >
            {!initialToken &&
              <TokenStep valid={token}/>
            }
            <ProductStep valid={productsStepValid()}/>
            <FormatStep/>
          </StepWizard>
        </div>
      ) : (
        <div style={{fontSize: '17px'}}>
          {i18n.t('referrals.no_b2c_description_first')}
          <br/>
          {i18n.t('referrals.no_b2c_description_last')}
          <br/>
          <hr/>
          <p>{i18n.t('referrals.contact_us')}</p>
          <strong><i className="fa fa-phone"/> (+34) 628 53 13 20</strong>
          <br/>
          <strong><i className="fa fa-envelope"/>comercial@ociobusiness.es</strong>
        </div>
      )}
    </Page>
  )
}

export default Referrals
