import React from 'react'
import Page from 'ui/page'
import {Button} from 'reactstrap'
import { useTranslation } from 'react-i18next'

const UnauthorizedPage = (props) => {
  const {i18n} = useTranslation()

  return (
    <Page {...props}>
      <div className="alert alert-warning mb-4 d-flex justify-content-left" role="alert">
        <div className="w-100">
          <div className="alert-heading d-flex justify-content-between m-0 p-0">
            <div className="align-self-center">
              <h4 className="alert-heading">{i18n.t('unauthorized_page.title')}</h4>
              <hr/>
              <p className="m-0">{i18n.t('unauthorized_page.description')}</p>
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default UnauthorizedPage
