import React from 'react'
import QRCode from 'react-qr-code'
import waiting from 'img/paygold/waiting.gif'
import step1 from 'img/paygold/step1'
import step2 from 'img/paygold/step2'
import step3 from 'img/paygold/step3'

const PaygoldCallout = ({cart}) => {
  return (
    <React.Fragment>
      <div className="alert alert-info mb-4 text-center border border-primary" role="alert">
        <h4 className="alert-heading">Esperando resultado de la operación de pago...</h4>
        <h5>Se ha enviado una solicitud de pago al teléfono y correo del cliente</h5>
        <img width="550" src={waiting}></img>
      </div>

      <div className="d-flex justify-content-center" style={{fontSize: '18px'}}>
        <img width="230" src={step1}></img>
        <div className="align-self-center p-3 py-4 m-3">
          <p>Se enviará al cliente un SMS y un correo electrónico a las<br/> direcciones especificadas durante el proceso de compra</p>
          <p>También puede acceder al enlace de pago <br/>escaneando el QR de la derecha</p>
        </div>
        <div className="align-self-center">
          <QRCode size={170} value={cart.payment_request.ds_urlpago2fases}/>
        </div>
      </div>

      <hr/>

      <div className="d-flex justify-content-center" style={{fontSize: '18px'}}>
        <div className="align-self-center p-3 py-4 m-3">
          <p>El cliente debe acceder al enlace incluido en el cuerpo del mensaje recibido.<br/>
          A continuación debe introducir los datos de la tarjeta y confirmar el pago</p>
        </div>
        <img width="230" src={step2}></img>
      </div>

      <hr/>

      <div className="d-flex justify-content-center" style={{fontSize: '18px'}}>
        <img width="230" src={step3}></img>
        <div className="align-self-center p-3 py-4 m-3">
          <p>
            No es necesario hacer nada más.<br/>
            La entidad bancaria nos notificará el resultado del pago y en <br/>
          en unos segundos se le redirigirá <strong>automáticamente</strong> .
          </p>
          <p>
            <i>Si el resultado del pago fuera fallido podrá generar otra petición de pago.</i>
          </p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PaygoldCallout
