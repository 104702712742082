import React from 'react'
import {ModulePanel, ModuleHeader, ModuleBody, ModuleFooter} from 'containers/purchase/modules/module_panel'
import TicketTypesSelectList from 'containers/purchase/modules/ticket_types_module/ticket_types_select_list'
import { useTranslation } from 'react-i18next'

const TicketTypesSelectorModule = (props) => {
  const {machine, status, type = 'event_ticket'} = props
  const { t, i18n } = useTranslation()
  const [current, send] = machine
  const { tickets } = current.context

  const qtyTickets = () => tickets.map((ticket) => ticket.qty).reduce((accum, current) => accum + current)

  const TicketHeader = () => {
    return (
      <span>
        <i className="fas fa-ticket-alt mr-2" aria-hidden="true"></i>
        {status == 'completed' ? (
          i18n.t('ticket_types_module.selected_tickets', {qty: qtyTickets()})
        ) : (
          i18n.t('ticket_types_module.select_ticket_types')
        )}
      </span>
    )
  }

  return (
    <ModulePanel>
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send('REOPEN_TICKETSSTEP') : null}>
        <TicketHeader/>
      </ModuleHeader>
      <ModuleBody status={status} className={' '}>
        <TicketTypesSelectList
          type={type}
          data={tickets}
          onTicketQtyChanged={(tickets) => send({type: 'TICKETS_SELECTION', tickets: tickets})}
        />
      </ModuleBody>
      <ModuleFooter {...props}/>
    </ModulePanel>
  )
}

export default TicketTypesSelectorModule
