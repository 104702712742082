import React from 'react'
import Card from 'containers/list/products/single_product_card'

const ProductCard = (props) => {
  return (
    <Card {...props.product} height="165" hideWrapper newWindow/>
  )
}

export default ProductCard
