import React, {useEffect} from 'react'
import { Formik, Field } from 'formik'
import { Form, FormGroup, Row } from 'reactstrap'
import TextInput from 'ui/forms/text_input'
import TextArea from 'ui/forms/textarea'
import Select from 'ui/forms/select'
import RadioButtons from 'ui/forms/radio_buttons'
import DatePicker from 'ui/forms/date_picker'
import TimePicker from 'ui/forms/time_picker'
import AddressSelector from 'ui/forms/address_selector'
import {keyBy, mapValues} from 'lodash'
import * as Yup from 'yup'
import i18n from 'i18n/config'
import moment from 'moment'

const fieldComponentsMap = (uiType) => {
  const typesMap = {
    input: TextInput,
    textbox: TextArea,
    checkbox: TextInput,
    radio: RadioButtons,
    selector: Select,
    datepicker: DatePicker,
    datetimepicker: DatePicker,
    address_selector: AddressSelector,
    timepicker: TimePicker
  }

  return typesMap[uiType]
}

const initFormValues = (customFields) => customFields
  .map((customField) => ({[customField.id]: customField.field_type == 'boolean' ? false : ''}))
  .reduce((accum, current) => ({...accum, ...current}))

const buildSchema = (customFields) => {
  const validationTypes = {
    boolean: Yup.boolean(),
    text: Yup.string().max(200, i18n.t('validations:string.max')),
    number: Yup.number().integer().typeError(i18n.t('validations:integer')),
    date: Yup.string().max(200, i18n.t('validations:string.max')),
    datetime: Yup.date().min(moment().toDate(), i18n.t('validations:date.min')),
    option: Yup.number().integer(),
    time: Yup.date(),
  }

  let validationSchema = mapValues(keyBy(customFields, 'id'), (customField) => {
    let validation = validationTypes[customField.field_type]
    if (customField.required == true) {
      validation = validation.required(i18n.t('validations:required'))
    }
    return validation
  })

  return Yup.object().shape(validationSchema)
}

const inputProps = (customField) => {
  const {field_type, id, placeholder} = customField

  let options = []
  if (field_type == 'option') {
    options = customField.options.map((option) => {
      return {
        value: option.id,
        label: option.value
      }
    })
  }

  if (field_type == 'boolean') {
    options = [
      {
        value: true,
        label: 'Sí'
      },
      {
        value: false,
        label: 'No'
      }
    ]
  }

  let props = {
    name: id,
    id: id,
    values: options,
    defaultoption: placeholder
  }

  if (field_type == 'date') {
    props['showTimeSelect'] = false
    props['dateFormat'] = 'P'
  }

  if (field_type == 'datetime') {
    props['showTimeSelect'] = true
    props['dateFormat'] = 'Pp'
  }

  return props
}

const CustomFieldsForm = (props) => {
  const {customFields, machine} = props

  return (
    <Formik
      validationSchema={buildSchema(customFields)}
      initialValues={initFormValues(customFields)}
      onSubmit={(values) => {}}
    >
      {(form) => {
        const {
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          submitForm,
          validateForm
        } = form

        useEffect(() => {
          buildSchema(customFields)
        }, [])

        useEffect(() => {
          props.setForm(form)
        }, [values])

        return (
          <Form>
            <Row>
              {customFields.map((customField) => {
                const {description, field_type, id, label, name, params, placeholder, required, ui_type} = customField

                return (
                  <FormGroup key={id} className="col-12 col-md-6">
                    <Field
                      required={required ? true : false}
                      label={label}
                      placeholder={placeholder}
                      params={params}
                      component={fieldComponentsMap(ui_type)}
                      name={id}
                      machine={machine}
                      {...inputProps(customField)}
                    />
                  </FormGroup>
                )}
              )}
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CustomFieldsForm
