import React from 'react'
import {ModulePanel, ModuleHeader, ModuleBody, ModuleFooter} from 'containers/purchase/modules/module_panel'
import VisitorsForm from './visitors_form'
import {useTranslation} from 'react-i18next'
import {get} from 'lodash'

export const VisitorsModule = (props) => {
  const {machine, config, status, hide_prices} = props
  const { t, i18n } = useTranslation()
  const [current, send] = machine

  const fields = get(config, 'product.external_api_config.visitor_fields', ['name', 'card_id'])

  const setForm = (form) => {
    send({type: 'VISITORS_FORM_CHANGE', form: form})
  }

  return (
    <ModulePanel>
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send('REOPEN_VISITORSSTEP') : null}>
        <span>
          <i className="fa fa-users mr-2" aria-hidden="true"></i>
          {i18n.t('visitors_module.visitors_module_title')}
        </span>
      </ModuleHeader>
      <ModuleBody status={status}>
        <VisitorsForm
          visitors={current.context.visitors}
          setForm={setForm}
          fields={fields}
          machine={machine}
          hidePrices={hide_prices}
        />
      </ModuleBody>
      <ModuleFooter {...props}/>
    </ModulePanel>
  )
}

export default VisitorsModule
