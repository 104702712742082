import {assign} from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import moment from 'moment'
import {nextStep} from 'utils/step_utils'
import i18n from 'i18n/config'

const stepName  = 'secondVenueStep'

const fetchEvents = (ctx) => {
  return (
    new ApiFetcher({
      key: 'events',
      endpoint: ctx.endpoints.events,
      query: {date: ctx.day, venue_id: '1', second_event: true, event_date: ctx.event.date}
    }).call()
  )
}

const setError = assign({error: i18n.t('validations:events_fetch_error')})
const setEventGroups = assign({access_event_groups: (ctx, e) => e.data})
const setEvent = assign({access_event: (ctx, e) => e.event})
const setUnready = assign({ready: false})
const setReady = assign({ready: true})
const setValidationError = assign({error: i18n.t('event_state.day_and_event_is_required')})
const cleanEvent = assign({second_event: null})
const cleanError = assign({error: null})

// const eventAndDayPicked = (ctx, e) => ctx.day && e.event

// const isUniqueEventAndReady = (ctx) => {
//   if (ctx.event_groups.length <= 0) {
//     return false
//   }
//   const reducer = (accumulator, currentValue) => accumulator + currentValue
//   const total_events = ctx.event_groups.map((o) => o.events.length).reduce(reducer)
//   const uniqueEvents = total_events == 1 ? true : false
//
//   return uniqueEvents && ctx.ready ? true : false
// }

const eventState = (config) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {'': {
          target: 'prepareFetching',
          actions: [setUnready]
        }},
      },
      prepareFetching: {
        on: {'': {
          target: 'fetchingEvents',
          actions: [cleanError, 'startSpinner']
        }},
      },
      fetchingEvents: {
        invoke: {
          src: fetchEvents,
          onDone: {
            target: 'waitingEventPick',
            actions: [setEventGroups, 'stopSpinner']
          },
          onError: {
            target: '#purchase.error',
            actions: [setError, 'stopSpinner']
          }
        },
      },
      waitingEventPick: {
        // on: {
        //   '': [
        //     {
        //       target: '#purchase.' + nextStep(config, stepName),
        //       cond: isUniqueEventAndReady,
        //       actions: setUniqueEvent
        //     }
        //   ]
        // }
      }
    },
    on: {
      EVENT_SELECTION: [
        {
          target: nextStep(config, stepName),
          actions: [setEvent, setReady]
        },
        {
          actions: [setValidationError]
        }
      ]
    }
  }
}

export default eventState
