import React from 'react'
import TreeMenu from 'react-simple-tree-menu'
import {ListGroup, ListGroupItem} from 'reactstrap'
import closedIconImg from 'img/closedIcon.png'
import openedIconImg from 'img/openedIcon.png'
import styled from 'styled-components'
import cn from 'classnames'
import {isMobile} from 'react-device-detect'
import {useTranslation} from 'react-i18next'
// import 'styles/categories_filter.scss'

const DEFAULT_PADDING = 10
const ICON_SIZE = 8
const LEVEL_SPACE = 16

const CategoryMenu = styled(ListGroup)`
`

const CategoryItem = styled(ListGroupItem)`
  position: relative;
  cursor: pointer;
  padding-left: ${props => (props.nodes == 'true' || props.id == 'best_sellers' || props.id == 'favorites' ? 0 : 23) + DEFAULT_PADDING + ICON_SIZE + props.level * LEVEL_SPACE}px !important;
  z-index: ${props => props.focused ? 500 : 'unset'}
`

// Icon example
const iconStyle = {
  verticalAlign: 'text-bottom',
}

const openedIcon = <img src={openedIconImg} alt="-" style={iconStyle}></img>
const closedIcon = <img src={closedIconImg} alt="+" style={iconStyle}></img>

const ToggleIcon = ({ on }) => <span style={{ marginRight: 8 }}>{on ? openedIcon : closedIcon}</span>
const ListItem = (opts) =>
{
  const {
    level = 0,
    id,
    hasNodes,
    isOpen,
    label,
    toggleNode,
    onClick,
    active,
    focused,
    first,
    LabelComponent
  } = opts

  return (
    <CategoryItem
      onClick={onClick}
      className={cn([
        active ? 'active' : '',
        first ? 'rounded-0' : '',
        'py-2'
      ])}
      level={level}
      focused={focused.toString()}
      nodes={hasNodes.toString()}
      id={id}
    >
      {hasNodes && (
        <div
          style={{ display: 'inline-block' }}
          onClick={e => {
            hasNodes && toggleNode && toggleNode()
            e.stopPropagation()
          }}
        >
          <ToggleIcon on={isOpen} />
        </div>
      )}
      {LabelComponent ? (LabelComponent()) : label}
    </CategoryItem>
  )
}

const CategoriesFilter = (props) => {
  const {machine, treeData} = props
  const [current, send] = machine
  const context = current.context
  const {i18n} = useTranslation()

  const categoryFilterd = context.filters.find((filter) => filter.param == 'category')

  const bestSellerCategory = {
    key: 'best_sellers',
    label: i18n.t('categories_filter.best_sellers_label'),
    LabelComponent: () => <span><i className="fa fa-star mr-2 text-warning"/>{i18n.t('categories_filter.best_sellers_label')}</span>,
    className: 'list-group-item py-2'
  }
  const favoriteCategory = {
    key: 'favorites',
    label: i18n.t('categories_filter.favorites_label'),
    LabelComponent: () => <span><i className="fa fa-heart mr-2 text-danger"/>{i18n.t('categories_filter.favorites_label')}</span>,
    className: 'list-group-item py-2'
  }
  const newTreeData = [bestSellerCategory, favoriteCategory, ...treeData]

  const path = props.initialCategory && props.initialCategory.path.toString().split('/')
  const initialOpenNodes = path ? path.map((c, i) => path.slice(0, i + 1).reduce((acc, cur) => acc + '/' + cur)) : []

  return (
    <TreeMenu
      initialOpenNodes={initialOpenNodes}
      data={newTreeData}
      debounceTime={500}
      onClickItem={(item) => {
        const category = item.key.split('/').slice(-1)[0]
        if (categoryFilterd && categoryFilterd.value == category) {
          send({type: 'FILTER_REMOVE', param: 'category'})
        } else {
          send({type: 'FILTER_SELECTION', filter: {param: 'category', value: category, label: item.label}})
        }

        if (isMobile) {
          props.setIsOpen(!props.isOpen)
        }
      }}
    >
      {({ items }) => (
        <CategoryMenu style={{margin: '0px'}}>
          {items.map((props, i) => {
            let active = false
            const key = props.key.split('/')

            if (categoryFilterd && categoryFilterd.value == key[key.length - 1]) {
              active = true
            }

            return (
              <ListItem key={i} id={props.key} {...props} active={active} first={i == 0}/>
            )
          })}
        </CategoryMenu>
      )}
    </TreeMenu>
  )
}
export default CategoriesFilter
