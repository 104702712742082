import cn from 'classnames'
import {Formik} from 'formik'
import React, {useState, useMemo} from 'react'
import {Button, Col, Form, FormGroup, Row} from 'reactstrap'
import TextInput from 'ui/forms/text_input'
import Textarea from 'ui/forms/textarea'
import ApiFetcher from 'lib/api_fetcher'
import { useTranslation } from 'react-i18next'

const ContactForm = (props) => {
  const {
    email = 'soporte@ociobusiness.es',
    phone = '(+34) 628 53 13 20',
    showEmail = true,
    showPhone = true
  } = props
  const [sent, setSent] = useState(false)
  const { t, i18n } = useTranslation()

  const onSubmit = (values, { props, setSubmitting, setErrors }) => {
    new ApiFetcher({
      key: 'contact',
      endpoint: '/contact',
      method: 'POST',
      body: JSON.stringify(values)
    }).call()
      .then((res) => {
        if (res.success == true) {
          setSent(true)
        } else {
          setErrors(res.errors)
        }
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={useMemo(() => ({
        name: '',
        email: '',
        subject: '',
        message: ''
      }), [])
      }
    >
      {({
        submitForm,
        isValid,
        isSubmitting
      }) => (
        <React.Fragment>
          {sent ? (
            <h4 className="text-center"><i className="fa fa-check text-success mr-2"/>{i18n.t('header_contact_form.email_sent_message')}</h4>
          ) : (
            <Form>
              <p>{i18n.t('header_contact_form.instructions')}:</p>
              <FormGroup>
                <Row>
                  <Col>
                    <TextInput label={i18n.t('header_contact_form.name')} name="name" required/>
                  </Col>
                  <Col>
                    <TextInput label={i18n.t('header_contact_form.email')} name="email" type="email" required/>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col>
                    <TextInput label={i18n.t('header_contact_form.subject')} name="subject" required/>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col>
                    <Textarea label={i18n.t('header_contact_form.message')} name="message" required/>
                  </Col>
                </Row>
              </FormGroup>

              <Button
                size={'lg'}
                color='primary'
                className={cn([
                  'text-white w-100',
                  isSubmitting || !isValid ? 'disabled' : ''
                ])}
                onClick={() => submitForm()}
              >
                {isSubmitting ? (
                  i18n.t('header_contact_form.sending')
                ) : (
                  i18n.t('header_contact_form.sent')
                )}
              </Button>
              <hr/>
              {(showPhone || showEmail) &&
                <React.Fragment>
                  <p>{i18n.t('header_contact_form.other_channels_to_contact_us')}:</p>
                  {showPhone &&
                    <p><strong><i className="fa fa-phone mr-2" aria-hidden="true"></i>{i18n.t('header_contact_form.phone')}: </strong><a href={'tel:+34628531320'}>{phone}</a></p>
                  }
                  {showEmail &&
                    <p><strong><i className="fa fa-envelope mr-2" aria-hidden="true"></i>{i18n.t('header_contact_form.email')}: </strong><a href={`mailto:${email}`}>{email}</a></p>
                  }
                </React.Fragment>
              }

            </Form>
          )}
        </React.Fragment>

      )}
    </Formik>
  )

}

export default ContactForm
