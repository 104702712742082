import React from 'react'
import {Button} from 'reactstrap'

const CartNotReadyCallout = ({cart}) => {
  // if (cart.status == 'payment_pending') {
  //   return PaymentRedirectCallout
  // }

  if (cart.status == 'payment_failed') {
    return (
      <React.Fragment>
        <h2 className="mb-3">El pago no se ha completado</h2>
        <div className="alert alert-danger mb-4" role="alert">
          <p><i className="fa fa-warning mr-1"></i> Se ha recibido el siguiente mensaje de la pasarela de pago:</p>
          <h4 className="alert-heading">9915 - PAGO CANCELADO</h4>

          <p>El usuario ha cancelado el pago</p>
        </div>
        <p>
          Si el problema persiste pruebe con otra tarjeta.
        </p>
        <p>
          Algunas tarjetas tienen limitada su operativa en comercios online cuando se encuentran en otro país.
          Contáctenos si tiene dudas al respecto.
        </p>
        <p>
          A continuación, puede intentar de nuevo el pago clicando en el siguiente botón.
        </p>

        <Button color="success">Volver a pasarela de pagos</Button>
      </React.Fragment>
    )
  }

  if (cart.status == 'confirming') {
    return (
      <div className="alert alert-info mb-4" role="alert">
        <h4 className="alert-heading">Se está confirmando su reserva</h4>
        <p className="m-0">En unos momentos su reserva estará confirmada.</p>
        <p className="m-0">Espere unos segundos...</p>
      </div>
    )
  }
}

export default CartNotReadyCallout
