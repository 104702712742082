import React, {useState, useEffect, useMemo} from 'react'
import ApiFetcher from 'lib/api_fetcher'
import useLayout from 'utils/hooks/use_layout'
import PurchaseWrapper from 'ui/purchase_wrapper'
import PurchaseWizard from 'containers/purchase/purchase_wizard'
import PaymentRedirectCallout from 'containers/purchase/actions/payment_redirect_callout'
import PaymentFailedCallout from 'containers/purchase/actions/payment_failed_callout'
import PaygoldCallout from 'containers/purchase/actions/paygold_callout'
import PaymentModule from 'containers/purchase/modules/payment_module/payment_module'
import {isMobileOnly} from 'react-device-detect'
import {Row, Col} from 'reactstrap'
import Page from 'ui/page'
import {Button} from 'reactstrap'
import {useTranslation} from 'react-i18next'
import {Formik} from 'formik'
import cn from 'classnames'
import paymentRedirect from 'lib/payment_redirect'
import Modal from 'ui/modal'
import i18n from 'i18n/config'
import SummaryModule from 'containers/purchase/modules/summary_module/summary_module'

const POOLING_INTERVAL = 5000

const cartFetcher = (endpoint) => (
  new ApiFetcher({
    key: 'cart',
    method: 'GET',
    endpoint: endpoint
  }).call()
)

const PaymentLogic = (props) => {
  const {cart, endpoints} = props

  const payment_code = cart.payment_method.code

  if (payment_code == 'generic_credit_card') {
    return (
      <PaymentRedirectCallout cart={cart} endpoint={endpoints.payment_request}/>
    )
  }

  if (payment_code == 'paygold') {
    return <PaygoldCallout cart={cart}/>
  }

  return ''
}

const PaymentStep = (props) => {
  const {endpoints, user_config} = props
  const {user} = user_config
  const {payment_methods} = user
  const { t, i18n } = useTranslation()

  const generic_credit_card = payment_methods.find((pm) => pm.code == 'generic_credit_card')

  const mode = useLayout()
  const [cart, setCart] = useState()
  const [cartFetched, setCartFetched] = useState(false)
  const [pooling, setPooling] = useState(false)
  const [error, setError] = useState()
  const [redirectUrl, setRedirectUrl] = useState()
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  useEffect(() => {
    PubSub.publish('loading-page')

    cartFetcher(endpoints.reservation)
      .then((data) => {
        if (['payment_pending', 'payment_failed'].includes(data.reservation.status)) {
          setPooling(true)
        } else {
          setPooling(false)
        }

        setCart(data.reservation)
        setRedirectUrl(data.redirect_url)
      })
      .catch(() => setError(true))
      .finally(() => {
        PubSub.publish('loaded-page')
        setCartFetched(true)
      })


  }, [])

  useEffect(() => {
    if (!pooling) { return }

    const interval = setInterval(() => {
      cartFetcher(endpoints.reservation).then((data) => {
        setCart(data.reservation)
        setRedirectUrl(data.redirect_url)
      })
    }, POOLING_INTERVAL)
    return () => clearInterval(interval)
  }, [pooling])

  const onSubmit = (values, { props, setSubmitting, setErrors }) => {
    setSubmitting(true)
    new ApiFetcher({
      key: 'payment_request',
      method: 'PUT',
      endpoint: endpoints.payment_request,
      body: JSON.stringify({payment_method: {
        payment_method: values.payment_method,
        payment_method_params: {
          redirect_url: redirectUrl
        }
      }})
    }).call()
      .then((data) => {
        if (data.success != true) { return }

        const cart = data.reservation
        if (cart.payment_request.endpoint) {
          paymentRedirect(cart.payment_request.endpoint, cart.payment_request.params)
        } else {
          window.location.href = endpoints.payment
        }
      })
      .finally(() => setSubmitting(false))
  }

  if(!cartFetched) {
    return (
      <Page {...props}>
        <h2 className="text-center">{i18n.t('payment_step.fetching_cart')}...</h2>
      </Page>
    )
  }

  if(error) {
    return (
      <Page {...props}>
        <div className="alert alert-danger" role="alert">
          <h2>{i18n.t('payment_step.fetching_cart_error_title')}</h2>
          <div>{i18n.t('payment_step.fetching_cart_error_description')}</div>
        </div>
      </Page>
    )
  }

  if (['discarded', 'open'].includes(cart.status)) {
    window.location.href = endpoints.products
    return ''
  }

  if (['confirming', 'confirmed', 'human_processing_pending', 'failed'].includes(cart.status)) {
    window.location.href = endpoints.summary
    return ''
  }

  return (
    <Page {...props}>
      <Row>
        <Col className="mb-3">
          {cart.status == 'payment_pending' &&
            <PaymentLogic cart={cart} endpoints={endpoints}/>
          }

          {cart.status == 'payment_failed' &&
            <PaymentFailedCallout cart={cart} endpoint={endpoints.payment_request} paymentUrl={endpoints.payment}/>
          }
        </Col>
      </Row>
      <Row>
        <Col>
          {cart.payment_method.code == 'generic_credit_card' &&
            <div className="alert alert-info mb-4 d-flex justify-content-left" role="alert">
              <div>
                <p>
                  {i18n.t('payment_failed_callout.try_other_card')}
                </p>
                <p>
                  {i18n.t('payment_failed_callout.card_limitations')}
                </p>
                <p>
                  {i18n.t('payment_failed_callout.click_button_to_try_again')}
                </p>
              </div>
            </div>
          }

          <hr className="mt-5"/>

          <div className="mt-3 text-center">
            <Modal
              isOpen={modal}
              toggle={toggle}
              header={'Cambiar método de pago'}
            >
              <div className="alert-danger mb-3 p-3">
                <h4>{i18n.t('payment_step.important_warning')}</h4>
                <p>
                  {i18n.t('payment_step.warning_description')}
                </p>
              </div>
              <Formik
                onSubmit={onSubmit}
                validateOnMount={true}
                initialValues={{
                  payment_method: cart.payment_method.code,
                }}
              >
                {({
                  submitForm,
                  isValid,
                  isSubmitting
                }) => (
                  <div>
                    <PaymentModule name="payment_method" cart={cart} user={user}/>
                    <Button
                      size={'lg'}
                      color='primary'
                      className={cn([
                        'pull-right text-white w-100',
                        isSubmitting || !isValid ? 'disabled' : ''
                      ])}
                      onClick={() => submitForm()}
                    >
                      {isSubmitting ? (
                        i18n.t('payment_step.submitting')
                      ) : (
                        i18n.t('payment_step.change_payment_method')
                      )}
                    </Button>
                  </div>
                )}
              </Formik>
            </Modal>
            <Button color="primary" className="mb-3 btn-block text-white" onClick={toggle}>
              <strong><i className="fas fa-exchange-alt mr-2"></i> {i18n.t('payment_step.change_payment_method')}</strong>
            </Button>
          </div>
        </Col>
        <Col>
          <SummaryModule cart={cart}/>
        </Col>
      </Row>
    </Page>
  )
}

export default PaymentStep
