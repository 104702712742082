import React from 'react'
import styled from 'styled-components'

const InputStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;

  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.18);
  border-radius: 3px;

  &:focus {
    background: red;
  }

  button {
    padding: .7rem;
    width: 100%;
    height: 50px;
    min-width: 3rem;
    font-size: 1rem;
    font-weight: bold;
    border: 0 solid #dbdbdb;
    text-align: center;
    text-shadow: 0 1px 0 rgba(#fff, .6);

    cursor: pointer;

    &:hover {
      background: darken(#f3f3f3, 10%);
      color: darken(#888, 20%)
    }

    &.left {
      border-radius: 3px 0 0 3px;
    }

    &.right {
      border-radius: 0 3px 3px 0;
    }
  }

  input {
    width: 4rem;
    height: 50px;
    padding: .7rem;
    font-size: 1rem;

    border: 0;
    border-top: 0 solid #dbdbdb;
    border-bottom: 0 solid #dbdbdb;
    text-align: center;
  }
`

const QtyInput = (props) => {
  const {id, label, alternativeId, value, onInputChange, onIncrement, onDecrement, isMaxQty} = props
  return (
    <React.Fragment>
      {label &&
        <label className="d-flex justify-content-center">
          {label}
        </label>
      }
      <InputStyles className="quantity-input">
        <button
          className="left bg-light"
          onClick={(e) => {
            e.preventDefault()
            onDecrement()
          }}
        >
          &mdash;
        </button>
        <input id={id} alternative-id={alternativeId} type="text" value={value} onChange={(e) => onInputChange(e.target.value)} readOnly/>
        <button
          disabled={isMaxQty}
          className="right bg-light"
          onClick={(e) => {
            e.preventDefault()
            onIncrement()
          }}
        >
          &#xff0b;
        </button>
      </InputStyles>
    </React.Fragment>
  )
}

export default QtyInput
