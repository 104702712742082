import React from 'react'
import BaseModal from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {isMobile} from 'react-device-detect'

const baseStyles = (width = '1050px') => ({
  overlay: {
    paddingTop: 'auto'
  },
  modal: {
    top: isMobile ? '0.7em' : '6.7em',
    // transform: 'translateY(-50%)',
    maxWidth: width,
    minWidth: isMobile ? 'auto' : '1050px',
    width: 'auto',
    padding: '0',
    borderRadius: '3px'
  }
})

const Modal = ({
  header,
  footer,
  isOpen,
  toggle,
  children,
  styles,
  bodyStyle,
  width,
  closeOverlay = false,
  showCloseIcon = true
}) => {
  return (
    <BaseModal
      open={isOpen}
      onClose={toggle}
      closeIconSize={15}
      closeOnOverlayClick={closeOverlay}
      showCloseIcon={showCloseIcon}
      styles={{...baseStyles(width), ...styles}}
    >
      <div>
        {header &&
          <h4 style={{margin: '0', padding: '18px 30px', borderBottom: '1px solid #e5e5e5', fontWeight: 'bold', background: 'linear-gradient(180deg, rgba(255,255,255,1) 40%, rgba(243,243,243,1) 100%)'}}>
            {header instanceof Function ? (header()) : (header)}
          </h4>
        }
        <div style={{padding: '20px 30px 30px', ...bodyStyle}}>
          {children}
        </div>
        {footer &&
          <div style={{margin: '0', padding: '15px 30px', borderTop: '1px solid #e5e5e5'}}>
            {footer instanceof Function ? (footer()) : (footer)}
          </div>
        }
      </div>
    </BaseModal>
  )
}

export default Modal
