import {assign} from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import moment from 'moment'
import {nextStep} from 'utils/step_utils'
import i18n from 'i18n/config'

const stepName  = 'eventStep'

const fetchMonth = (ctx) => {
  return new ApiFetcher({
    key: 'daily_availability',
    endpoint: ctx.endpoints.daily_availability,
    query: {date: ctx.year_month}
  }).call()
}

const fetchEvents = (ctx) => {
  return (
    new ApiFetcher({
      key: 'events',
      endpoint: ctx.endpoints.events,
      query: {date: ctx.day}
    }).call()
  )
}

const setError = assign({error: i18n.t('validations:events_fetch_error')})
const setYearMonth = assign({year_month: (ctx, e) => e.year_month})
const setDaysAvailable = assign({days_available: (ctx, e) => e.data})
const setDay = assign({day: (ctx, e) => moment(e.day).format('YYYY-MM-DD')})
const setDayAsToday = assign({day: (ctx, e) => moment().format('YYYY-MM-DD')})
const setEventGroups = assign({event_groups: (ctx, e) => e.data})
const setEvent = assign({event: (ctx, e) => e.event})
const setUniqueEvent = assign({event: (ctx, e) => ctx.event_groups[0].events[0]})
const setUnready = assign({ready: false})
const setReady = assign({ready: true})
const setValidationError = assign({error: i18n.t('event_state.day_and_event_is_required')})
const cleanDay = assign({day: null})
const cleanEventGroups = assign({event_groups: null})
const cleanEvent = assign({event: null})
const cleanError = assign({error: null})
const cleanOrSetToday = assign({day: (ctx, e) => null})

const eventAndDayPicked = (ctx, e) => ctx.day && e.event

const isUniqueEventAndReady = (ctx) => {
  // TODO: Hard Disabled
  return false

  if (ctx.event_groups.length <= 0) {
    return false
  }
  const reducer = (accumulator, currentValue) => accumulator + currentValue
  const total_events = ctx.event_groups.map((o) => o.events.length).reduce(reducer)
  const uniqueEvents = total_events == 1 ? true : false

  return uniqueEvents && ctx.ready ? true : false
}

const todayIsAvailable = (ctx, e) => {
  const isCurrentMonth = moment(ctx.day).format('YYYY-MM') == ctx.year_month

  if (!isCurrentMonth) { return false }

  if (
    ctx.days_available == null ||
    (ctx.days_available.length > 0 && ctx.days_available.some((d) => d == moment().format('YYYY-MM-DD')))
  ) {
    return true
  } else {
    return false
  }
}

const eventState = (config) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {'': {
          target: 'prepareFetchingCalendar'
        }},
      },
      prepareFetchingCalendar: {
        on: {'': {
          target: 'fetchingCalendar',
          actions: ['startSpinner', setUnready]
        }},
      },
      fetchingCalendar: {
        invoke: {
          src: fetchMonth,
          onDone: {
            target: 'fetchEventRequiredDecision',
            actions: [setDaysAvailable, 'stopSpinner']
          },
          onError: {
            target: '#purchase.error',
            actions: [setError, 'stopSpinner']
          }
        },
      },
      fetchEventRequiredDecision: {
        on: {'': [
          {
            target: 'prepareFetchingEvents',
            cond: todayIsAvailable,
            actions: [setDayAsToday]
          },
          {
            target: 'waitingDayPick'
          }
        ]}
      },
      waitingDayPick: {},
      prepareFetchingEvents: {
        on: {'': {
          target: 'fetchingEvents',
          actions: [cleanError, 'startSpinner']
        }},
      },
      fetchingEvents: {
        invoke: {
          src: fetchEvents,
          onDone: {
            target: 'waitingEventPick',
            actions: [setEventGroups, 'stopSpinner']
          },
          onError: {
            target: '#purchase.error',
            actions: [setError, 'stopSpinner']
          }
        },
      },
      waitingEventPick: {
        on: {
          '': [
            {
              target: '#purchase.' + nextStep(config, stepName),
              cond: isUniqueEventAndReady,
              actions: setUniqueEvent
            }
          ]
        }
      }
    },
    on: {
      MONTH_CHANGE: {
        target: '.prepareFetchingCalendar',
        actions: [cleanOrSetToday, cleanEventGroups, cleanEvent, setYearMonth]
      },
      DAY_SELECTION: {
        target: '.prepareFetchingEvents',
        actions: [cleanEventGroups, cleanEvent, setDay, setReady]
      },
      EVENT_SELECTION: [
        {
          target: nextStep(config, stepName),
          actions: [setEvent, setReady],
          cond: eventAndDayPicked
        },
        {
          actions: [setValidationError]
        }
      ]
    }
  }
}

export default eventState
