import React from 'react'
import NumericInput from 'react-numeric-input'
import { ListGroup, ListGroupItem, Button } from 'reactstrap'
import {filter, findIndex} from 'lodash'
import { useTranslation } from 'react-i18next'
import {currency} from 'i18n/config'
import {groupBy} from 'lodash'
import {isMobile} from 'react-device-detect'
import QtyInput from 'ui/forms/qty_input'

const TicketTypesSelectList = ({data, type, onTicketQtyChanged}) => {
  const { t, i18n } = useTranslation()

  const GroupItem = (props) => {
    // const {id, alternative_id, name, description, total_price, discount, additional_fee, base_price, qty, is_available, available,
    //   show_price, thumbnail, seat_capacity, luggage_capacity, max_tickets, min_tickets} = props
    const {tickets} = props

    const {
      id, group_name, group_id
    } = tickets[0]

    return (
      <ListGroupItem key={group_id} className={'rounded-0 d-flex justify-content-between flex-column flex-md-row'}>
        <div className={'p-2 pb-3 align-self-center text-center text-md-left'}>
          <strong style={{fontWeight: 'bold'}}>{group_name}</strong>
        </div>

        <div className={'d-flex justify-content-end align-self-center flex-column flex-sm-row'}>
          <select name="select" groupId={group_id} onChange={(e) => {


            let newtickets = [...data]

            if (!e.target.value) {
              newtickets
                .filter((ticket) => ticket.group_id == e.target.getAttribute('groupId'))
                .forEach((ticket) => {
                  let temporalIndex = findIndex(newtickets, {id: ticket.id})
                  newtickets[temporalIndex]['qty'] = 0
                })

              return onTicketQtyChanged(newtickets)
            }

            let indexChanged = findIndex(newtickets, {id: e.target.value})

            newtickets.filter((ticket) => ticket.group_id == newtickets[indexChanged].group_id)
            newtickets.forEach((ticket) => {
              let temporalIndex = findIndex(newtickets, {id: ticket.id})
              if (newtickets[temporalIndex].group_id == newtickets[indexChanged].group_id) {
                newtickets[temporalIndex]['qty'] = 0
              }
            })

            newtickets[indexChanged]['qty'] = 1

            onTicketQtyChanged(newtickets)
          }}>
            <option value="">No one</option>
            {tickets.map((ticket) => {
              const {
                id, name, total_price
              } = ticket

              return (
                <option key={id} value={id} selected={
                  ticket.qty > 0
                }>
                  {name} - {currency(total_price)}
                </option>
              )
            })}
          </select>
        </div>
      </ListGroupItem>
    )




    return 'hola'

    return (
      <ListGroupItem key={id} className={'rounded-0 d-flex justify-content-between flex-column flex-md-row'}>
        <div className={'p-2 pb-3 align-self-center text-center text-md-left'}>
          {thumbnail &&
            <img className="mr-4" src={thumbnail} style={{width: '150px'}}/>
          }
          <strong style={{fontWeight: 'bold'}}>{name}</strong>

          <small style={{marginLeft: '12px'}}>{description}</small>
        </div>

        <div className={'d-flex justify-content-end align-self-center flex-column flex-sm-row'}>
          <select name="select" onChange={() => console.log('hola')}>
            <option value="value1">Value 1</option>
            <option value="value2" selected>Value 2</option>
            <option value="value3">Value 3</option>
          </select>
        </div>
      </ListGroupItem>
    )
  }

  return (
    <ListGroup>
      {Object.values(groupBy(data, 'group_id')).map((tickets, i) => (
        <GroupItem tickets={tickets} key={i}/>
      ))}
    </ListGroup>
  )
}

export default TicketTypesSelectList
