import React from 'react'

const DataList = ({label, value}) => {
  if (!value) {
    return ''
  }

  return (
    <React.Fragment>
      <dt className="col-sm-6 py-1">{label}</dt>
      <dd className="col-sm-6 py-1">{value}</dd>
    </React.Fragment>
  )
}

export default DataList
