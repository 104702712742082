import React, {useState} from 'react'
import {Row, Col} from 'reactstrap'
import styled from 'styled-components'
import poweredOcio from 'img/powered-ociobusiness-white-h'
import { useTranslation } from 'react-i18next'
import {usePartial} from 'i18n/config'
import Modal from 'ui/modal'
import clientConfig from 'config/client_config.json'

const FooterWrapper = styled.footer`
  margin-top: 7em;
`

const Footer = (props) => {
  const {i18n} = useTranslation()
  const {supportTelephone} = clientConfig
  const {site_name} = props.client_params

  const LegalWarningLink = () => {
    const LegalWarning = usePartial('auth/legal_warning', {supportTelephone: supportTelephone, site_name: site_name})
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)

    return (
      <React.Fragment>
        <a className="text-white mr-3" style={{cursor: 'pointer'}} onClick={() => toggle()}>{i18n.t('sign_in.legal_warning')}</a>
        <Modal
          isOpen={modal}
          toggle={toggle}
          header={i18n.t('sign_in.legal_warning')}
          width={'90%'}
        >
          <LegalWarning supportTelephone={supportTelephone} site_name={site_name}/>
        </Modal>
      </React.Fragment>
    )
  }

  const ReturnPolicyLink = () => {
    const ReturnPolicy = usePartial('auth/return_policy', {supportTelephone: supportTelephone, site_name: site_name})
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)

    return (
      <React.Fragment>
        <a className="text-white" style={{cursor: 'pointer'}} onClick={() => toggle()}>{i18n.t('sign_in.return_policy')}</a>
        <Modal
          isOpen={modal}
          toggle={toggle}
          header={i18n.t('sign_in.return_policy')}
          width={'90%'}
        >
          <ReturnPolicy supportTelephone={supportTelephone}/>
        </Modal>
      </React.Fragment>
    )
  }

  const year = new Date().getFullYear()

  return (
    <FooterWrapper className="bg-dark py-3 text-white">
      <div className="container">
        <Row className="d-flex flex-column-reverse flex-md-row">
          <Col xs="12" md="4" className="d-flex justify-content-center justify-content-md-start">{site_name} {year}</Col>
          <Col xs="12" md="4" className="d-flex justify-content-center my-3 my-md-0">
            <a className="text-white text-decoration-none" href="http://ociobusiness.es/" target={'_blank'} rel="noreferrer">Powered by <img height="20" src={poweredOcio}></img></a>
          </Col>
          <Col xs="12" md="4" className="d-flex justify-content-center justify-content-md-end">
            <LegalWarningLink/>
            <ReturnPolicyLink/>
          </Col>
        </Row>
      </div>

    </FooterWrapper>
  )
}

export default Footer
