import EventModule from 'containers/purchase/modules/event_module/event_module'
import TicketTypesModule from 'containers/purchase/modules/ticket_types_module/ticket_types_module'
import multieventState from 'containers/purchase/modules/event_module/multievent_state'
import ticketTypesState from 'containers/purchase/modules/ticket_types_module/ticket_types_state'
import DayModule from 'containers/purchase/modules/day_module/day_module'
import dayState from 'containers/purchase/modules/day_module/day_state'

const reservationMapper = (ctx) => {
  return {
    event_id: ctx.event.id,
    ticket_types: ctx.tickets,
    params: {
      events: ctx.events
    }
  }
}

const config = (params) => {
  const {external_api_config} = params
  const predefinedEvent = external_api_config && external_api_config.event
  const eventPickMode = external_api_config && external_api_config.event_pick_mode
  let steps = {}

  if (!predefinedEvent) {
    if (eventPickMode == 'day') {
      steps['dayStep'] = {
        component: DayModule,
        autoNext: true,
        states: dayState,
        context: {
          year_month: params.initialYearMonth,
          event_groups: [],
          event: null
        }
      }
    } else {
      steps['eventStep'] = {
        component: EventModule,
        autoNext: true,
        states: multieventState,
        context: {
          year_month: params.initialYearMonth,
          event_groups: [],
          event: []
        }
      }
    }
  }

  steps['ticketsStep'] = {
    component: TicketTypesModule,
    autoNext: false,
    states: ticketTypesState,
    context: {
      event: {
        id: predefinedEvent ? external_api_config.event : null
      },
      tickets: []
    }
  }

  return {
    reservationMapper: reservationMapper,
    steps: steps
  }
}

export default config
