import React, {useState} from 'react'
import parse from 'react-html-parser'
import styled from 'styled-components'
import { Button, Form, FormGroup, Input, Card, CardTitle, Row, Col } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import {isMobile} from 'react-device-detect'
import * as Yup from 'yup'
import CopyBlock from 'ui/copy_block'
import Modal from 'ui/modal'
import ApiFetcher from 'lib/api_fetcher'
import thumbnailNotAvailable from 'img/image-not-available.png'

const HeaderBackground = styled.div`
  position: relative;
  background-image: url(${props => props.background});
  /* Set a specific height */
  height: 310px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: black;
    background: linear-gradient(-90deg, rgba(2,0,36,1) 0%, rgba(2,0,36,0.5) 20%, rgba(255,255,255,0) 40%);
    opacity: 0.7;
    z-index: 0;
  }
`

const HeaderContent = styled.div`
  position: relative;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  color: #333;
  line-height: 1.5;
  margin: auto;
  z-index: 1;

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.6);
  }
`

const Title = styled.h2`
  color: #214c4f;
  font-size: ${props => props.size > 44 ? '1.7' : '2.3'}rem;

  @media (max-width: 768px) {
     font-size: ${props => props.size > 44 ? '1.35' : '1.8'}rem;
  }
`

const Thumbnail = styled.img`
  height: 100%;
  width: 16em;
  z-index: -99999;
  clip-path: polygon(0% 0%, 85% 0, 100% 50%, 85% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 75% 98%, 0 100%);
  clip-path: circle(98.8% at 14% 33%);
  border-radius: 10px 0 0 10px;
`

const Headline = styled.small.attrs({
  className: 'py-2'
})`
  display: block;
  min-height: 35px;
  color: #214c4f;
  font-size: ${props => props.size > 215 ? '14' : '17'}px;
`

const Notification = styled.div.attrs({
  className: 'alert alert-info mb-0 mb-md-3 border-top-0'
})`
  border-radius: 0 0 5px 5px
`

const ProductHeader = (props) => {
  const {product, template_link} = props
  const {notification, title, description, cover_image} = product
  const {i18n} = useTranslation()
  const saleChannel = props.user.user.sale_channel
  const b2cAccounts = props.user.client.b2c_accounts

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const B2cModal = () => {
    const [link, setLink] = useState('')
    const [linkSent, setLinkSent] = useState(false)
    const [email, setEmail] = useState('')
    const { t, i18n } = useTranslation()
    const token = b2cAccounts && b2cAccounts[0] && b2cAccounts[0].token

    const fetchReferral = () => {
      return new ApiFetcher({
        key: 'retrieve_referral',
        body: JSON.stringify({
          user_token: token,
          modality: 'product',
          destination: props.destination.id,
          product: product.id,
          format: 'web'
        }),
        endpoint: props.endpoints.retrieve_referral,
        method: 'POST'
      }).call()
    }

    React.useEffect(() => {
      if (!modal || !token) { return }

      let isMounted = true

      fetchReferral().then((res) => {
        if (isMounted) setLink(res.url)
      })

      return () => { isMounted = false }
    }, [modal])

    const sendLink = () => {
      PubSub.publish('loading-page')

      new ApiFetcher({
        key: 'send_b2c_email',
        method: 'POST',
        endpoint: props.endpoints.send_b2c_email,
        body: JSON.stringify({
          email: email,
          product_id: product.id,
          link: link
        })
      }).call().then(() => {
        setLinkSent(true)
        PubSub.publish('loaded-page')
      })
    }

    const isValid = email && Yup.string().email().isValidSync(email)

    return (
      <Modal
        isOpen={modal}
        toggle={toggle}
        header={i18n.t('b2c_modal.title')}
        width={'450px'}
      >
        <Row>
          <Col>
            <Card body outline color="secondary" className="text-center mb-3">
              <CardTitle><h5>{i18n.t('b2c_modal.copy_link_title')}</h5></CardTitle>
              <p>{i18n.t('b2c_modal.direct_link.instructions')}</p>
              <CopyBlock color="secondary" value={link}/>
            </Card>

            <Card body outline color="secondary" className="text-center">
              <CardTitle><h5>{i18n.t('b2c_modal.send_mail_title')}</h5></CardTitle>
              <p>{i18n.t('b2c_modal.send_email.instructions')}</p>
              {!linkSent &&
                <Form>
                  <FormGroup className="py-1">
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="customer@email.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormGroup>
                  <Button disabled={!isValid} onClick={sendLink} className="w-100">
                    {isValid ? (
                      i18n.t('b2c_modal.send_button_ready')
                    ) : (
                      i18n.t('b2c_modal.send_button_unready')
                    )}
                  </Button>
                </Form>
              }

              {linkSent &&
                <Button disabled>
                  <i className="fa fa-check mr-2"/>
                  {i18n.t('b2c_modal.link_sent')}
                </Button>
              }
            </Card>
          </Col>
        </Row>
      </Modal>
    )
  }

  const SideButton = ({color, position, onClick, children, style}) => {
    let borderRadius
    if (position == 'top') {
      borderRadius = '0 10px 0 0'
    } else if (position == 'bottom') {
      borderRadius = '0 0 10px 0'
    } else {
      borderRadius = '0 10px 10px 0'
    }

    return (
      <div
        className={cn(
          'bg-primary d-flex align-content-center justify-content-center flex-column',
          color == 'dark' ? 'bg-dark' : 'bg-primary',
          position == 'fullsize' ? 'h-100' : 'h-50'
        )}
        style={{
          cursor: 'pointer',
          borderRadius: borderRadius

        }}
        onClick={onClick}
      >
        <div>{children}</div>
      </div>
    )
  }

  const isShareButton = saleChannel == 'b2b' && b2cAccounts.length > 0

  return (
    <div className={props.className}>
      {isMobile &&
        <div className="container d-flex h-100 mt-3">
          <Row className="m-0">
            <Col xs="12" className="d-flex bg-light p-3 rounded-top">
              <div className="align-self-center">
                <Title size={title.length}>{title}</Title>
                {description &&
                  <Headline size={description.length}>{description}</Headline>
                }
              </div>
            </Col>
            <Col xs="12" className="p-0">
              <img width="100%" src={cover_image ? cover_image : thumbnailNotAvailable}/>
            </Col>
            {(template_link || isShareButton) &&
              <div className="d-flex justify-content-between w-100">
                {template_link &&
                  <a className="btn-block m-0 rounded-0 btn btn-info" style={{width: isShareButton ? '50%' : '100%'}} onClick={() => window.open(template_link)}>
                    <strong><i className="fa fa-plus-circle"/> Info</strong>
                  </a>
                }
                {isShareButton &&
                  <button
                    type="button"
                    className={cn("btn-block rounded-0 btn bg-dark text-white m-0", template_link ? 'w-50' : 'w-100')}
                    onClick={() => toggle(true)}
                  >
                    <i className="fa fa-share-alt"/> {i18n.t('b2c_modal.send_email.share')}
                  </button>
                }
              </div>
            }
            <B2cModal {...props} modal={modal} toggle={toggle}/>
          </Row>
        </div>
      }

      {!isMobile &&
        <HeaderBackground className="" background={props.destination.background_url}>
          <div className="container d-flex h-100">
            <HeaderContent className="shadow-lg w-100">
              <Row className="m-0">
                <Col xs="12" sm="6" md="4" lg="3"  className="pl-0">
                  <Thumbnail src={cover_image ? cover_image : thumbnailNotAvailable}/>
                </Col>
                <Col xs="12" sm="6" md="8" lg="8"  className="d-flex">
                  <div className="align-self-center">
                    <Title size={title && title.length}>{title}</Title>
                    <Headline size={description && description.length}>{description}</Headline>
                  </div>
                </Col>
                {(template_link || isShareButton) &&
                  <Col xs="12" sm="6" md="8" lg="1"  className="p-0 text-white text-center">
                    {template_link &&
                      <SideButton position={isShareButton ? 'top' : 'fullsize'} onClick={() => window.open(template_link)}>
                        <i className="fa fa-info-circle"/> {i18n.t('product_header.info_button')}
                      </SideButton>
                    }
                    {isShareButton &&
                      <SideButton color="dark" position={template_link ? 'bottom' : 'fullsize'} onClick={() => toggle(true)}>
                        <i className="fa fa-share-alt"/> {i18n.t('product_header.share_button')}
                      </SideButton>
                    }
                    <B2cModal {...props} modal={modal} toggle={toggle}/>
                  </Col>
                }
              </Row>
            </HeaderContent>
          </div>
        </HeaderBackground>
      }

      {notification &&
        <div className="container">
          <Notification>
            {parse(notification)}
          </Notification>
        </div>
      }
    </div>
  )
}

export default ProductHeader
