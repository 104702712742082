import ApiFetcher from 'lib/api_fetcher'
import AppProvider from 'utils/app_provider'
import React, { useState, useEffect, useContext } from 'react'
import {isBrowser, isMobile} from 'react-device-detect'
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarText,
  NavbarToggler as NavbarTogglerBase,
  NavItem as NavItemBase,
  NavLink as NavLinkBase,
  UncontrolledDropdown as UncontrolledDropdownBase,
  ListGroup,
  ListGroupItem,
  Button,
} from 'reactstrap'
import Modal from 'ui/modal'
import {useTranslation} from 'react-i18next'
import ContactForm from './contact_form'
import DestinationSelector from './destination_selector'
import SearchBox from './search_box'
import LanguageSwitcher from 'ui/language_switcher'
import CartSummary from './cart_summary'
import {themeBydomain} from 'lib/themes'
import {get} from 'lodash'
import time from 'i18n/time'
import styled from 'styled-components'
import AppContext from 'utils/app_context'
import infoIcon from 'img/icons/info'
import cartIcon from 'img/icons/cart'
import logoutIcon from 'img/icons/logout'
import { BorderWidth } from 'react-bootstrap-icons'

const NavItem = styled(NavItemBase).attrs({
  className: 'd-flex align-items-center py-1 flex-column flex-md-row'
})`
  transition: 0.1s;
  position: relative;
  cursor: pointer;

  @media (max-width: 768px) {
     border-top: 1px solid;
  }
`

const CartItem = styled(NavItem).attrs({
  className: ''
})`

  @media (max-width: 768px) {
     border: 0;
  }
`

const UncontrolledDropdown = styled(UncontrolledDropdownBase).attrs({
  className: 'd-flex align-items-center py-1 flex-column flex-md-row'
})`
  transition: 0.1s;
  position: relative;
  cursor: pointer;

  @media (max-width: 768px) {
     border-top: 1px solid;
  }
`

const NavLink = styled(NavLinkBase).attrs({
  className: 'p-2 px-md-3 py-md-3'
})`
`

const NavbarToggler = styled(NavbarTogglerBase).attrs({
  className: ''
})`
  border-width: 3px !important;
`

const AppNavbar = (props) => {
  const {cart, cart_params, client_params} = props
  const {scssVariables} = useContext(AppContext)
  const {enable_cart, auto_open_cart} = cart_params
  const {root, links, user, language_links, destination_id, destination_link, change_destination_link, product_link, logout_link, show_menu} = props.menu_props
  const [isOpen, setIsOpen] = useState(false)

  const {theme} = user.user_data.user

  const toggle = () => setIsOpen(!isOpen)

  const goToPage = (url) => window.location.href = url

  const destinations = user.user_data.user.destinations
  const channel = user.user_data.user.sale_channel

  const TopBar = () => {
    return (
      <div className="topbar" style={{paddingTop: '0.1em', paddingBottom: '0.1em'}}>
        <div className="container d-md-flex align-items-center">
          <div className="mr-auto">
            <div className="d-flex d-md-inline-flex flex-column flex-sm-row topbar-first">
              {isBrowser &&
                <React.Fragment>
                  <div className="text-center px-2 pl-sm-0">
                    <i className="fa fa-phone mr-2"/><a href={`tel:${client_params.support_telephone}`}>{client_params.support_telephone_label}</a>
                  </div>

                  <div className="text-center px-2">
                    <i className="fa fa-envelope mr-1"/><a href={`mailto:${client_params.support_email}`}>{client_params.support_email}</a>
                  </div>
                </React.Fragment>
              }
            </div>
          </div>
          <div className="justify-content-center py-1 search-box-content">
            <SearchBox productLink={product_link} user={user}/>
          </div>
        </div>
      </div>
    )
  }

  const ContactLink = () => {
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => setIsOpen(!isOpen)
    const {i18n} = useTranslation()

    return (
      <React.Fragment>
        <NavItem>
          <NavLink active={false} style={{cursor: 'pointer'}} onClick={() => toggle()}>
            <img className="themed-icon" src={infoIcon}/>
          </NavLink>
        </NavItem>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          header={i18n.t('navbar.contact_link.modal_title')}
        >
          <ContactForm/>
        </Modal>
      </React.Fragment>
    )
  }

  const SignOutLink = ({endpoint}) => {
    const logoutRequest = () => {
      new ApiFetcher({
        key: 'sign_out',
        method: 'DELETE',
        endpoint: endpoint
      }).call()
        .then(({redirect_to}) => {
          window.location.replace(redirect_to)
        })
    }

    return (
      <a onClick={logoutRequest} style={{cursor: 'pointer'}}>
        <span className="mr-1">{user.username}</span>
        <img className="themed-icon" src={logoutIcon}/>
      </a>
    )
  }

  if (!show_menu) { return '' }

  const brand_redirect_url = theme && theme.settings && theme.settings.brand_logo && theme.settings.brand_logo.redirect_url

  const Brand = () => {
    const clientLogoWidth = get(user, 'user_data.client.attachments.settings.brand_logo.width')
    const clientLogoHeight = get(user, 'user_data.client.attachments.settings.brand_logo.height')

    const logoThemeWidth = get(theme, 'settings.brand_logo.width', '100%')
    const logoThemeHeight = get(theme, 'settings.brand_logo.height', '35px')
    const imageRendering = get(theme, 'settings.brand_logo.image_rendering', 'auto')

    if (theme && theme.brand_logo_url) {
      return <img src={theme.brand_logo_url} width={clientLogoWidth || logoThemeWidth} height={clientLogoHeight || logoThemeHeight} style={{imageRendering: imageRendering}}/>
    } else if (theme && theme.code) {
      return theme.code
    } else {
      return themeBydomain().name
    }
  }

  const CartIcon = (props) => {
    const [open, setOpen] = useState(false)
    const {items} = props

    useEffect(() => {
      if (auto_open_cart && cart && cart.items.length > 0) {
        setOpen(true)
      }
    }, [cart])

    const Bubble = () => {
      return (
        <div style={{
          position: 'absolute',
          color: 'white',
          width: '17px',
          height: '17px',
          background: 'red',
          bottom: '0px',
          left: '5px',
          textAlign: 'center',
          borderRadius: '30px',
          fontWeight: 'bold',
          fontSize: '0.8em'
        }}>
          {items.length}
        </div>
      )
    }

    return (
      <React.Fragment>
        {open &&
          <CartSummary cart={cart} params={cart_params} setOpen={setOpen}/>
        }
        <CartItem
          onClick={() => setOpen(!open)}
          style={{position: 'relative', cursor: 'pointer', padding: '0'}}
          className="py-0"
        >
          {cart &&
            <Bubble/>
          }

          <NavLink className="p-0">
            <img className="themed-icon" src={cartIcon}/>
          </NavLink>
        </CartItem>
      </React.Fragment>
    )
  }

  return (
    <AppProvider user={user}>
      <div className="shadow-sm">
        <Navbar dark expand="lg" className='p-0'>
          <div className="container h-100 d-flex flex-row">
            <div className="d-flex flex-column flex-md-row" style={{width: isMobile ? '100%' : 'auto'}}>
              <div className='d-flex flex-row justify-content-between px-2 pt-2 p-md-0'>
                <NavbarBrand className="px-2 py-0 mr-md-2 d-flex justify-content-between align-items-center" tag="div" style={{fontSize:'1.7em'}}>
                  <a href={brand_redirect_url ? brand_redirect_url : root} className="text-capitalize">
                    <Brand/>
                  </a>
                </NavbarBrand>
                <div>
                </div>
                <NavbarToggler onClick={toggle}>
                  <i class="fas fa-bars"></i>
                </NavbarToggler>
              </div>
              <div className='d-flex justify-content-between px-2'>
                <Nav className="flex-row justify-content-end align-items-center align-items-center align-items-md-stretch ml-md-auto py-0" navbar>
                  {destination_id && (destinations && destinations.length > 1) &&
                    <NavItem className="px-0" style={{borderTop: '0px'}}>
                      <DestinationSelector
                        url={destination_link}
                        destination_id={destination_id}
                        change_destination_link={change_destination_link}
                        className={"nav-link px-2 px-md-3 py-0 py-md-2"}
                        style={isMobile ? {fontSize: '1em'} : {border: '1px dotted', borderTop: '0px', borderBottom: '0px', fontSize: '1.2em'}}
                      />
                    </NavItem>
                  }
                </Nav>
                <Nav className="flex-row justify-content-end ml-md-auto p-0 navbar">
                  {isBrowser &&
                    <NavItem>
                      <NavLink className="py-1">
                        <LanguageSwitcher en es fr urls={language_links}/>
                      </NavLink>
                    </NavItem>
                  }

                  {enable_cart && isMobile &&
                    <CartIcon {...cart}/>
                  }
                </Nav>
              </div>
            </div>

            <Collapse isOpen={isOpen} navbar className="h-100">
              <Nav className="justify-content-end ml-auto main-menu py-0 h-100" navbar>
                {links.map(({label, link, active, links}, i) => (
                  <React.Fragment key={'i' + i}>
                    {links && links.length > 0 ? (
                      <UncontrolledDropdown nav inNavbar className="px-0 px-md-2">
                        <DropdownToggle nav caret className="p-2 p-md-0">
                          <span className="menu-dropdown-label mr-1">{label}</span>
                        </DropdownToggle>
                        <DropdownMenu right className="m-0">
                          {links.map(({label, link, active}, s) => (
                            <DropdownItem key={'s' + s} onClick={() => goToPage(link)}>
                              {label}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    ) : (
                      <NavItem>
                        <NavLink active={active} href={link}>
                          <span>{label}</span>
                        </NavLink>
                      </NavItem>
                    )}
                  </React.Fragment>
                ))}
                <ContactLink/>
                {isMobile &&
                  <NavItem className="text-center">
                    <NavLink>
                      <LanguageSwitcher en es fr urls={language_links}/>
                    </NavLink>
                  </NavItem>
                }
                {enable_cart && isBrowser &&
                  <CartIcon {...cart}/>
                }
              </Nav>
              {!['b2c', 'staff'].includes(channel) &&
                <NavItem>
                  <NavLink>
                    <SignOutLink endpoint={logout_link}/>
                  </NavLink>
                </NavItem>
              }
            </Collapse>
          </div>
        </Navbar>
        <TopBar/>
      </div>
    </AppProvider>
  )
}

export default AppNavbar
