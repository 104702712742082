import React, {useState, useEffect, useMemo} from 'react'
import {useTable, usePagination, useFlexLayout, useResizeColumns, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce} from 'react-table'
import {join, merge, find} from 'lodash'
import styled from 'styled-components'
import {Button, ButtonGroup} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import FieldInfo from 'ui/field_info'

const headerProps = (props, {column}) => {
  return [
    props,
    {
      style: {
        ...column.headerStyle,
        justifyContent: column.justifyContent,
        alignItems: 'flex-start',
        display: 'flex'
      },
    },
  ]
}

const cellProps = (props, {cell}) => {
  return [
    props,
    {
      style: {
        ...(cell.column.style instanceof Function ? cell.column.style(cell) : cell.column.style),
        justifyContent: cell.column.justifyContent,
        alignItems: 'flex-start',
        display: 'flex'
      },
    },
  ]
}

const Table = ({loading, columns, data, tableKey, doFetch, params, setTable}) => {
  const { t, i18n } = useTranslation()
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 35, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      disableFilters: true
    }),
    []
  )

  const pagination = data.pagination

  const table = useTable(
    {
      useControlledState: state => {
        return React.useMemo(
          () => {
            return {
              ...state,
              pageIndex: data.pagination.current_page - 1,
              pageSize: data.pagination.per_page,
              sortBy: data.sort,
              filters: data.filter,
            }
          },
          [state, data]
        )
      },
      doFetch: doFetch,
      loading: loading,
      actions: params && params.rowActions,
      params: params,
      serverResponse: data,
      columns: useMemo(() => columns, []),
      data: useMemo(() => data.records, [data.records]),
      defaultColumn: defaultColumn,
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
      pageCount: pagination.total_pages,
      initialState: {
        pageIndex: pagination.page - 1,
        pageSize: data.pagination.per_page,
        sortBy: data.sort,
        filters: data.filter
      },
    },
    useFilters,
    useGlobalFilter,
    // useSortBy,
    usePagination,
    useFlexLayout,
    useResizeColumns
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state
  } = table

  const {pageIndex, pageSize, sortBy, globalFilter, filters} = state

  useEffect(() => {
    setTable({...table})
  }, [state])

  const sortColumn = (column, multi) => {
    if (!column.canSort) { return null}

    let desc = undefined
    if (column.isSortedDesc == undefined) {
      desc = false
    } else if (column.isSortedDesc == false) {
      desc = true
    }

    let sortQuery = sortBy

    if (multi) {
      sortQuery = sortQuery.filter((sort) => sort.id != column.id)
    } else {
      sortQuery = []
    }

    if (desc != undefined) {
      sortQuery = sortQuery.concat([{id: column.id, desc: desc}])
    }

    sortQuery = join(sortQuery.map((p) => p.desc == true ? `-${p.id}` : p.id), ',')

    doFetch((query) => {
      return {...query, page: 1, sort: sortQuery}
    })
  }

  return (
    <div className="table-wrapper">
      <div {...getTableProps()} className="table">
        <div className="thead">
          {headerGroups.map(headerGroup => (
            <React.Fragment>
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map(column => (
                  <div {...column.getHeaderProps(headerProps)} className="th">
                    {column.canSort &&
                      <SortOverlay onClick={(e) => sortColumn(column, e.shiftKey)}/>
                    }
                    <span>
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </span>
                    {/* Use column.getResizerProps to hook up the events correctly */}
                    {column.canResize && (
                      <div
                        {...column.getResizerProps()}
                        className={`resizer ${
                          column.isResizing ? 'isResizing' : ''
                        }`}
                      />
                    )}
                  </div>
                ))}
              </div>
              {headerGroup.headers.some(({canFilter}) => canFilter) &&
                <div {...headerGroup.getHeaderGroupProps()} key="filters" className="tr filters-row">
                  {headerGroup.headers.map(column => (
                    <div {...column.getHeaderProps(headerProps)} className="th">
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  ))}
                </div>
              }
            </React.Fragment>
          ))}
        </div>
        <div className="tbody">
          {rows.map(row => {
            prepareRow(row)
            return (
              <div {...row.getRowProps()} className="tr">
                {row.cells.map(cell => {
                  return (
                    <div {...cell.getCellProps(cellProps)} className="td">
                      <span>
                        {cell.render('Cell')}
                      </span>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
      <div className="footer-row pagination">
        <div className="previous">
          <button className="btn" onClick={() => {
            doFetch((query) => {
              return {...query, page: query.page - 1}
            })
           }} disabled={!canPreviousPage}>
            {i18n.t('async_table.table.previous')}
          </button>{' '}
        </div>
        <div className="center">
          <div>
            <strong>
              {i18n.t('async_table.table.page', {page: pageIndex + 1, totalPages: pageOptions.length})}
            </strong>
          </div>
          <div>
            {i18n.t('async_table.table.go_to_page', {page: pageIndex + 1, totalPages: pageOptions.length})}{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              min="1"
              max={pageCount}
              onChange={e => {
                let page = e.target.value ? Number(e.target.value) : 1
                if (page <= 0) {
                  page = 1
                } else if (page >= pageCount) {
                  page = pageCount
                }
                doFetch((query) => {
                  return {...query, page: page}
                })
              }}
              className="form-control"
              style={{ width: '100px', display: 'inline-block' }}
            />

            {' '}
            <select
              className="form-control"
              style={{ width: '150px', display: 'inline-block' }}
              value={pageSize}
              onChange={e => {
                e.persist()
                doFetch((query) => {
                  return {...query, page: 1, per_page: parseInt(e.target.value)}
                })
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {i18n.t('async_table.table.items_per_page_selector', {pageSize: pageSize})}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="next">
          <button className="btn" disabled={!canNextPage} onClick={() => {
              doFetch((query) => {
                return {...query, page: query.page + 1}
            })
           }}>
            {i18n.t('async_table.table.next')}
          </button>
        </div>
      </div>
    </div>
  )
}

// const importTableOptions = (tableKey, setTableOptions, data, params) => (
//   import(/* webpackMode: "eager" */ `./../../backend/${tableKey}/grid_options`)
//     .then(tableOptions => setTableOptions(tableOptions.default(data, params)))
// )

const importTableOptions = () => ({})

const AsyncTable = (props) => {
  const {data, tableKey, params, doFetch} = props
  const [tableOptions, setTableOptions] = useState(false)

  const pagination = data.pagination

  useEffect(() => {
    setTableOptions(props.tableOptions(data, params))
  }, [data])

  if (!tableOptions) {
    return ''
  }

  return (
    <React.Fragment>
      {tableOptions && tableOptions.columns &&
        <Styles className="rounded">
          <Table {...props} columns={tableOptions.columns}/>
        </Styles>
      }
    </React.Fragment>
  )
}

const SortOverlay = styled.span`
  cursor: pointer;
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  right: 0;
  width: calc(100% - 10px);
  height: 100%;
  margin: auto;
`

const Styles = styled.div`
  display: block;
  max-width: 100%;
  padding: 0;  
  border: 1px solid #dadada;

  .table-wrapper {
    display: block;
    max-width: 100%;
    overflow-y: hidden;
  }

  .table {
    color: #373e4a;
    margin: 0;
    border-spacing: 0;

    .thead {
      ${'' /* These styles are required for a scrollable body to align with the header properly */}
      // overflow-x: hidden;
    }

    .tbody {
      ${'' /* These styles are required for a scrollable table body */}
      // overflow-x: hidden;

      .tr:nth-child(odd) {
        background-color: rgba(0,0,0,.025);
      }
    }

    .thead {
      box-shadow: 0 2px 15px 0 rgba(0,0,0,.07);

      .tr {
        :first-child {
          border-top: 0px;
        }
      }
    }

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }

      border-top: 1px solid #ebebeb;
    }

    .tr.filters-row {
      .th {
        overflow: visible;
        padding: 3px;
      }
    }

    .th {
      color: #000;
      font-weight: bold;
      padding: 9px 8px;
      background: #fdfdfd;
    }

    .td {
      padding: 7px 8px;
    }

    .th,
    .td {
      margin: 0;
      border-right: 1px solid #ebebeb;

      ${'' /* In this example we use an absolutely position resizer,
       so this is required. */}
      position: relative;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      :last-child {
        border-right: 0;
      }

      .resizer {
        right: -4px;
        width: 8px;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action :none;

        &.isResizing {
          background: black;
        }
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    color: #373e4a;
    margin: 0;
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.1);
    border: 1px solid rgba(0,0,0,0.1);
    border-top: 2px solid rgba(0,0,0,0.1);
    padding: 3px;
    font-size: 12px;

    .previous, .next {
      width: 20%;

      .btn {
        width:100%;
        height:100%;
        border:0;
        border-radius:3px;
        padding:6px;
        background: rgba(0,0,0,0.1);
        transition: all .1s ease;
        cursor:pointer;

        &:not([disabled]):hover {
          background: rgba(0,0,0,.3);
          color: #fff;
        }
      }
    }

    .center {
      padding: 3px 0;
      text-align: center;
      display: flex;
      justify-content: space-between;

      div {
        align-self: center;
        padding: 2px 30px;
      }
    }
  }
`

export default AsyncTable
