import React from 'react'
import Page from 'ui/page'
import ProductCard from 'containers/list/products/single_product_card'
import { useTranslation } from 'react-i18next'
import noResults from 'img/no-results'
import cn from 'classnames'

const Masonry = (props) => {
  const {products, urls} = props
  const {i18n} = useTranslation()

  const fullGridSize = () => {
    if (products.length > 3) {
      return 'row-cols-lg-4'
    } else {
      return 'row-cols-lg-3'
    }
  }

  return (
    <Page {...props}>
      {products.length > 0 ? (
        <div className="px-4 py-2">
          <div className={cn('row row-cols-1 row-cols-sm-2 p-0 justify-content-left', fullGridSize())}>
            {products.map((product, key) => (
              <ProductCard
                {...product}
                key={key}
                url={urls.service.replace(/ *\:[^)]*\: */g, product.slug)}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="alert alert-warning mb-4 d-flex justify-content-left" role="alert">
          <div>
            <img width="110" height="110" className="mr-4" src={noResults}></img>
          </div>
          <div>
            <h4 className="alert-heading">{i18n.t('product_list.without_results_alert_title')}</h4>
            <hr/>
            <p className="m-0">{i18n.t('product_list.without_results_alert_content')}</p>
          </div>
        </div>
      )}
    </Page>
  )
}

export default Masonry
