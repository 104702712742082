import React from 'react'

const WhatWeDo = (props) => {
  return (
    <div>
      Sistema on-line para comercializar productos y servicios de ocio en hoteles, ofreciendo un valor añadido a los clientes.
    </div>
  )
}

export default WhatWeDo
