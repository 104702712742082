import React, {useEffect, useState} from 'react'
import PageUnlogged from 'ui/page_unlogged'

const getCookie = (cookieName) => {
  let cookie = {}
  document.cookie.split(';').forEach(function(el) {
    let [key,value] = el.split('=')
    cookie[key.trim()] = value
  })
  return cookie[cookieName]
}

const TokenSessionCheck = (props) => {
  const {redirect_url} = props

  let url = new URL(redirect_url)
  let params = new URLSearchParams(url.search)
  params.delete('token')
  const newUrl = url.pathname + '?' + params.toString()

  const redirect = () => { window.location.replace(newUrl) }

  const [requestButton, setRequestButton] = useState(false)

  useEffect(() => {
    if (document.hasStorageAccess) {
      console.log('API activada')

      document.hasStorageAccess().then(result => {
        // If we don't have access we must request it, but the request
        // must come from a UI event.
        if (result) {
          redirect()
          // // Show the button and tie to the click.
          // const requestStorageAccessButton = document.getElementById('requestStorageAccessButton');
          // requestStorageAccessButton.style.display = "block";
          // requestStorageAccessButton.addEventListener('click', event => {
          //   // On UI event, consume the event by requesting access.
          //   document.requestStorageAccess().then(result => {
          //     // Finally, we are allowed! Reload to get the cookie.
          //     // window.location.reload()
          //     console.log('ACCESO ACEPTADO')
          //   }).catch(err => {
          //     console.log('ACCESO DENEGADO')
          //   })
          // })
        } else {
          setRequestButton(true)
        }
      }).catch(err => console.error(err))
    } else {
      redirect()
    }


    // if (document.hasStorageAccess()) {
    //   console.log('TIENE ACCESO')
    // } else {
    //   console.log('NO TIENE ACCESO')
    // }
    // if (document.requestStorageAccess) {
    //   document.requestStorageAccess().then(
    //     () => {
    //       console.log('access granted')
    //       window.location.replace(newUrl)
    //     },
    //     () => { console.log('access denied') }
    //   )
    // }

    // console.log(document.cookie)
    // console.log(getCookie('_obclientv2_session'))
    //
    // if (getCookie('_obclientv2_session')) {
    //   window.location.replace(newUrl)
    // } else {
    //   console.log('NO HAY COOKIE')
    // }
  }, [])

  return (
    <PageUnlogged {...props}>
      Cargando aplicación...
      {requestButton &&
        <button onClick={() => {
          document.requestStorageAccess().then(result => {
            redirect()
          }).catch(err => {
            console.log('ACCESO DENEGADO')
          })
        }}>
          Pedir acceso
        </button>
      }
    </PageUnlogged>
  )
}

export default TokenSessionCheck
