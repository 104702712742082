import {assign} from 'xstate'
import qs from 'qs'

const stepName  = 'iframeStep'

const generateIframeLink = (ctx) => {
  const {iframeEndpoint, iframeToken, event} = ctx

  const query = {
    token: iframeToken,
    session_id: event.id
  }

  return iframeEndpoint + '?' + qs.stringify(query)
}

const setIframeLink = assign({iframeLink: (ctx, e) => generateIframeLink(ctx)})

const setTickets = assign({tickets: (ctx, e) => console.log(e)})
const setReservation = assign({reservation: (ctx, e) => e.reservation})

const iframeModule = (config) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {'': {
          target: 'generateIframe'
        }},
      },
      generateIframe: {
        on: {
          '': [
            {
              target: 'waitingIframeLoaded',
              actions: [generateIframeLink, setIframeLink, 'startSpinner']
            }
          ],
        },
      },
      waitingIframeLoaded: {
        on: {
          IFRAME_LOADED: [
            {
              target: 'waitingIframeResponse',
              actions: ['stopSpinner']
            }
          ],
        },
      },
      waitingIframeResponse: {}
    },
    on: {
      RESERVATION_CALLBACK: {
        target: '#purchase.reserving',
        actions: [setReservation]
      }
    }
  }
}

export default iframeModule
