import React, {useState, useEffect} from 'react'
import {ModulePanel, ModuleHeader, ModuleBody} from 'containers/purchase/modules/module_panel'
import { useTranslation } from 'react-i18next'
import {currentStep} from 'utils/step_utils'
import {get} from 'lodash'

export const MixentradasIframeModule = ({machine, status}) => {
  const [current, send] = machine
  const {iframeLink} = current.context
  const { t, i18n } = useTranslation()

  const ApiWidgetClientesMix_Reserva = (reservation) => {
    send('RESERVATION_CALLBACK', {reservation: reservation})
  }

  const ApiWidgetClientesMix_PersonalizarRespuestaApiWidgetMix = (datos) => {
    const compra = datos.data
    const tipo   = compra.event_id

    if(tipo=='apiwidgetmix.iframe-height'){
      send({type: 'IFRAME_LOADED'})

      const alto = parseInt(compra.height) + 60

      document.getElementById('mix-iframe-api-widget').height = String(alto) + 'px'
    }

    if (tipo=='apiwidgetmix.reserva-realizada') {
      ApiWidgetClientesMix_Reserva(compra.reserva)
    }
  }

  useEffect(() => {
    if(window.addEventListener){
      window.addEventListener('message', ApiWidgetClientesMix_PersonalizarRespuestaApiWidgetMix, false)
    }else{
      window.attachEvent('onmessage', ApiWidgetClientesMix_PersonalizarRespuestaApiWidgetMix)
    }
  }, [])

  return (
    <ModulePanel>
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send('REOPEN_MIXENTRADASIFRAMESTEP') : null}>
        <span>
          <i className="fa fa-map-marker mr-2" aria-hidden="true"></i>
          {i18n.t('mixentradas_iframe_module.iframe_title')}
        </span>
      </ModuleHeader>
      <ModuleBody status={status} className="none">
        {get(current, 'value.iframeStep') == 'waitingIframeLoaded' &&
          <h3 className="text-center p-3">
            {i18n.t('mixentradas_iframe_module.loading')}...
          </h3>

        }
        {iframeLink &&
          <iframe id="mix-iframe-api-widget" src={iframeLink} style={{border: '0px', width: '100%', height: '1000px'}}/>
        }
      </ModuleBody>
    </ModulePanel>
  )
}

export default MixentradasIframeModule
