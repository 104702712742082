import React, {useState, useMemo} from 'react'
import cn from 'classnames'
import {Formik} from 'formik'
import {Button, Col, Form, FormGroup, Row} from 'reactstrap'
import TextInput from 'ui/forms/text_input'
import ApiFetcher from 'lib/api_fetcher'
import { useTranslation } from 'react-i18next'

const SendMerchandisingForm = ({id, type, endpoint, resourceUrl, attachment}) => {
  const [sent, setSent] = useState(false)
  const { t, i18n } = useTranslation()

  const onSubmit = (values, {props, setSubmitting, setErrors}) => {
    new ApiFetcher({
      key: 'send_merchandising',
      endpoint: endpoint,
      method: 'POST',
      body: JSON.stringify({...values, url: resourceUrl, id: id, type: type})
    }).call()
      .then((res) => {
        if (res.success == true) {
          setSent(true)
        } else {
          setErrors(res.errors)
        }
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={useMemo(() => ({
        email: ''
      }), [])
      }
    >
      {({
        submitForm,
        isValid,
        isSubmitting,
        setFieldValue
      }) => (
        <React.Fragment>
          {sent ? (
            <h4 className="text-center"><i className="fa fa-check text-success mr-2"/>{i18n.t('merchandising.email_sent_message')}</h4>
          ) : (
            <Form>
              <p>{i18n.t('merchandising.email_label')}</p>
              <FormGroup>
                <Row>
                  <Col>
                    <TextInput label={i18n.t('merchandising.email')} name="email" type="email" required/>
                  </Col>
                </Row>
              </FormGroup>
              <Button
                size={'lg'}
                color='primary'
                className={cn([
                  'text-white w-100',
                  isSubmitting || !isValid ? 'disabled' : ''
                ])}
                onClick={() => {
                  if (!resourceUrl && attachment) {
                    attachment.then((base64) => setFieldValue('attachment', base64))
                  }

                  submitForm()
                }}
              >
                {isSubmitting ? (
                  i18n.t('format:actions.sending')
                ) : (
                  i18n.t('format:actions.send')
                )}
              </Button>
            </Form>
          )}
        </React.Fragment>
      )}
    </Formik>
  )
}

export default SendMerchandisingForm
