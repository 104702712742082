import React, {useState, useEffect} from 'react'
import PubSub from 'pubsub-js'
import {Toast, ToastHeader, ToastBody, Button} from 'reactstrap'

const LoaderSpinner = (props) => {
  if (!props) { return '' }
  
  const {color, title, body} = props
  const [show, setShow] = useState(color)
  const toggle = () => setShow(!show)

  useEffect(() => {
    // PubSub.subscribe('loading-page', () => setActive(true))
    // PubSub.subscribe('loaded-page', () => setActive(false))
  }, [])

  // useEffect(() => {
  //   if (show) {
  //     setTimeout(function() {
  //       setShow(false)
  //     }, 3000)
  //   }
  // })

  if (!show) { return '' }

  return (
    <Toast style={{zIndex: '99999', position: 'absolute', bottom: '20px', right: '20px'}}>
      <ToastHeader icon={color} toggle={toggle}>{title}</ToastHeader>
      <ToastBody>
        {body}
      </ToastBody>
    </Toast>
  )
}

export default LoaderSpinner
