import { Machine, assign } from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import PubSub from 'pubsub-js'
import thumbnailNotAvailable from 'img/image-not-available.png'
import {scrollTo, scrollIntoView} from 'scroll-js'
import {isMobile} from 'react-device-detect'
import { reject } from 'lodash'
import i18n from 'i18n/config'

const stopSpinner = () => PubSub.publish('loaded-page')
const startSpinner = () => PubSub.publish('loading-page')
const scrollToTop = () => {
  if (isMobile) {
    const target = document.body.getElementsByClassName('page')[0]
    scrollIntoView(target, document.body, { behavior: 'smooth' })
  } else {
    scrollTo(window, { top: 0, easing: 'ease-in-out' })
  }
}

const setFirstPage = (ctx) => {
  let pagination = ctx.pagination
  pagination.current_page = 1
  return pagination
}

const fetchProducts = (ctx) => {
  let query = {}
  ctx.filters.map((filter) => query[filter.param] = filter.value)

  if (ctx.pagination.current_page) {
    query['page'] = ctx.pagination.current_page
  }

  return new ApiFetcher({
    key: 'products',
    endpoint: ctx.endpoint,
    query: query
  }).call()
}

const initialProducts = () => {
  let items = []

  for(let i = 0; i < 6; i++) {
    let item = {
      key: i,
      title: i18n.t('products_machine.loading_products'),
      thumbnail: thumbnailNotAvailable,
      state: {
        name: i18n.t('products_machine.wait')
      }
    }
    items.push(item)
  }

  return items
}

const productsMachine = Machine({
  id: 'products_list',
  initial: 'fetchingProducts',
  context: {
    products: initialProducts(),
    filters: []
  },
  states: {
    fetchingProducts: {
      onEntry: startSpinner,
      invoke: {
        id: 'getProducts',
        src: fetchProducts,
        onDone: {
          target: 'products',
          actions: [
            assign({products: (context, event) => event.data.products}),
            assign({pagination: (context, event) => event.data.pagination})
          ]
        },
        onError: {
          target: 'products'
        }
      }
    },
    products: {
      onEntry: [stopSpinner, scrollToTop],
      on: {
        FILTER_SELECTION: {
          target: 'fetchingProducts',
          actions: [
            assign({filters: (ctx, event) => {
              let filters = reject(ctx.filters, (o) => o.param == event.filter.param)
              filters.push(event.filter)
              return filters
            }}),
            assign({pagination: setFirstPage}),
            startSpinner
          ]
        },
        FILTER_REMOVE: {
          target: 'fetchingProducts',
          actions: [
            assign({filters: (ctx, event) => {
              return ctx.filters.filter((f) => f.param != event.param)
            }}),
            assign({pagination: setFirstPage}),
            startSpinner
          ]
        },
        FILTER_REMOVE_ALL: {
          target: 'fetchingProducts',
          actions: [
            assign({filters: () => []}),
            assign({pagination: setFirstPage}),
            startSpinner
          ]
        },
        PAGINATION_SELECTION: {
          target: 'fetchingProducts',
          actions: [
            assign({pagination: (ctx, event) => {
              let pagination = ctx.pagination
              pagination.current_page = event.page
              return pagination
            }}),
            startSpinner
          ]
        },
        REFRESH: {
          target: 'fetchingProducts'
        }
      }
    }
  }
})

export default productsMachine
