import { assign } from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import {nextStep} from 'utils/step_utils'
import {EUR} from 'utils/currency'
import i18n from 'i18n/config'
import time  from 'i18n/time'

const setError = assign({error: i18n.t('validations:fill_all_fields')})
const cleanError = assign({error: null})
const setValidationError = assign({error: i18n.t('validations:fill_all_fields')})
const setVehicle = assign({vehicle: (ctx, e) => e.vehicle})

const VehicleState = (steps, params, step) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {'': {
          target: 'waitingVehiclePick',
          actions: cleanError
        }},
      },
      waitingVehiclePick: {}
    },
    on: {
      VEHICLE_SELECTION: {
        actions: [setVehicle]
      },
      NEXT_STEP_CLICK: [
        {
          target: '#purchase.vehicleRateStep'
        },
        {
          actions: [setValidationError]
        }
      ]
    }
  }
}

export default VehicleState
