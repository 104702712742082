const initializeWidget = (window, p) => {
  var domain_endpoint = 'https://portal-pre.oneboxtickets.net';

  p.e = {};

  p.subscribe = function( name, handler, context ) {
      addEventListener( name, handler.bind( context ) );
  };

  p.unsubscribe = function( name, handler, context ) {
      removeEventListener( name, handler.bind( context ) );
  };

  p.publish = function(name, data) {
      if(!p.e[name]){
          try{
              p.e[name] = new Event(name);
          }
          catch(e){
              // Create the event
              p.e[name] = document.createEvent('Event');
              // Define the event name
              p.e[name].initEvent(name, true, true);
          }

      }
      p.e[name].data = data;
      dispatchEvent(p.e[name]);
  };

  window._onebox = window._onebox || {};
  //var widgets_root_path = 'http://'+domain_endpoint+'/%CHANNEL_NAME%/widgets/%WIDGET_NAME%';
        var widgets_root_path = domain_endpoint+'/%CHANNEL_NAME%/widgets/%WIDGET_NAME%';
  var script_uris = [{
      "name" : "easyXDM",
      "src" : "https://cdnjs.cloudflare.com/ajax/libs/easyXDM/2.4.18.25/easyXDM.min.js"
    }
  ];
  var loadedModules = {};
  var ob = window._onebox;
  ob.moduleLoader = {
    loadModules : function () {
      loadModules();
    },
    loadWidget : function (container) {
      loadWidget(container);
    }
  };
  function scriptLoader(script_item) {
    var script_tag = document.createElement('script');
    script_tag.setAttribute("type", "text/javascript");
    script_tag.setAttribute("src", script_item.src);
    if (script_tag.readyState) {
      script_tag.onreadystatechange = function () {
        if (this.readyState == 'complete' || this.readyState == 'loaded') {
          scriptLoaderCallback();
        }
      };
    } else {
      script_tag.onload = scriptLoaderCallback;
    }
    (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script_tag);
  }
  function scriptLoaderCallback() {
    script_uri_count++;
    if (script_uri_count >= script_uris.length) {
      main();
    } else {
      scriptLoader(script_uris[script_uri_count]);
    }
  }
        function removejscssfile(filename){
            var allsuspects=document.getElementsByTagName("script");
            for (var i=allsuspects.length; i>=0; i--){
             if (allsuspects[i] && allsuspects[i].getAttribute("src")!=null && allsuspects[i].getAttribute("src").indexOf(filename)!=-1)
              allsuspects[i].parentNode.removeChild(allsuspects[i]);
            }
        }
  function loadModule(script_item) {
                removejscssfile(script_item.module);
    var script_tag = document.createElement('script');
    script_tag.setAttribute("type", "text/javascript");
    script_tag.setAttribute("src", script_item.src);
    // Try to find the head, otherwise default to the documentElement
    (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script_tag);

  }
  function getNodeAttributes(element) {
    var params = '?';
    var paramsMap = {};
    for (var i = 0; i < element.attributes.length; i++) {
      var attrib = element.attributes[i];
      if (attrib.specified) {
        if (attrib.name.indexOf('data-') > -1) {
          var nameParam = attrib.name.split('-')[1];
          var valueParam = attrib.value;
          paramsMap[nameParam] = valueParam;
          if (params.length > 1) {
            params = params + '&' + nameParam + '=' + valueParam;
          } else {
            params = params + nameParam + '=' + valueParam;
          }

        }
      }
    }
                if (params.indexOf('version') === -1){
                    params = params + '&version=1';
                }
    params = params + '&container=' + element.id;
                params = params + '&rootPath=' +domain_endpoint;
    return {
      'name' : element.id,
      'src' : widgets_root_path.replace(/%CHANNEL_NAME%/, paramsMap.channel).replace(/%WIDGET_NAME%/, paramsMap.module) + params,
                        'module' : paramsMap.module
                };
  }
  function getElementsByClassName(node, classname) { //cross-browser getElementsByClassName
    var a = [];
    var re = new RegExp('(^| )' + classname + '( |$)');
    var els = node.getElementsByTagName("*");
    for (var i = 0, j = els.length; i < j; i++)
      if (re.test(els[i].className))
        a.push(els[i]);
    return a;
  }
  function loadWidget(container) {
    var widgetElements = document.getElementById(container);
    var s = getNodeAttributes(widgetElements);
    loadModule(s);
    loadedModules[container] = true;

  }
  function loadModules() {
    var widgetElements = getElementsByClassName(document.body, 'ob-widget');
    for (var i = 0; i < widgetElements.length; i++) {
      var s = getNodeAttributes(widgetElements[i]);
      loadModule(s);
    }
  }


  var script_uri_count = 0;
  scriptLoader(script_uris[script_uri_count]);

  function main() {
    //loadModules();
  }
}

export {initializeWidget}
