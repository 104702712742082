import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import Page from 'ui/page'
import {Button, ListGroup, ListGroupItem, Card, CardHeader, CardBody} from 'reactstrap'
import AsyncTable from 'ui/async_table'
import useTableFetch from 'utils/hooks/use_table_fetch'
import tableOptions from './products_catalog_grid_options'
import parse from 'react-html-parser'
import cn from 'classnames'
import PubSub from 'pubsub-js'
import ApiFetcher from 'lib/api_fetcher'
import time from 'i18n/time'

const Faq = (props) => {
  console.log(props)
  const {records, urls} = props
  const {i18n} = useTranslation()

  const [response, setResponse] = useState()
  const [page, setPage] = useState(1)

  useEffect(() => {
    PubSub.publish('loading-page')

    new ApiFetcher({
      key: 'events',
      endpoint: urls.bulletin_grid,
      method: 'POST',
      body: JSON.stringify({
        page: page
      })
    })
      .call()
      .then((res) => setResponse(res))
      .finally(() => PubSub.publish('loaded-page'))
  }, [page])

  const BulletinItem = (props) => {
    const {title, excerpt, content, created_at} = props
    const [active, setActive] = useState(false)

    return (
      <Card className="mb-3">
        <CardHeader>
          <div className="d-flex justify-content-between" style={{cursor: 'pointer'}} onClick={() => setActive(!active)}>
            <h5 className="m-0" ><a className="text-primary">{title}</a></h5>
            <div>
              <i>{time(created_at).format('LLLL')}</i>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          {parse(content)}
        </CardBody>
      </Card>
    )
  }

  return (
    <Page {...props}>
      <h1 className="mb-4">{i18n.t('bulletin.title')}</h1>
      {response &&
        <React.Fragment>
          <ListGroup>
            {response.records.map((i) => <BulletinItem key={i.id} {...i}/>)}
          </ListGroup>
          <div className="d-flex justify-content-between mt-3">
            <Button
              disabled={response.pagination.total_pages <= 1 || response.pagination.current_page <= 1}
              onClick={() => setPage((page) => page - 1)}
            >
              {i18n.t('format:pagination.newer')}
            </Button>
            <div>
              {i18n.t('format:pagination.page_of_total', {page: response.pagination.current_page, total: response.pagination.total_pages})}
            </div>
            <Button
              disabled={response.pagination.total_pages <= response.pagination.current_page}
              onClick={() => setPage((page) => page + 1)}
            >
              {i18n.t('format:pagination.older')}
            </Button>
          </div>
        </React.Fragment>
      }

    </Page>
  )
}

export default Faq
