import React from 'react'
import {usePartial} from 'i18n/config'
import AppProvider from 'utils/app_provider'
import Footer from 'containers/layouts/footer'
import LanguageSwitcher from 'ui/language_switcher'
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem
} from 'reactstrap'

const PageUnlogged = (props) => {
  const language_links = props.menu_props && props.menu_props.language_links

  return (
    <AppProvider {...props}>
      <Navbar color="dark" light expand="md">
        <div className="container">
          <NavbarBrand className="text-white" href="/">Ticketinhotel</NavbarBrand>

          <Nav className="ml-auto" navbar>
            <NavItem>
              {language_links &&
                <LanguageSwitcher en es urls={language_links}/>
              }
            </NavItem>
          </Nav>
        </div>

      </Navbar>

      <div className="container mt-5">
        {props.children}
      </div>

      <Footer {...props}/>
    </AppProvider>
  )
}

export default PageUnlogged
