import tickeinhotelSignIn from 'img/themes/ticketinhotel-sing-in-logo'
import tickeforcitySignIn from 'img/themes/ticketforcity-sing-in-logo'

const themes = [
  {
    code: 'ticketinhotel',
    name: 'Ticketinhotel',
    brand_logo_url: null,
    sing_in_logo: tickeinhotelSignIn
  },
  {
    code: 'ticketforcity',
    name: 'Ticketforcity',
    brand_logo_url: null,
    sing_in_logo: tickeforcitySignIn
  },
  {
    code: 'localhost',
    name: 'Localhost',
    brand_logo_url: null,
    sing_in_logo: tickeinhotelSignIn
  },
  {
    code: 'default',
    name: 'Ticketinhotel',
    brand_logo_url: null,
    sing_in_logo: tickeinhotelSignIn
  },
]

const themeBydomain = () => {
  return themes.find((t) => window.location.hostname.includes(t.code) || t.code == 'default')
}

export default themes
export {themeBydomain}
