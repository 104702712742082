import React from 'react'
import CalendarPicker from 'components/calendar_picker'
import EventGroups from 'containers/purchase/modules/event_module/event_groups'
import {ModulePanel, ModuleHeader, ModuleBody, ModuleFooter} from 'containers/purchase/modules/module_panel'
import { Row, Col, Alert } from 'reactstrap'
import time from 'i18n/time'
import {groupBy} from 'lodash'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

export const EventModule = (props) => {
  const {config, machine, status} = props
  const [current, send] = machine
  const { initialDate, locale, external_api_config } = config
  const { days_available, day, event, events, event_groups } = current.context

  const eventRenderMode = external_api_config.event_render_mode || 'time'

  return (
    <ModulePanel>
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send('REOPEN_EVENTSTEP') : null}>
        <EventSummary day={day} event={event}/>
      </ModuleHeader>
      <ModuleBody status={status}>
        <Row>
          <Col md="5" lg="4" className="d-flex justify-content-center">
            <CalendarPicker
              initialDay={initialDate}
              onDaySelected={(day) => send({type: 'DAY_SELECTION', day: day})}
              onMonthChange={(date) => send({type: 'MONTH_CHANGE', year_month: moment(date).format('YYYY-MM')})}
              daysAvailable={days_available}
              locale={locale}
            />
          </Col>
          <Col md="7" lg="8" className="mt-3 mt-md-0">
            <EventsSection
              eventGroups={event_groups}
              eventRenderMode={eventRenderMode}
              isFetchingCalendar={current.matches({ eventStep: 'fetchingCalendar' })}
              isFetchingEvents={current.matches({ eventStep: 'fetchingEvents' })}
              send={send}
              event={events}
              day={day}
            />
          </Col>
        </Row>
      </ModuleBody>
      <ModuleFooter {...props}/>
    </ModulePanel>
  )
}

const EventsSection = ({day, eventGroups, event, isFetchingEvents, isFetchingCalendar, eventRenderMode, send}) => {
  const { t, i18n } = useTranslation()

  if (isFetchingCalendar) {
    return (
      <Alert color="info">
        <i className="fa fa-hourglass-half mr-2"></i> {i18n.t('event_module.loading_calendar')}...
      </Alert>
    )
  }

  if (isFetchingEvents) {
    return (
      <Alert color="info">
        <i className="fa fa-hourglass-half mr-2"></i> {i18n.t('event_module.loading_events')}...
      </Alert>
    )
  }

  if (!day) {
    return (
      <Alert color="info">
        <i className="far fa-calendar-alt mr-2"></i> {i18n.t('event_module.select_a_day')}
      </Alert>
    )
  }

  if (eventGroups.length > 0) {
    const selectionGroups = groupBy(eventGroups, (item) => item.selection_group && item.selection_group.id)

    if (Object.keys(selectionGroups).length > 1) {
      return Object.values(selectionGroups).map((selectionGroup) => {
        return <EventGroups
          eventRenderMode={eventRenderMode}
          key={selectionGroup.id}
          data={selectionGroup}
          eventsActive={event}
          onEventSelected={(event_item) => send({type: 'EVENT_SELECTION', event: event_item})}
        />
      })
    } else {
      return <EventGroups
        eventRenderMode={eventRenderMode}
        data={eventGroups}
        eventActive={event}
        onEventSelected={(event_item) => send({type: 'EVENT_SELECTION', event: event_item})}
      />
    }
  } else {
    return (
      <div className="alert alert-info" role="alert">
        {i18n.t('event_module.events_not_available')}
      </div>
    )
  }
}

const EventSummary = ({day, event}) => {
  const { t, i18n } = useTranslation()

  const NothingSelected = () => {
    return (
      <React.Fragment>
        <div>
          <i className="far fa-calendar-alt mr-2" aria-hidden="true"></i>
          {i18n.t('event_module.select_a_day')}
        </div>
        <div>
        </div>
      </React.Fragment>
    )
  }

  let day_label = time(day).format('LL')

  const DaySelected = () => {
    return (
      <React.Fragment>
        <div>
          <i className="far fa-calendar-alt mr-2" aria-hidden="true"></i>
          <span>{day_label}</span>
        </div>
        <div>
          <span>{i18n.t('event_module.select_an_event')}</span>
        </div>
      </React.Fragment>
    )
  }

  const EventSelected = () => {
    return (
      <React.Fragment>
        <div>
          <i className="far fa-calendar-alt mr-2" aria-hidden="true"></i>
          <span>{day_label}</span>
        </div>
        <div>
          <i className="far fa-clock mr-2" aria-hidden="true"></i>
          <span>{event.description} {event.time}</span>
        </div>
      </React.Fragment>
    )
  }

  if (!day) {
    return <NothingSelected/>
  }

  if(!event) {
    return <DaySelected/>
  }

  return <EventSelected/>
}

export default EventModule
