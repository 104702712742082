import React from 'react'
import {ModulePanel, ModuleHeader, ModuleBody, ModuleFooter} from 'containers/purchase/modules/module_panel'
import CustomFieldsForm from 'containers/purchase/modules/fh_custom_fields_module/custom_fields_form'
import { useTranslation } from 'react-i18next'

export const CustomFieldsModule = (props) => {
  const {machine, status} = props
  const { t, i18n } = useTranslation()
  const [current, send] = machine
  const { customFields } = current.context

  const setForm = (form) => {
    send({type: 'FAREHARBOR_FORM_CHANGE', form: form})
  }

  return (
    <ModulePanel>
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send('REOPEN_CUSTOMFIELDSSTEP') : null}>
        <span>
          <i className="fa fa-question-circle mr-2" aria-hidden="true"></i>
          {i18n.t('custom_fields_module.custom_fields_title')}
        </span>
      </ModuleHeader>
      <ModuleBody status={status}>
        <CustomFieldsForm customFields={customFields} setForm={setForm}/>
      </ModuleBody>
      <ModuleFooter {...props}/>
    </ModulePanel>
  )
}

export default CustomFieldsModule
