import React from 'react'
import {ModulePanel, ModuleHeader, ModuleBody, ModuleFooter} from 'containers/purchase/modules/module_panel'
import { useTranslation } from 'react-i18next'
import {ListGroup, ListGroupItem} from 'reactstrap'
import cn from 'classnames'
import styled from 'styled-components'
import {currency} from 'i18n/config'

const Styles = styled.div`
  align-self: center !important;

  input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: white;
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: var(--primary);
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid var(--primary);
    border-radius: 50%;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
  }

  input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--primary);
    /* Windows High Contrast Mode */
    background-color: red;
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }

  input[type="radio"]:focus {
    outline: max(2px, 0.15em) solid var(--primary);
    outline-offset: max(2px, 0.15em);
  }
`

export const VehiclesModule = (props) => {
  const {config, machine, status} = props
  const [current, send] = machine
  const { t, i18n } = useTranslation()
  const {vehicle, vehicles} = current.context

  const onVehicleSelected = (vehicle) => send({type: 'VEHICLE_SELECTION', vehicle: vehicle})

  return (
    <ModulePanel>
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send('REOPEN_VEHICLESSTEP') : null}>
        <span>
          <i className="fa fa-users mr-2" aria-hidden="true"/>
          {props.title}
        </span>
      </ModuleHeader>
      <ModuleBody status={status}>
        <ListGroup>
          {vehicles && vehicles.map((v) => {
            const {capacity, category, features, rate_totals} = v

            return (
              <ListGroupItem
                key={category.vehicle_class_code}
                className={cn(
                  'rounded-0 d-flex justify-content-between flex-column flex-md-row',
                )}
                style={{cursor: 'pointer', background: (vehicle && vehicle.category.vehicle_class_code) == category.vehicle_class_code ? '#ffeeba' : ''}}
                onClick={() => onVehicleSelected(v)}
              >

                <div className="d-flex justify-content-between w-100 p-2 pr-3">
                  <div className="d-flex justify-content-between">
                    <div>
                      <img width="120" src={category.image_url}/>
                    </div>
                    <div className="ml-3">
                      <div className="font-weight-bold">
                        {category.name}
                        <span className="ml-3">
                          <span className="mr-1">{capacity.seats}</span>
                          <i className="fa fa-user"/>
                        </span>

                        {capacity.luggage_capacity.small_suitcase &&
                          <span className="ml-2">
                            <span className="mr-1">{capacity.luggage_capacity.small_suitcase}</span>
                            <i className="fa fa-shopping-bag"/>
                          </span>
                        }

                        {capacity.luggage_capacity.large_suitcase &&
                          <span className="ml-2">
                            <span className="mr-1">{capacity.luggage_capacity.large_suitcase}</span>
                            <i className="fa fa-suitcase" aria-hidden="true"></i>
                          </span>
                        }
                      </div>
                      <div className={'font-weight-italic'}>
                        {category.vehicle_class_name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-self-center flex-column justify-content-center border-left p-2 pl-3 col-3">
                  <div className={'d-flex flex-sm-row mt-3'}>
                    <div className="price text-right mr-5" style={{fontSize: '18px', width: '200'}}>
                      <strong>
                        {i18n.t('quotations_module.total_price')}:
                        <span className="ml-2" style={{fontSize: '1.35em'}}>
                          {currency(rate_totals.pay_later.reservation_total)}
                        </span>
                      </strong>
                    </div>
                    <Styles>
                      <input
                        type="radio"
                        checked={(vehicle && vehicle.category.vehicle_class_code) == category.vehicle_class_code}
                        name="component_key"
                        value={currency(rate_totals.pay_later.reservation_total)}
                      />
                    </Styles>
                  </div>
                </div>
              </ListGroupItem>
            )})}
        </ListGroup>
      </ModuleBody>
      <ModuleFooter {...props}/>
    </ModulePanel>
  )
}

export default VehiclesModule
