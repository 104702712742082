import React, {useState, useEffect} from 'react'
import { Alert, Button, Col, Row, ListGroup, ListGroupItem } from 'reactstrap'
import { purchaseMachine } from 'containers/purchase/purchase_machine'
import PriceSummary from 'components/price_summary'
import { useMachine } from '@xstate/react'
import generic from 'containers/purchase/machines/generic_machine_config'
import volcano from 'containers/purchase/machines/volcano_machine_config'
import lowCost from 'containers/purchase/machines/lct_machine_config'
import fareharbor from 'containers/purchase/machines/fareharbor_machine_config'
import clorian from 'containers/purchase/machines/clorian_machine_config'
import orsag from 'containers/purchase/machines/orsag_machine_config'
import tourcms from 'containers/purchase/machines/tourcms_machine_config'
import janto_iframe from 'containers/purchase/machines/janto_iframe_config'
import mixentradas_iframe from 'containers/purchase/machines/mixentradas_iframe_config'
import experticket from 'containers/purchase/machines/experticket_config'
import transfer from 'containers/purchase/machines/transfer_machine_config'
import servantrip from 'containers/purchase/machines/servantrip_machine_config'
import avis from 'containers/purchase/machines/avis_machine_config'
import onebox from 'containers/purchase/machines/onebox_machine_config'
import iryo from 'containers/purchase/machines/iryo_machine_config'
import {stepConfig, isLastStep, currentStep, stepStatus} from 'utils/step_utils'
import PurchaseWizard from 'containers/purchase/purchase_wizard'
import ProductHeader from 'containers/purchase/partials/product_header'
import PurchaseWrapper from 'ui/purchase_wrapper'
import useLayout from 'utils/hooks/use_layout'
import { useTranslation } from 'react-i18next'
import stopSales from 'img/stop-sales'
import {isMobile, isMobileOnly} from 'react-device-detect'
import cn from 'classnames'
import Page from 'ui/page'
import Modal from 'ui/modal'
import parse from 'react-html-parser'
import RelatedProducts from './partials/related_products'

const getConfig = (machineApi, data) => {
  const configsMap = {
    'generic': generic,
    'low_cost': lowCost,
    'fareharbor': fareharbor,
    'clorian': clorian,
    'orsag': orsag,
    'tourcms': tourcms,
    'janto_iframe': janto_iframe,
    'experticket': experticket,
    'transfer': transfer,
    'volcano': volcano,
    'mixentradas_iframe': mixentradas_iframe,
    'servantrip': servantrip,
    'avis': avis,
    'onebox': onebox,
    'iryo': iryo
  }

  return configsMap[machineApi](data)
}

const SummaryModal = (props) => {
  const {modal, endpoints, relatedProducts, cart, redirectUrl} = props
  const { t, i18n } = useTranslation()

  return (
    <Modal
      isOpen={modal}
      footer={() => {
        return (
          <React.Fragment>
            <Row>
              <Col>
                <Button
                  href={endpoints.products}
                  color="primary"
                  className="btn-block text-primary py-3 text-uppercase"
                  style={{background: 'transparent'}}
                >
                  <i className="fa fa-plus-square mr-2"/> {i18n.t('summary_modal.see_more_products')}
                </Button>
              </Col>

              <Col>
                <Button
                  href={redirectUrl}
                  color="primary"
                  className="btn-block text-white font-weight-bold py-3 text-uppercase"
                >
                  <i className="fa fa-caret-square-right mr-2"/> {i18n.t('cart_summary.link_to_reservation')}
                </Button>
              </Col>
            </Row>

            <div>
              <RelatedProducts
                products={relatedProducts}
                url={endpoints.service}
                limitProducts={3}
                rowProps={{
                  className: 'row-cols-1 row-cols-sm-2 row-cols-md-3 mt-3',
                  style: {justifyContent: 'center'}
                }}
              />
            </div>
          </React.Fragment>

        )
      }}
      width={850}
      showCloseIcon={false}
      bodyStyle={{padding: '35px'}}
    >
      {cart &&
        <ListGroup>
          <ListGroupItem className="bg-light font-weight-bold">
            <i className="fa fa-shopping-bag mr-2"/>{i18n.t('summary_module.title')}
          </ListGroupItem>
          {cart.items.map((item) => {
            const {id, product, total_price_label} = item

            return (
              <ListGroupItem key={id} className="d-flex justify-content-between">
                <div>
                  {product.title}
                </div>
                <div>
                  <strong>
                    {total_price_label}
                  </strong>
                </div>
              </ListGroupItem>
            )
          })}
          <ListGroupItem color="primary" className="d-flex justify-content-between font-weight-bold">
            <div>{i18n.t('summary_module.total_amount')}</div>
            <div>{cart.final_price_label}</div>
          </ListGroupItem>
        </ListGroup>
      }
    </Modal>
  )
}

const NotificationModal = (props) => {
  const {notification, notification_type, modal, toggle} = props

  if (notification && notification_type == 'popup') {
    return (
      <Modal
        isOpen={modal}
        toggle={toggle}
      >
        {parse(notification)}
      </Modal>
    )
  } else {
    return null
  }
}

const ProductPurchase = (props) => {
  const {product_id, endpoints, product, related_products, cart_params} = props
  const [notificationModal, setNotificationModal] = useState(true)
  const [cartModal, setCartModal] = useState(false)
  const toggleNotificationModal = () => setNotificationModal(!notificationModal)

  const {i18n} = useTranslation()
  const config = getConfig(props.machine, props)
  const stepsConfig = config.steps

  const machine = useMachine(purchaseMachine(config), {
    context: {
      endpoints: endpoints,
      product_id: product_id,
      timezone: product.local_timezone,
      currency: product.currency,
      related_products: related_products
    }
  })

  const mode = useLayout()

  const [current, send] = machine
  const {reservation_ready, cart, redirect_url} = current.context

  useEffect(() => {
    if (current.value == 'reserved') {
      if (cart_params.enable_cart) {
        setCartModal(true)
      } else {
        document.location.href = redirect_url
      }
    }
  }, [current.value])

  const currentStepConfig = stepConfig(stepsConfig, current.value)

  const {error} = current.context
  const {product_status, title, notification, notification_type} = props.product

  const PurchaseError = ({error}) => (
    <div id="purchase-error">
      {error &&
        <Alert color="danger">{error}</Alert>
      }
    </div>
  )

  const ReservationButton = () => {
    return (
      <Button
        disabled={!reservation_ready}
        size={'lg'}
        style={{padding: '9px 62px'}}
        color='danger'
        className={cn('text-white', isMobile ? 'btn-block' : '')}
        onClick={() => send('RESERVATION_REQUEST')}
      >
        {i18n.t('product_purchase.reservation_button')}
      </Button>
    )
  }

  if (product_status != 'active') {
    return (
      <PurchaseWrapper mode={mode} className="text-center p-3 container">
        <div className="alert alert-warning" role="alert">
          <h2>{title}</h2>
          <p className="m-0">{i18n.t('product_purchase.stop_sales_description')}</p>
          <img src={stopSales}/>
        </div>
      </PurchaseWrapper>
    )
  }

  return (
    <Page {...props} className=" ">
      <NotificationModal 
        notification={notification} 
        notification_type={notification_type} 
        modal={notificationModal} 
        toggle={toggleNotificationModal}
      />

      <SummaryModal
        modal={cartModal}
        endpoints={endpoints}
        relatedProducts= {product.related_products}
        cart={cart}
        redirectUrl={redirect_url}
      />

      {props.mode == 'web' &&
        <ProductHeader {...props}/>
      }

      <div className="container mt-3">
        {props.mode == 'iframe' &&
          <h1 className="h3 text-center mb-3">{title}</h1>
        }
        {!isMobileOnly &&
          <PurchaseWizard stepActive={0}/>
        }
        <PurchaseError error={error}/>

        {Object.keys(stepsConfig).map((step) => {
          const Module = stepsConfig[step].component
          const moduleStatus = stepStatus(stepsConfig, step, currentStep(current.value))

          return (
            <Module
              key={step}
              config={props}
              machine={machine}
              status={moduleStatus}
              isLastStep={isLastStep(stepsConfig, current.value)}
              stepConfig={currentStepConfig}
              {...stepsConfig[step].componentProps}
            />
          )
        })}

        <div className="d-flex flex-column-reverse flex-md-row-reverse align-items-start">
          {!config.hideReservatioButton && isLastStep(stepsConfig, current.value) &&
            <ReservationButton/>
          }

          <PriceSummary machine={machine}/>
        </div>
      </div>
    </Page>
  )
}

export default ProductPurchase
