import React from 'react'
import { FormGroup, Label, FormFeedback } from 'reactstrap'
import { useField, useFormikContext } from 'formik'
import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

const Select = ({label, creatable, formatCreateLabel, noOptionsMessage, ...props }) => {
  const name = (props.field && props.field.name) || props.name
  const [field, meta] = useField(name)
  const {setFieldValue} = useFormikContext()
  const { t, i18n } = useTranslation()

  const defaultNoOptionsMessage = () => i18n.t('format:no_records')

  return (
    <FormGroup className={ meta.touched && meta.error ? 'has-error' : '' }>
      <Label className={cn('control-label', props.labelClass)} htmlFor={props.id || props.name}>
        {label}
        {props.required &&
          <span className="text-danger ml-1">*</span>
        }
      </Label>
      <div
        style={{display: 'none'}}
        className={
          cn([
            'form-control',
            meta.touched && meta.error ? 'is-invalid' : '',
            meta.touched && !meta.error ? 'is-valid' : ''
          ])
        }
      >
      </div>
      {creatable ? (
        <CreatableSelect
          isClearable={props.required ? false : true}
          defaultValue={props.values && props.values.find((item) => item.value == field.value)}
          onChange={(selected) => {
            setFieldValue(field.name, selected ? selected.value  : '')
            if (props.onSelect) {
              props.onSelect()
            }
          }}
          options={props.values}
          noOptionsMessage={noOptionsMessage || defaultNoOptionsMessage}
          formatCreateLabel={formatCreateLabel}
          {...props}
        />
      ) : (
        <ReactSelect
          isClearable={props.required ? false : true}
          defaultValue={props.values && props.values.find((item) => item.value == field.value)}
          onChange={(selected) => {
            setFieldValue(field.name, selected ? selected.value  : '')
            if (props.onSelect) {
              props.onSelect(selected)
            }
          }}
          options={props.values}
          styles={{
            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
            menu: (provided) => ({ ...provided, zIndex: 9999 })
          }}
          {...props}
        />
      )}

      {meta.touched && meta.error ? (
        <FormFeedback className={'help-block'}>{meta.error}</FormFeedback>
      ) : null}
    </FormGroup>
  )
}

export default Select
