import React from 'react'
import {ModulePanel, ModuleHeader, ModuleBody, ModuleFooter} from 'containers/purchase/modules/module_panel'
import {Row, Col, Button, Alert} from 'reactstrap'
import { useTranslation } from 'react-i18next'

export const EventModule = (props) => {
  const {config, machine, status} = props
  const [current, send] = machine
  const {access_event_groups} = current.context
  const { t, i18n } = useTranslation()

  return (
    <ModulePanel>
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send('REOPEN_EVENTSTEP') : null}>
        <span>
          <i className="fa fa-map-marker mr-2"/>
          {i18n.t('event_list_module.module_title')}
        </span>
      </ModuleHeader>
      <ModuleBody status={status}>
        <EventsSection
          eventGroups={access_event_groups}
          isFetching={current.matches({ eventStep: 'fetchingEvents' }) ? true : false }
          send={send}
        />
      </ModuleBody>
      <ModuleFooter {...props}/>
    </ModulePanel>
  )
}

const EventsSection = ({eventGroups, event, isFetching, send}) => {
  const { t, i18n } = useTranslation()

  if (isFetching) {
    return (
      <Alert color="info">
        <i className="fa fa-hourglass-half mr-2"></i> {i18n.t('event_module.loading_events')}...
      </Alert>
    )
  } else if (eventGroups.length > 0) {
    return (
      <Row className="justify-content-start">
        {eventGroups[0].events.map((event) => (
          <Col key={event.id} xs={"12"} md="2">
            <Button
              color="primary w-100"
              className="text-white py-1 my-2"
              onClick={() => send({type: 'EVENT_SELECTION', event: event})}
            >
              <i className="far fa-clock mr-1" aria-hidden="true"></i>
              {event.time}
            </Button>
          </Col>
        ))}
      </Row>
    )
  } else {
    return (
      <div className="alert alert-info" role="alert">
        {i18n.t('event_module.events_not_available')}
      </div>
    )
  }
}

export default EventModule
