import {assign} from 'xstate'
import qs from 'qs'

const stepName  = 'iframeStep'

const generateIframeLink = (session) => {
  const endpoint = 'https://entradas.janto.es/ociobusiness/ocbiframe/public/janto/main.php'
  const query = {
    Nivel: 'Detalle_1_Sesion',
    Idioma: 'ES',
    idSesion: session,
    modo: 'IFRAME',
    urlOrigen: window.location.href
  }

  return endpoint + '?' + qs.stringify(query)
}

const setIframeLink = assign({iframeLink: (ctx, e) => generateIframeLink(ctx.event.id)})

const iframeModule = (config) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {
          '': [
            {
              target: 'waitingIframeResponse',
              actions: [generateIframeLink, setIframeLink]
            }
          ],
        },
      },
      waitingIframeResponse: {}
    },
    on: {

    }
  }
}

export default iframeModule
