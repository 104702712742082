import ContactModule from 'containers/purchase/modules/contact_module/contact_module'
import contactState from 'containers/purchase/modules/contact_module/contact_state'
import CustomFieldsModule from 'containers/purchase/modules/custom_fields_module/custom_fields_module'
import customFieldsState from 'containers/purchase/modules/custom_fields_module/custom_fields_state'
import DayModule from 'containers/purchase/modules/day_module/day_module'
import dayState from 'containers/purchase/modules/day_module/day_state'
import EventModule from 'containers/purchase/modules/event_module/event_module'
import eventState from 'containers/purchase/modules/event_module/event_state'
import LodginPickupsModule from 'containers/purchase/modules/lodgin_pickups_module/lodgin_pickups_module'
import lodginPickupsState from 'containers/purchase/modules/lodgin_pickups_module/lodgin_pickups_state'
import TicketTypesModule from 'containers/purchase/modules/ticket_types_module/ticket_types_module'
import ticketTypesState from 'containers/purchase/modules/ticket_types_module/ticket_types_state'
import VisitorsModule from 'containers/purchase/modules/visitors_module/visitors_module'
import visitorsState from 'containers/purchase/modules/visitors_module/visitors_state'

const reservationMapper = (ctx) => {
  return {
    event_id: ctx.event.id,
    ticket_types: ctx.tickets,
    custom_fields: ctx.customFieldValues,
    customers: ctx.visitors,
    params: {
      contact_values: ctx.contactValues,
      lodgin: ctx.lodgin,
      pickup: ctx.pickup
    }
  }
}

const config = (params) => {
  const {external_api_config} = params
  const predefinedEvent = external_api_config && external_api_config.event
  const eventPickMode = external_api_config && external_api_config.event_pick_mode
  const customFields = params.product && params.product.custom_fields
  const contactRequired = external_api_config && external_api_config.contact_required
  const visitorsRequired = external_api_config && external_api_config.visitorsRequired
  const pickupRequired = external_api_config && external_api_config.pickup_required

  let steps = {}

  if (!predefinedEvent) {
    if (eventPickMode == 'day') {
      steps.dayStep = {
        component: DayModule,
        autoNext: true,
        states: dayState,
        context: {
          year_month: params.initialYearMonth,
          event_groups: [],
          event: null
        }
      }
    } else {
      steps.eventStep = {
        component: EventModule,
        autoNext: true,
        states: eventState,
        context: {
          year_month: params.initialYearMonth,
          event_groups: [],
          event: null
        }
      }
    }
  }

  steps.ticketsStep = {
    component: TicketTypesModule,
    autoNext: false,
    states: ticketTypesState,
    context: {
      event: {
        id: predefinedEvent ? external_api_config.event : null
      },
      tickets: []
    }
  }

  if (visitorsRequired) {
    steps.visitorsStep = {
      component: VisitorsModule,
      autoNext: false,
      states: visitorsState,
      context: {
        visitors: []
      }
    }
  }

  if (customFields && customFields.length > 0) {
    steps.customFieldsStep = {
      component: CustomFieldsModule,
      autoNext: false,
      states: customFieldsState,
      context: {
        customFields: customFields
      }
    }
  }

  if (pickupRequired) {
    steps.pickupRequired = {
      component: LodginPickupsModule,
      autoNext: false,
      states: lodginPickupsState,
      context: {
        pickups: [],
        pickup: null
      }
    }
  }

  if (contactRequired == 'customer') {
    steps.contactRequired = {
      component: ContactModule,
      autoNext: false,
      states: contactState,
      context: {
        contactValues: []
      }
    }
  }

  return {
    reservationMapper: reservationMapper,
    steps: steps,
    params: params,
  }
}

export default config
