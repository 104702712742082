import React from 'react'
import { FormGroup, Label, FormFeedback } from 'reactstrap'
import { useField } from 'formik'
import cn from 'classnames'

const Textarea = ({ label, ...props }) => {
  const name = (props.field && props.field.name) || props.name
  const [field, meta] = useField(name)

  return (
    <FormGroup className={ meta.touched && meta.error ? 'has-error' : '' }>
      <Label className='control-label' htmlFor={props.id || props.name}>
        {label}
        {props.required &&
          <span className="text-danger ml-1">*</span>
        }
      </Label>
      <textarea
        className={
          cn([
            'form-control',
            meta.touched && meta.error ? 'is-invalid' : '',
            meta.touched && !meta.error ? 'is-valid' : ''
          ])
        }
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <FormFeedback className={'help-block'}>{meta.error}</FormFeedback>
      ) : null}
    </FormGroup>
  )
}

export default Textarea
