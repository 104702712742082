import IryoRouteModule from 'containers/purchase/modules/iryo_route_module/iryo_route_module'
import iryoRouteState from 'containers/purchase/modules/iryo_route_module/iryo_route_state'
import IryoTrainModule from 'containers/purchase/modules/iryo_train_module/iryo_train_module'
import iryoTrainState from 'containers/purchase/modules/iryo_train_module/iryo_train_state'
import VisitorsModule from 'containers/purchase/modules/visitors_module/visitors_module'
import visitorsState from 'containers/purchase/modules/visitors_module/visitors_state'
// import TransferServiceModule from 'containers/purchase/modules/transfer_service_module/transfer_service_module'
// import transferServiceState from 'containers/purchase/modules/transfer_service_module/transfer_service_state'
// import QuotationsModule from 'containers/purchase/modules/quotations_module/quotations_module'
// import quotationsState from 'containers/purchase/modules/quotations_module/quotations_state'
// import ContactModule from 'containers/purchase/modules/contact_module/contact_module'
// import contactState from 'containers/purchase/modules/contact_module/contact_state'
import i18n from 'i18n/config'

const reservationMapper = (ctx) => {
  console.log(ctx.availability)

  return {
    event_id: 'IR',
    ticket_types: ctx.tickets,
    customers: ctx.visitors,
    params: {
      outbound: ctx.outbound,
      inbound: ctx.inbound,
      passengers: ctx.availability.offer.passengers,
      srchToken: ctx.availability.offer.srchToken
    }
  }
}

const config = (params) => {
  const {external_api_config} = params

  let steps = {}

  steps.routeStep = {
    component: IryoRouteModule,
    componentProps: {
      title: i18n.t('iryo_machine_config.route_step_module_title'),
      formName: 'route_form',
    },
    currentStep: 'route',
    target: '#purchase.trainStep',
    // target: '#purchase.dropoffStep',
    autoNext: false,
    states: iryoRouteState,
    context: {
      train_resources: {
        stations: []
      },
    }
  }

  steps.trainStep = {
    component: IryoTrainModule,
    componentProps: {
      title: i18n.t('iryo_machine_config.train_step_module_title')
    },
    currentStep: 'train',
    // target: '#purchase.dropoffStep',
    autoNext: true,
    states: iryoTrainState,
    context: {
      train_stations: [],
      activeTab: 'outbound'
    }
  }

  steps.visitorsStep = {
    component: VisitorsModule,
    componentProps: {
      hide_prices: true
    },
    autoNext: false,
    states: visitorsState,
    context: {
      visitors: []
    }
  }

  // steps.dropoffStep = {
  //   component: RouteModule,
  //   componentProps: {
  //     title: i18n.t('servantrip_machine_config.dropoff_step_module_title'),
  //     formName: 'dropoff_form',
  //   },
  //   autoNext: false,
  //   currentStep: 'dropoff',
  //   target: '#purchase.transferServiceStep',
  //   states: routeState,
  //   context: {
  //     tickets: []
  //   }
  // }

  // steps.transferServiceStep = {
  //   component: TransferServiceModule,
  //   componentProps: {
  //     title: i18n.t('servantrip_machine_config.transfer_service_step_module_title'),
  //   },
  //   currentStep: 'transferService',
  //   target: '#purchase.quotationsStep',
  //   autoNext: false,
  //   states: transferServiceState,
  //   context: {
  //     tickets: []
  //   }
  // }

  // steps.quotationsStep = {
  //   component: QuotationsModule,
  //   autoNext: false,
  //   states: quotationsState,
  //   context: {
  //     quotation: null
  //   }
  // }

  // steps.contactRequired = {
  //   component: ContactModule,
  //   autoNext: false,
  //   states: contactState,
  //   context: {
  //     contactValues: []
  //   }
  // }

  return {
    reservationMapper: reservationMapper,
    steps: steps,
    params: params,
  }
}

export default config
