import React, {useState} from 'react'
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import { useField } from 'formik'
import cn from 'classnames'

const TextInput = ({label, hideError, uncontrolled = false, ...props }) => {
  const name = (props.field && props.field.name) || props.name
  const [field, meta, helpers] = useField(name)

  const [value, setValue] = useState(field.value)

  let uncontrolledFieldParams = {}

  if (uncontrolled) {
    uncontrolledFieldParams = {
      onChange: (e) => setValue(e.target.value),
      onBlur: (e) => helpers.setValue(e.target.value),
      value: value
    }
  }

  return (
    <FormGroup>
      {label &&
        <Label className='control-label' htmlFor={props.id || props.name}>
          {label}
          {props.required &&
            <span className="text-danger ml-1">*</span>
          }
        </Label>
      }
      <Input
        {...field}
        {...props}
        {...uncontrolledFieldParams}
        className={
          cn([
            meta.touched && meta.error ? 'is-invalid' : '',
            meta.touched && !meta.error ? 'is-valid' : ''
          ])
        }
      />

      {!hideError && meta.touched && meta.error ? (
        <FormFeedback className={'help-block'}>{meta.error}</FormFeedback>
      ) : null}
    </FormGroup>
  )
}

export default TextInput
