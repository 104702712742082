import React, {useContext} from 'react'
import ProductsListSidebar from 'containers/list/products/products_list_sidebar'
import ProductsGridHeader from 'containers/list/products/products_grid_header'
import ProductsGridPaginator from 'containers/list/products/products_grid_paginator'
import productsMachine from 'containers/list/products/products_machine'
import ProductCard from 'containers/list/products/product_card'
import {Col, Row} from 'reactstrap'
import { useMachine } from '@xstate/react'
import noResults from 'img/no-results'
import { useTranslation } from 'react-i18next'
import styles from 'styled-components'
import AppContext from 'utils/app_context'

const ProductList = (props) => {
  const {urls, categories, suppliers, cities, destination_id, query} = props
  const {mediaBreakpoint} = useContext(AppContext)
  const {i18n} = useTranslation()

  function findCategory(items, key) {
    if (!items) { return }

    for (const item of items) {
      if (item.key.toString() == key) { return item }

      const child = findCategory(item.children, key)
      if (child) { return child }
    }
  }

  const initialCategory = findCategory(categories, query.category)
  const initialSupplier = suppliers.find((c) => c.value.toString() == query.supplier)
  const initialCity = cities.find((c) => c.value.toString() == query.city)

  const initialFilters = () => {
    let filters = []

    if (initialCategory) {
      filters.push({param: 'category', value: initialCategory.key, label: initialCategory.title})
    }

    if (initialSupplier) {
      filters.push({param: 'supplier', value: initialSupplier.value, label: initialSupplier.label})
    }

    if (initialCity) {
      filters.push({param: 'city', value: initialCity.value, label: initialCity.label})
    }

    return filters
  }

  const machine = useMachine(productsMachine, {
    context: {
      endpoint: urls.grid,
      pagination: {current_page: query.page},
      filters: initialFilters()
    }
  })

  const [current, send] = machine
  const {context} = current
  const {filters, pagination, products} = context

  return (
    <Row>
      <Col xs="12" lg="3" style={{flex: mediaBreakpoint == 'xl' ? '0 0 22%' : 'auto'}}>
        <ProductsListSidebar
          machine={machine}
          categories={categories}
          suppliers={suppliers}
          cities={cities}
          initialCategory={initialCategory}
          filterCities={props.destination.filter_cities}
        />
      </Col>
      <Col xs="12" lg="9" style={mediaBreakpoint == 'xl' ? {flex: '0 0 78%', maxWidth: '78%'} : {}}>
        <ProductsGridHeader
          destination_id={destination_id}
          filters={filters}
          pagination={pagination}
          onRemoveFilter={(e) => send({type: 'FILTER_REMOVE', param: e.currentTarget.dataset.param})}
          onRemoveAllFilters={() => send({type: 'FILTER_REMOVE_ALL'})}
        />

        {products.length > 0 ? (
          <div className="px-2">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 p-0">
              {products.map((product, key) => (
                <ProductCard
                  {...product}
                  machine={machine}
                  key={key}
                  urls={urls}
                  url={urls.service.replace(/ *\:[^)]*\: */g, product.slug)}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="alert alert-warning mb-4 d-flex justify-content-left" role="alert">
            <div>
              <img width="110" height="110" className="mr-4" src={noResults}></img>
            </div>
            <div>
              <h4 className="alert-heading">{i18n.t('product_list.without_results_alert_title')}</h4>
              <hr/>
              <p className="m-0">{i18n.t('product_list.without_results_alert_content')}</p>
            </div>
          </div>
        )}

        {products.length > 0 &&
          <div className="row d-flex flex-column flex-md-row justify-content-between mx-0">
            <span className="p-2 bg-white rounded text-primary order-2 text-center">{i18n.t('product_list.total_products', {count: pagination.total_items})}</span>
            <div className="d-flex justify-content-center order-0 order-md-1">
              <ProductsGridPaginator
                {...pagination}
                onPageSelected={(page) => send({type: 'PAGINATION_SELECTION', page: page})}
              />
            </div>
            <span className="my-3 my-md-0 p-2 bg-white rounded text-primary order-0 order-md-0 text-center">{i18n.t('product_list.current_page', {page: pagination.current_page})}</span>
          </div>
        }
      </Col>
    </Row>
  )
}

export default ProductList
