import React from 'react'

const WhatWeDo = (props) => {
  return (
    <div>
      On-line system to market products and entertainment services in hotels, offering added value to customers.
    </div>
  )
}

export default WhatWeDo
