import React from 'react'
import NumericInput from 'react-numeric-input'
import { ListGroup, ListGroupItem, Button } from 'reactstrap'
import {filter, findIndex} from 'lodash'
import { useTranslation } from 'react-i18next'
import {currency} from 'i18n/config'
import {isMobile} from 'react-device-detect'
import QtyInput from 'ui/forms/qty_input'

const TicketTypesList = ({data, type, maxQtyReservation, onTicketQtyChanged}) => {
  const { t, i18n } = useTranslation()

  const TicketItem = (props) => {
    const {id, alternative_id, name, description, total_price, total_price_label, discount, discount_label, additional_fee, additional_fee_label, 
      base_price, base_price_label, qty, is_available, available,
      show_price, thumbnail, seat_capacity, luggage_capacity, max_tickets, min_tickets} = props

    const Thumbnail = () => {
      if (!thumbnail) { return null }

      return (
        <div className="text-center mr-0 mr-md-4">
          <img src={thumbnail} style={{width: '130px'}}/>
        </div>

      )
    }

    const Title = () => {
      return (
        <div className="d-flex flex-column align-self-center text-center text-md-left py-1 pl-md-3">
          <div>
            <strong style={{fontWeight: 'bold'}}>{name}</strong>
            {type == 'vehicle' &&
              <React.Fragment>
                <span className="ml-3">
                  <span className="mr-1">{seat_capacity}</span>
                  <i className="fa fa-user"/>
                </span>
                <span className="ml-2">
                  <span className="mr-1">{luggage_capacity}</span>
                  <i className="fa fa-shopping-bag"/>
                </span>
              </React.Fragment>
            }
          </div>
          <div>
            <small>{description}</small>
          </div>
        </div>
      )
    }

    const Availability = () => {
      if (!is_available || available <= 0) { return null }

      return (
        <small className="d-flex align-self-center py-1 mr-md-5">
          {i18n.t('ticket_types_list.available_qty', {qty: available})}
        </small>
      )
    }

    const Price = () => {
      if (!show_price) { return null }

      return (
        <div className="d-flex flex-column-reverse justify-content-center flex-md-row mr-0 mr-md-5 align-self-center price py-1" style={{fontSize: '18px'}}>
          {additional_fee > 0 &&
            <small className="text-info mr-md-5 d-flex align-self-center">
              {i18n.t('ticket_types_list.booking_fee', {amount: additional_fee_label})}
            </small>
          }
          <div className="text-center">
            {parseFloat(discount) > 0 ? (
              <React.Fragment>
                <span className="mr-2 text-danger">{total_price_label}</span>
                <span style={{textDecoration: 'line-through'}}><small>{base_price_label}</small></span>
              </React.Fragment>
            ) : (
              total_price_label
            )}
          </div>
        </div>
      )
    }

    const Qty = () => {
      if (!is_available) {
        return (
          <span className="bg-light text-center" style={{padding: '10px 48px', background: 'grey', borderRadius: '10px'}}>
            {i18n.t('ticket_types_list.sold_out')}
          </span>
        )
      }

      if (max_tickets == 1) {
        if (qty == 1) {
          return (
            <Button
              color="primary"
              className="btn-block text-white"
              style={{width: '160px'}}
              onClick={() => {
                let newtickets = [...data]
                let indexChanged = findIndex(newtickets, {id: id})
                newtickets[indexChanged]['qty'] = 0
                onTicketQtyChanged(newtickets)
              }}
            >
              <i className="far fa-check-square mr-2"></i>{i18n.t('ticket_types_list.added')}
            </Button>
          )
        } else {
          return (
            <Button
              color="light"
              className="btn-block"
              style={{width: '160px'}}
              onClick={() => {
                let newtickets = [...data]
                let indexChanged = findIndex(newtickets, {id: id})
                newtickets[indexChanged]['qty'] = 1
                onTicketQtyChanged(newtickets)
              }}
            >
              <i className="far fa-square mr-2"/>{i18n.t('ticket_types_list.add')}
            </Button>
          )
        }
      }

      const ticket = data.find((t) => t.id == id)

      const totalTickets = data.reduce((acc, ticket) => acc + ticket.qty, 0)

      return (
        <div className="d-flex align-self-center">
          <QtyInput
            id={id}
            alternativeId={alternative_id}
            value={qty}
            onIncrement={() => {
              let newtickets = [...data]
              let indexChanged = findIndex(newtickets, {id: id})
              newtickets[indexChanged]['qty'] = newtickets[indexChanged]['qty'] + 1
              onTicketQtyChanged(newtickets)
            }}
            onDecrement={() => {
              let newtickets = [...data]
              let indexChanged = findIndex(newtickets, {id: id})
              const newQty = newtickets[indexChanged]['qty'] <= 0 ? 0 : newtickets[indexChanged]['qty'] - 1
              newtickets[indexChanged]['qty'] = newQty
              onTicketQtyChanged(newtickets)
            }}
            isMaxQty={(ticket.available ? ticket.qty >= ticket.available : false) || (maxQtyReservation ? totalTickets >= maxQtyReservation : false)}
          />
        </div>
      )
    }

    return (
      <ListGroupItem key={id} className={'rounded-0 d-flex justify-content-between flex-column flex-md-row'}>
        <div className="d-flex flex-column flex-md-row justify-content-center">
          <Thumbnail/>
          <Title/>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-center">
          <Availability/>
          <Price/>
          <Qty/>
        </div>
      </ListGroupItem>
    )
  }

  return (
    <ListGroup>
      {filter(data, {visible: true, type: type})
        .map((ticket) => <TicketItem {...ticket} key={ticket.id}/>)}
    </ListGroup>
  )
}

export default TicketTypesList
