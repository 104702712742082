import EventModule from 'containers/purchase/modules/event_module/event_module'
import TicketTypesModule from 'containers/purchase/modules/ticket_types_module/ticket_types_module'
import EventListModule from 'containers/purchase/modules/event_list_module/event_list_module'
import eventState from 'containers/purchase/modules/event_module/event_state'
import ticketTypesState from 'containers/purchase/modules/ticket_types_module/ticket_types_state'
import eventListState from 'containers/purchase/modules/event_list_module/event_list_state'

const reservationMapper = (ctx) => {
  return {
    event_id: ctx.event.id,
    ticket_types: ctx.tickets,
    params: {
      events: ctx.events,
      access_date: ctx.access_event.date
    }
  }
}

const config = (params) => {
  const {external_api_config} = params

  return {
    reservationMapper: reservationMapper,
    steps: {
      eventStep: {
        component: EventModule,
        autoNext: true,
        states: eventState,
        context: {
          year_month: params.initialYearMonth,
          event_groups: [],
          access_event_groups: [],
          event: []
        }
      },
      secondVenueStep: {
        component: EventListModule,
        autoNext: false,
        states: eventListState,
        context: {
          tickets: []
        }
      },
      ticketsStep: {
        component: TicketTypesModule,
        autoNext: false,
        states: ticketTypesState,
        context: {
          tickets: []
        }
      }
    }
  }
}

export default config
