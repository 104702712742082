import React from 'react'
import styled from 'styled-components'
import cn from 'classnames'

const Box = styled.div`
  background: #fff;
  width: 100%;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
  height: 100%;
`

const Icon = styled.div`
  text-align: center;
  font-size: 45px;
  line-height: 90px;
  background: rgba(0,0,0,0.2);
  width: 2em;
  height: 100%;
`

const InfoBox = ({icon, label, link, value, color}) => {
  return (
    <Box className={'d-flex justify-content-left rounded'}>
      <Icon className={cn('text-white', color) }>
        {icon}
      </Icon>
      <div className="p-3">
        <a className="text-uppercase" href={link}>{label}</a>
        <p className="mt-3 m-0 h4">{value}</p>
      </div>
    </Box>
  )
}

export default InfoBox
