import { Machine, assign } from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import PubSub from 'pubsub-js'
import {mapValues, omitBy} from 'lodash'
import {scrollIntoView} from 'scroll-js'

const scrollToError = () => {
  const alert = window.document.getElementById('purchase-error')

  scrollIntoView(alert, window.document.body, { behavior: 'smooth' })
}

const actions = {
  stopSpinner: () => PubSub.publish('loaded-page'),
  startSpinner: () => PubSub.publish('loading-page'),
  scrollToError: scrollToError,
  cleanTicketTypes: assign({ticket_types: () => []})
}

const redirectNextStep = (ctx, e) => {
  document.location.href = ctx.redirect_url
}

const generateStates = (config, params) => {
  return mapValues(config, (step) => step.states(config, params, step))
}
const generateContext = (config) => Object.values(mapValues(config, (step) => step.context))
  .reduce((accum, current) => ({...accum, ...current}))

const reopenStepEvents = (config) => {
  const stepNames = Object.keys(config)
  return stepNames.map((stepName, index) => {
    const stepsToClean = stepNames.slice(index + 1)
    const contextsToClean = stepsToClean.map((step) => config[step].context)

    let nextContext = {}
    if (contextsToClean.length > 0) {
      nextContext = contextsToClean.reduce((accum, current) => ({...accum, ...current}))
    }

    const eventName = `REOPEN_${stepName.toUpperCase()}`
    return {
      [eventName]: {
        target: stepName,
        actions: [
          assign(nextContext),
          assign({priceSummary: (ctx) => omitBy(ctx.priceSummary, (o, k) => stepsToClean.includes(k))})
        ]
      }
    }
  }).reduce((accum, current) => ({...accum, ...current}))
}

export const purchaseMachine = (config) => {
  const {steps, params} = config

  const reservate = (ctx) => {
    PubSub.publish('loading-page')

    return new ApiFetcher({
      key: 'reservation',
      method: 'POST',
      endpoint: ctx.endpoints.reservation,
      body: JSON.stringify({
        ...config.reservationMapper(ctx),
        product_id: ctx.product_id,
        referral_params: {
          id: ctx.product_id,
          url: window.location.href,
          related_products: ctx.related_products
        }
      })
    }).call()
  }

  const firstStep = Object.keys(steps)[0]

  return Machine(
    {
      id: 'purchase',
      initial: firstStep,
      context: {
        next_ready: true,
        reservation_ready: true,
        ...generateContext(steps),
        reservation_id: null,
        priceSummary: {},
        error: null
      },
      states: {
        ...generateStates(steps, params),
        reserving: {
          invoke: {
            src: reservate,
            onDone: {
              target: 'reserved',
              actions: [
                assign({reservation_id: (context, event) => event.data.reservation.id}),
                assign({redirect_url: (context, event) => event.data.url}),
                assign({cart: (context, event) => event.data.reservation}),
                assign({errors: () => null}),
                'stopSpinner'
              ]
            },
            onError: {
              target: 'error',
              actions: [
                assign({error: (context, event) =>
                  event.data.errors.items ? Object.values(event.data.errors.items)[0].display_text : Object.values(event.data.errors)[0]
                })
              ]
            }
          }
        },
        error: {
          onEntry: 'stopSpinner',
        },
        reserved: {
          type: 'final'
        }
      },
      on: {
        ...reopenStepEvents(steps),
        RESERVATION_REQUEST: {
          target: 'reserving'
        }
      }
      // onDone: {
      //   actions: [
      //     assign({error: () => null}),
      //     'startSpinner',
      //   ]
      // }
    },
    {
      actions: actions
    }
  )
}
