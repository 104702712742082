import React from 'react'
import {useTranslation} from 'react-i18next'
import Page from 'ui/page'
import AsyncTable from 'ui/async_table'
import useTableFetch from 'utils/hooks/use_table_fetch'
import tableOptions from './products_catalog_grid_options'

const ProductsCatalog = (props) => {
  const {i18n} = useTranslation()
  const tableFetch = useTableFetch(props.urls.grid)
  const {data, doFetch, loading} = tableFetch

  return (
    <Page {...props}>
      <h1>{i18n.t('products_catalog.title')}</h1>
      <AsyncTable
        {...tableFetch}
        tableOptions={tableOptions}
      />
    </Page>
  )
}

export default ProductsCatalog
