import React, {useState, useEffect} from 'react'
import { Formik, Field } from 'formik'
import { Row, Col, Form, FormGroup, Label, Card, CardHeader, CardBody, ButtonGroup, Button } from 'reactstrap'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import Select from 'ui/forms/select'
import DatetimePicker from 'ui/forms/datetime_picker'
import time from 'i18n/time'

const CarLocationForm = (props) => {
  const {machine} = props
  const { t, i18n } = useTranslation()
  const [current, send] = machine
  const {car_locations} = current.context

  const formatOptionLabel = ({ label, hours, address }) => (
    <div>
      <div style={{ display: 'flex' }}>
        <div>{label}</div>
        <div style={{ marginLeft: '10px'}}>
          <smal className="text-muted">{address.address_line_1}, {address.postal_code}</smal>
        </div>
      </div>
    </div>
  )

  return (
    <Formik
      initialValues={{
        pickup_location: '',
        pickup_date: '',
        dropoff_location: '',
        dropoff_date: '',
      }}
      validationSchema={
        Yup.object().shape({
          pickup_location: Yup.string().required(i18n.t('validations:required')),
          pickup_date: Yup.date().required(i18n.t('validations:required')),
          dropoff_location: Yup.string().required(i18n.t('validations:required')),
          dropoff_date: Yup.date().required(i18n.t('validations:required')),
        })
      }
      onSubmit={() => {}}
    >
      {(form) => {
        const {values, errors, touched, setFieldValue} = form

        useEffect(() => {
          props.setForm(form)
        }, [values, errors, touched])

        const [sameDropoff, setSameDropoff] = useState(true)

        const pickupLocation = car_locations && car_locations.find((l) => l.code == values.pickup_location)
        const dropoffLocation = car_locations && car_locations.find((l) => l.code == values.dropoff_location)

        return (
          <Form>
            <Row className="justify-content-md-center">
              <Col>
                <Card>
                  <CardHeader>
                    {i18n.t('car_location.pickup_panel_title')}
                  </CardHeader>
                  <CardBody>
                    <Select
                      label={i18n.t('car_location.pickup_location_label')}
                      placeholder={i18n.t('car_location.pickup_location_placeholder')}
                      name={'pickup_location'}
                      className="mt-2"
                      labelClass="m-0"
                      required
                      formatOptionLabel={formatOptionLabel}
                      values={car_locations ? car_locations.map((c) => ({label: c.name, value: c.code, ...c})) : []}
                      onSelect={(selected) => {
                        if (sameDropoff) {
                          setFieldValue('dropoff_location', selected ? selected.value  : '')
                        }
                      }}
                    />

                    <Field
                      label={i18n.t('car_location.pickup_time')}
                      name={'pickup_date'}
                      machine={machine}
                      component={DatetimePicker}
                      initialDate={time().add(1, 'days').toDate()}
                      showTimeSelect={true}
                      minDate={null}
                      peekNextMonth
                      dropdownMode="select"
                    />

                    {pickupLocation &&
                      <Card>
                        <CardBody>
                          <h5>{pickupLocation.name}</h5>
                          <div><strong>Dirección: </strong> {pickupLocation.address.address_line_1}, {pickupLocation.address.postal_code} {pickupLocation.address.state_name}</div>
                          <div><strong>Horario: </strong> {pickupLocation.hours}</div>
                          <div><strong>Teléfono: </strong> {pickupLocation.telephone}</div>
                        </CardBody>
                      </Card>
                    }
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardHeader>
                    {i18n.t('car_location.dropoff_panel_title')}
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label className="control-label w-100" >{i18n.t('car_location.same_dropoff')}</Label>
                      <div className='clearfix'></div>

                      <ButtonGroup className="btn-block">
                        <Button
                          color="primary text-white"
                          active={sameDropoff}
                          onClick={() => {
                            setFieldValue('dropoff_location', values.pickup_location)
                            setSameDropoff(true)
                          }}
                        >
                          {sameDropoff &&
                            <i className="fa fa-check-circle mr-1" aria-hidden="true"></i>
                          }
                          Sí
                        </Button>
                        <Button
                          color="primary text-white"
                          active={!sameDropoff}
                          onClick={() => setSameDropoff(false)}
                        >
                          {!sameDropoff &&
                            <i className="fa fa-check-circle mr-1" aria-hidden="true"></i>
                          }
                          No
                        </Button>
                      </ButtonGroup>
                    </FormGroup>

                    {!sameDropoff &&
                        <Select
                          label={i18n.t('car_location.dropoff_location_label')}
                          placeholder={i18n.t('car_location.dropoff_location_placeholder')}
                          name={'dropoff_location'}
                          className="mt-2"
                          labelClass="m-0"
                          required
                          formatOptionLabel={formatOptionLabel}
                          values={car_locations ? car_locations.map((c) => ({label: c.name, value: c.code, ...c})) : []}
                        />
                    }

                    <Field
                      label={i18n.t('car_location.dropoff_time')}
                      name={'dropoff_date'}
                      machine={machine}
                      component={DatetimePicker}
                      showTimeSelect={true}
                      initialDate={time().add(2, 'days').toDate()}
                      minDate={null}
                      peekNextMonth
                      dropdownMode="select"
                    />

                    {values.dropoff_location && dropoffLocation && !sameDropoff &&
                      <Card>
                        <CardBody>
                          <h5>{dropoffLocation.name}</h5>
                          <div><strong>Dirección: </strong> {dropoffLocation.address.address_line_1}, {dropoffLocation.address.postal_code} {dropoffLocation.address.state_name}</div>
                          <div><strong>Horario: </strong> {dropoffLocation.hours}</div>
                          <div><strong>Teléfono: </strong> {dropoffLocation.telephone}</div>
                        </CardBody>
                      </Card>
                    }

                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CarLocationForm
