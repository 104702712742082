import { assign } from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import {nextStep} from 'utils/step_utils'
import i18n from 'i18n/config'

const stepName  = 'customFieldsStep'

const fetchCustomFields = (ctx) => (
  new ApiFetcher({
    key: 'custom_fields',
    endpoint: ctx.endpoints.custom_fields,
    query: {event_id: ctx.event.id}
  }).call()
)

const submitForm = (ctx) => {
  return new Promise((resolve, reject) => {
    ctx.form.submitForm()
      .then(ctx.form.validateForm)
      .then(errors => {
        const isValid = Object.keys(errors).length === 0
        if (isValid) {
          resolve(ctx.form.values)
        } else {
          reject(errors)
        }
      })
  })
}

const initCustomFields = assign({customFields: (ctx, e) => e.data})
const setCustomFieldValues = assign({customFieldValues: (ctx, e) => {
  if (!e.data) {
    return null
  }

  return Object.keys(e.data).map((key) => {
    let value = e.data[key]

    const id = key.replace('custom_field_', '')
    return {
      'custom_field': parseInt(id),
      'value': value
    }
  })
}})
const updateForm = assign({form: (ctx, e) => e.form})
const setError = (msg) => assign({error: msg})
const cleanError = assign({error: null})

const haveCustomFields = (ctx, e) => e.data && e.data.length > 0

const customFieldsModule = (config) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {
          '': [
            {
              target: 'fetchingCustomFields',
              actions: [cleanError, 'startSpinner']
            },
            {
              target: 'waitingCustomFieldsResponses'
            }
          ],
        },
      },
      fetchingCustomFields: {
        invoke: {
          src: fetchCustomFields,
          onDone: [
            {
              target: 'waitingCustomFieldsResponses',
              actions: [initCustomFields, 'stopSpinner'],
              cond: haveCustomFields
            },
            {
              target: `#purchase.${nextStep(config, stepName)}`,
              actions: ['stopSpinner']
            }
          ],
          onError: {
            target: '#purchase.error',
            actions: [setError(i18n.t('custom_fields_state.error_fetching_custom_fields')), 'stopSpinner']
          }
        }
      },
      waitingCustomFieldsResponses: {},
      formValidation: {
        invoke: {
          src: submitForm,
          onDone: {
            target: `#purchase.${nextStep(config, stepName)}`,
            actions: [setCustomFieldValues]
          },
          onError: {
            target: 'waitingCustomFieldsResponses',
            actions: [setError(i18n.t('custom_fields_state.invalid_custom_field_response'))]
          }
        }
      }
    },
    on: {
      FAREHARBOR_FORM_CHANGE: {
        actions: [updateForm, cleanError]
      },
      NEXT_STEP_CLICK: [
        {
          target: '.formValidation'
        }
      ],
      RESERVATION_REQUEST: [
        {
          target: '.formValidation'
        }
      ]
    }
  }
}

export default customFieldsModule
