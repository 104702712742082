import {assign} from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import moment from 'moment'
import {nextStep} from 'utils/step_utils'
import i18n from 'i18n/config'

const stepName  = 'eventStep'

const fetchMonth = (ctx) => {
  return new ApiFetcher({
    key: 'daily_availability',
    endpoint: ctx.endpoints.daily_availability,
    query: {date: ctx.year_month}
  }).call()
}

const fetchEvents = (ctx) => (
  new ApiFetcher({
    key: 'events',
    endpoint: ctx.endpoints.events,
    query: {date: ctx.day}
  }).call()
)

const initSelectionGroups = assign({events: (ctx, e) => {
  return ctx.event_groups.reduce((accum, val) => {
    return {...accum, [val.selection_group.id]: null}
  }, {})
}})
const setError = assign({error: i18n.t('validations:events_fetch_error')})
const setYearMonth = assign({year_month: (ctx, e) => e.year_month})
const setDayAsToday = assign({day: (ctx, e) => moment().format('YYYY-MM-DD')})
const setDaysAvailable = assign({days_available: (ctx, e) => e.data})
const setDay = assign({day: (ctx, e) => moment(e.day).format('YYYY-MM-DD')})
const setEventGroups = assign({event_groups: (ctx, e) => e.data})
const setMainEvent = assign({event: (ctx) => Object.values(ctx.events)[0]})
const addEvents = assign({events: (ctx, e) => fillEvents(e.event, ctx.events)})
const setUnready = assign({ready: false})
const cleanDay = assign({day: null})
const cleanEventGroups = assign({event_groups: null})
const cleanEvent = assign({event: null})
const cleanError = assign({error: null})

const fillEvents = (event, events) => {
  let newEvents = {...events}
  newEvents[event.selection_group_id] = event
  return newEvents
}

const eventsAndDayPicked = (ctx, e) => {
  const currentEvents = fillEvents(e.event, ctx.events)
  return Object.values(currentEvents).every((value) => value != null && value != undefined)
}

const todayIsAvailable = (ctx, e) => {
  const isCurrentMonth = moment(ctx.day).format('YYYY-MM') == ctx.year_month

  if (!isCurrentMonth) { return false }

  if (
    ctx.days_available == null ||
    (ctx.days_available.length > 0 && ctx.days_available.some((d) => d == moment().format('YYYY-MM-DD')))
  ) {
    return true
  } else {
    return false
  }
}

// const isUniqueEvent = (ctx) => {
//   if (ctx.event_groups.length <= 0) {
//     return false
//   }
//   const reducer = (accumulator, currentValue) => accumulator + currentValue
//   const total_events = ctx.event_groups.map((o) => o.events.length).reduce(reducer)
//   return total_events == 1 ? true : false
// }

const eventState = (config) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {'': {
          target: 'prepareFetchingCalendar',
          actions: setDayAsToday
        }},
      },
      prepareFetchingCalendar: {
        on: {'': {
          target: 'fetchingCalendar',
          actions: ['startSpinner', setUnready]
        }},
      },
      fetchingCalendar: {
        invoke: {
          src: fetchMonth,
          onDone: {
            target: 'fetchEventRequiredDecision',
            actions: [setDaysAvailable, 'stopSpinner']
          },
          onError: {
            target: '#purchase.error',
            actions: [setError, 'stopSpinner']
          }
        },
      },
      fetchEventRequiredDecision: {
        on: {'': [
          {
            target: 'prepareFetchingEvents',
            cond: todayIsAvailable,
          },
          {
            target: 'waitingDayPick'
          }
        ]}
      },
      waitingDayPick: {},
      prepareFetchingEvents: {
        on: {'': {
          target: 'fetchingEvents',
          actions: [cleanError, 'startSpinner']
        }},
      },
      fetchingEvents: {
        invoke: {
          src: fetchEvents,
          onDone: {
            target: 'waitingEventPick',
            actions: [setEventGroups, initSelectionGroups, 'stopSpinner']
          },
          onError: {
            target: '#purchase.error',
            actions: [setError, 'stopSpinner']
          }
        },
      },
      waitingEventPick: {}
    },
    on: {
      MONTH_CHANGE: {
        target: '.prepareFetchingCalendar',
        actions: [cleanDay, cleanEventGroups, cleanEvent, setYearMonth]
      },
      DAY_SELECTION: {
        target: '.prepareFetchingEvents',
        actions: [setDay]
      },
      EVENT_SELECTION: [
        {
          actions: [addEvents, setMainEvent],
          target: nextStep(config, stepName),
          cond: eventsAndDayPicked,
        },
        {
          actions: [addEvents, setMainEvent]
        }
      ]
    }
  }
}

export default eventState
