import React, {useState, useEffect, useMemo} from 'react'
import {Row, Col, Button} from 'reactstrap'
import SummaryModule from 'containers/purchase/modules/summary_module/summary_module'
import BuyerModule from 'containers/purchase/modules/buyer_module/buyer_module'
import PaymentModule from 'containers/purchase/modules/payment_module/payment_module'
import ApiFetcher from 'lib/api_fetcher'
import cn from 'classnames'
import { Formik } from 'formik'
import * as Yup from 'yup'
import PubSub from 'pubsub-js'
import PurchaseWizard from 'containers/purchase/purchase_wizard'
import paymentRedirect from 'lib/payment_redirect'
import {get} from 'lodash'
import { useTranslation } from 'react-i18next'
import {isMobileOnly} from 'react-device-detect'
import Page from 'ui/page'
import Modal from 'ui/modal'

const cartFetcher = (url) => (
  new ApiFetcher({
    key: 'cart',
    method: 'GET',
    endpoint: url,
    cache: 'no-store'
  }).call()
)

const BuyerStep = (props) => {
  const { t, i18n } = useTranslation()
  const {endpoints, user_config, client_params, cart_params} = props
  const {enable_cart} = cart_params
  const {user} = user_config
  const {payment_methods} = user

  const languageOptions = [
    { value: 'es', label: i18n.t('format:languages.spanish') },
    { value: 'en', label: i18n.t('format:languages.english') }
  ]

  const [response, setResponse] = useState({loading: true})
  const [baseError, setBaseError] = useState()
  const cart = response && response.reservation
  const confirmUrl = response && response.url
  const redirectUrl = response && response.redirect_url
  const [confirmationPending, setConfirmationPending] = useState(false)

  const validationSchema = (saleChannel) => {
    let customerShape = Yup.object().shape({
      first_name: Yup.string().required(i18n.t('validations:required')).max(25, i18n.t('validations:string.max')),
      surname: Yup.string().required(i18n.t('validations:required')).max(35, i18n.t('validations:string.max')),
      email: Yup.string().email(i18n.t('validations:email')).required(i18n.t('validations:required')),
      notifications_email: Yup.string().email(i18n.t('validations:email')),
    })

    if (saleChannel == 'b2b') {
      customerShape['notifications_email'] = Yup.string().email(i18n.t('validations:email')).required(i18n.t('validations:required'))
    }

    if (saleChannel == 'b2c') {
      customerShape['telephone'] = Yup.string().required(i18n.t('validations:required'))
    }

    return Yup.object().shape({
      main_customer: customerShape,
      payment_method: Yup.string().required(i18n.t('buyer_step.payment_method_required')),
      consent: Yup.bool()
        .test(
          'consent',
          i18n.t('buyer_step.consent'),
          value => value === true
        )
    })
  }

  const onConfirmButton = (submitForm, isValid, confirmationPending, toggleConfirmModal) => {
    if (isValid && confirmationPending) {
      toggleConfirmModal()
    } else {
      submitForm()
    }
  }

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    PubSub.publish('loading-page')

    Object.assign(values, {name: values.first_name + ' ' + values.last_name})

    const attendantValue = values.attended_by && values.attended_by.value

    let attendant = cart.attendants.find((a) => a.id === attendantValue)

    let reservation_params = {
      main_customer: values.main_customer,
      notifications_language: values.notifications_language,
      app_language: i18n.language,
      payment_method: {
        payment_method: values.payment_method,
        payment_method_params: {
          redirect_url: redirectUrl
        }
      }
    }

    if (attendant) {
      reservation_params['attendant_id'] = attendant.id
    } else if (values.attended_by) {
      reservation_params['attendant_name'] = attendantValue
    }

    return new ApiFetcher({
      key: 'reservation',
      method: 'PUT',
      endpoint: confirmUrl,
      body: JSON.stringify(reservation_params)
    }).call()
      .then((data) => {
        if (data.success == true) {
          const cart = data.reservation

          if (cart.status == 'payment_pending') {
            if (cart.payment_request.endpoint) {
              paymentRedirect(cart.payment_request.endpoint, cart.payment_request.params)
            } else {
              window.location.href = endpoints.payment
            }
          } else {
            window.location.href = endpoints.summary
          }
        }
      })
      .catch((data) => {
        if (data.phase == 'confirm') {
          window.location.href = endpoints.summary
        } else {
          setErrors(data.error)
        }
      })
      .finally(() => {
        setSubmitting(false)
        PubSub.publish('loaded-page')
      })
  }

  React.useEffect(() => {
    PubSub.publish('loading-page')

    cartFetcher(endpoints.reservation).then((data) => {
      history.pushState({preventBack: true}, null, "?preventBack=true");

      setResponse({loading: false, ...data})

      const alertType = get(data, 'reservation.items[0].product.return_policy.alert_type')

      if (alertType) {
        setConfirmationPending(true)
      }

      PubSub.publish('loaded-page')
    }).catch(() => {
      setResponse({loading: false, success: false})
    })
  }, [])

  const BuyerForm = () => {
    const product_url = (cart) => endpoints.service.replace(/ *\:[^)]*\:*/g, cart.items[0].product.id)
    const returnPolicy = cart && cart.items[0].product.return_policy
    const generic_credit_card = payment_methods.find((pm) => pm.code == 'generic_credit_card')

    const reservation = cart.items[0]
    let defaultNoticationLanguage = 'en'
    if (languageOptions.find((l) => l.value == I18n.locale)) {
      defaultNoticationLanguage = languageOptions.find((l) => l.value == I18n.locale).value
    }

    return (
      <React.Fragment>
        {cart && cart.status == 'open' &&
          <div>
            {!isMobileOnly &&
              <PurchaseWizard stepActive={1} endpoints={{...endpoints, service: product_url(cart)}}/>
            }
            {baseError &&
              <div className="alert alert-danger" role="alert">
                {baseError}
              </div>
            }
            <Formik
              onSubmit={onSubmit}
              validateOnMount={true}
              validationSchema={validationSchema(user.sale_channel)}
              initialValues={useMemo(() => ({
                main_customer: {
                  first_name: '',
                  surname: '',
                  email: user.sale_channel == 'b2b' ? (user && user.email_notifications) : '',
                  notifications_email: get(cart, 'main_customer.notifications_email', ''),
                  prefix_phone: '',
                  telephone: get(cart, 'main_customer.telephone', ''),
                  room: '',
                  comments: ''
                },
                attended_by: '',
                notifications_language: defaultNoticationLanguage,
                payment_method: (generic_credit_card && generic_credit_card.code) || payment_methods[0].code,
                consent: user.sale_channel != 'b2c' ? true : false
              }), [])
              }
            >
              {({
                submitForm,
                isValid,
                isSubmitting,
                setFieldValue
              }) => {
                const [confirmModal, setConfirmModal] = useState(false)
                const toggleConfirmModal = () => setConfirmModal(!confirmModal)

                const ConfirmPurchaseModal = () => {
                  if (!returnPolicy) {
                    return null
                  }

                  const reservation = cart.items[0]

                  return (
                    <Modal
                      isOpen={confirmModal}
                      toggle={toggleConfirmModal}
                      header={i18n.t('buyer_step.confirm_purchase_modal.header')}
                    >
                      {returnPolicy.warning_text}

                      <hr/>

                      <h4>{i18n.t('buyer_step.confirm_purchase_modal.summary_title')}</h4>
                      <SummaryModule cart={cart} reservation={reservation}/>

                      <hr/>

                      <Button
                        size={'lg'}
                        color='primary'
                        className={cn([
                          'pull-right text-white w-100',
                          isSubmitting || !isValid ? 'disabled' : ''
                        ])}
                        onClick={() => {
                          toggleConfirmModal()
                          setConfirmationPending(false)
                          submitForm()
                        }}
                      >
                        {i18n.t('buyer_step.confirm_purchase')}
                      </Button>
                    </Modal>
                  )
                }

                const ReservationActionPanel = () => {
                  return (
                    <Button
                      size={'lg'}
                      color='primary'
                      className={cn([
                        'pull-right text-white w-100',
                        isSubmitting || !isValid ? 'disabled' : ''
                      ])}
                      onClick={() => onConfirmButton(submitForm, isValid, confirmationPending, toggleConfirmModal)}
                    >
                      {isSubmitting ? (
                        i18n.t('buyer_step.confirming_purchase')
                      ) : (
                        i18n.t('buyer_step.confirm_purchase')
                      )}
                    </Button>
                  )
                }

                return (
                  <Row className="mx-0">
                    <ConfirmPurchaseModal/>
                    <Col md={6} xs={12} className="p-0 pr-md-3">
                      <BuyerModule
                        user={user}
                        cart={cart}
                        setFieldValue={setFieldValue}
                        languageOptions={languageOptions}
                        client_params={client_params}
                      />
                    </Col>
                    <Col md={6} xs={12} className="p-0 pl-md-3">
                      <SummaryModule cart={cart} reservation={reservation} cartParams={cart_params} discardEnabled={cart_params.enable_cart}/>

                      <PaymentModule name="payment_method" cart={cart} user={user}/>

                      <ReservationActionPanel/>
                    </Col>
                  </Row>
                )
              }}
            </Formik>
          </div>
        }
      </React.Fragment>
    )
  }

  if(!cart) {
    return (
      <Page {...props}>
        <h2 className="text-center">{i18n.t('payment_step.fetching_cart')}...</h2>
      </Page>
    )
  }

  if (cart && ['discarded'].includes(cart.status)) {
    window.location.href = endpoints.products
    return
  }

  if (cart && ['payment_pending', 'payment_failed'].includes(cart.status)) {
    window.location.href = endpoints.payment
    return
  }

  if (cart && ['confirming', 'confirmed', 'failed', 'human_processing_pending'].includes(cart.status)) {
    window.location.href = endpoints.summary
    return
  }

  return (
    <Page {...props}>
      {response.loading == false && response.success == true &&
        <BuyerForm/>
      }
    </Page>
  )
}

export default BuyerStep
