import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import ApiFetcher from 'lib/api_fetcher'
import Modal from 'ui/modal'
import { Button, Form, FormGroup, Input, Card, CardTitle, Row, Col } from 'reactstrap'
import CopyBlock from 'ui/copy_block'
import * as Yup from 'yup'

const B2cModal = (props) => {
  const {modal, toggle} = props
  const [link, setLink] = useState('')
  const [linkSent, setLinkSent] = useState(false)
  const [email, setEmail] = useState('')
  const { t, i18n } = useTranslation()
  const token = b2cAccounts && b2cAccounts[0] && b2cAccounts[0].token

  const b2cAccounts = props.user.client.b2c_accounts
  const productId = props.product.id

  const fetchReferral = () => {
    return new ApiFetcher({
      key: 'retrieve_referral',
      body: JSON.stringify({
        user_token: token,
        modality: 'product',
        destination: props.destination.id,
        product: productId,
        format: 'web'
      }),
      endpoint: props.endpoints.retrieve_referral,
      method: 'POST'
    }).call()
  }

  React.useEffect(() => {
    if (!modal || !token) { return }

    let isMounted = true

    fetchReferral().then((res) => {
      if (isMounted) setLink(res.url)
    })

    return () => { isMounted = false }
  }, [modal])

  const sendLink = () => {
    PubSub.publish('loading-page')

    new ApiFetcher({
      key: 'send_b2c_email',
      method: 'POST',
      endpoint: props.endpoints.send_b2c_email,
      body: JSON.stringify({
        email: email,
        product_id: productId,
        link: link
      })
    }).call().then(() => {
      setLinkSent(true)
      PubSub.publish('loaded-page')
    })
  }

  const isValid = email && Yup.string().email().isValidSync(email)

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      header={i18n.t('b2c_modal.title')}
      width={'450px'}
    >
      <Row>
        <Col>
          <Card body outline color="secondary" className="text-center mb-3">
            <CardTitle><h5>{i18n.t('b2c_modal.copy_link_title')}</h5></CardTitle>
            <p>{i18n.t('b2c_modal.direct_link.instructions')}</p>
            <CopyBlock color="secondary" value={link}/>
          </Card>

          <Card body outline color="secondary" className="text-center">
            <CardTitle><h5>{i18n.t('b2c_modal.send_mail_title')}</h5></CardTitle>
            <p>{i18n.t('b2c_modal.send_email.instructions')}</p>
            {!linkSent &&
              <Form>
                <FormGroup className="py-1">
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="customer@email.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
                <Button disabled={!isValid} onClick={sendLink} className="w-100">
                  {isValid ? (
                    i18n.t('b2c_modal.send_button_ready')
                  ) : (
                    i18n.t('b2c_modal.send_button_unready')
                  )}
                </Button>
              </Form>
            }

            {linkSent &&
              <Button disabled>
                <i className="fa fa-check mr-2"/>
                {i18n.t('b2c_modal.link_sent')}
              </Button>
            }
          </Card>
        </Col>
      </Row>
    </Modal>
  )
}

export default B2cModal
