import { assign } from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import {nextStep} from 'utils/step_utils'
import {EUR} from 'utils/currency'
import i18n from 'i18n/config'
import time  from 'i18n/time'

const setError = assign({error: i18n.t('validations:fill_all_fields')})
const cleanError = assign({error: null})
const setValidationError = assign({error: i18n.t('validations:fill_all_fields')})
const updateForm = assign({car_location_form: (ctx, e) => e.form})

const fetchCarLocations = (ctx) => (
  new ApiFetcher({
    key: 'car_locations',
    endpoint: ctx.endpoints.car_locations
  }).call()
)

const fetchVehicles = (ctx) => (
  new ApiFetcher({
    key: 'vehicles',
    endpoint: ctx.endpoints.vehicles,
    method: 'GET',
    query: ctx.car_location_values
  }).call()
)

const submitForm = (ctx, event) => {
  const formName = 'car_location_form'

  return new Promise((resolve, reject) => {
    ctx[formName].submitForm()
      .then(ctx[formName].validateForm)
      .then(errors => {
        const isValid = Object.keys(errors).length === 0
        if (isValid) {
          resolve(ctx[formName].values)
        } else {
          reject(errors)
        }
      })
  })
}

const carLocationsState = (steps, params, step) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {'': {
          target: 'prepareFetchCarLocations',
          actions: cleanError
        }},
      },
      prepareFetchCarLocations: {
        on: {'': {
          target: 'fetchCarLocations',
          actions: ['startSpinner']
        }},
      },
      fetchCarLocations: {
        invoke: {
          src: fetchCarLocations,
          onDone: [
            {
              target: 'waitingCarLocationParams',
              actions: [
                assign({car_locations: (ctx, e) => e.data.data}),
                'stopSpinner'
              ]
            }
          ],
          onError: {
            target: 'waitingCarLocationParams',
            actions: [setError, 'stopSpinner']
          }
        }
      },
      waitingCarLocationParams: {},
      formValidation: {
        invoke: {
          src: submitForm,
          onDone: [
            {
              target: 'prepareFetchVehicles',
              actions: assign({car_location_values: (ctx, e) => {
                const format = 'YYYY-MM-DD[T]HH:mm:ss'
                let values = e.data
                values.pickup_date = time(values.pickup_date).format(format)
                values.dropoff_date = time(values.dropoff_date).format(format)

                return values
              }})
            }
          ],
          onError: {
            target: 'waitingCarLocationParams',
            actions: [setError]
          }
        }
      },
      prepareFetchVehicles: {
        on: {'': {
          target: 'fetchVehicles',
          actions: ['startSpinner']
        }},
      },
      fetchVehicles: {
        invoke: {
          src: fetchVehicles,
          onDone: [
            {
              target: '#purchase.vehiclesStep',
              actions: [
                assign({vehicles: (ctx, e) => e.data.data}),
                'stopSpinner'
              ]
            }
          ],
          onError: {
            target: 'waitingCarLocationParams',
            actions: [setError, 'stopSpinner']
          }
        }
      }
    },
    on: {
      CAR_LOCATION_FORM_CHANGE: {
        actions: [updateForm]
      },
      NEXT_STEP_CLICK: [
        {
          target: '.formValidation'
        },
        {
          actions: [setValidationError]
        }
      ]
    }
  }
}

export default carLocationsState
