import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {shuffle} from 'lodash'
import parse from 'html-react-parser'

const settings = {
  showThumbs: false,
  showIndicators: false,
  showStatus: false,
  autoPlay: true,
  infiniteLoop: true,
  interval: 7000,
  axis: 'horizontal'
}

const rediretToLink = (url, target) => {
  if (url) {
    if (target == 'same_window') {
      window.location.href = url
    } else {
      window.open(url,'_blank')
    }
  }
}

const Banner = (props) => {
  const {banner_type, content, slides, href, link_target, banner_image} = props

  if (banner_type == 'slider') {
    return (
      <Carousel {...settings}>
        {slides.map(({position, image_url, href, link_target}) =>
          <div key={position} style={{cursor: href ? 'pointer' : 'auto'}} onClick={() => rediretToLink(href, link_target)}>
            <img src={image_url} width="100%" height="100%" style={{maxHeight: '150px'}}></img>
          </div>
        )}
      </Carousel>
    )
  }

  if (banner_type == 'text') {
    return (
      <div className="alert alert-info" role="alert">
        {content}
      </div>
    )
  }

  if (banner_type == 'html') {
    return (
      parse(content)
    )
  }

  if (banner_type == 'image') {
    return (
      <div style={{cursor: href ? 'pointer' : 'auto'}} onClick={() => rediretToLink(href, link_target)}>
        <img src={banner_image} width="100%" height= {'110'}></img>
      </div>
    )
  }

  return null
}

export default Banner
