import {assign} from 'xstate'
import moment from 'moment'
import {nextStep} from 'utils/step_utils'
import i18n from 'i18n/config'

const stepName  = 'contactStep'

const updateForm = assign({contactForm: (ctx, e) => e.form})
const cleanError = assign({error: null})
const setError = (msg) => assign({error: i18n.t(msg)})
const setContactValues = assign({contactValues: (ctx, e) => e.data})

const submitForm = (ctx) => {
  return new Promise((resolve, reject) => {
    ctx.contactForm.submitForm()
      .then(ctx.contactForm.validateForm)
      .then(errors => {
        const isValid = Object.keys(errors).length === 0
        if (isValid) {
          resolve(ctx.contactForm.values)
        } else {
          reject(errors)
        }
      })
  })
}

const contactState = (config) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {'': {
          target: 'waitingContactResponses',
          actions: cleanError
        }},
      },
      waitingContactResponses: {},
      formValidation: {
        invoke: {
          src: submitForm,
          onDone: {
            target: '#purchase.reserving',
            actions: [setContactValues]
          },
          onError: {
            target: 'waitingContactResponses',
            actions: [setError(i18n.t('contact_state.submiting_contact_form'))]
          }
        }
      }
    },
    on: {
      CONTACT_FORM_CHANGE: {
        actions: [updateForm, cleanError]
      },
      NEXT_STEP_CLICK: [
        {
          target: '.formValidation'
        }
      ],
      RESERVATION_REQUEST: [
        {
          target: '.formValidation'
        }
      ]
    }
  }
}

export default contactState
