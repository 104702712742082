import React, {useState, useEffect} from 'react'
import i18n from 'i18next'
import translationEN from 'i18n/en/translation.json'
import translationES from 'i18n/es/translation.json'
import translationFR from 'i18n/fr/translation.json'
import translationZH from 'i18n/zh/translation.json'
import formatEN from 'i18n/en/format.json'
import formatES from 'i18n/es/format.json'
import formatFR from 'i18n/fr/format.json'
import formatZH from 'i18n/zh/format.json'
import formatES_MX from 'i18n/es_mx/format.json'
import validationsEN from 'i18n/en/validations.json'
import validationsES from 'i18n/es/validations.json'
import validationsFR from 'i18n/fr/validations.json'
import validationsZH from 'i18n/zh/validations.json'
import pcrSpainEN from 'i18n/en/pcr_spain.json'
import pcrSpainES from 'i18n/es/pcr_spain.json'
import pcrSpainFR from 'i18n/fr/pcr_spain.json'
import pcrSpainZH from 'i18n/zh/pcr_spain.json'
import { initReactI18next } from 'react-i18next'
import legalWarning from 'i18n/es/partials/auth/legal_warning'

i18n
  .use(initReactI18next)
  .init({
    lng: I18n.locale,
    debug: true,
    returnEmptyString: false,
    fallbackLng: {
      'es_mx': ['es']
    },
    resources: {
      'en': {
        'translation': translationEN,
        'format': formatEN,
        'validations': validationsEN,
        'pcr_spain': pcrSpainEN
      },
      'es': {
        'translation': translationES,
        'format': formatES,
        'validations': validationsES,
        'pcr_spain': pcrSpainES
      },
      'es_mx': {
        'format': formatES_MX,
      },
      'fr': {
        'translation': translationFR,
        'format': formatFR,
        'validations': validationsFR,
        'pcr_spain': pcrSpainFR
      },
      'zh': {
        'translation': translationZH,
        'format': formatZH,
        'validations': validationsZH,
        'pcr_spain': pcrSpainZH
      }
    }
  })

const EmptyComponent = () => {
  return <div></div>
}

const usePartial = (path, props = {}) => {
  const [Partial, setPartial] = useState(EmptyComponent)

  const basePath = `./${I18n.locale}/partials`

  useEffect(() => {
    import(/* webpackMode: "eager" */ `${basePath}/${path}`).then(res => setPartial(res.default(props)))
  }, [])

  return () => Partial
}

const currency = (amount) => {
  return new Intl.NumberFormat(I18n.locale, {
    style: 'currency', currency: 'EUR'
  }).format(amount)
}

export default i18n
export {usePartial, currency}
