import React from 'react'
import {Card, CardBody} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import {useTimer} from 'react-timer-hook'

const CartTimerCard = ({children}) => {
  return (
    <Card className="mb-3">
      <CardBody>
        {children}
      </CardBody>
    </Card>
  )
}

const CartTimer = (props) => {
  const {showCard, expiryTimestamp} = props
  const { t, i18n } = useTranslation()
  const {seconds, minutes, hours, days} = useTimer({expiryTimestamp, onExpire: () => console.warn('onExpire called') })

  if (hours > 0 || days > 0) {
    return ''
  }

  const TimerContent = () => {
    return (
      <div style={{textAlign: 'center'}}>
        <div>
          <div style={{fontSize: '1.5em'}}><i className="fa fa-clock mr-2"/>{i18n.t('cart_summary.expiration_time')}</div>
          <div style={{fontSize: '1.8em'}}>
            {minutes == 0 ? (
              <span>{seconds} s</span>
            ) : (
              <span>{minutes} min</span>
            )}
          </div>
        </div>
      </div>
    )
  }

  if (showCard == true) {
    return (
      <CartTimerCard>
        <TimerContent/>
      </CartTimerCard>
    )
  } else {
    return <TimerContent/>
  }
}

export default CartTimer
