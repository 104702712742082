import {assign} from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import moment from 'moment'
import {nextStep} from 'utils/step_utils'
import i18n from 'i18n/config'

const stepName  = 'dayStep'

const fetchMonth = (ctx) => {
  return new ApiFetcher({
    key: 'daily_availability',
    endpoint: ctx.endpoints.daily_availability,
    query: {date: ctx.year_month}
  }).call()
}

const fetchEvents = (ctx) => (
  new ApiFetcher({
    key: 'events',
    endpoint: ctx.endpoints.events,
    query: {date: ctx.day}
  }).call()
)

const setError = assign({error: i18n.t('validations:events_fetch_error')})
const setYearMonth = assign({year_month: (ctx, e) => e.year_month})
const setDaysAvailable = assign({days_available: (ctx, e) => e.data})
const setDay = assign({day: (ctx, e) => moment(e.day).format('YYYY-MM-DD')})
const setEvent = assign({event: (ctx, e) => e.data[0].events[0]})
const cleanError = assign({error: null})
const cleanDay = assign({day: null})
const cleanEvent = assign({event: null})
const setUnready = assign({ready: false})
const setReady = assign({ready: true})

const eventsAvailable = (ctx, e) => {
  if (e.data.length <= 0) {
    return false
  }

  if (e.data[0].events.length <= 0) {
    return false
  }

  return ctx.ready
}

const dayState = (config) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {'': {
          target: 'prepareFetchingCalendar'
        }},
      },
      prepareFetchingCalendar: {
        on: {'': {
          target: 'fetchingCalendar',
          actions: ['startSpinner', setUnready]
        }},
      },
      fetchingCalendar: {
        invoke: {
          src: fetchMonth,
          onDone: {
            target: 'waitingDayPick',
            actions: [setDaysAvailable, 'stopSpinner']
          },
          onError: {
            target: '#purchase.error',
            actions: [setError, 'stopSpinner']
          }
        },
      },
      waitingDayPick: {
      },
      prepareFetchingEvents: {
        on: {'': {
          target: 'fetchingEvents',
          actions: [cleanError, 'startSpinner']
        }},
      },
      fetchingEvents: {
        invoke: {
          src: fetchEvents,
          onDone: [
            {
              target: '#purchase.' + nextStep(config, stepName),
              actions: [setEvent, 'stopSpinner'],
              cond: eventsAvailable
            },
            {
              target: 'waitingDayPick',
              actions: ['stopSpinner']
            }
          ],
          onError: {
            target: '#purchase.error',
            actions: [setError, 'stopSpinner']
          }
        },
      },
    },
    on: {
      MONTH_CHANGE: {
        target: '.prepareFetchingCalendar',
        actions: [cleanDay, cleanEvent, setYearMonth]
      },
      DAY_SELECTION: {
        target: '.prepareFetchingEvents',
        actions: [setDay, setReady]
      }
    }
  }
}

export default dayState
