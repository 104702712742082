import React from 'react'
import styled from 'styled-components'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {Button} from 'reactstrap'
import {useToasts} from 'react-toast-notifications'
import {useTranslation} from 'react-i18next'

const CodeBlock = styled.textarea.attrs({
  rows: '3',
  className: 'rounded border-primary'
})`
  display: flex;
  margin: auto;
  width: 100%;
  max-width: 800px;
  background: #f7f7f7;
`

const CopyBlock = ({value, color}) => {
  const {addToast} = useToasts()
  const {i18n} = useTranslation()

  return (
    <React.Fragment>
      <CodeBlock value={value} readOnly />
      <CopyToClipboard text={value} onCopy={() => addToast(i18n.t('format:successfully_copied_to_clipboard'), {appearance: 'success', autoDismiss: true})}>
        <Button color={color || 'success'} className="mt-2">{i18n.t('format:copy')}</Button>
      </CopyToClipboard>
    </React.Fragment>
  )
}

export default CopyBlock
