const cardTypes = {
  "es": [
    {
        "code": "NIF",
        "description": "NIF Español"
    },
    {
        "code": "NIE",
        "description": "NIE Español"
    },
    {
        "code": "PASSPORT",
        "description": "Pasaporte"
    },
    {
        "code": "MINOR",
        "description": "Menor no documentado"
    },
    {
        "code": "CIF",
        "description": "CIF Español"
    },
    {
        "code": "TIN",
        "description": "Número identificación fiscal (Extranjero)"
    },
    {
        "code": "ID_CARD",
        "description": "Documento Identidad Europeo"
    }
  ],
  "en": [
    {
        "code": "NIF",
        "description": "Spanish NIF"
    },
    {
        "code": "NIE",
        "description": "Spanish NIE"
    },
    {
        "code": "PASSPORT",
        "description": "Passport"
    },
    {
        "code": "MINOR",
        "description": "Undocumented minor"
    },
    {
        "code": "CIF",
        "description": "Spanish CIF"
    },
    {
        "code": "TIN",
        "description": "Tax Identification Number"
    },
    {
        "code": "ID_CARD",
        "description": "European Id card"
    }
  ]
}

export default cardTypes
