const statusIcons = (value) => {
  switch (value) {
  case 'confirmed':
    return 'fa fa-check text-success'
  case 'human_processing_pending':
    return 'fa fa-hourglass-half text-warning'
  case 'payment_pending':
    return 'fas fa-money-bill-alt text-info'
  case 'cancellation_pending':
    return 'fa fa-exclamation-circle text-danger'
  case 'cancelled':
    return 'fa fa-ban text-danger'
  case 'failed':
    return 'fa fa-times text-danger'
  }
}

export {statusIcons}
