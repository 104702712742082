import React, {useState, useEffect} from 'react'
import Page from 'ui/page'
import Highcharts from 'highcharts'
// import highcharts3d from 'highcharts/highcharts-3d'
import HighchartsReact from 'highcharts-react-official'
import classnames from 'classnames'
import ApiFetcher from 'lib/api_fetcher'
import {Nav, NavItem, NavLink, Row, Col, Card, Button, CardHeader, CardFooter, CardBody, CardTitle, CardText, TabContent, TabPane } from 'reactstrap'
import Select from 'react-select'
import DateRangePicker from 'ui/date_range_picker'
import InfoBox from 'ui/info_box'
import moment from 'moment'
import {currency} from 'i18n/config'
import { useTranslation } from 'react-i18next'

const colorsPalette = ['#449999', '#BF3D36', '#D88044', '#C965AD', '#32687F',
  '#F0DD6C', '#85DD93', '#343a40', '#efebce', '#C628BB', '#543FEF']

const DailySalesChart = ({data, canSeeCommissions}) => {
  const { t, i18n } = useTranslation()

  const options = (data) => {
    let series = [
      {
        type: 'column',
        name: i18n.t('sales_dashboard.series.total_price'),
        data: data.map((i) => [i.date, i.total_price ? parseFloat(i.total_price) : 0]),
        tooltip: {
          pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y:.2f} €</b><br/>'
        }
      }
    ]

    if (canSeeCommissions) {
      series.push({
        type: 'column',
        name: i18n.t('sales_dashboard.series.total_commission'),
        data: data.map((i) => [i.date, i.total_commission ? parseFloat(i.total_commission) : 0]),
        tooltip: {
          pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y:.2f} €</b><br/>'
        }
      })
    }

    series.push({
      type: 'spline',
      name: i18n.t('sales_dashboard.series.operations'),
      yAxis: 1,
      data: data.map((i) => [i.date, i.operations ? i.operations : 0]),
    })

    return {
      colors: colorsPalette,
      chart: {
        zoomType: 'xy'
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'category',
        labels: {
          rotation: -45,
          style: {
            fontSize: '12px'
          }
        }
      },
      yAxis: [
        {
          min: 0,
          labels: {
            format: '{value} €',
          },
          title: {
            text: i18n.t('sales_dashboard.yaxis.total_price')
          }
        },
        {
          min: 0,
          allowDecimals: false,
          labels: {
            format: '{value}',
          },
          title: {
            text: i18n.t('sales_dashboard.yaxis.operations')
          },
          opposite: true
        }
      ],
      plotOptions: {
        column: {
          stacking: 'normal'
        }
      },
      series: series
    }
  }

  return (
    <Card>
      <CardHeader className="text-secondary">
        <h5>{i18n.t('sales_dashboard.daily_sales_chart.title')}</h5>
      </CardHeader>
      <CardBody>
        <HighchartsReact
          highcharts={Highcharts}
          options={options(data)}
        />
      </CardBody>
    </Card>
  )
}

const ProductSalesChart = ({data, canSeeCommissions}) => {
  const { t, i18n } = useTranslation()

  let series = [
    {
      type: 'column',
      name: i18n.t('sales_dashboard.series.total_price'),
      data: data.confirmed.map((i) => [i.title, i.total_price ? parseFloat(i.total_price) : 0]),
      tooltip: {
        pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y:.2f} €</b><br/>'
      }
    }
  ]

  if (canSeeCommissions) {
    series.push({
      type: 'column',
      name: i18n.t('sales_dashboard.series.total_commission'),
      data: data.confirmed.map((i) => [i.title, i.total_commission ? parseFloat(i.total_commission) : 0]),
      tooltip: {
        pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y:.2f} €</b><br/>'
      }
    })
  }

  series.push({
    type: 'spline',
    name: i18n.t('sales_dashboard.series.operations'),
    yAxis: 1,
    data: data.confirmed.map((i) => [i.title, i.operations])
  })

  const options = (data) => {
    return {
      colors: colorsPalette,
      chart: {
        zoomType: 'xy',
        height: '375',
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'category',
        labels: {
          rotation: -45,
          style: {
            fontSize: '12px'
          }
        }
      },
      yAxis: [
        {
          min: 0,
          labels: {
            format: '{value} €',
          },
          title: {
            text: i18n.t('sales_dashboard.yaxis.total_price')
          }
        },
        {
          min: 0,
          allowDecimals: false,
          labels: {
            format: '{value}',
          },
          title: {
            text: i18n.t('sales_dashboard.yaxis.operations')
          },
          opposite: true
        }
      ],
      plotOptions: {
        column: {
          stacking: 'normal'
        }
      },
      series: series
    }
  }

  return (
    <Card>
      <CardHeader className="text-secondary">
        <h5>{i18n.t('sales_dashboard.product_sales_chart.title')}</h5>
      </CardHeader>
      <CardBody>
        <HighchartsReact
          highcharts={Highcharts}
          options={options(data)}
        />
      </CardBody>
    </Card>
  )
}

const ClientSalesChart = ({data, canSeeCommissions}) => {
  const { t, i18n } = useTranslation()

  let series = [
    {
      type: 'column',
      name: i18n.t('sales_dashboard.series.total_price'),
      data: data.confirmed.map((i) => [i.name, i.total_price ? parseFloat(i.total_price) : 0]),
      tooltip: {
        pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y:.2f} €</b><br/>'
      }
    }
  ]

  if (canSeeCommissions) {
    series.push({
      type: 'column',
      name: i18n.t('sales_dashboard.series.total_commission'),
      data: data.confirmed.map((i) => [i.name, i.total_commission ? parseFloat(i.total_commission) : 0]),
      tooltip: {
        pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y:.2f} €</b><br/>'
      }
    })
  }

  series.push({
    type: 'spline',
    name: i18n.t('sales_dashboard.series.operations'),
    yAxis: 1,
    data: data.confirmed.map((i) => [i.name, i.operations])
  })

  const options = (data) => {
    return {
      colors: colorsPalette,
      chart: {
        zoomType: 'xy',
        height: '375',
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'category',
        labels: {
          rotation: -45,
          style: {
            fontSize: '12px'
          }
        }
      },
      yAxis: [
        {
          min: 0,
          labels: {
            format: '{value} €',
          },
          title: {
            text: i18n.t('sales_dashboard.yaxis.total_price')
          }
        },
        {
          min: 0,
          allowDecimals: false,
          labels: {
            format: '{value}',
          },
          title: {
            text: i18n.t('sales_dashboard.yaxis.operations')
          },
          opposite: true
        }
      ],
      plotOptions: {
        column: {
          stacking: 'normal'
        }
      },
      series: series
    }
  }

  return (
    <Card>
      <CardHeader className="text-secondary">
        <h5>{i18n.t('sales_dashboard.client_sales.title')}</h5>
      </CardHeader>
      <CardBody>
        <HighchartsReact
          highcharts={Highcharts}
          options={options(data)}
        />
      </CardBody>
    </Card>
  )
}

const ContactSalesChart = ({data, canSeeCommissions}) => {
  const { t, i18n } = useTranslation()

  const options = (serie) => ({
    colors: colorsPalette,
    chart: {
      type: 'pie',
      height: '310',
      options3d: {
        enabled: true,
        alpha: 45
      }
    },
    title: {
      text: ''
    },
    series: [serie]
  })

  const [activeTab, setActiveTab] = useState('1')

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab)
  }

  return (
    <Card>
      <CardHeader className="text-secondary">
        <h5>{i18n.t('sales_dashboard.contacts_sales_chart.title')}</h5>
      </CardHeader>
      <CardHeader>
        <Nav pills className="card-header-pills nav-fill">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              style={{cursor: 'pointer'}}
              onClick={() => { toggle('1') }}
            >
              {i18n.t('sales_dashboard.product_sales_chart.by_operations_tab')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              style={{cursor: 'pointer'}}
              onClick={() => { toggle('2') }}
            >
              {i18n.t('sales_dashboard.product_sales_chart.by_total_price_tab')}
            </NavLink>
          </NavItem>
          {canSeeCommissions &&
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '3' })}
                style={{cursor: 'pointer'}}
                onClick={() => { toggle('3') }}
              >
                {i18n.t('sales_dashboard.product_sales_chart.by_total_commission_tab')}
              </NavLink>
            </NavItem>
          }
        </Nav>
      </CardHeader>
      <CardBody>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <HighchartsReact
              highcharts={Highcharts}
              options={options(
                {
                  name: i18n.t('sales_dashboard.yaxis.operations'),
                  data: data.map((i) => [i[0] ? i[0] : 'Sin especificar', i[1].total_operations]),
                  size: '80%',
                  innerSize: '55%',
                }
              )}
            />
          </TabPane>
          <TabPane tabId="2">
            <HighchartsReact
              highcharts={Highcharts}
              options={options(
                {
                  name: i18n.t('sales_dashboard.yaxis.total_price'),
                  data: data.map((i) => [i[0] ? i[0] : 'Sin especificar', i[1].total_price]),
                  size: '80%',
                  innerSize: '55%',
                  tooltip: {
                    pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y:.2f} €</b><br/>'
                  }
                }
              )}
            />
          </TabPane>
          {canSeeCommissions &&
            <TabPane tabId="3">
              <HighchartsReact
                highcharts={Highcharts}
                options={options(
                  {
                    name: i18n.t('sales_dashboard.yaxis.total_commission'),
                    data: data.map((i) => [i[0] ? i[0] : i18n.t('sales_dashboard.series.unspecified'), i[1].total_commission]),
                    size: '80%',
                    innerSize: '55%',
                    tooltip: {
                      pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y:.2f} €</b><br/>'
                    }
                  }
                )}
              />
            </TabPane>
          }
        </TabContent>
      </CardBody>
    </Card>
  )
}

const SalesDashboard = (props) => {
  const {user} = props
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const defaultFrom = moment().startOf('month').toDate()
  const defaultTo = moment().endOf('month').toDate()
  const [from, setFrom] = useState(defaultFrom)
  const [to, setTo] = useState(defaultTo)
  const { t, i18n } = useTranslation()

  const canSeeCommissions = user.user_data.user.can_see_commissions
  const canSeeUsers = user.user_data.user.can_see_users
  const canSeeOrders = user.user_data.user.can_see_orders

  const fetchData = (from, to) => {
    PubSub.publish('loading-page')
    setLoading(true)

    new ApiFetcher({
      key: 'sales_dashboard_grid',
      method: 'GET',
      endpoint: props.urls.grid,
      query: {
        from: from,
        to: to
      }
    }).call()
      .then((response) => {
        PubSub.publish('loaded-page')
        setData(response.data)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchData(defaultFrom, defaultTo)
  }, [])

  if (!data) {
    return ''
  }

  return (
    <Page {...props}>
      <Row>
        <Col md="5">
          <Row>
            <Col>
              <Card>
                <CardHeader className="">
                  <h5>{i18n.t('sales_dashboard.date_range_panel_title')}</h5>
                </CardHeader>
                <CardBody>
                  <DateRangePicker from={from} setFrom={setFrom} to={to} setTo={setTo} onChange={fetchData}/>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <InfoBox
                color={'bg-info'}
                label={i18n.t('sales_dashboard.info_box_total_price_label')}
                value={currency(data.summary_stats.total_sales)}
                icon={<i className="fa fa-shopping-bag"/>}
              />
            </Col>
          </Row>

          {canSeeCommissions &&
            <Row className="mt-3">
              <Col>
                <InfoBox
                  color={'bg-danger'}
                  label={i18n.t('sales_dashboard.info_box_total_commission_label')}
                  value={currency(data.summary_stats.total_earnings)}
                  icon={<i className="fas fa-euro-sign"/>}
                />
              </Col>
            </Row>
          }

          <Row className="mt-3">
            <Col>
              <InfoBox
                color={'bg-success'}
                label={i18n.t('sales_dashboard.info_box_total_cash_label')}
                value={currency(data.summary_stats.total_cash)}
                icon={<i className="fa fa-money-bill-alt"/>}
              />
            </Col>

            <Col>
              <InfoBox
                color={'bg-warning'}
                label={i18n.t('sales_dashboard.info_box_total_card_label')}
                value={currency(data.summary_stats.total_card)}
                icon={<i className="fa fa-credit-card"/>}
              />
            </Col>
          </Row>
        </Col>
        <Col md="7">
          <DailySalesChart data={data.daily_sales} canSeeCommissions={canSeeCommissions}/>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          {canSeeOrders == 'group_orders' ? (
            <ClientSalesChart data={data.client_sales} canSeeCommissions={canSeeCommissions}/>
          ) : (
            <ContactSalesChart data={data.contact_sales} canSeeCommissions={canSeeCommissions}/>
          )}
        </Col>
        <Col>
          <ProductSalesChart data={data.product_sales} canSeeCommissions={canSeeCommissions}/>
        </Col>
      </Row>
    </Page>
  )
}

export default SalesDashboard
