import React from 'react'

const WhatWeOffer = (props) => {
  return (
    <div>
      <p>A new way to <strong>generate revenue</strong> for the hotel and <strong> give more service </strong> to your guests.</p>
      <ul>
        <li><p><strong>Ease of use</strong>. <strong>No installation required</strong> since it is a web service, and <strong>has no costs</strong> for the Hotel.</p></li>
        <li><p>Agility, transparency and legality in the liquidation of <strong> commissions</strong>.</p></li>
        <li><p>Service available 365 days a year, 24 hours a day, with <strong> immediate confirmation of availability</strong>.</p></li>
      </ul>
      <p>Our system allows <strong> integration </strong> with the hotel website and create product packs for hotel nights or hotel services with the products in our catalog.</p>
      <ul>
        <li>
          <p>All products and services that are marketed pass quality control and evaluation by our team of specialists in leisure activities, events and catering.</p>
        </li>
      </ul>
    </div>
  )
}

export default WhatWeOffer
