import React, {useState, useMemo} from 'react'
import Modal from 'ui/modal'
import {useTranslation} from 'react-i18next'
import {ModulePanel, ModuleHeader, ModuleBody} from 'containers/purchase/modules/module_panel'
import {Row, Col, ButtonGroup, Button, Form, FormGroup} from 'reactstrap'
import ApiFetcher from 'lib/api_fetcher'
import DataList from 'ui/data_list'
import time from 'i18n/time'
import SummaryModule from 'containers/purchase/modules/summary_module/summary_module'
import {Formik} from 'formik'
import TextInput from 'ui/forms/text_input'
import Textarea from 'ui/forms/textarea'
import cn from 'classnames'

const ReservationData = ({order_code, order_date, attended_by, items, payment_method}) => {
  const { t, i18n } = useTranslation()
  const {order_reference, status_label} = items.find((item) => item.order_code == order_code)

  return (
    <ModulePanel>
      <ModuleHeader status="active" showIcon={false}>
        <span>
          <i className="fa fa-bars mr-2" aria-hidden="true"></i>
          {i18n.t('summary.order_data')}
        </span>
      </ModuleHeader>
      <ModuleBody status="active">
        <Row className={'p-2'}>
          <DataList label={i18n.t('summary.order_date')} value={time(order_date).format('lll')}/>
          <DataList label={i18n.t('summary.order_code')} value={order_code}/>
          <DataList label={i18n.t('summary.status')} value={status_label}/>
          <DataList label={i18n.t('summary.order_reference')} value={order_reference}/>
          <DataList label={i18n.t('summary.payment_method')} value={payment_method && payment_method.title}/>
          <DataList label={i18n.t('summary.attended_by')} value={attended_by}/>
        </Row>
      </ModuleBody>
    </ModulePanel>
  )
}

const BuyerData = ({name, email, telephone, room}) => {
  const { t, i18n } = useTranslation()

  return (
    <ModulePanel>
      <ModuleHeader status="active" showIcon={false}>
        <span>
          <i className="fa fa-user mr-2"></i>
          {i18n.t('summary.contact_data')}
        </span>
      </ModuleHeader>
      <ModuleBody status="active">
        <Row className="p-2">
          <DataList label={i18n.t('summary.name')} value={name}/>
          <DataList label={i18n.t('summary.email')} value={email}/>
          <DataList label={i18n.t('summary.telephone')} value={telephone}/>
          <DataList label={i18n.t('summary.room')} value={room}/>
        </Row>
      </ModuleBody>
    </ModulePanel>
  )
}

const OrderModal = (props) => {
  const [modal, setModal] = useState()
  const toggle = () => setModal(!modal)
  const [cancellation, setCancellation] = useState()
  const [sent, setSent] = useState(false)
  const { t, i18n } = useTranslation()

  const {order, urls} = props

  const cartFetcher = () => (
    new ApiFetcher({
      key: 'cart',
      method: 'GET',
      endpoint: urls.reservation.replace(/ *\:[^)]*\: */g, order.uuid || order.cart_uuid)
    }).call()
  )

  const [cart, setCart] = useState()
  const reservation = cart && cart.items.find((item) => item.order_code == order.code)

  React.useEffect(() => {
    if (props.modal || modal) {
      PubSub.publish('loading-page')

      cartFetcher().then((data) => {
        setCart(data.reservation)
        PubSub.publish('loaded-page')
      })
    }
  }, [props.modal || modal, sent])

  const OrderView = () => {
    return (
      <Row className="mx-0">
        <Col className="pl-0">
          <ReservationData order_code={order.code} {...cart}/>
          <BuyerData {...cart.main_customer}/>
        </Col>
        <Col className="pr-0">
          <SummaryModule reservation={reservation}/>

          <ButtonGroup vertical className="btn-block">
            {reservation.status == 'confirmed' &&
              <Button color="success" href={urls.voucher.replace(/ *\:[^)]*\: */g, order.uuid || order.cart_uuid)} target="blank"><i className="fa fa-download"/> {i18n.t('order_modal.see_vouchers_button')}</Button>
            }


            {['confirmed', 'human_processing_pending'].includes(reservation.status) &&
              <Button color="danger" onClick={() => setCancellation(true)}><i className="fa fa-ban"/> {i18n.t('order_modal.request_cancellation_button')}</Button>
            }
          </ButtonGroup>
        </Col>
      </Row>
    )
  }

  const CancellationView = () => {
    const {i18n} = useTranslation()

    const onSubmit = (values, {setSubmitting, setErrors}) => {
      new ApiFetcher({
        key: 'contact',
        endpoint: urls.request_cancellation.replace(/ *\:[^)]*\: */g, order.id),
        method: 'DELETE',
        body: JSON.stringify(values)
      }).call()
        .then((res) => {
          if (res.success == true) {
            setSent(true)
            props.doFetch()
          } else {
            setErrors(res.errors)
          }
        })
        .finally(() => setSubmitting(false))
    }

    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={useMemo(() => ({
          name: '',
          email: '',
          message: ''
        }), [])
        }
      >
        {({
          submitForm,
          isValid,
          isSubmitting
        }) => (
          <React.Fragment>
            {sent ? (
              <h4 className="text-center"><i className="fa fa-check text-success mr-2"/>{i18n.t('cancellation_view.email_sent_message')}</h4>
            ) : (
              <Form>
                <p>{i18n.t('cancellation_view.instructions')}:</p>
                <FormGroup>
                  <Row>
                    <Col>
                      <TextInput label={i18n.t('cancellation_view.name')} name="name" required/>
                    </Col>
                    <Col>
                      <TextInput label={i18n.t('cancellation_view.email')} name="email" type="email" required/>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col>
                      <Textarea label={i18n.t('cancellation_view.message')} name="message" required/>
                    </Col>
                  </Row>
                </FormGroup>

                <Button
                  size={'lg'}
                  color='danger'
                  className={cn([
                    'text-white w-100',
                    isSubmitting || !isValid ? 'disabled' : ''
                  ])}
                  onClick={() => submitForm()}
                >
                  {isSubmitting ? (
                    i18n.t('cancellation_view.sending')
                  ) : (
                    i18n.t('cancellation_view.sent')
                  )}
                </Button>
                <hr/>
                <p>{i18n.t('header_contact_form.other_channels_to_contact_us')}:</p>
                <p><strong><i className="fa fa-phone mr-2" aria-hidden="true"></i>{i18n.t('header_contact_form.phone')}: </strong><a href="tel:+34628531320">(+34) 628 53 13 20</a></p>
                <p><strong><i className="fa fa-envelope mr-2" aria-hidden="true"></i>{i18n.t('header_contact_form.email')}: </strong><a href="mailto:soporte@ociobusiness.es">soporte@ociobusiness.es</a></p>
              </Form>
            )}
          </React.Fragment>
        )}
      </Formik>
    )
  }

  return (
    <React.Fragment>
      {props.Button ? (
        props.Button()
      ) : (
        <a onClick={() => (props.toggle && props.toggle()) || toggle()} style={{cursor: 'pointer'}}><i className="fa fa-search-plus text-white"/></a>
      )}
      <Modal
        isOpen={props.modal || modal}
        toggle={props.toggle || toggle}
        header={() => (
          <span>
            {cancellation &&
              <i className="fa fa-caret-square-o-left mr-2" style={{cursor: 'pointer'}} onClick={() => setCancellation(false)}/>
            }
            {order.product.title} ({order.code})
          </span>
        )}
        width={'1200px'}
      >
        {cart &&
          <React.Fragment>
            {cancellation ? (
              <CancellationView/>
            ) : (
              <OrderView/>
            )}
          </React.Fragment>
        }
      </Modal>
    </React.Fragment>
  )
}

export default OrderModal
