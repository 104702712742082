import React, {useState} from 'react'
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import { useField } from 'formik'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import es from 'react-phone-input-2/lang/es.json'
import cn from 'classnames'

const PhoneInputField = ({label, hideError, uncontrolled = false, setFieldValue, prefixName, ...props }) => {
  const name = (props.field && props.field.name) || props.name
  const [field, meta, helpers] = useField(name)

  return (
    <FormGroup>
      {label &&
        <Label className='control-label' htmlFor={props.id || props.name}>
          {label}
          {props.required &&
            <span className="text-danger ml-1">*</span>
          }
        </Label>
      }

      <PhoneInput
        country={'es'}
        value={field.value}
        onChange={(phone, country, e, formattedValue) => {
          helpers.setValue(phone)

          if (prefixName) {
            setFieldValue(prefixName, country.dialCode)
          }
        }}
        inputStyle={{width: '100%'}}
        inputProps={{
          required: true
        }}
        enableSearch
        localization={es}
      />

      <Input
        className={
          cn([
            'd-none',
            meta.touched && meta.error ? 'is-invalid' : '',
            meta.touched && !meta.error ? 'is-valid' : ''
          ])
        }
      />

      {!hideError && meta.touched && meta.error ? (
        <FormFeedback className={'help-block'}>{meta.error}</FormFeedback>
      ) : null}
    </FormGroup>
  )
}

export default PhoneInputField
