import EventModule from 'containers/purchase/modules/event_module/event_module'
import OneboxIframeModule from 'containers/purchase/modules/onebox_iframe_module/onebox_iframe_module'
import eventState from 'containers/purchase/modules/event_module/event_state'
import iframeState from 'containers/purchase/modules/onebox_iframe_module/onebox_iframe_state'
import ContactModule from 'containers/purchase/modules/contact_module/contact_module'
import contactState from 'containers/purchase/modules/contact_module/contact_state'
import VisitorsModule from 'containers/purchase/modules/visitors_module/visitors_module'
import visitorsState from 'containers/purchase/modules/visitors_module/visitors_state'

const reservationMapper = (ctx) => {
  return {
    event_id: ctx.event.id,
    ticket_types: ctx.tickets,
    customers: ctx.visitors,
    params: {
      reservation_code: ctx.onebox_cart.token,
      contact_values: ctx.contactValues
    }
  }
}

const config = (params) => {
  const {external_api_config} = params
  const visitorsRequired = external_api_config && external_api_config.visitorsRequired
  const sessionId = external_api_config && external_api_config.session_id

  let steps = {}

  if (!sessionId) {
    steps['eventStep'] = {
      component: EventModule,
      autoNext: true,
      states: eventState,
      context: {
        year_month: params.initialYearMonth,
        event_groups: [],
        event: null
      }
    }
  }

  steps['iframeStep'] = {
    component: OneboxIframeModule,
    autoNext: false,
    states: iframeState,
    context: {
      // event: {
      //   id: sessionId,
      //   params: {
      //     token: '689b8fb1fd8628a7c942b26df617466c',
      //   }
      // },
      sessionId: sessionId,
      iframeLink: null
    }
  }

  if (visitorsRequired) {
    steps['visitorsStep'] = {
      component: VisitorsModule,
      autoNext: false,
      states: visitorsState,
      context: {
        visitors: []
      }
    }
  }

  steps['contactRequired'] = {
    component: ContactModule,
    autoNext: false,
    states: contactState,
    context: {
      contactValues: []
    }
  }

  return {
    reservationMapper: reservationMapper,
    steps: steps,
    params: params
  }
}

export default config
