import React, {useState} from 'react'
import EventItem from 'containers/purchase/modules/event_module/event_item'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import moment from 'moment'

const EventGroups = ({data, eventsActive, eventRenderMode, onEventSelected}) => {
  const [activeTab, setActiveTab] = useState(data[0].id)

  const isActive = (event, eventsActive) => {
    if (!eventsActive) {
      return false
    }

    return Object.values(eventsActive).findIndex((eventActive) => (eventActive && eventActive.id) == event.id) != -1
  }

  const toggle = (tab) => setActiveTab(tab)

  const navItemMargin = (index, length) => {
    if (length == 1 || index == length - 1) {
      return "m-0"
    } else if (index == 0) {
      return "mr-md-2"
    } else {
      return "mx-md-2"
    }
  }

  const Tabs = () => {
    return data.map((event_group, i) => {
      return (
        <NavItem
          key={event_group.id}
          onClick={() => { toggle(event_group.id) }}
          style={{
            cursor: 'pointer',
            borderTop: '1px solid',
            borderLeft: '1px solid',
            borderRight: '1px solid',
            borderBottom: '1px solid',
            borderColor: activeTab === event_group.id ? 'var(--dark)' : 'var(--dark)'
          }}
          className={classnames([
            navItemMargin(i, data.length),
            activeTab === event_group.id ? ['active', 'bg-lighter'] : 'bg-lighter',
            'd-flex',
            'justify-content-center',
            'rounded',
            'text-uppercase',
          ])}
        >
          <div
            href={'#'}
            className={classnames([
              'h-100',
              'w-100',
              'd-flex',
              'align-self-center',
              'justify-content-between',
              activeTab === event_group.id ? 'text-primary' : 'text-dark',
              'font-weight-bold'
            ])}

          >
            <div
              className={classnames([
                activeTab === event_group.id ? 'bg-primary' : 'bg-dark',
                "d-flex"
              ])}
            >
              <i
                className={classnames([
                  activeTab === event_group.id ? 'fa-dot-circle text-white' : 'fa-circle text-white',
                  "p-2 align-self-center fa"
                ])}
              />
            </div>
            <div className="w-100 p-2 d-flex justify-content-center align-self-center">
              {event_group.name}
            </div>
          </div>
        </NavItem>
      )
    })
  }

  const Contents = () => {
    return data.map((event_group) => {
      return (
        <TabPane key={event_group.id} tabId={event_group.id} className="px-2">
          <div className={eventRenderMode == 'time' ? 'row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5' : ''}>
            <Events events={event_group.events}/>
          </div>
        </TabPane>
      )
    })
  }

  const Events = ({events}) => {
    return events.map((event) =>
      <EventItem
        id={event.id}
        key={event.id}
        eventData={event}
        eventRenderMode={eventRenderMode}
        showAvailability={event.show_availability}
        hour={event.time}
        active={isActive(event, eventsActive)}
        onEventSelected={onEventSelected}
        availability={event.availability}
      />
    )
  }

  return (
    <React.Fragment>
      <Nav fill pills justified><Tabs /></Nav>
      <TabContent activeTab={activeTab} className={'my-1'}>
        <Contents />
      </TabContent>
    </React.Fragment>
  )
}

export default EventGroups
