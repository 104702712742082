import React from 'react'
import styled from 'styled-components'
import cn from 'classnames'


const FilterBox = styled.div`
  user-select: none;

  .card-header {
    cursor: pointer;
  }

  .card-body {
    ${props => !props.isOpen &&`
      display: none;
    `}
  }
`

const FilterItem = (props) => {
  const {wrapperClass, headerClass, title, bodyClass, children, isOpen, setIsOpen} = props


  return (
    <FilterBox className={'card ' + wrapperClass} isOpen={isOpen}>
      <div className={'d-flex card-header ' + headerClass} onClick={() => setIsOpen(!isOpen)}>
        <span className="mr-auto">{title}</span>
        <i className={cn(
          isOpen ? 'fa-caret-up' : 'fa-caret-down',
          'fa align-self-center'
        )}></i>
      </div>
      <div className={'card-body ' + bodyClass}>
        {children}
      </div>
    </FilterBox>
  )
}

FilterItem.defaultProps = {
  wrapperClass: 'mb-3 border-1 shadow-sm',
  headerClass: 'py-2 bg-gradient-primary-h text-white',
  bodyClass: 'py-4'
}

export default FilterItem
