import React from 'react'
import noResults from 'img/no-results'
import Page from 'ui/page'
import { useTranslation } from 'react-i18next'

const UnknowDestination = (props) => {
  const {client_params} = props
  const {i18n} = useTranslation()

  return (
    <Page {...props}>
      <div className="alert alert-warning d-flex justify-content-left" role="alert">
        <div>
          <img width="110" height="110" className="mr-4" src={noResults}></img>
        </div>
        <div>
          <h4 className="alert-heading">{i18n.t('unknow_destination.title')}</h4>
          <hr/>
          <p className="m-0">{i18n.t('unknow_destination.content', {email: client_params.support_email})}</p>
        </div>
      </div>
    </Page>
  )
}

export default UnknowDestination
