import React from 'react'
import currency from 'i18n/currency'
import {Button, ListGroup, ListGroupItem} from 'reactstrap'
import time from 'i18n/time'
import {useTimer} from 'react-timer-hook'
import {useTranslation} from 'react-i18next'
import {groupBy} from 'lodash'
import {isBrowser, isMobile} from 'react-device-detect'
import ApiFetcher from 'lib/api_fetcher'
import currencyDefinition from '../../i18n/currency'

const CartSummary = (props) => {
  const {cart, params, setOpen} = props
  const urls = params && params.urls
  const {i18n} = useTranslation()

  const fetchDiscardCart = (discardResource) => {
    PubSub.publish('loading-page')

    new ApiFetcher({
      key: 'cart',
      endpoint: discardResource.url,
      method: discardResource.method
    }).call()
      .then((res) => {
        setOpen(false)
        PubSub.publish('loaded-page')
        location.reload()
      })
  }

  const fetchDiscardReservation = (reservation_id, discardReservationResource) => {
    PubSub.publish('loading-page')

    new ApiFetcher({
      key: 'cart',
      endpoint: discardReservationResource.url.replace(/ *\:[^)]*\: */g, reservation_id),
      method: discardReservationResource.method
    }).call()
      .then((res) => {
        setOpen(false)
        PubSub.publish('loaded-page')
        location.reload()
      })
  }

  const CartTimer = ({expiryTimestamp}) => {
    const {minutes, hours, days} = useTimer({expiryTimestamp})

    if (hours > 0 || days > 0) {
      return ''
    }

    return (
      <div style={{textAlign: 'center'}}>
        <div>
          <div style={{fontSize: '1.5em'}}>{i18n.t('cart_summary.expiration_time')}</div>
          <div style={{fontSize: '1.8em'}}>
            <span>{minutes}</span>m
          </div>
        </div>
      </div>
    )
  }

  const CartWithProducts = () => {
    const expiryDate = time(cart.expires_at).toDate()
    // const expiryDate = Date.now()

    return (
      <React.Fragment>
        <ListGroup>
          {cart.items.map((props) => {
            const {id, product, event, tickets} = props
            const {start_date, show_date, show_time} = event

            const ticketGroups = Object.values(groupBy(tickets, ({ticket_type_code}) => ticket_type_code))
              .map((group) => {
                return {
                  ...group[0],
                  title: `${group.length}x ${group[0].title}`,
                  total_price: group.reduce((acc, ticket) => acc + parseFloat(ticket.total_price), 0),
                  currency: group[0].currency
                }
              })

            return (
              <div key={id} className="mb-3">
                <div className="d-flex justify-content-between bg-light border-top border-right border-left border-bottom-0 rounded-top rounded-right p-3">
                  <div>
                    <strong>{product.title}</strong>
                    {show_date == true &&
                      <div>
                        <i>{time(start_date).format(show_time == true ? 'LLL' : 'LL')}</i>
                      </div>
                    }
                  </div>
                  <div>
                    <i className="fa fa-trash" style={{cursor: 'pointer'}} onClick={() => {
                      if (cart.items.length > 1) {
                        fetchDiscardReservation(id, params.urls.discard_reservation)
                      } else {
                        fetchDiscardCart(params.urls.discard)
                      }
                    }}/>
                  </div>
                </div>

                <div>
                  {ticketGroups.map((props) => {
                    const {id, title, total_price} = props

                    return (
                      <ListGroupItem key={id} className="d-flex justify-content-between">
                        <div>
                          {title}
                        </div>
                        <div>
                          {currency(total_price, props.currency).format()}
                        </div>
                      </ListGroupItem>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </ListGroup>
        <div className="d-flex justify-content-between font-weight-bold">
          <div>{i18n.t('cart_summary.total_price')}</div>
          <div>{cart.final_price_label}</div>
        </div>
        <hr/>
        <CartTimer expiryTimestamp={expiryDate} />
        <div
          style={{cursor: 'pointer'}}
          className="text-center"
          onClick={() => {
            if (confirm('¿Está seguro que quiere cancelar la reserva?') == true) {
              fetchDiscardCart(params.urls.discard)
            }
          }}
        >
          <i className="fa fa-trash mr-2"/>{i18n.t('cart_summary.discard_cart')}
        </div>
        <hr/>
        <Button
          href={urls && urls.buyer_step}
          className="btn btn-primary btn-block bg-primary mt-3 text-uppercase font-weight-bold"
        >
          {i18n.t('cart_summary.link_to_reservation')}
        </Button>
      </React.Fragment>
    )
  }

  const EmptyCart = () => {
    return (
      <div className="text-center">
        <hr/>
        <i>{i18n.t('cart_summary.without_reservations_alert')}</i>
      </div>
    )
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: isBrowser ? '15px' : '55px',
        right: '15px',
        background: 'white',
        zIndex: '99999',
        width: '315px',
      }}
      className="p-3 shadow rounded"
    >
      <h4 className="mb-3 d-flex justify-content-between">
        <div>{i18n.t('cart_summary.cart')}</div>
        <div style={{cursor: 'pointer'}} onClick={() => setOpen(false)}><i className="fa fa-times-circle"/></div>
      </h4>
      {cart ? (
        <CartWithProducts/>
      ) : (
        <EmptyCart/>
      )}
    </div>
  )
}

export default CartSummary
