import React from 'react'

const OurProducts = (props) => {
  return (
    <div>
      <p>Nuestro canal de ventas on-line nos permite <strong>agilizar la gestión</strong> de venta y la <strong> personalización del catálogo para cada hotel</strong></p>

      <p>Los productos y servicios que comercializamos son, entre otros:</p>

      <p className="text-center">
        Restaurantes ● Espectáculos ● Museos ● Dinner Shows ● Discotecas ● Sala de fiestas ● Teatros ● Bares musicales ● Parques de atracciones ● Parques temáticos ● Atracciones turísticas ● Conciertos ● Actividades de ocio
      </p>

      <p>Disponemos de algunos productos que comercializamos en <strong>exclusiva</strong>.</p>
    </div>
  )
}

export default OurProducts
