import React from 'react'
import ApiFetcher from 'lib/api_fetcher'
import paymentRedirect from 'lib/payment_redirect'
import {Button} from 'reactstrap'
import moment from 'moment'
import crono from 'img/crono'
import i18n from 'i18n/config'
import {useTranslation} from 'react-i18next'

const offsetTime = 15

const PaymentRedirectCallout = ({cart, endpoint}) => {
  const { t, i18n } = useTranslation()

  const paymentRequestFetcher = () => (
    new ApiFetcher({
      key: 'payment_request',
      method: 'PUT',
      endpoint: endpoint
    }).call()
  )

  if (cart && cart.status == 'payment_pending') {
    return (
      <div className="alert alert-info text-center" role="alert">
        <h3 className="alert-heading"><i className="fa fa-check text-success mr-2"/>
          {i18n.t('payment_redirect_callout.wait_few_seconds_title')}
        </h3>
        <p>{i18n.t('payment_redirect_callout.wait_few_seconds_description')}</p>
        <p><img width="150" height="150" src={crono}/></p>
      </div>
    )
  }

  return ''
}

export default PaymentRedirectCallout
