import React, {useState} from 'react'
import {usePartial} from 'i18n/config'
import Modal from 'ui/modal'
import { Trans } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import i18n from 'i18n/config'
import Checkbox from 'ui/forms/checkbox'

const ConsentCheckbox = (props) => {
  const {client_params} = props
  const { t, i18n } = useTranslation()

  const ReturnPolicyLink = ({children}) => {
    const ReturnPolicy = usePartial('auth/return_policy', {supportTelephone: client_params.support_telephone_label, site_name: client_params.site_name})
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)

    return (
      <React.Fragment>
        <a className="text-primary" style={{cursor: 'pointer'}} onClick={() => toggle()}>{children}</a>
        <Modal
          isOpen={modal}
          toggle={toggle}
          header={i18n.t('sign_in.return_policy')}
          width={'90%'}
        >
          <ReturnPolicy supportTelephone={client_params.support_telephone_label}/>
        </Modal>
      </React.Fragment>
    )
  }

  const LegalWarningLink = ({children}) => {
    const LegalWarning = usePartial('auth/legal_warning', {supportTelephone: client_params.support_telephone_label, site_name: client_params.site_name})
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)

    return (
      <React.Fragment>
        <a className="text-primary" style={{cursor: 'pointer'}} onClick={() => toggle()}>{children}</a>
        <Modal
          isOpen={modal}
          toggle={toggle}
          header={i18n.t('sign_in.return_policy')}
          width={'90%'}
        >
          <LegalWarning supportTelephone={client_params.support_telephone_labe}/>
        </Modal>
      </React.Fragment>
    )
  }

  return (
    <Checkbox label={
      <Trans i18nKey="buyer_form.consent" t={t} components={[
        <ReturnPolicyLink/>,
        <LegalWarningLink/>
      ]}/>
    } name="consent"/>
  )
}

export default ConsentCheckbox
