import React, {useEffect} from 'react'
import { Formik, Field } from 'formik'
import { Row, Col, Form, FormGroup, Alert, Card, CardHeader, CardBody, ButtonGroup, Button } from 'reactstrap'
import { ReactstrapInput } from 'reactstrap-formik'
import QtyInput from 'ui/forms/qty_input'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import {get} from 'lodash'
import Select from 'ui/forms/select'
import TextInput from 'ui/forms/text_input'
import CheckButtons from 'ui/forms/check_buttons'
import moment from 'moment'
import {uniq} from 'lodash'
import {currentStep} from 'utils/step_utils'

const TransferServiceForm = (props) => {
  const { t, i18n } = useTranslation()

  const {step, config, machine} = props
  const [current, send] = machine
  const {resources} = current.context

  const incrementQty = (currentQty, maxQty) => {
    if (currentQty >= maxQty) {
      return maxQty
    } else {
      return currentQty + 1
    }
  }
  const decrementQty = (currentQty, minQty) => {
    if (currentQty <= minQty) {
      return minQty
    } else {
      return currentQty - 1
    }
  }

  const categories = [
    {
      value: 'sedan',
      label: i18n.t('transfer_service_form.sedan')
    },
    {
      value: 'suv',
      label: i18n.t('transfer_service_form.suv')
    },
    {
      value: 'minivan',
      label: i18n.t('transfer_service_form.minivan')
    },
    {
      value: 'minibus',
      label: i18n.t('transfer_service_form.minibus')
    },
    {
      value: 'bus',
      label: i18n.t('transfer_service_form.bus')
    },
    {
      value: 'other',
      label: i18n.t('transfer_service_form.shared')
    }
  ]

  const classes = [
    {
      value: 'economy',
      label: i18n.t('transfer_service_form.economy')
    },
    {
      value: 'standard',
      label: i18n.t('transfer_service_form.standard')
    },
    {
      value: 'premium',
      label: i18n.t('transfer_service_form.premium')
    },
    {
      value: 'business',
      label: i18n.t('transfer_service_form.business')
    }
  ]

  return (
    <Formik
      initialValues={{
        adult: 0,
        child: 0,
        infant: 0,
        categories: ['sedan', 'suv', 'minivan'],
        classes: ['economy', 'standard', 'premium', 'business']
      }}
      validationSchema={
        Yup.object().shape({
          adult: Yup.number().min(1),
          child: Yup.number().min(0),
          infant: Yup.number().min(0),
          categories: Yup.array().min(1, i18n.t('validations:one_must_be_selected')),
          classes: Yup.array().min(1, i18n.t('validations:one_must_be_selected'))
        })
      }
      onSubmit={() => {}}
    >
      {(form) => {
        const {values, errors, touched, setFieldValue} = form

        useEffect(() => {
          props.setForm(form)
        }, [values, errors, touched])

        return (
          <Form>
            <Row className="justify-content-md-center">
              <Col xs="6">
                <FormGroup>
                  <Row>
                    <Col>
                      <QtyInput
                        label={i18n.t('transfer_service_form.adults')}
                        name="people_detail.adult"
                        value={values.adult}
                        onIncrement={() => setFieldValue('adult', incrementQty(values.adult, 20))}
                        onDecrement={() => setFieldValue('adult', decrementQty(values.adult, 1))}
                      />

                    </Col>
                    <Col>
                      <QtyInput
                        label={i18n.t('transfer_service_form.children')}
                        value={values.child}
                        onIncrement={() => setFieldValue('child', incrementQty(values.child, 20))}
                        onDecrement={() => setFieldValue('child', decrementQty(values.child, 0))}
                      />
                    </Col>
                    <Col>
                      <QtyInput
                        label={i18n.t('transfer_service_form.infants')}
                        value={values.infant}
                        onIncrement={() => setFieldValue('infant', incrementQty(values.infant, 20))}
                        onDecrement={() => setFieldValue('infant', decrementQty(values.infant, 0))}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <div className="alert alert-primary">
                  {i18n.t('transfer_service_form.service_categories_info')}
                </div>
                <FormGroup>
                  <CheckButtons label={i18n.t('transfer_service_form.car_types')} name="categories" values={categories} />
                </FormGroup>
                <FormGroup>
                  <CheckButtons label={i18n.t('transfer_service_form.car_category_types')} name="classes" values={classes} />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}

export default TransferServiceForm
