import React from 'react'
import styled, {keyframes} from 'styled-components'

const spinnerAnimation = keyframes`
   from {
     transform: rotate(0deg);
   }
   to {
     transform: rotate(359deg);
   }
`

const ComponentSpinner = styled.div`
  position: absolute;
  z-index: 99999;
  left: 45%;
  top: 45%;
  height:60px;
  width:60px;
  margin:0px auto;
  -webkit-animation: ${spinnerAnimation} .6s infinite linear;
  -moz-animation: ${spinnerAnimation} .6s infinite linear;
  -o-animation: ${spinnerAnimation} .6s infinite linear;
  animation: ${spinnerAnimation} .6s infinite linear;
  border-left:6px solid rgba(0,174,239,.15);
  border-right:6px solid rgba(0,174,239,.15);
  border-bottom:6px solid rgba(0,174,239,.15);
  border-top:6px solid rgba(0,174,239,.8);
  border-radius:100%;
`

export default ComponentSpinner
