import React from 'react'
import Page from 'ui/page'
import {Button} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import notFound from 'img/not-found'

const NotFound = (props) => {
  const {i18n} = useTranslation()

  return (
    <Page {...props}>
      <div className="alert alert-info mb-4 d-flex justify-content-left" role="alert">
        <div className="w-100">
          <div className="alert-heading d-flex justify-content-between m-0 p-0">
            <div className="align-self-center">
              <h4 className="alert-heading">{i18n.t('not_found.title')}</h4>
              <hr/>
              <p className="m-0">{i18n.t('not_found.description')}</p>
            </div>

            <div>
              <p className="text-right m-0">
                <Button color="success" href="/">
                  {i18n.t('not_found.button')}
                </Button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-3">
        <img width="700" src={notFound}/>
      </div>
    </Page>
  )
}

export default NotFound
