import { assign } from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import {nextStep} from 'utils/step_utils'
import {EUR} from 'utils/currency'
import i18n from 'i18n/config'

const setError = assign({error: i18n.t('validations:fill_all_fields')})
const cleanError = assign({error: null})
const setValidationError = assign({error: i18n.t('validations:pickups_required')})
const updateForm = assign({route_form: (ctx, e) => e.form})
// const dropoffUpdateForm = assign({dropoff_form: (ctx, e) => e.form})

const setEvent = assign({event: (ctx, e) => ({
  id: 'IRYO',
  date: e.data.departureDate
})})
const setRoute = assign({route_values: (ctx, e) => e.data})
const setResources = assign({train_resources: (ctx, e) => e.data.data})
const setAvailability = assign({availability: (ctx, e) => e.data.data})

const fetchResources = (ctx) => {
  return (
    new ApiFetcher({
      key: 'stations',
      endpoint: ctx.endpoints.train_resources
    }).call()
  )
}

const fetchAvailability = (ctx) => {
  return (
    new ApiFetcher({
      key: 'events',
      method: 'POST',
      endpoint: ctx.endpoints.train_availability,
      body: JSON.stringify({route: ctx.route_values})
    }).call()
  )
}

const submitForm = (ctx, event) => {
  const formName = 'route_form'

  return new Promise((resolve, reject) => {
    ctx[formName].submitForm()
      .then(ctx[formName].validateForm)
      .then(errors => {
        const isValid = Object.keys(errors).length === 0
        if (isValid) {
          resolve(ctx[formName].values)
        } else {
          reject(errors)
        }
      })
  })
}

const routeState = (steps, params, step) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {'': {
          target: 'prepareFetchingResources',
          actions: cleanError
        }},
      },
      prepareFetchingResources: {
        on: {'': {
          target: 'fetchingResources',
          // actions: ['startSpinner']
        }},
      },
      fetchingResources: {
        invoke: {
          src: fetchResources,
          onDone: {
            target: 'waitingForm',
            actions: [setResources, 'stopSpinner']
          },
          onError: {
            target: '#purchase.error',
            actions: [setError, 'stopSpinner']
          }
        },
      },
      waitingForm: {
      },
      formValidation: {
        invoke: {
          src: submitForm,
          onDone: [
            {
              target: 'prepareFetchingAvailability',
              actions: [setRoute, setEvent, cleanError]
            }
          ],
          onError: {
            target: 'waitingForm',
            actions: [setError]
          }
        }
      },
      prepareFetchingAvailability: {
        on: {'': {
          target: 'fetchingAvailability',
          actions: ['startSpinner']
        }},
      },
      fetchingAvailability: {
        invoke: {
          src: fetchAvailability,
          onDone: {
            target: step.target,
            actions: [setAvailability, 'stopSpinner']
          },
          onError: {
            target: '#purchase.error',
            actions: ['stopSpinner']
          }
        },
      },
    },
    on: {
      ROUTE_FORM_CHANGE: {
        actions: [updateForm]
      },
      NEXT_STEP_CLICK: [
        {
          target: '.formValidation'
        },
        {
          actions: [setValidationError]
        }
      ]
    }
  }
}

export default routeState
