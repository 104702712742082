import React from 'react'
import Steps, {Step} from 'rc-steps'
import { useTranslation } from 'react-i18next'
import 'ui/steps/styles/index.less'

const containerStyle = {
  border: '1px solid rgb(235, 237, 240)',
  background: 'white'
}

const redirect = (url) => {
  window.location.href = url
}

const status = (current, stepActive) => {
  if (current == 0) {
    return stepActive == 0 ? 'process' : 'finished'
  } else if (current == 1) {
    if (stepActive == 0) {
      return 'wait'
    } else if (stepActive == 1) {
      return 'process'
    } else {
      return 'finished'
    }
  } else {
    return stepActive == 2 ? 'process' : 'wait'
  }
}

const PurchaseWizard = ({stepActive, endpoints, className}) => {
  const { t, i18n } = useTranslation()

  return (
    <Steps style={containerStyle} type="navigation" current={stepActive} className={className || 'mb-3'}>
      <Step
        title={i18n.t('purchase_wizard.product_step')}
        status={status(0, stepActive)}
        onClick={() => status(0, stepActive) == 'finished' ? redirect(endpoints.service) : ''}
        style={{cursor: 'pointer'}}
      />
      <Step
        title={i18n.t('purchase_wizard.buyer_step')}
        status={status(1, stepActive)}
        onClick={() => status(1, stepActive) == 'finished' ? redirect(endpoints.service) : ''}
        style={{cursor: 'pointer'}}
      />
      <Step title={i18n.t('purchase_wizard.payment_step')} status={status(2, stepActive)}/>
    </Steps>
  )
}

export default PurchaseWizard
