import React, {useState, useEffect} from 'react'
import { Formik, Field, FieldArray } from 'formik'
import { Card, CardHeader, CardBody, Form, FormGroup, Alert } from 'reactstrap'
import { ReactstrapInput } from 'reactstrap-formik'
import DatetimePicker from 'ui/forms/datetime_picker'
import { Row, Col } from 'reactstrap'
import {currency} from 'i18n/config'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import {countries} from 'countries-list'
import cardTypes from './card_types'
import Select from 'ui/forms/select'

const VisitorsForm = (props) => {
  const {visitors, setForm, fields, machine, hidePrices} = props
  const { t, i18n } = useTranslation()

  let formShape = {
    name: Yup.string().required('Required'),
    surname: Yup.string().required('Required'),
    card_id: Yup.string().required('Required')
  }

  if (fields.includes('birthdate')) {
    formShape.birthdate = Yup.date().required('Required')
  }

  if (fields.includes('nationality')) {
    formShape.nationality = Yup.string().required('Required')
  }

  if (fields.includes('id_card_type')) {
    formShape.id_card_type = Yup.string().required('Required')
  }

  const schema = Yup.object().shape({
    visitors: Yup.array()
      .of(
        Yup.object().shape(formShape)
      )
      .min(visitors.length)
  })

  return (
    <Formik
      validationSchema={schema}
      enableReinitialize
      onSubmit={(values) => console.log(values)}
      initialValues={{
        visitors: visitors
      }}
    >
      {(form) => {
        const {values, setFieldValue} = form

        useEffect(() => {
          setForm(form)
        }, [values])

        return (
          <FieldArray
            name="visitors"
            render={() => (
              <Row className="row-cols-lg-2">
                {values.visitors.map(((visitor, i) => {
                  return (
                    <Col key={i} xs="12" md="6">
                      <Card key={visitor.id} className="mb-3">
                        <CardHeader>
                          <div className="d-flex justify-content-between">
                            <div>
                              <strong className="text-uppercase">{visitor.ticket_type_name}</strong>
                            </div>
                            {!hidePrices &&
                              <div>
                                {currency(visitor.total_price)}
                              </div>
                            }
                          </div>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <FormGroup>
                              <Row className="row-cols-1 row-cols-md-2">
                                {fields.includes('name') &&
                                  <Col>
                                    <Field label={i18n.t('visitors_form.name')} name={`visitors.${i}.name`} component={ReactstrapInput}/>
                                  </Col>
                                }
                                {fields.includes('name') &&
                                  <Col>
                                    <Field label={i18n.t('visitors_form.surname')} name={`visitors.${i}.surname`} component={ReactstrapInput}/>
                                  </Col>
                                }
                                {fields.includes('id_card_type') &&
                                  <Col>
                                    <Field
                                      values={Object.values(cardTypes.es).map((c) => ({label: c.description, value: c.code}))}
                                      placeholder={i18n.t('visitors_form.id_card_type')}
                                      label={i18n.t('visitors_form.id_card_type')}
                                      name={`visitors.${i}.id_card_type`}
                                      component={Select}
                                      onSelect={(selected) => {
                                        if (selected.value == 'MINOR') {
                                          setFieldValue(`visitors.${i}.card_id`, '-')
                                        }
                                      }}
                                    />
                                  </Col>
                                }
                                {fields.includes('card_id') &&
                                  <Col>
                                    <Field label={i18n.t('visitors_form.card_id')} name={`visitors.${i}.card_id`} component={ReactstrapInput}/>
                                  </Col>
                                }
                                {fields.includes('birthdate') &&
                                  <Col>
                                    <Field
                                      label={i18n.t('visitors_form.birthdate')}
                                      name={`visitors.${i}.birthdate`}
                                      machine={machine}
                                      component={DatetimePicker}
                                      dateFormat={'P'}
                                      showTimeSelect={false}
                                      minDate={null}
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                    />
                                  </Col>
                                }
                                {fields.includes('nationality') &&
                                  <Col>
                                    <Field
                                      values={Object.values(countries).map((c) => ({label: `${c.emoji} ${c.native} (${c.name})`, value: c.name}))}
                                      placeholder={i18n.t('visitors_form.nationality')}
                                      label={i18n.t('visitors_form.nationality')}
                                      name={`visitors.${i}.nationality`}
                                      component={Select}
                                    />
                                  </Col>
                                }
                              </Row>
                            </FormGroup>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  )
                }))}
              </Row>
            )}
          />
        )
      }}
    </Formik>
  )
}

export default VisitorsForm
