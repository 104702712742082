import React from 'react'
import EventModule from 'containers/purchase/modules/event_module/event_module'
import TicketTypesModule from 'containers/purchase/modules/ticket_types_module/ticket_types_module'
import SupplementsModule from 'containers/purchase/modules/supplements_module/supplements_module'
import PickupModule from 'containers/purchase/modules/lct_pickup_module/pickup_module'
import ContactModule from 'containers/purchase/modules/contact_module/contact_module'
import eventState from 'containers/purchase/modules/event_module/event_state'
import ticketTypesState from 'containers/purchase/modules/ticket_types_module/ticket_types_state'
import supplementsState from 'containers/purchase/modules/supplements_module/supplements_state'
import pickupState from 'containers/purchase/modules/lct_pickup_module/lct_pickup_state'
import contactState from 'containers/purchase/modules/contact_module/contact_state'

const reservationMapper = (ctx) => {
  const tickets = ctx.tickets.filter((ticket) => ticket.qty > 0 && ticket.type == 'event_ticket')
  const supplements = ctx.supplements.filter((ticket) => ticket.qty > 0 && ticket.type == 'supplement')

  return {
    event_id: ctx.event.id,
    ticket_types: tickets.concat(supplements),
    params: {
      pickup: ctx.pickup,
      contact_values: ctx.contactValues
    }
  }
}

const config = (params) => {
  return {
    reservationMapper: reservationMapper,
    steps: {
      eventStep: {
        component: EventModule,
        autoNext: true,
        states: eventState,
        context: {
          year_month: params.initialYearMonth,
          event_groups: [],
          event: null
        }
      },
      ticketsStep: {
        component: TicketTypesModule,
        autoNext: false,
        states: ticketTypesState,
        context: {
          tickets: []
        }
      },
      supplementsStep: {
        component: SupplementsModule,
        autoNext: false,
        states: supplementsState,
        context: {
          supplements: []
        }
      },
      pickupStep: {
        component: PickupModule,
        autoNext: false,
        states: pickupState,
        context: {
          pickups: [],
          pickup: null
        }
      },
      contactStep: {
        component: ContactModule,
        autoNext: false,
        states: contactState,
        context: {
          contactValues: []
        }
      }
    }
  }
}

export default config
