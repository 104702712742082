import React from 'react'
import {ModulePanel, ModuleHeader, ModuleBody} from 'containers/purchase/modules/module_panel'
import { Row, Col, Form, FormGroup, Alert, Label } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useField, useFormikContext } from 'formik'
import TextInput from 'ui/forms/text_input'
import Textarea from 'ui/forms/textarea'
import Select from 'ui/forms/select'
import PhoneInput from 'ui/forms/phone_input'
import ConsentCheckbox from 'ui/forms/consent_checkbox'

export const BuyerModule = (props) => {
  const { t, i18n } = useTranslation()
  const {cart, user, languageOptions, client_params, setFieldValue} = props
  const {attendants} = cart
  const reservation = cart.items[0]
  const {evoucher} = reservation.product

  const AttendantSelect = (props) => {
    const [field, meta, helpers] = useField(props)

    const createOptionFromInputValue = (value) => {
      helpers.setValue({label: value, value: value})
    }

    const onInputBlur = (e) => {
      createOptionFromInputValue(e.target.value)
    }

    return (
      <Select
        label={<b>{i18n.t('buyer_form.attended_by')}</b>}
        name="attended_by"
        values={attendants.map((a) => ({...a, value: a.id, label: a.name}))}
        value={field.value}
        placeholder={i18n.t('buyer_form.attended_by_placeholder')}
        onChange={(selected) => {
          helpers.setValue(selected ? selected  : '')
        }}
        noOptionsMessage={() => (
          <span>
            <i className="fas fa-pencil-alt mr-2" aria-hidden="true"/>
            {i18n.t('buyer_form.attended_by_no_records')}
          </span>
        )}
        formatCreateLabel={(value) => (
          <span>
            <i className="fa fa-plus-circle mr-2" aria-hidden="true"/>
            {i18n.t('buyer_form.attended_by_create_label', {attendant: value})}
          </span>
        )}
        onBlur={onInputBlur}
        creatable
      />
    )
  }

  return (
    <React.Fragment>
      {user.sale_channel == 'b2b' &&
        <ModulePanel>
          <ModuleHeader status="active" showIcon={false}>
            <span>
              <i className="far fa-envelope mr-2" aria-hidden="true"></i>
              {i18n.t('buyer_form.email_b2b')}
            </span>
          </ModuleHeader>
          <ModuleBody status="active">
            <TextInput label={null} type="email" name="main_customer.email" required/>
            <Label><i>{i18n.t('buyer_form.email_b2b_description')}</i></Label>
          </ModuleBody>
        </ModulePanel>
      }
      <ModulePanel>
        <ModuleHeader status="active" showIcon={false}>
          <span>
            <i className="far fa-address-card mr-2" aria-hidden="true"></i>
            {user.sale_channel == 'b2b' ? i18n.t('buyer_module.buyer_form_title_b2b') : i18n.t('buyer_module.buyer_form_title')}
          </span>
        </ModuleHeader>
        <ModuleBody status="active">
          <Form>
            <FormGroup>
              <Row>
                <Col sm={6}>
                  <TextInput
                    label={user.sale_channel == 'b2b' ? i18n.t('buyer_form.first_name_b2b') : i18n.t('buyer_form.first_name')}
                    name="main_customer.first_name"
                    required
                  />
                </Col>
                <Col sm={6}>
                  <TextInput
                    label={user.sale_channel == 'b2b' ? i18n.t('buyer_form.last_name_b2b') : i18n.t('buyer_form.last_name')}
                    name="main_customer.surname"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  {user.sale_channel == 'b2b' ? (
                    <TextInput label={i18n.t('buyer_form.notifications_email')} type="email" name="main_customer.notifications_email" required/>
                  ) : (
                    <TextInput label={i18n.t('buyer_form.email')} type="email" name="main_customer.email" required/>
                  )}
                </Col>
                <Col sm={6}>
                  <PhoneInput
                    label={user.sale_channel == 'b2b' ? i18n.t('buyer_form.phone_b2b') : i18n.t('buyer_form.phone')}
                    name="main_customer.telephone"
                    prefixName="main_customer.prefix_phone"
                    required={user.sale_channel == 'b2c' ? true : false}
                    setFieldValue={setFieldValue}
                  />
                </Col>
              </Row>
              {user.sale_channel == 'b2b' &&
              <Row>
                <Col sm={6}>
                  <TextInput label={i18n.t('buyer_form.room')} type="input" name="main_customer.room"/>
                </Col>
                <Col sm={6}>
                  <AttendantSelect name="attended_by"/>
                </Col>
              </Row>
              }
              <Row>
                <Col md="12">
                  <Textarea label={i18n.t('buyer_form.comments')} name="main_customer.comments"/>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  {evoucher == true ? (
                    <Alert color="info" style={{margin: '10px 0'}}>
                      <Row style={{marginBottom: '5px'}}>
                        <Col>
                          <i className="fa fa-mobile" style={{marginRight: '8px'}}></i>
                          {i18n.t('buyer_form.eticket_support')}
                        </Col>
                      </Row>
                      {user.sale_channel == 'b2b' &&
                      <Row>
                        <Col md="12">
                          <Select
                            label={<b>{i18n.t('buyer_form.notifications_language')}</b>}
                            name="notifications_language"
                            values={languageOptions}
                            placeholder={i18n.t('buyer_form.notifications_language_placeholder')}
                            required="true"
                          />
                        </Col>
                      </Row>
                      }
                    </Alert>
                  ) : (
                    <Alert color="warning" style={{margin: '10px 0'}}>
                      <i className="fa fa-print" style={{marginRight: '8px'}}></i>
                      <b>{i18n.t('buyer_form.eticket_unsupported')}</b>

                      <Row className="mt-3">
                        <Col>
                          <Select
                            label={i18n.t('buyer_form.notifications_language')}
                            name="notifications_language"
                            values={languageOptions}
                            placeholder={i18n.t('buyer_form.notifications_language_placeholder')}
                            required="true"
                          />
                        </Col>
                      </Row>
                    </Alert>
                  )}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <ConsentCheckbox client_params={client_params}/>
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </ModuleBody>
      </ModulePanel>
    </React.Fragment>
  )
}

export default BuyerModule
