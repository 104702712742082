import { assign } from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import {nextStep} from 'utils/step_utils'
import {EUR} from 'utils/currency'
import i18n from 'i18n/config'

const setError = assign({error: i18n.t('validations:fill_all_fields')})
const cleanError = assign({error: null})
const setValidationError = assign({error: i18n.t('validations:pickups_required')})
const pickupUpdateForm = assign({pickup_form: (ctx, e) => e.form})
const dropoffUpdateForm = assign({dropoff_form: (ctx, e) => e.form})

const setQuotationResources = assign({resources: (ctx, e) => e.data})

const fetchQuotationResources = (ctx) => {
  return (
    new ApiFetcher({
      key: 'events',
      endpoint: ctx.endpoints.quotation_resources,
      query: {date: ctx.day}
    }).call()
  )
}

const stepMap = {
  pickupStep: 'pickup_form',
  dropoffStep: 'dropoff_form'
}

const submitForm = (ctx, event) => {
  const currentStep = event.currentStep

  const formName = stepMap[currentStep]

  return new Promise((resolve, reject) => {
    ctx[formName].submitForm()
      .then(ctx[formName].validateForm)
      .then(errors => {
        const isValid = Object.keys(errors).length === 0
        if (isValid) {
          resolve(ctx[formName].values)
        } else {
          reject(errors)
        }
      })
  })
}

const routeState = (steps, params, step) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {'': {
          target: 'prepareFetchingResources',
          actions: cleanError
        }},
      },
      prepareFetchingResources: {
        on: {'': {
          target: 'fetchingResources',
          actions: ['startSpinner']
        }},
      },
      fetchingResources: {
        invoke: {
          src: fetchQuotationResources,
          onDone: {
            target: 'waitingQuotationParams',
            actions: [setQuotationResources, 'stopSpinner']
          },
          onError: {
            target: '#purchase.error',
            actions: [setError, 'stopSpinner']
          }
        },
      },
      waitingQuotationParams: {},
      formValidation: {
        invoke: {
          src: submitForm,
          onDone: [
            {
              target: step.target,
              actions: assign({[step.currentStep + '_values']: (ctx, e) => e.data})
            }
          ],
          onError: {
            target: 'waitingQuotationParams',
            actions: [setError]
          }
        }
      }
    },
    on: {
      PICKUP_FORM_CHANGE: {
        actions: [pickupUpdateForm]
      },
      DROPOFF_FORM_CHANGE: {
        actions: [dropoffUpdateForm]
      },
      NEXT_STEP_CLICK: [
        {
          target: '.formValidation'
        },
        {
          actions: [setValidationError]
        }
      ]
    }
  }
}

export default routeState
