import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.p`
  display: block;
  padding: 7px 0;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 0.85rem;
`

const Label = styled.span`
  vertical-align: top;
  position: relative;
  color: #949494;
  display: inline-block;
  overflow: hidden;
  margin-right: 15px;
  line-height: 17px;
  top: 2px;
  width: 120px;
  overflow: hidden;
`

const Value = styled.span`
  vertical-align: top;
  position: relative;
  display: inline-block;
  color: #373e4a;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  max-width: 70%;
`

const FieldInfo = ({label, value, showEmpty}) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
      {value &&
        <Value>{value}</Value>
      }
      {showEmpty && !value &&
        <Value><i className={'text-muted'}>Sin asignar</i></Value>
      }
    </Wrapper>
  )
}

export default FieldInfo
