import React, {useState, useEffect} from 'react'
import AppProvider from 'utils/app_provider'
import ReactResizeDetector from 'react-resize-detector'
import Banner from 'ui/banner'
import Navbar from 'containers/layouts/navbar'
import HeaderIframe from 'containers/layouts/header_iframe'
import Footer from 'containers/layouts/footer'
import ApiFetcher from 'lib/api_fetcher'
import cn from 'classnames'
import {get, delay} from 'lodash'
import time from 'i18n/time'

const fetchDiscardCart = (params) => {
  PubSub.publish('loading-page')

  new ApiFetcher({
    key: 'cart',
    endpoint: params.urls.discard.url,
    method: params.urls.discard.method
  }).call()
    .then((res) => {
      PubSub.publish('loaded-page')
      location.reload()
    })
}

const fetchCart = (cart_params, setCart) => {
  return (
    new ApiFetcher({
      key: 'cart',
      endpoint: cart_params.urls.show
    }).call()
    .then((res) => {
      setCart(res.reservation)
      const expirationDate = time(res.reservation.expires_at)

      const difference = expirationDate.diff(time(), 'miliseconds', true);

      delay(() => fetchDiscardCart(cart_params), difference)
    })
  )
}

const Page = (props) => {
  const {user, mode, className, style, show_banner, menu_props, theme, headless, cart_params, children} = props
  const onResize = (width, height) => window.parent.postMessage(['resizeHeight', height + 90], '*')
  const themeHeadless  = get(theme, 'settings.headless', false)
  const bannerTop = get(user, 'user_data.banners.top')

  const expiryTimestamp = time().add('1', 'year').toDate()

  const [scssVariables, setScssVariables] = useState()
  const [cart, setCart] = useState()

  useEffect(() => {
    if (theme && theme != 'ticketinhotel') {
      import(`stylesheets/themes/${theme.style_code}/bootstrap.scss`)
        .then((data) => setScssVariables(data))
        .catch(() => console.log(theme.style_code + ': THEME NOT INSTALLED'))
    }

    if (cart_params && cart_params.enable_cart && cart_params.uuid) { fetchCart(cart_params, setCart) }
  }, [])

  if (headless || themeHeadless) {
    return (
      <AppProvider {...props} scssVariables={scssVariables}>
        <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
        <div style={style}>
          {children}
        </div>
      </AppProvider>
    )
  }

  return (
    <AppProvider {...props} scssVariables={scssVariables}>
      <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
      {mode == 'iframe' ? <HeaderIframe {...props} cart={cart}/> : <Navbar {...props} cart={cart}/>}

      <div className={className || cn(
        'page'  ,
        mode == 'web' ? 'container mt-3 pt-3 mt-lg-4 pt-lg-4' : 'container mt-3 mb-3'
      )} style={style}>
        {show_banner && bannerTop &&
          <div className="mb-4">
            <Banner {...bannerTop}/>
          </div>
        }
        {children}
      </div>

      {mode != 'iframe' &&
        <Footer {...props}/>
      }
    </AppProvider>
  )
}

export default Page
