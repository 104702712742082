import React from 'react'
import {useTranslation} from 'react-i18next'
import {isMobile} from 'react-device-detect'

const StatusFilter = (props) => {
  const {machine, suppliers} = props
  const [current, send] = machine
  const {i18n} = useTranslation()

  const statusFilterd = current.context.filters.find((filter) => filter.param == 'status')
  const status = statusFilterd && statusFilterd.value

  const onStatusFilter = (e) => {
    if (status) {
      send({type: 'FILTER_REMOVE', param: 'status'})
    } else {
      send({type: 'FILTER_SELECTION', filter: {
        param: 'status',
        label: i18n.t('status_filter.status_active_label'),
        value: 'active'
      }})
    }

    if (isMobile) {
      props.setIsOpen(!props.isOpen)
    }
  }

  return (
    <React.Fragment>
      <input
        type={'checkbox'}
        className="mr-2"
        value={status == 'active' ? true : false}
        checked={status == 'active' ? true : false}
        style={{cursor: 'pointer'}}
        onChange={onStatusFilter}
      />
      <label onClick={onStatusFilter} style={{cursor: 'pointer'}}>
        {i18n.t('status_filter.only_active')}
      </label>
    </React.Fragment>

  )
}

export default StatusFilter
