import React from 'react'
import Select from 'react-select'
import {useTranslation} from 'react-i18next'
import {isMobile} from 'react-device-detect'

const SuppliersFilter = (props) => {
  const {machine, suppliers} = props
  const [current, send] = machine
  const {i18n} = useTranslation()

  const supplierFilterd = current.context.filters.find((filter) => filter.param == 'supplier')

  const onSupplierFilter = (supplier) => {
    if (supplier) {
      send({type: 'FILTER_SELECTION', filter: {
        param: 'supplier',
        label: supplier.label,
        value: supplier.value
      }})
    } else {
      send({type: 'FILTER_REMOVE', param: 'supplier'})
    }

    if (isMobile) {
      props.setIsOpen(!props.isOpen)
    }
  }

  return (
    <Select
      value={supplierFilterd}
      options={suppliers}
      onChange={onSupplierFilter}
      placeholder={i18n.t('suppliers_filter.placeholder')}
      isClearable={true}
    />
  )
}

export default SuppliersFilter
