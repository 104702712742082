import { assign } from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import PriceSummaryCalculator from 'lib/price_summary_calculator'
import {nextStep} from 'utils/step_utils'
import i18n from 'i18n/config'

const stepName  = 'ticketsStep'

const fetchTicketTypes = (ctx) => (
  new ApiFetcher({
    key: 'ticket_types',
    endpoint: ctx.endpoints.pricing,
    query: {event_id: ctx.event.id}
  }).call()
)

const initTickets = assign({tickets: (ctx, e) => {
  let tickets = e.data.ticket_types
  return tickets.map((ticket_type) => {
    ticket_type.qty = 0
    return ticket_type
  })
}})
const setTickets = assign({tickets: (ctx, e) => e.tickets})
const setError = assign({error: i18n.t('validations:tickets_fetch_error')})
const setPriceSummary = assign({priceSummary: (ctx) => {
  let summary = new PriceSummaryCalculator({ticket_types: ctx.tickets, currency: ctx.currency}).call()
  const {total_base_price, total_discount, total_additional_fee, total_final_price} = summary

  return Object.assign(ctx.priceSummary, {
    ticketsStep: {
      base_price: total_base_price,
      discount: total_discount,
      additional_fee: total_additional_fee,
      total_price: total_final_price
    }
  })
}})
const setValidationError = assign({error: i18n.t('validations:at_least_one_ticket')})
const cleanError = assign({error: null})

const emptyTickets = (ctx) => ctx.tickets.length <= 0
const unlessOneTicket = (ctx) => ctx.tickets.map((ticket) => ticket.qty).reduce((accum, current) => accum + current) > 0

const ticketTypesState = (config) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {
          '': [
            {
              target: 'fetchingTicketTypes',
              cond: emptyTickets,
              actions: [cleanError, 'startSpinner']
            },
            {
              target: 'waitingTicketPick'
            }
          ],
        },
      },
      fetchingTicketTypes: {
        invoke: {
          src: fetchTicketTypes,
          onDone: {
            target: 'waitingTicketPick',
            actions: [initTickets, setPriceSummary, 'stopSpinner']
          },
          onError: {
            target: '#purchase.error',
            actions: [setError, 'stopSpinner', 'scrollToError']
          }
        }
      },
      waitingTicketPick: {
        onEntry: []
      }
    },
    on: {
      TICKETS_SELECTION: {
        actions: [setTickets, setPriceSummary, cleanError]
      },
      NEXT_STEP_CLICK: [
        {
          target: nextStep(config, stepName),
          cond: unlessOneTicket
        },
        {
          actions: [setValidationError, 'scrollToError']
        }
      ]
    }
  }
}

export default ticketTypesState
