import React from 'react'
import {ModulePanel, ModuleHeader, ModuleBody, ModuleFooter} from 'containers/purchase/modules/module_panel'
import { useTranslation } from 'react-i18next'
import RouteForm from './route_form'

export const PickupModule = (props) => {
  const {config, machine, status} = props
  const [current, send] = machine
  const { t, i18n } = useTranslation()

  const formName = props.formName.toUpperCase()

  const setForm = (form) => {
    send({type: props.formName.toUpperCase() + '_CHANGE', form: form})
  }

  return (
    <ModulePanel>
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send(`REOPEN_${formName}STEP`.replace('_FORM', '')) : null}>
        <span>
          <i className="fa fa-map-marker mr-2" aria-hidden="true"></i>
          {props.title}
        </span>
      </ModuleHeader>
      <ModuleBody status={status}>
        <RouteForm step={props.formName} setForm={setForm} config={config} machine={machine}/>
      </ModuleBody>
      <ModuleFooter {...props}/>
    </ModulePanel>
  )
}

export default PickupModule
