const initialGroup = ['PT_FLEX2']
const singularGroup = ['PS_FLEX2', 'PS_FLEX1']
const singularOnlyYouGroup = ['PS_CFFLEX2', 'PS_CFFLEX1']
const infinitaGroup = ['PI_FLEX2', 'PI_FLEX1']
const infinitaBistroGroup = ['PI_GRFLEX2', 'PI_GRFLEX1']

const colorClasses = (code) => {
  if (initialGroup.includes(code)) {
    return '#864a33'
  } else if (singularGroup.includes(code)) {
    return '#a6192e'
  } else if (singularOnlyYouGroup.includes(code)) {
    return '#a6192e'
  } else if (infinitaGroup.includes(code)) {
    return '#000'
  } else if (infinitaBistroGroup.includes(code)) {
    return '#000'
  }
}

const arrowPositions = (code) => {
  if (initialGroup.includes(code)) {
    return '41.5%'
  } else if (singularGroup.includes(code)) {
    return '58%'
  } else if (singularOnlyYouGroup.includes(code)) {
    return '75%'
  } else if (infinitaGroup.includes(code)) {
    return '91.5%'
  } else if (infinitaBistroGroup.includes(code)) {
    return '91.5%'
  }
}

export {initialGroup, singularGroup, singularOnlyYouGroup, infinitaGroup, infinitaBistroGroup, arrowPositions, colorClasses}
