import {assign} from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import {nextStep, isLastStep} from 'utils/step_utils'
import {EUR} from 'utils/currency'
import i18n from 'i18n/config'

const stepName  = 'tourcmsComponentStep'

const setError = assign({error: i18n.t('validations:events_fetch_error')})
const setComponents = assign({components: (ctx, e) => e.data})
const setDefaultComponent = assign({component: (ctx, e) => e.data[0]})
const setComponent = assign({component: (ctx, e) => e.component})
const cleanError = assign({error: null})
const cleanComponent = assign({component: null})
const initializePriceSummary = assign({priceSummary: ({priceSummary, component}) => {
  if (component) {
    return Object.assign(priceSummary, {
      tourcmsComponentStep: {
        base_price: EUR(component.total_price),
        discount: EUR(0),
        additional_fee: EUR(0),
        total_price: EUR(component.total_price)
      }
    })
  } else {
    return Object.assign(priceSummary, {
      tourcmsComponentStep: {
        base_price: EUR(0),
        discount: EUR(0),
        additional_fee: EUR(0),
        total_price: EUR(0)
      }
    })
  }
}})
const setPriceSummary = assign({priceSummary: (ctx, {component}) => {
  return Object.assign(ctx.priceSummary, {
    tourcmsComponentStep: {
      base_price: EUR(component.total_price),
      discount: EUR(0),
      additional_fee: EUR(0),
      total_price: EUR(component.total_price)
    }
  })
}})
const componentSelected = (ctx) => ctx.component
const setValidationError = assign({error: i18n.t('validations:component_required')})

const fetchComponents = (ctx) => {
  const rates = ctx.tickets.reduce((accum, current) => {
    if (current.qty > 0) {
      return {...accum, [current.id]: current.qty}
    } else {
      return {...accum}
    }
  }, {})

  return new ApiFetcher({
    key: 'tourcms_components',
    endpoint: ctx.endpoints.tourcms_components,
    query: {qs: JSON.stringify({date: ctx.day, rates: rates})}
  }).call()
}

const tourcmsComponentStep = (config) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {'': {
          target: 'fetchingComponents',
          actions: [cleanError, cleanComponent, 'startSpinner']
        }},
      },
      fetchingComponents: {
        invoke: {
          src: fetchComponents,
          onDone: [
            {
              target: 'initializePriceSummary',
              actions: ['stopSpinner', setComponents, setDefaultComponent]
            }
          ],
          onError: {
            target: '#purchase.error',
            actions: [setError, 'stopSpinner']
          }
        },
      },
      initializePriceSummary: {
        on: {'': {
          target: 'waitingComponentPick',
          actions: [initializePriceSummary]
        }},
      },
      waitingComponentPick: {}
    },
    on: {
      COMPONENT_SELECTION: {
        // target: '#purchase.reserving',
        actions: [setComponent, setPriceSummary, cleanError]
      },
      NEXT_STEP_CLICK: [
        {
          target: isLastStep(config, 'tourcmsComponentStep') ? '#purchase.reserving' : '#purchase.' + nextStep(config, 'tourcmsComponentStep'),
          cond: componentSelected
        },
        {
          actions: setValidationError
        }
      ],
      RESERVATION_REQUEST: [
        {
          target: '#purchase.reserving',
          cond: componentSelected
        },
        {
          actions: setValidationError
        }
      ],
    }
  }
}

export default tourcmsComponentStep
