import React, {useState, useEffect} from 'react'
import bgDefault from 'img/bg-default.jpg'
import {Button} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const LinkWrapper = styled.div.attrs((props) => ({
  className: [
    'bg-white  d-flex justify-content-center m-3'
  ]
}))`
  button {
    font-size: 1.7em;
    width: 100%;
  }
`

const DirectLinkRedirect = (props) => {
  // useEffect(() => {
  //   const bg = localStorage.getItem('sign_in_bg')
  //   document.getElementsByTagName('body')[0].style = `background-image: url(${bg ? bg : bgDefault})`
  // }, [])

  const { t, i18n } = useTranslation()

  return (
    <LinkWrapper>
      <Button color="primary" className="btn-block text-dark rounded shadow">
        <a className="text-white" href={props.link_url} target="_blank" referral="noreferrer noopener">
          <strong><i className="fa fa fa-hand-pointer mr-3"></i>{i18n.t('direct_link_redirect.button')}</strong>
        </a>
      </Button>
    </LinkWrapper>
  )
}

export default DirectLinkRedirect
