import React from 'react'
import ProductsList from './products/products_list'
import Page from 'ui/page'
import banner from 'img/banner.jpg'

const Products = (props) => {
  return (
    <Page {...props}>
      {false &&
        <div className="mb-4">
          <img className="shadow rounded-lg" src={banner} width="100%" height="110"></img>
        </div>
      }
      <ProductsList {...props}/>
    </Page>
  )
}

export default Products
