 import React, {useState} from 'react'
import Page from 'ui/page'
import LanguageSwitcher from 'ui/language_switcher'
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody
} from 'reactstrap'
import parse from 'react-html-parser'
import styled from 'styled-components'
import Modal from 'ui/modal'
import SendProductInfoForm from 'containers/list/send_product_info_form'
import clientConfig from 'config/client_config.json'
import {useTranslation} from 'react-i18next'

const Styles = styled.div`
  strong {
    font-weight: bold;
  }

  .card-body img {
    width: 100%;
  }

  .navbar {
    a {
     cursor: pointer;
     transition: 0.2s ease;

      &:hover {
        color: yellow !important;
      }
    }
  }

  table {
    th {
      background: #DDD;
    }

    td, th {
      border: 1px solid #DDD;
      padding: 5px 8px;
    }
  }
`

const ProductInfo = (props) => {
  const {product, user} = props
  const {title, subtitle, body, sidebar, featured_image, images} = product.info
  const {supportTelephone, supportEmail} = clientConfig
  const {i18n} = useTranslation()

  const Topbar = () => {
    return (
      <Navbar color="dark" light expand="md" className="d-print-none mb-4">
        <Container>
          <Nav navbar className="mr-auto">
            <NavItem className="mr-4">
              <NavLink className="text-white" href={`tel:${supportTelephone}`}>
                <i className="fa fa-phone mr-1"/>{supportTelephone}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="text-white" href={`mailto:${supportEmail}`}>
                <i className="fa fa-envelope mr-1"/>{supportEmail}
              </NavLink>
            </NavItem>
          </Nav>
          <Nav navbar>
            <NavItem className="mr-3">
              <NavLink>
                <LanguageSwitcher en es fr urls={props.language_links}/>
              </NavLink>
            </NavItem>
            <NavItem className="mx-2" onClick={() => window.print()}>
              <NavLink className="text-white">
                <i className="fa fa-print mr-1"/>{i18n.t('format:actions.print')}
              </NavLink>
            </NavItem>
            <NavItem>
              <SendEmailLink/>
            </NavItem>

          </Nav>
        </Container>
      </Navbar>
    )
  }

  const SendEmailLink = () => {
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)

    return (
      <React.Fragment>
        <NavLink className="text-white" onClick={() => toggle()}>
          <i className="fa fa-envelope mr-1"/>{i18n.t('format:actions.send_email')}
        </NavLink>

        <Modal
          isOpen={modal}
          toggle={toggle}
          header={'Enviar por email'}
          width={'450px'}
        >
          <SendProductInfoForm endpoint={props.urls.send_product_info} url={props.urls.info}/>
        </Modal>
      </React.Fragment>
    )
  }

  return (
    <Page {...props} headless>
      <Styles>
        <Topbar/>
        <Container className="mb-3">
          <Row>
            <Col xs="12" md="8">
              <Card>
                <CardHeader className="bg-dark text-white text-center" style={{fontWeight: 'bold'}}>
                  {title}
                </CardHeader>
                {subtitle &&
                  <CardHeader>
                    {subtitle}
                  </CardHeader>
                }
                <CardBody className="px-4 py-3">
                  {featured_image &&
                    <img src={featured_image} width="100%" className="img-thumbnail mb-3"/>
                  }
                  {parse(body)}
                </CardBody>
              </Card>
            </Col>
            {(images.length > 0 || sidebar) &&
              <Col xs="12" md="4">
                {images.map((image, i) => (
                  <img key={i} src={image} width="100%" className="mb-3 img-thumbnail p-2"/>
                ))}

                {sidebar &&
                  <Card>
                    <CardBody>
                      {parse(sidebar)}
                    </CardBody>
                  </Card>
                }
              </Col>
            }
          </Row>
        </Container>
      </Styles>
    </Page>
  )
}

export default ProductInfo
