import React, {useEffect} from 'react'
import { Media } from 'reactstrap'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'

const PickupList = ({data, onPickupSelected}) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    onPickupSelected(data[0])
  }, [])

  const formatOptionLabel = (pickup, {context}) => {
    if (context == 'menu') {
      return (
        <Media>
          {pickup.thumbnail &&
            <Media left href="#">
              <img width={100} height={65} src={pickup.thumbnail}/>
            </Media>
          }
          <Media body className={'p-2'}>
            <Media heading style={{fontSize: '15px'}}>
              <strong>{pickup.name} - {pickup.pickup_time}</strong>
              {pickup.distance &&
                <span>({pickup.distance})</span>
              }

              {pickup.pricing_enabled &&
                <strong className="pull-right">+{pickup.price}€</strong>
              }
            </Media>
            <small>
              <span className={'mr-2'}>{pickup.description}</span>
              {pickup.distance &&
                <a href={pickup.gmap} target='_blank' rel='noreferrer noopener'>{i18n.t('pickup_list.map_link')}</a>
              }
            </small>
          </Media>
        </Media>
      )
    } else {
      return (
        <div className="d-flex justify-content-between">
          <div>{pickup.name} - {pickup.pickup_time}</div>
          {pickup.distance &&
            <div className="pl-2 pr-2"><small>{pickup.distance}</small></div>
          }

          {pickup.distance &&
            <div><small><a href={pickup.gmap} target="_blank" rel='noreferrer noopener'>({i18n.t('pickup_list.map_link')})</a></small></div>
          }

          {pickup.pricing_enabled &&
            <div className="ml-5">{pickup.price} €</div>
          }
        </div>
      )
    }
  }

  return <Select
    onChange={(pickup) => onPickupSelected(pickup)}
    formatOptionLabel={formatOptionLabel}
    options={data}
    defaultValue={data[0]}
  />
}

export default PickupList
