import React from 'react'
import {ModulePanel, ModuleHeader, ModuleBody} from 'containers/purchase/modules/module_panel'
import { useTranslation } from 'react-i18next'

export const JantoIframeModule = ({machine, status}) => {
  const [current, send] = machine
  const {iframeLink} = current.context
  const { t, i18n } = useTranslation()

  return (
    <ModulePanel>
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send('REOPEN_JANTOIFRAMESTEP') : null}>
        <span>
          <i className="fa fa-map-marker mr-2" aria-hidden="true"></i>
          {i18n.t('janto_iframe_module.select_ticket_types')}
        </span>
      </ModuleHeader>
      <ModuleBody status={status} className="none">
        {iframeLink ? (
          <iframe src={iframeLink} style={{border: '0px', width: '100%', height: '1000px'}}/>
        ) : (
          i18n.t('janto_iframe_module.loading')
        )}
      </ModuleBody>
    </ModulePanel>
  )
}

export default JantoIframeModule
