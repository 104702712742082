import EventModule from 'containers/purchase/modules/event_list_module/event_list_module'
import TicketTypesModule from 'containers/purchase/modules/ticket_types_module/ticket_types_module'
import CustomFieldsModule from 'containers/purchase/modules/custom_fields_module/custom_fields_module'
import eventState from 'containers/purchase/modules/event_list_module/event_list_state'
import dayState from 'containers/purchase/modules/day_module/day_state'
import ticketTypesState from 'containers/purchase/modules/ticket_types_module/ticket_types_state'
import customFieldsState from 'containers/purchase/modules/custom_fields_module/custom_fields_state'

const reservationMapper = (ctx) => {
  return {
    event_id: ctx.event.id,
    ticket_types: ctx.tickets,
    custom_fields: ctx.customFieldValues
  }
}

const config = (params) => {
  const {external_api_config, } = params
  const predefinedEvent = external_api_config && external_api_config.event
  const eventPickMode = external_api_config && external_api_config.event_pick_mode
  const customFields = params.product && params.product.custom_fields

  let steps = {}

  // steps.eventStep = {
  //   component: EventModule,
  //   autoNext: true,
  //   states: eventState,
  //   context: {
  //     day: params.initialDate,
  //     event_groups: [],
  //     event: null
  //   }
  // }

  steps.ticketsStep = {
    component: TicketTypesModule,
    componentProps: {
      type: 'vehicle'
    },
    autoNext: false,
    states: ticketTypesState,
    context: {
      event: {
        id: external_api_config.event,
        local_timezone: params.product.local_timezone
      },
      tickets: []
    }
  }

  if (customFields && customFields.length > 0) {
    steps.customFieldsStep = {
      component: CustomFieldsModule,
      autoNext: false,
      states: customFieldsState,
      context: {
        customFields: customFields
      }
    }
  }

  return {
    reservationMapper: reservationMapper,
    steps: steps
  }
}

export default config
