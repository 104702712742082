import React from 'react'
import ApiFetcher from 'lib/api_fetcher'
import paymentRedirect from 'lib/payment_redirect'
import {Button} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import cartFailed from 'img/cart-failed'

const PaymentFailedCallout = ({cart, endpoint, paymentUrl}) => {
  const { t, i18n } = useTranslation()

  const paymentRequestFetcher = () => (
    new ApiFetcher({
      key: 'payment_request',
      method: 'PUT',
      endpoint: endpoint
    }).call()
  )

  return (
    <React.Fragment>
      <div className="alert alert-danger mb-4 d-flex flex-column flex-md-row justify-content-left" role="alert">
        <div className="text-center">
          <img width="110" height="110" className="mr-4" src={cartFailed}></img>
        </div>
        <div className="w-100 mt-3 mt-md-0">
          <div className="alert-heading d-flex flex-column flex-md-row justify-content-between m-0 p-0">
            <div className="align-self-center">
              <h5 className="alert-heading">{i18n.t('payment_failed_callout.payment_failed_title')}</h5>
              <p className="m-0">{i18n.t('payment_failed_callout.payment_gateway_reponse_received')}</p>
            </div>
            <div className="text-center mt-3 mt-md-0">
              <Button
                color="success"
                onClick={() => paymentRequestFetcher().then((data) => {
                  if (data.success != true) { return }
                  const cart = data.reservation
                  if (cart.payment_request.endpoint) {
                    paymentRedirect(cart.payment_request.endpoint, cart.payment_request.params)
                  } else {
                    window.location.href = paymentUrl
                  }
                })}
              >
                {i18n.t('payment_failed_callout.go_to_payment_gateway_button')}
              </Button>
            </div>
          </div>
          <hr/>
          <h4 className="m-0">{cart.payment_response}</h4>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PaymentFailedCallout
