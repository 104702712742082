import React, {useEffect} from 'react'
import { Formik, Field } from 'formik'
import { Form, FormGroup, Alert } from 'reactstrap'
import { ReactstrapInput } from 'reactstrap-formik'
import { Row, Col } from 'reactstrap'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'ui/forms/phone_input'

const ContactForm = (props) => {
  const { t, i18n } = useTranslation()

  return (
    <Formik
      validationSchema={
        Yup.object().shape({
          contact_firstname: Yup.string().required(i18n.t('validations:required')).max(25, i18n.t('validations:string.max')),
          contact_lastname: Yup.string().required(i18n.t('validations:required')).max(35, i18n.t('validations:string.max')),
          contact_email: Yup.string().email(i18n.t('validations:email')).required(i18n.t('validations:required')),
          contact_phone: Yup.string().required(i18n.t('validations:required')),
          contact_prefix_phone: Yup.string().required(i18n.t('validations:required')),
        })
      }
      initialValues={{
        contact_firstname: '',
        contact_lastname: '',
        contact_email: '',
        contact_phone: '',
        contact_prefix_phone: ''
      }}
      onSubmit={() => {}}
    >
      {(form) => {
        const {values, setFieldValue} = form

        useEffect(() => {
          props.setForm(form)
        }, [values])

        return (
          <Form>
            <FormGroup>
              <Alert color="info">
                {i18n.t('contact_form.contact_data_reminder')}
                <br/>
                {i18n.t('contact_form.contact_data_warning')}
              </Alert>
              <Row>
                <Col>
                  <Field label={i18n.t('contact_form.contact_firstname')} name="contact_firstname" component={ReactstrapInput}/>
                </Col>
                <Col>
                  <Field label={i18n.t('contact_form.contact_lastname')} name="contact_lastname" component={ReactstrapInput}/>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field label={i18n.t('contact_form.contact_email')} type="email" name="contact_email" component={ReactstrapInput}/>
                </Col>
                <Col>
                  <Field
                    label={i18n.t('contact_form.contact_phone')}
                    type="tel"
                    name="contact_phone"
                    prefixName="contact_prefix_phone"
                    component={PhoneInput}
                    setFieldValue={setFieldValue}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ContactForm
