import React from 'react'
import { ListGroup, ListGroupItem, Row, Col } from 'reactstrap'
import currency from 'i18n/currency'
import { useTranslation } from 'react-i18next'
import {isMobile} from 'react-device-detect'

const PriceSummary = ({machine}) => {
  const { t, i18n } = useTranslation()
  const [current, send] = machine
  const { priceSummary } = current.context
  const stepPrices = Object.values(priceSummary)

  if (stepPrices && stepPrices.length > 0) {
    const total_base_price = stepPrices.map((price) => price.base_price).reduce((accum, current) => currency(accum).add(current))
    const total_discount = stepPrices.map((price) => price.discount).reduce((accum, current) => currency(accum).add(current))
    const total_additional_fee = stepPrices.map((price) => price.additional_fee).reduce((accum, current) => currency(accum).add(current))
    const total_final_price = stepPrices.map((price) => price.total_price).reduce((accum, current) => currency(accum).add(current))

    return (
      <ListGroup className="mr-3 mb-2" style={{minWidth: isMobile ? 'auto' : '380px', width: isMobile ? '100%' : 'auto'}}>
        {total_base_price.value != total_final_price.value &&
          <ListGroupItem className="d-flex">
            <span className="mr-auto">{i18n.t('price_summary.base_price')}</span>
            <span>{total_base_price.format()}</span>
          </ListGroupItem>
        }
        {total_discount.value != 0 &&
          <ListGroupItem className="d-flex">
            <span className="mr-auto">{i18n.t('price_summary.discount')}</span>
            <span>{total_discount.format()}</span>
          </ListGroupItem>
        }
        {total_additional_fee.value != 0 &&
          <ListGroupItem className="d-flex">
            <span className="mr-auto">{i18n.t('price_summary.additional_fee')}</span>
            <span>{total_additional_fee.format()}</span>
          </ListGroupItem>
        }
        <ListGroupItem color="primary h5" className="d-flex">
          <strong className="mr-auto">{i18n.t('price_summary.total_price')}</strong>
          <span className='font-weight-bold text-danger'>{total_final_price.format()}</span>
        </ListGroupItem>
      </ListGroup>
    )
  }

  return null
}

export default PriceSummary
