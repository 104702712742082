import React from 'react'

const StatusIcon = ({status}) => {
  let icon = ''
  let color = ''

  if (status == 'confirmed') {
    icon = 'check'
    color = 'success'
  } else if (status == 'pending') {
    icon = 'hourglass-half'
    color = 'warning'
  } else if (status == 'cancelling') {
    icon = 'exclamation-circle'
    color = 'danger'
  } else if (status == 'cancelled') {
    icon = 'ban'
    color = 'danger'
  } else {
    return ''
  }


  return (
    <span className="mr-2">
      <i className={`fa fa-${icon} text-${color}`} aria-hidden="true"></i>
    </span>
  )
}

export default StatusIcon
