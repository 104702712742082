import React from 'react'
import {Row, Col, Button, Card, CardHeader, CardBody} from 'reactstrap'
import {ModulePanel, ModuleHeader, ModuleBody} from 'containers/purchase/modules/module_panel'
import { ListGroup, ListGroupItem } from 'reactstrap'
import time from 'i18n/time'
import currency from 'i18n/currency'
import StatusIcon from 'ui/status_icon'
import {groupBy} from 'lodash'
import { useTranslation } from 'react-i18next'
import CartTimer from 'ui/cart_timer'
import ApiFetcher from 'lib/api_fetcher'
import cn from 'classnames'
import {statusColors, statusIcons} from 'utils/classes/reservation_status'

const fetchDiscardCart = (discardResource) => {
  PubSub.publish('loading-page')

  new ApiFetcher({
    key: 'cart',
    endpoint: discardResource.url,
    method: discardResource.method
  }).call()
    .then((res) => {
      PubSub.publish('loaded-page')
      location.reload()
    })
}

const fetchDiscardReservation = (reservation_id, discardReservationResource) => {
  PubSub.publish('loading-page')

  new ApiFetcher({
    key: 'cart',
    endpoint: discardReservationResource.url.replace(/ *\:[^)]*\: */g, reservation_id),
    method: discardReservationResource.method
  }).call()
    .then((res) => {
      PubSub.publish('loaded-page')
      location.reload()
    })
}

const SummaryModule = (props) => {
  const {cart, showStatus, cartParams, discardEnabled} = props
  const { t, i18n } = useTranslation()

  const Reservation = (props) => {
    const {reservation, onDiscard} = props
    const {id, order_reference, order_code, product, event, total_price_label, status, status_label, custom_fields} = reservation

    const eventDate = (date) => {
      const {show_date, show_time, local_timezone} = event

      const moment = time(date)

      if (show_date == true && show_time == true) {
        return moment.tz(local_timezone).format('LLLL')
      } else if (show_date == true && show_time == false) {
        return moment.tz(local_timezone).format('LL')
      } else {
        return false
      }
    }

    const formatResponse = ({field_type, value}) => {
      if (field_type == 'boolean') {
        return value == true ? i18n.t('format:boolean.yes') : i18n.t('format:boolean.no')
      }

      if (field_type == 'date') {
        return time(value).tz(event.local_timezone).format('LL')
      }

      if (field_type == 'datetime') {
        return time(value).tz(event.local_timezone).format('LLLL')
      }

      return value
    }

    const ticketGroups = Object.values(groupBy(reservation.tickets, ({ticket_type_code}) => ticket_type_code))
      .map((group) => {
        return {
          ...group[0],
          title: `${group.length}x ${group[0].title}`,
          total_price: group.reduce((acc, ticket) => acc + parseFloat(ticket.total_price), 0)
        }
      })

    return (
      <div className="d-flex justify-content-between vertical-align-center">
        {product.thumbnail &&
          <div className="pr-1 d-none d-md-block">
            <img className="rounded border" width="100%" src={product.thumbnail}/>
            {showStatus &&
              <div className={cn('mt-1 text-center font-weight-bold', `text-${statusColors(status)}`)}><i className={statusIcons(status)}/> {status_label}</div>
            }
          </div>
        }
        <div className="w-100 pl-1">
          <ModulePanel className="module-panel mb-0">
            <div className="d-flex justify-content-between px-3 py-2 w-100 bg-primary text-white font-weight-bold">
              <div>
                {product.title}
              </div>
              {discardEnabled &&
                <div>
                  <i
                    className="fa fa-trash"
                    style={{cursor: 'pointer'}}
                    title={i18n.t('summary_module.discard_reservation')}
                    onClick={onDiscard}
                  />
                </div>
              }
            </div>
            <ModuleBody status="active" className="p-0">
              <ListGroup>
                <ListGroupItem>
                  {order_code &&
                    <dl className="row m-0">
                      <dt className="col-sm-5 px-0 py-1 m-0">{i18n.t('summary_module.order_code')}</dt>
                      <dd className="col-sm-7 px-0 py-1 m-0">{order_code}</dd>
                    </dl>
                  }
                  {order_reference &&
                    <dl className="row m-0">
                      <dt className="col-sm-5 px-0 py-1 m-0">{i18n.t('summary_module.order_reference')}</dt>
                      <dd className="col-sm-7 px-0 py-1 m-0">{order_reference}</dd>
                    </dl>
                  }
                  <dl className="row m-0">
                    {event.type != 'timeless' && eventDate(event.start_date) &&
                      <React.Fragment>
                        <dt className="col-sm-5 px-0 py-1 m-0">{i18n.t('summary_module.event')}</dt>
                        <dd className="col-sm-7 px-0 py-1 m-0">{eventDate(event.start_date)}</dd>
                      </React.Fragment>
                    }
                    {custom_fields && custom_fields.map((props) => ((
                      <React.Fragment key={props.id}>
                        <dt className="col-sm-4 px-0 py-1 m-0">{props.label}</dt>
                        <dd className="col-sm-8 px-0 py-1 m-0">
                          {formatResponse(props)}
                        </dd>
                      </React.Fragment>
                    )))}
                  </dl>
                </ListGroupItem>
                {ticketGroups.map((ticket) => (
                  <ListGroupItem key={ticket.id} className="d-flex justify-content-between">
                    <span>
                      <StatusIcon status={ticket.status}/>
                      {ticket.title}
                      {ticket.customer && ticket.customer.name &&
                        <small className="ml-2 text-muted">({ticket.customer.name})</small>
                      }
                    </span>
                    <span>
                      {currency(ticket.total_price, ticket.currency).format()}
                    </span>
                  </ListGroupItem>
                ))}
                <ListGroupItem color="primary" className="d-flex justify-content-end">
                  <span>
                    <strong>
                      {i18n.t('summary_module.amount', {amount: total_price_label})}
                    </strong>
                  </span>
                </ListGroupItem>
              </ListGroup>
            </ModuleBody>
          </ModulePanel>
        </div>
      </div>
    )
  }

  if (!cart) { return '' }

  const expiryDate = time(cart.expires_at).toDate()

  return (
    <React.Fragment>
      <Card className="p-1">
        <CardBody status="active" className="p-2">
          <h4 className="d-flex justify-content-between" style={{fontSize: '1.2em'}}>
            <div>
              <i className="fa fa-shopping-bag mr-2"/>
              {i18n.t('summary_module.title')}
            </div>

            {discardEnabled && cart.items.length > 1 &&
              <Button
                color="warning"
                className="btn-xs px-2 py-0"
                style={{background: '#fff3d0'}}
                title={i18n.t('summary_module.discard_cart_button_title')}
                onClick={() => fetchDiscardCart(cartParams.urls.discard)}
              >
                <i className="fa fa-trash mr-2"/>{i18n.t('summary_module.discard_cart_button')}
              </Button>
            }
          </h4>

          {cart.items.map((item, i) => {
            return (
              <Row key={item.id}>
                <Col className={cn(cart.items.length == i + 1 ? '' : 'mb-3')}>
                  <Reservation key={item.id} reservation={item} onDiscard={() => {
                    if (cart.items.length > 1) {
                      fetchDiscardReservation(item.id, cartParams.urls.discard_reservation)
                    } else {
                      fetchDiscardCart(cartParams.urls.discard)
                    }
                  }}/>
                </Col>
              </Row>
            )
          })}
        </CardBody>
      </Card>

      <Card className="my-3">
        <CardBody className="d-flex justify-content-between  p-3 font-weight-bold">
          <div style={{fontSize: '1.3em'}}>{i18n.t('summary_module.total_amount')}</div>
          <div style={{fontSize: '1.3em'}}>{cart.final_price_label}</div>
        </CardBody>
      </Card>

      {['open', 'payment_pending', 'payment_failed'].includes(cart.status) &&
        <CartTimer showCard={true} expiryTimestamp={expiryDate}/>
      }
    </React.Fragment>
  )
}

export default SummaryModule
