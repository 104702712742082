import { assign } from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import PriceSummaryCalculator from 'lib/price_summary_calculator'
import {nextStep} from 'utils/step_utils'
import i18n from 'i18n/config'

const stepName  = 'supplementsStep'

const fetchSupplements = (ctx) => (
  new ApiFetcher({
    key: 'ticket_types',
    endpoint: ctx.endpoints.pricing,
    query: {event_id: ctx.event.id}
  }).call()
)

const initSupplements = assign({supplements: (ctx, e) => {
  let tickets = e.data.ticket_types
  return tickets.map((ticket_type) => {
    ticket_type.qty = 0
    return ticket_type
  })
}})
const setSupplements = assign({supplements: (ctx, e) => e.tickets})
const setError = assign({error: i18n.t('validations:supplements_fetch_error')})
const setPriceSummary = assign({priceSummary: (ctx) => {
  let summary = new PriceSummaryCalculator({ticket_types: ctx.supplements}).call()
  const {total_base_price, total_discount, total_additional_fee, total_final_price} = summary

  return Object.assign(ctx.priceSummary, {
    supplementsStep: {
      base_price: total_base_price,
      discount: total_discount,
      additional_fee: total_additional_fee,
      total_price: total_final_price
    }
  })
}})
const cleanError = assign({error: null})

const emptySupplements = (ctx) => ctx.supplements.length <= 0

const haveSupplements = (ctx, e) => {
  const supplements = e.data.ticket_types.filter((item) => item.type == 'supplement')
  if (supplements && supplements.length > 0) {
    return true
  } else {
    return false
  }
}

const supplementsModule = (config) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {
          '': [
            {
              target: 'fetchingSupplements',
              cond: emptySupplements,
              actions: [cleanError, 'startSpinner']
            },
            {
              target: 'waitingSupplementPick'
            }
          ],
        },
      },
      fetchingSupplements: {
        invoke: {
          src: fetchSupplements,
          onDone: [
            {
              target: 'waitingSupplementPick',
              actions: [initSupplements, setPriceSummary, 'stopSpinner'],
              cond: haveSupplements
            },
            {
              target: `#purchase.${nextStep(config, stepName)}`,
              actions: ['stopSpinner'],

            }
          ],
          onError: {
            target: '#purchase.error',
            actions: [setError, 'stopSpinner']
          }
        }
      },
      waitingSupplementPick: {
        onEntry: []
      }
    },
    on: {
      TICKETS_SELECTION: {
        actions: [setSupplements, setPriceSummary, cleanError]
      },
      NEXT_STEP_CLICK: [
        {
          target: nextStep(config, stepName)
        }
      ]
    }
  }
}

export default supplementsModule
