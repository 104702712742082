import React, { useState, Component } from 'react'
import AsyncSelect from 'react-select/async'
import { FormGroup, Label, FormFeedback } from 'reactstrap'
import { useField, useFormikContext } from 'formik'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import ApiFetcher from 'lib/api_fetcher'
import {debounce} from 'lodash'

const apiKey = 'pk.eyJ1Ijoib2NpbyIsImEiOiJjanQ3bTFyOXIwcGk4M3ludmpsYW1qcnphIn0.sGJhvd35mN_KRx_wC6zJVw'

const AddressSelector = ({ label, ...props }) => {
  const name = (props.field && props.field.name) || props.name
  const [field, meta] = useField(name)
  const {setFieldValue} = useFormikContext()
  const { t, i18n } = useTranslation()

  const loadOptions = debounce((inputValue, callback) => {
    new ApiFetcher({
      key: 'address_autocomplete',
      endpoint: '/addresses/autocomplete',
      query: {term: inputValue}
    })
      .call()
      .then((res) => callback(res.response.map((feature) => ({label: feature.description, value: feature.description}))))
  }, 350)

  // const loadOptions = (inputValue, callback) => {
  //   fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${inputValue}.json?limit=10&country=es&access_token=${apiKey}`)
  //     .then(function(response) {
  //       return response.json();
  //     })
  //     .then(function(json) {
  //       callback(json.features.map((feature) => ({label: feature.place_name, value: feature.place_name})))
  //     })
  // }

  return (
    <FormGroup className={ meta.touched && meta.error ? 'has-error' : '' }>
      <Label className='control-label' htmlFor={props.id || props.name}>
        {label}
        {props.required &&
          <span className="text-danger ml-1">*</span>
        }
      </Label>
      <div
        style={{display: 'none'}}
        className={
          cn([
            'form-control',
            meta.touched && meta.error ? 'is-invalid' : '',
            meta.touched && !meta.error ? 'is-valid' : ''
          ])
        }
      >
      </div>
      <AsyncSelect
        placeholder={i18n.t('address_selector.placeholder')}
        cacheOptions
        loadOptions={loadOptions}
        onChange={(selected) => {
          setFieldValue(field.name, selected ? selected.value  : '')
        }}
        noOptionsMessage={() => i18n.t('address_selector.no_options')}
        {...props}
      />
      {meta.touched && meta.error ? (
        <FormFeedback className={'help-block'}>{meta.error}</FormFeedback>
      ) : null}
    </FormGroup>
  )
}

export default AddressSelector
