import React, {useState, useEffect} from 'react'
import { FormGroup, Label, FormFeedback } from 'reactstrap'
import { useField, useFormikContext } from 'formik'
import cn from 'classnames'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'
import time from 'i18n/time'

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    display: block;
  }
`

const InlineDatetimePicker = ({label, ...props }) => {
  const {hideError} = props
  const [field, meta] = useField(props.name)
  const {setFieldValue} = useFormikContext()

  const [selected, setSelected] = useState(props.initialDate)

  useEffect(() => {
    registerLocale('es', es)
  }, [])

  useEffect(() => {
    setFieldValue(props.name, selected)
  }, [selected])

  return (
    <FormGroup>
      <DatePickerWrapper>
        <Label className='control-label' htmlFor={props.id || props.name}>
          {label}
          {props.required &&
            <span className="text-danger ml-1">*</span>
          }
        </Label>
        <ReactDatePicker
          className={
            cn([
              'form-control',
              meta.touched && meta.error ? 'is-invalid' : '',
              meta.touched && !meta.error ? 'is-valid' : ''
            ])
          }
          locale="es"
          selected={ selected }
          onChange={(date) => setSelected(date)}
          dateFormat="Pp"
          timeIntervals={5}
          minDate={props.initialDate || time().toDate()}
          showTimeSelect
          autoComplete={false}
          inline
          {...props}
        />
      </DatePickerWrapper>
      {!hideError && meta.error ? (
        <FormFeedback className={'help-block'}>{meta.error}</FormFeedback>
      ) : null}
    </FormGroup>
  )
}

export default InlineDatetimePicker
