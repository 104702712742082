import { assign } from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import {nextStep} from 'utils/step_utils'
import {EUR} from 'utils/currency'
import i18n from 'i18n/config'

const stepName  = 'lodginStep'

const fetchLodgins = (ctx) => (
  new ApiFetcher({
    key: 'lodgins',
    endpoint: ctx.endpoints.lodgins,
    query: {event_id: ctx.event.id}
  }).call()
)

const fetchPickup = (ctx) => (
  new ApiFetcher({
    key: 'pickup',
    endpoint: ctx.endpoints.pickup,
    query: {event_id: ctx.event.id, lodgin_id: ctx.lodgin.id}
  }).call()
)

const setLodgins = assign({lodgins: (ctx, e) => e.data})
const setLodgin = assign({lodgin: (ctx, e) => e.lodgin})
const setPickup = assign({pickup: (ctx, e) => e.data.pickup})
const setError = assign({error: i18n.t('validations:lodgins_fetch_error')})
const cleanError = assign({error: null})
const setValidationError = assign({error: i18n.t('validations:lodgins_required')})

const emptyLodgin = (ctx) => ctx.lodgin == null
const lodginSelected = (ctx) => ctx.lodgin != null

const lodginsModule = (config) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {
          '': [
            {
              target: 'fetchLodgins',
              cond: emptyLodgin,
              actions: [cleanError, 'startSpinner']
            },
            {
              target: 'waitingLodginPick'
            }
          ],
        },
      },
      fetchLodgins: {
        invoke: {
          src: fetchLodgins,
          onDone: {
            target: 'waitingLodginPick',
            actions: [setLodgins, 'stopSpinner']
          },
          onError: {
            target: '#purchase.error',
            actions: [setError, 'stopSpinner']
          }
        }
      },
      fetchPickup: {
        invoke: {
          src: fetchPickup,
          onDone: {
            target: 'waitingLodginPick',
            actions: [setPickup, 'stopSpinner']
          },
          onError: {
            target: '#purchase.error',
            actions: [setError, 'stopSpinner']
          }
        }
      },
      waitingLodginPick: {}
    },
    on: {
      LODGIN_SELECTION: {
        target: '.fetchPickup',
        actions: [setLodgin, 'startSpinner']
      },
      NEXT_STEP_CLICK: [
        {
          target: nextStep(config, stepName),
          guard: lodginSelected
        },
        {
          actions: [setValidationError]
        }
      ]
    }
  }
}

export default lodginsModule
