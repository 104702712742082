import { assign } from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import {nextStep} from 'utils/step_utils'
import i18n from 'i18n/config'

const stepName  = 'ticketsStep'

const fetchTicketTypes = (ctx) => (
  new ApiFetcher({
    key: 'ticket_types',
    endpoint: ctx.endpoints.pricing,
    query: {event_id: ctx.event.id}
  }).call()
)

const initTickets = assign({tickets: (ctx, e) => {
  let tickets = e.data.ticket_types
  return tickets.map((ticket_type) => {
    ticket_type.qty = 0
    return ticket_type
  })
}})
const setTickets = assign({tickets: (ctx, e) => e.tickets})
const setError = assign({error: i18n.t('validations:tickets_fetch_error')})
const setValidationError = assign({error: i18n.t('validations:at_least_one_ticket')})
const cleanError = assign({error: null})

const emptyTickets = (ctx) => ctx.tickets.length <= 0
const unlessOneTicket = (ctx) => ctx.tickets.map((ticket) => ticket.qty).reduce((accum, current) => accum + current) > 0

const tourcmsRatesState = (config) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {
          '': [
            {
              target: 'fetchingTicketTypes',
              cond: emptyTickets,
              actions: [cleanError, 'startSpinner']
            },
            {
              target: 'waitingTicketPick'
            }
          ],
        },
      },
      fetchingTicketTypes: {
        invoke: {
          src: fetchTicketTypes,
          onDone: {
            target: 'waitingTicketPick',
            actions: [initTickets, 'stopSpinner']
          },
          onError: {
            target: '#purchase.error',
            actions: [setError, 'stopSpinner']
          }
        }
      },
      waitingTicketPick: {
        onEntry: []
      }
    },
    on: {
      TICKETS_SELECTION: {
        actions: [setTickets, cleanError]
      },
      NEXT_STEP_CLICK: [
        {
          target: nextStep(config, stepName),
          cond: unlessOneTicket
        },
        {
          actions: [setValidationError]
        }
      ]
    }
  }
}

export default tourcmsRatesState
