import React, {useState} from 'react'
import Modal from 'ui/modal'
import { useTranslation } from 'react-i18next'
import i18n from 'i18n/config'
import Page from 'ui/page'
import {Col, Card, CardHeader, CardFooter, CardBody, Button} from 'reactstrap'
import SendMerchandisingForm from 'containers/resources/send_merchandising_form'
import mapThumbnail from 'img/resources/map-thumbnail'
import flyerThumbnail from 'img/resources/flyer-thumbnail'
import cn from 'classnames'
import ApiFetcher from 'lib/api_fetcher'
import { jsPDF } from 'jspdf'
import QRCode from 'qrcode'
import {useToasts} from 'react-toast-notifications'
import {get} from 'lodash'

const merchandising = (props) => {
  const {urls, user} = props
  const attachments = user.user_data.client.attachments
  const destinations = user.user_data.user.destinations
  const stand_flyer_settings = get(user, 'user_data.client.attachments.settings.stand_flyer')

  const generateStandFlyer = async (params, outputType = 'blob') => {
    PubSub.publish('loading-page')

    const frontPage = params.stand_flyer_front
    const backPage = params.stand_flyer_back
    const settings = params.settings

    const b2cAccounts = user.user_data.client.b2c_accounts
    let referral

    if (b2cAccounts && b2cAccounts.length > 0) {
      referral = await new ApiFetcher({
        key: 'retrieve_referral',
        body: JSON.stringify({
          user_token: b2cAccounts[0].token,
          modality: 'grid',
          destination: params.destination_id || 'main_destination',
          language: 'en',
          format: 'web'
        }),
        endpoint: urls.retrieve_referral,
        method: 'POST'
      }).call()
    }

    try {
      const doc = new jsPDF()

      var width = doc.internal.pageSize.getWidth()
      var height = doc.internal.pageSize.getHeight()
      doc.addImage(frontPage, 'JPEG', 0, 0, width, height)

      if (referral) {
        let placement = [124, 52, 30, 33]

        // if (params.name == 'Sevilla') {
        //   placement = [135, 235, 30, 33]
        // }

        let options = {
          color: {
            dark: '#000',
            light: '#FFF'
          }
        }

        if (settings) {
          placement = settings.placement

          options = {
            color: {
              dark: settings.color || '#000',
              light: '#FFF'
            }
          }
        }

        const qr = await QRCode.toDataURL(referral.url, options)
        doc.addImage(qr, 'JPEG', ...placement)
      }

      if (backPage) {
        doc.addPage()
        doc.addImage(backPage, 'JPEG', 0, 0, width, height)
      }

      PubSub.publish('loaded-page')

      if (outputType == 'base64') {
        return await readFileAsync(doc)
      } else {
        return await doc.output('bloburl')
      }
    } catch (err) {
      PubSub.publish('loaded-page')
      // addToast(i18n.t('stand_flyer_form.error_generating_pdf'), {appearance: 'error', autoDismiss: true})
    }
  }

  function readFileAsync(doc) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader()

      reader.onload = () => {
        resolve(reader.result.replace('data:application/pdf;base64,', ''))
      }

      reader.onerror = reject

      reader.readAsDataURL(doc.output('blob'))
    })
  }

  const Resource = ({type, id, title, url, thumbnail, attachment, onClick}) => {
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)

    return (
      <Col className="my-2">
        <Card>
          <Modal
            isOpen={modal}
            toggle={toggle}
            width={500}
          >
            <SendMerchandisingForm
              type={type}
              endpoint={props.urls.send_merchandising}
              resourceUrl={url}
              attachment={attachment}
              id={id}
            />
          </Modal>
          <CardBody className="p-0">
            <img
              width="100%"
              height="200"
              src={thumbnail}
              onClick={() => onClick ? onClick() : window.open(url)}
              style={{cursor: 'pointer'}}
            />
          </CardBody>
          <CardFooter className="text-primary d-flex justify-content-between">
            <a href={url} target="_blank" rel="noreferrer">{title}</a>
            <Button size="sm" color="primary" className="text-white" onClick={(e) => {
              e.stopPropagation()
              toggle()
            }}>
              <i className="fa fa-envelope"/>
            </Button>
          </CardFooter>
        </Card>
      </Col>
    )
  }

  const BlockItem = (props) => {
    const {title, children} = props
    const [active, setActive] = useState(props.defaultActive)

    return (
      <Card className="rounded-0 mb-3">
        <CardHeader>
          <div className="d-flex justify-content-between" style={{cursor: 'pointer'}} onClick={() => setActive(!active)}>
            <h5 className="m-0" >{title}</h5>
            <i className={cn(['fa', active ? 'fa-caret-up' : 'fa-caret-down'])}/>
          </div>
        </CardHeader>
        {active &&
          <CardBody>
            {children}
          </CardBody>
        }
      </Card>

    )
  }

  const isClientBlock = attachments.stand_flyer || attachments.map || attachments.items.length > 0
  let clientItemsCount = attachments.items.length
  if (attachments.stand_flyer) { clientItemsCount++ }
  if (attachments.map) { clientItemsCount++ }

  return (
    <Page {...props}>
      <h1 className="mb-3">{i18n.t('merchandising.title')}</h1>

      {isClientBlock &&
        <BlockItem title={`${user.user_data.client.name} (${clientItemsCount})`} defaultActive={true}>
          <div className="row row-cols-4">
            {attachments.stand_flyer &&
              <Resource
                id="stand-flyer"
                title={i18n.t('merchandising.stand_flyer')}
                url={stand_flyer_settings ? null : attachments.stand_flyer}
                attachment={{
                  if (stand_flyer_settings) {
                    return generateStandFlyer({
                      stand_flyer_front: attachments.stand_flyer,
                      settings: stand_flyer_settings
                    }, 'base64')
                  }
                }}
                onClick={() => {
                  if (stand_flyer_settings) {
                    generateStandFlyer({
                      stand_flyer_front: attachments.stand_flyer,
                      settings: stand_flyer_settings
                    })
                      .then((url) => window.open(url, '_blank'))
                  } else {
                    window.open(attachments.stand_flyer)
                  }
                }}
                thumbnail={flyerThumbnail}
              />
            }

            {attachments.map &&
              <Resource
                id="map"
                title={i18n.t('merchandising.map')}
                url={attachments.map}
                thumbnail={mapThumbnail}
              />
            }

            {attachments.items.map((a) =>
              <Resource
                id={a.id}
                key={a.key}
                title={a.label}
                url={a.file}
                thumbnail={a.thumbnail}
              />
            )}
          </div>
        </BlockItem>
      }

      {destinations.filter((d) => (
        d.stand_flyer_front || d.stand_flyer_back || d.flyer_front || d.flyer_back || d.map
      )).map((d, i) => {
        let destinationItemsCount = d.attachments.length
        if (d.stand_flyer_front || d.stand_flyer_back) { destinationItemsCount++ }
        if (d.flyer_front || d.flyer_back) { destinationItemsCount++ }
        if (d.map_url) { destinationItemsCount++ }

        return (
          <BlockItem key={d.id} title={`${d.name} (${destinationItemsCount})`} defaultActive={!isClientBlock && i == 0}>
            <div className="row row-cols-4">
              {(d.stand_flyer_front || d.stand_flyer_back) &&
                <Resource
                  title={i18n.t('merchandising.stand_flyer')}
                  onClick={() => generateStandFlyer(d).then((url) => window.open(url, '_blank'))}
                  thumbnail={flyerThumbnail}
                  attachment={generateStandFlyer(d, 'base64')}
                />
              }

              {(d.flyer_front || d.flyer_back) &&
                <Resource
                  id="flyer"
                  title={i18n.t('merchandising.flyer')}
                  url={d.flyer_front}
                  thumbnail={flyerThumbnail}
                />
              }

              {d.map_url &&
                <Resource
                  id="map"
                  title={i18n.t('merchandising.map')}
                  url={d.map_url}
                  thumbnail={mapThumbnail}
                />
              }

              {d.attachments.map((a) => {
                let url = urls.destination_attachment.replace(/ *\:[id]*\: */g, a.id)
                if (a.params && a.params.class_name == 'Attachments::MiniFlyerProcessor') {
                  url = url + '?qr=generate_referral&destination=' + d.slug
                }
                return (
                  <Resource
                    type={'destination_attachment'}
                    key={a.id}
                    id={a.id}
                    title={a[`label_${i18n.language}`]}
                    url={url}
                    thumbnail={a.thumbnail}
                  />
                )
              })}
            </div>
          </BlockItem>
        )}
      )}
    </Page>
  )
}

export default merchandising
