import React from 'react'
import {ModulePanel, ModuleHeader, ModuleBody, ModuleFooter} from 'containers/purchase/modules/module_panel'
import {Row, Col, Card, CardBody, ListGroupItem} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import {currency} from 'i18n/config'
import styled from 'styled-components'
import Steps, {Step} from 'rc-steps'
import moment from 'moment-timezone'

const Styles = styled.div`
  align-self: center !important;

  input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: white;
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: var(--primary);
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid var(--primary);
    border-radius: 50%;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
  }

  input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--primary);
    /* Windows High Contrast Mode */
    background-color: red;
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }

  input[type="radio"]:focus {
    outline: max(2px, 0.15em) solid var(--primary);
    outline-offset: max(2px, 0.15em);
  }
`

export const QuotationsModule = (props) => {
  const {config, machine, status} = props
  const [current, send] = machine
  const { t, i18n } = useTranslation()
  const {quotation, quotations, ground_info} = current.context

  const onQuotationSeleted = (quotation) => send({type: 'QUOTATION_SELECTION', quotation: quotation})

  const RouteSummary = () => {
    if (!ground_info || ground_info.length == 0 ) { return null }

    const {pickup_point, dropoff_point, pickup_when} = ground_info

    const displayTime = moment.tz(pickup_when, config.product.local_timezone).format('LLLL')

    return (
      <Card>
        <CardBody>
          <Row>
            <Col xs="12" md="5" className="d-flex flex-column justify-content-center align-items-center">
              <div>
                <strong><i className="fa fa-calendar-check mr-1 text-primary"/>
                  {i18n.t('quotations_module.pickup_time')}:</strong> {displayTime}
              </div>
            </Col>
            <Col xs="12" md="7">
              <Steps type="navigation" className={'mb-3'}>
                <Step
                  title={i18n.t('quotations_module.pickup')}
                  description={pickup_point.display_text}
                  icon={<i className="fa fa-map-marker"></i>}
                  status={'finish'}
                />
                <Step
                  title={i18n.t('quotations_module.dropoff')}
                  description={dropoff_point.display_text}
                  icon={<i className="fa fa-map-marker"/>}
                  status={'finish'}
                />
              </Steps>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  return (
    <ModulePanel>
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send('REOPEN_QUOTATIONSSTEP') : null}>
        <span>
          <i className="fa fa-list mr-2" aria-hidden="true"></i> {i18n.t('quotations_module.title')}
        </span>
      </ModuleHeader>
      <ModuleBody status={status}>
        <div className="mb-3">
          <RouteSummary/>
        </div>

        {current.value.quotationsStep == 'fetchingQuotations' &&
          <div className="alert alert-info">
            {i18n.t('quotations_module.fetching_quotations')}
          </div>
        }

        {!quotations && current.value.quotationsStep != 'fetchingQuotations' &&
          <div className="alert alert-warning">
            {i18n.t('quotations_module.fetching_quotations_error')}
          </div>
        }

        {quotations && quotations.length == 0 &&
          <div className="alert alert-info">
            {i18n.t('quotations_module.quotations_not_found')}
          </div>
        }

        {quotations && quotations.map((q, i) => {
          const {cancellation_rates, ground_information, preorder_id, pricing, provider} = q.service_details[0]

          if (!quotation) {
            return null
          }

          const return_policy = cancellation_rates && cancellation_rates[cancellation_rates.length - 1]


          return (
            <ListGroupItem
              key={preorder_id}
              className={cn(
                'rounded-0 d-flex justify-content-between flex-column flex-md-row',
              )}
              style={{cursor: 'pointer', background: quotation.preorder_id == preorder_id ? '#ffeeba' : ''}}
              onClick={() => onQuotationSeleted(q.service_details[0])}
            >

              <div className="d-flex justify-content-between w-100 p-2 pr-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <img width="120" src={ground_information.service_picture}/>
                  </div>
                  <div className="ml-3">
                    <div className="font-weight-bold">
                      {ground_information.car_type_name}
                      <span className="ml-3">
                        <span className="mr-1">{ground_information.max_people}</span>
                        <i className="fa fa-user"/>
                      </span>
                      {ground_information.baggage_allowed.standard_suitcase &&
                        <span className="ml-2">
                          <span className="mr-1">{ground_information.baggage_allowed.standard_suitcase}</span>
                          <i className="fa fa-shopping-bag"/>
                        </span>
                      }
                      {ground_information.baggage_allowed.special &&
                        <span className="ml-2">
                          <span className="mr-1">{ground_information.baggage_allowed.special}</span>
                          <i className="fa fa-suitcase" aria-hidden="true"></i>
                        </span>
                      }
                    </div>
                    <div className={'font-weight-italic'}>
                      {ground_information.car_type_description}
                    </div>
                    {return_policy &&
                      <div className="mt-2 font-italic small">
                        *{i18n.t('quotations_module.refund_policy', {hours: return_policy.hour_range_min, amount: return_policy.amount + '%'})}
                      </div>
                    }
                  </div>
                </div>

                <div className="justify-content-end text-right">
                  {i18n.t('quotations_module.waiting_time_minutes', {minutes: ground_information.waiting_time_minutes})}
                  <i className="ml-2 fa fa-clock" aria-hidden="true"/> <br/>
                  <small>{i18n.t('quotations_module.waiting_time_text')}</small>
                </div>
              </div>
              <div className="d-flex align-self-center flex-column justify-content-center border-left p-2 pl-3 col-3">
                <img width="120" src={provider.logo}></img>
                <div className={'d-flex flex-sm-row mt-3'}>
                  <div className="price text-right mr-5" style={{fontSize: '18px', width: '200'}}>
                    <strong>
                      {i18n.t('quotations_module.total_price')}:
                      <span className="ml-2" style={{fontSize: '1.35em'}}>
                        {currency(pricing.final_price)}
                      </span>
                    </strong>
                  </div>
                  <Styles>
                    <input
                      type="radio"
                      checked={quotation.preorder_id == preorder_id}
                      name="component_key"
                      value={pricing.final_price}
                    />
                  </Styles>
                </div>
              </div>
            </ListGroupItem>
          )
        })}
      </ModuleBody>
      <ModuleFooter {...props}/>
    </ModulePanel>
  )
}

export default QuotationsModule
