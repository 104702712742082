import React, {useContext} from 'react'
import AppContext from 'utils/app_context'

const ProductsGridHeader = (props) => {
  const {destination_id, filters, pagination, onRemoveFilter, onRemoveAllFilters} = props
  const {user} = useContext(AppContext)

  const destination = user.user_data.user.destinations.find((d) => d.id == destination_id || d.slug == destination_id)

  return (
    <div className="d-flex flex-column flex-lg-row pt-1 pb-2">
      <div className="mr-auto text-secondary mb-2 mb-lg-0">
        <span className="h4 mr-2">{destination && destination.name}</span>
        {pagination.total_items > 0 &&
          <strong className="h5">({pagination.total_items})</strong>
        }
      </div>
      {filters && filters.length > 0 &&
        <React.Fragment>
          <div className="grid-filter-items">
            {
              filters.map((filter) => {
                return (
                  <button key={filter.param} data-param={filter.param} onClick={onRemoveFilter} type="button" className="btn btn-sm mr-2 bg-light mb-2 mb-lg-0">
                    {filter.label} <span className="badge badge-light ml-1"><i className="fa fa-times"></i></span>
                  </button>
                )
              })
            }
          </div>
          <div className="remove-all-filters">
            <button onClick={onRemoveAllFilters} type="button" className="btn btn-secondary btn-sm btn-block mb-2 mb-lg-0">
              Quitar filtros <span className="badge badge-light ml-1"><i className="fa fa-times"></i></span>
            </button>
          </div>
        </React.Fragment>
      }
    </div>
  )
}

export default ProductsGridHeader
