import React from 'react'
import {ModulePanel, ModuleHeader, ModuleBody, ModuleFooter} from 'containers/purchase/modules/module_panel'
import { ListGroup, ListGroupItem, Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const VariantModule = (props) => {
  const {machine, status} = props
  const { t, i18n } = useTranslation()
  const [current, send] = machine
  const { variants } = current.context

  const Header = () => {
    return (
      <span>
        <i className="fas fa-ticket-alt mr-2" aria-hidden="true"></i>
        {status == 'completed' ? (
          i18n.t('ticket_types_module.selected_variant', {variant: 'hola'})
        ) : (
          i18n.t('ticket_types_module.select_variant')
        )}
      </span>
    )
  }

  const Body = () => {
    if (variants) {
      return (
        <ListGroup>
          {variants.map(({id, name, meeting_point}) => (
            <ListGroupItem key={id}>
              <div className="d-flex justify-content-between">
                <div>
                  {meeting_point ? (
                    <span>{name} - <small>{meeting_point}</small></span>
                  ) : (
                    <span>{name}</span>
                  )}
                </div>
                <div>
                  <Button
                    color="primary"
                    size="sm"
                    variant_id={id}
                    onClick={(e) => send({type: 'VARIANT_SELECTION', variant: variants.find((v) => v.id == e.target.getAttribute('variant_id'))})}
                  >
                    {i18n.t('ticket_types_module.select_variant_button')}
                  </Button>
                </div>
              </div>
            </ListGroupItem>
          ))}
        </ListGroup>
      )
    } else {
      return 'No variants'
    }
  }

  return (
    <ModulePanel>
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send('REOPEN_VARIANTSTEP') : null}>
        <Header/>
      </ModuleHeader>
      <ModuleBody status={status} className={' '}>
        <Body/>
      </ModuleBody>
      <ModuleFooter {...props}/>
    </ModulePanel>
  )
}

export default VariantModule
