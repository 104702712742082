import React from 'react'

const WhatWeOffer = (props) => {
  return (
    <div>
      <p>Una nueva manera de <strong>generar ingresos</strong> atípicos para el hotel y <strong>dar más servicio</strong> a sus huéspedes.</p>
      <ul>
        <li><p><strong>Facilidad de uso, no requiere instalación</strong> ya que es un servicio web, y <strong>no tiene costes</strong> para el Hotel.</p></li>
        <li><p>Agilidad, transparencia y legalidad en la liquidación de <strong>comisiones</strong>.</p></li>
        <li><p>Servicio disponible 365 días al año, 24 horas al día, con <strong>confirmación inmediata de disponibilidad</strong>.</p></li>
      </ul>
      <p>Nuestro sistema permite la <strong> integración</strong> con la web del hotel y crear packs de producto de noches de hotel o servicios de hotel con los productos de nuestro catálogo.</p>
      <ul>
        <li>
          <p>Todos los productos y servicios que se comercializan pasan un control de calidad y de evaluación por nuestro equipo de especialistas en actividades de ocio, eventos y restauración.</p>
        </li>
      </ul>

    </div>
  )
}

export default WhatWeOffer
