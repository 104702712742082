import React from 'react'
import {useTranslation} from 'react-i18next'
import {isMobile} from 'react-device-detect'

const CitiesFilter = (props) => {
  const {machine, suppliers, cities} = props
  const [current, send] = machine
  const {i18n} = useTranslation()

  const cityFilterd = current.context.filters.find((filter) => filter.param == 'city')
  const cityFilterValue = cityFilterd && cityFilterd.value

  const onCityFilter = (city) => {
    if (cityFilterValue == city.value) {
      send({type: 'FILTER_REMOVE', param: 'city'})
    } else {
      send({type: 'FILTER_SELECTION', filter: {
        param: 'city',
        label: city.label,
        value: city.value
      }})
    }

    if (isMobile) {
      props.setIsOpen(!props.isOpen)
    }
  }

  return (
    <React.Fragment>
      {cities.map((city) => (
        <div key={city.value}>
          <input
            type={'radio'}
            className="mr-2"
            value={city.value == cityFilterValue ? true : false}
            checked={city.value == cityFilterValue ? true : false}
            style={{cursor: 'pointer'}}
            onClick={() => onCityFilter(city)}
          />
          <label onClick={() => onCityFilter(city)} style={{cursor: 'pointer'}}>
            {city.label}
          </label>
        </div>
      ))}
    </React.Fragment>

  )
}

export default CitiesFilter
