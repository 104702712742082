import {useState, useEffect, useCallback} from 'react'
import tableFetch from 'lib/table_fetcher'

const useTableFetch = (url) => {
  const [table, setTable] = useState(null)
  const [query, setQuery] = useState(false)
  const [data, setData] = useState({records: [], filter: [], sort: [], pagination: {current_page: 1, per_page: 30}, summary: {}})
  const [loading, setLoadingTable] = useState(false)
  const [isRefresh, setIsRefresh] = useState()
  const refresh = useCallback(() => setIsRefresh({}), [])

  const cacheKey = 'table.' + url

  useEffect(() => {
    query ? tableFetch(url, query, setData, setLoadingTable, cacheKey) : undefined

    if (typeof query === 'object') {
      localStorage.setItem(cacheKey, JSON.stringify(query))
    }
  }, [query, isRefresh])

  useEffect(() => {
    // const cache = JSON.parse(localStorage.getItem(cacheKey))

    const defaultParams = {page: 1, per_page: 30, sort: ''}

    doFetch(defaultParams)
  }, [])

  const doFetch = (query) => {
    if (typeof query === 'undefined') {
      refresh()
    } else {
      setQuery(query)
    }
  }

  return {query, data, loading, table, setTable, doFetch}
}

export default useTableFetch
