const cssVariableKeys = [
  'blue',
  'indigo',
  'purple',
  'pink',
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'cyan',
  'white',
  'gray',
  'gray-dark',
  'primary',
  'secondary',
  'success',
  'info',
  'warning',
  'danger',
  'light',
  'dark',
  'breakpoint-xs',
  'breakpoint-sm',
  'breakpoint-md',
  'breakpoint-lg',
  'breakpoint-xl',
  'breakpoint-xxl',
  'font-family-sans-serif',
  'font-family-monospace'
]

const cssVariables = cssVariableKeys.map((key) => {
  return {
    name: key,
    value: window.getComputedStyle(document.documentElement).getPropertyValue('--' + key),
    set: (value) => document.documentElement.style.setProperty('--' + key, value)
  }
})

export default cssVariables
