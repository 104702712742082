import React from 'react'
import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'
import UrlManager from 'lib/url_manager'
import PubSub from 'pubsub-js'

export default class RangeSlider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: { min: 200, max: 700 },
    }
  }

  onChangeComplete(value) {
    UrlManager.addSearch('price_max', value.max)
    UrlManager.addSearch('price_min', value.min)
    PubSub.publish('product-grid-filter')
  }

  render() {
    return (
      <InputRange
        maxValue={1000}
        minValue={0}
        value={this.state.value}
        onChange={value => this.setState({ value })}
        onChangeComplete={value => this.onChangeComplete(value)} />
    )
  }
}
