import {assign} from 'xstate'
import moment from 'moment'
import {nextStep} from 'utils/step_utils'
import i18n from 'i18n/config'

const stepName  = 'dayStep'

const setDay = assign({day: (ctx, e) => moment(e.day).format('YYYY-MM-DD')})

const dayState = (config) => {
  return {
    initial: 'waitingDayPick',
    states: {
      waitingDayPick: {}
    },
    on: {
      DAY_SELECTION: {
        target: '#purchase.' + nextStep(config, stepName),
        actions: [setDay]
      },
      MONTH_CHANGE: {
        actions: [() => console.log('TODO: MONTH CHANGE')]
      }
    }
  }
}

export default dayState
