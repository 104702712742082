import React, {useState, useContext} from 'react'
import FilterItem from 'containers/list/products/filter_item'
import CategoriesFilter from 'containers/list/products/filters/categories_filter'
import SuppliersFilter from 'containers/list/products/filters/suppliers_filter'
import StatusFilter from 'containers/list/products/filters/status_filter'
import CitiesFilter from 'containers/list/products/filters/cities_filter'
import renameKeys from 'deep-rename-keys'
import {useTranslation} from 'react-i18next'
import {isBrowser} from 'react-device-detect'
import AppContext from 'utils/app_context'

const ProductsListSidebar = (props) => {
  const {machine, categories, suppliers, cities, initialCategory, filterCities} = props
  const {i18n} = useTranslation()
  const [categoryIsOpen, setCategoryIsOpen] = useState(isBrowser)
  const [supplierIsOpen, setSupplierIsOpen] = useState(isBrowser)
  const [statusIsOpen, setStatusIsOpen] = useState(isBrowser)

  const {user} = useContext(AppContext)
  const isB2b = user.user_data.user.sale_channel == 'b2b'

  return (
    <React.Fragment>
      <FilterItem
        title={i18n.t('products_list_sidebar.categories_filter_title')}
        wrapperClass="mb-3 border-0 shadow-sm" bodyClass="p-0"
        isOpen={categoryIsOpen}
        setIsOpen={setCategoryIsOpen}
      >
        <CategoriesFilter
          machine={machine}
          treeData={
            renameKeys(categories, (key) => {
              if (key === 'id') return 'key'
              if (key === 'title') return 'label'
              if (key === 'children') return 'nodes'
              return key
            })
          }
          initialCategory={initialCategory}
          isOpen={categoryIsOpen}
          setIsOpen={setCategoryIsOpen}
        />
      </FilterItem>

      {filterCities &&
        <FilterItem
          title={i18n.t('products_list_sidebar.cities_filter_title')}
          isOpen={statusIsOpen}
          setIsOpen={setStatusIsOpen}
        >
          <CitiesFilter
            machine={machine}
            isOpen={statusIsOpen}
            setIsOpen={setStatusIsOpen}
            cities={cities}
          />
        </FilterItem>
      }

      {false &&
        <FilterItem
          title={i18n.t('products_list_sidebar.status_filter_title')}
          isOpen={statusIsOpen}
          setIsOpen={setStatusIsOpen}
        >
          <StatusFilter
            machine={machine}
            isOpen={statusIsOpen}
            setIsOpen={setStatusIsOpen}
          />
        </FilterItem>
      }

      {isB2b &&
        <FilterItem
          title={i18n.t('products_list_sidebar.suppliers_filter_title')}
          isOpen={supplierIsOpen}
          setIsOpen={setSupplierIsOpen}
        >
          <SuppliersFilter
            machine={machine}
            suppliers={suppliers}
            isOpen={supplierIsOpen}
            setIsOpen={setSupplierIsOpen}
          />
        </FilterItem>
      }

    </React.Fragment>
  )
}

export default ProductsListSidebar
