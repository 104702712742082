import React from 'react'
import {ModulePanel, ModuleHeader, ModuleBody, ModuleFooter} from 'containers/purchase/modules/module_panel'
import ContactForm from 'containers/purchase/modules/contact_module/contact_form'
import { useTranslation } from 'react-i18next'

export const ContactModule = (props) => {
  const {machine, status} = props
  const { t, i18n } = useTranslation()
  const [current, send] = machine

  const setForm = (form) => {
    send({type: 'CONTACT_FORM_CHANGE', form: form})
  }

  return (
    <ModulePanel>
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send('REOPEN_CONTACTFORMSTEP') : null}>
        <span>
          <i className="fa fa-user mr-2" aria-hidden="true"></i>
          {i18n.t('contact_module.contact_title')}
        </span>
      </ModuleHeader>
      <ModuleBody status={status}>
        <ContactForm setForm={setForm}/>
      </ModuleBody>
      <ModuleFooter {...props}/>
    </ModulePanel>
  )
}

export default ContactModule
