import moment from 'moment-timezone'
import momentDurationFormatSetup from 'moment-duration-format'
import i18n from 'i18n/config'

let time = moment
momentDurationFormatSetup(time)

time.locale(i18n.language)

export default time
