import React, { useState } from 'react'
import Helmet from 'react-helmet'
import Modal from 'ui/modal'
import DayPicker, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import { Button, ButtonGroup } from 'reactstrap'
import MomentLocaleUtils from 'react-day-picker/moment'
import 'moment/locale/es'
import moment from 'moment'
import {isEqual} from 'lodash'
import { useTranslation } from 'react-i18next'

const DateRangeFilter = (props) => {
  const {doFetch, state, serverResponse, column} = props
  const { t, i18n } = useTranslation()

  const param = column.filterParam || column.id

  // const [defaultFrom, setDefaultFrom] = useState(null)
  // const [defaultTo, setDefaultTo] = useState(null)
  const defaultFrom = moment().startOf('month').toDate()
  const defaultTo = moment().endOf('month').toDate()
  const [from, setFrom] = useState()
  const [to, setTo] = useState()
  const [enteredTo, setEnteredTo] = useState(defaultTo)
  const [modal, setModal] = useState(false)

  const modifiers = { start: from, end: enteredTo }
  const selectedDays = [from, { from, to: enteredTo }]

  const toggle = () => setModal(!modal)

  const isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from)
    const isRangeSelected = from && to
    return !from || isBeforeFirstDay || isRangeSelected
  }

  const onChange = (from, to) => {
    const newfilters = state.filters
      .filter((filter) => filter.id != param)
      .concat([{id: param, value: {from: from, to: to}}])

    doFetch((query) => {
      return {...query, page: 1, filter: JSON.stringify(newfilters)}
    })
  }

  const handleDayClick = (day) => {
    if (isSelectingFirstDay(from, to, day)) {
      setFrom(day)
      setTo(null)
      setEnteredTo(null)
    } else {
      setTo(day)
      setEnteredTo(day)
      toggle()
      onChange(from, day)
    }
  }

  const handleDayMouseEnter = (day) => {
    if (!isSelectingFirstDay(from, to, day)) {
      setEnteredTo(day)
    }
  }

  const handleRemoveFilter = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    setFrom(null)
    setTo(null)
    setEnteredTo(null)
    onChange(defaultFrom, defaultTo)
  }

  const handlePreset = (from, to) => {
    setFrom(from)
    setEnteredTo(to)
    setTo(to)
    toggle()
    onChange(from, to)
  }

  const filteringLabel = () => {
    if (!from && !to) {
      return i18n.t('date_range_picker.select_from')
    } else if (from && !to) {
      return i18n.t('date_range_picker.select_to')
    } else if (from && to) {
      return i18n.t('date_range_picker.filtering_from_to', {from: from.toLocaleDateString(), to: to.toLocaleDateString(), interpolation: { escapeValue: false } })
    }
  }

  return (
    <React.Fragment>
      <Button
        title={filteringLabel()}
        onClick={toggle}
        style={{width: '100%', height: '100%', padding: '0px'}}
        className={from && to ? 'btn-info' : ''}
      >
        <i className={'far fa-calendar-alt'}/>
        {from && to &&
          <Button tag={'span'} className="pr-1" onClick={handleRemoveFilter} close style={{position: 'absolute', color: 'white', opacity: 0.8, right: '5px'}}/>
        }
      </Button>
      <Modal isOpen={modal} toggle={toggle} title={filteringLabel()} width={850} bodyStyle={{padding: '35px'}}>
        <div className="d-inline-flex align-items-center">
          <div className="mr-3">
            <ButtonGroup vertical>
              <Button style={{padding: '9px 10px'}} className="text-white" color="primary">{i18n.t('date_range_picker.custom_interval')}</Button>
              <Button style={{padding: '9px 30px'}} className="text-white" onClick={() => handlePreset(moment().startOf('day').toDate(), moment().endOf('day').toDate())} color="primary">{i18n.t('date_range_picker.today')}</Button>
              <Button style={{padding: '9px 30px'}} className="text-white" onClick={() => handlePreset(moment().subtract(1, 'day').startOf('day').toDate(), moment().subtract(1, 'day').endOf('day').toDate())} color="primary">{i18n.t('date_range_picker.yesterday')}</Button>
              <Button style={{padding: '9px 30px'}} className="text-white" onClick={() => handlePreset(moment().startOf('week').toDate(), moment().endOf('week').toDate())} color="primary">{i18n.t('date_range_picker.this_week')}</Button>
              <Button style={{padding: '9px 30px'}} className="text-white" onClick={() => handlePreset(moment().startOf('month').toDate(), moment().endOf('month').toDate())} color="primary">{i18n.t('date_range_picker.this_month')}</Button>
              <Button style={{padding: '9px 30px'}} className="text-white" onClick={() => handlePreset(moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate())} color="primary">{i18n.t('date_range_picker.last_month')}</Button>
            </ButtonGroup>
          </div>
          <div>
            <DayPicker
              className='Range'
              numberOfMonths={2}
              month={from}
              selectedDays={selectedDays}
              modifiers={modifiers}
              onDayClick={handleDayClick}
              onDayMouseEnter={handleDayMouseEnter}
              localeUtils={MomentLocaleUtils}
              locale={'es'}
            />
            <Helmet>
              <style>{`
                // .Range {
                //   font-size: 15px;
                //   height: 288px;
                // }

                .DayPicker-wrapper {
                  padding-bottom: 0em;
                }

                .DayPicker-Month {
                  margin: 0 1em;
                }

                .Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                  background-color: #f0f8ff !important;
                  color: #4a90e2;
                }
                .Range .DayPicker-Day {
                  border-radius: 0 !important;
                }
              `}</style>
            </Helmet>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default DateRangeFilter
