const statusColors = (value) => {
  switch (value) {
  case 'confirming':
    return 'success'
  case 'confirmed':
    return 'success'
  case 'discarding':
    return 'danger'
  case 'discarded':
    return 'danger'
  case 'failed':
    return 'danger'
  case 'cancellation_pending':
    return 'danger'
  case 'cancelled':
    return 'danger'
  case 'human_processing_pending':
    return 'info'
  case 'checking_out':
    return 'info'
  case 'checked_out':
    return 'info'
  }
}

const statusIcons = (value) => {
  switch (value) {
  case 'confirmed':
    return 'fa fa-check'
  case 'discarding':
    return 'fa fa-trash'
  case 'discarded':
    return 'fa fa-trash'
  case 'failed':
    return 'fa fa-times'
  case 'cancellation_pending':
    return 'fa fa-exclamation-circle'
  case 'cancelled':
    return 'fa fa-ban'
  case 'human_processing_pending':
    return 'fa fa-hourglass-half'
  case 'payment_pending':
    return 'fas fa-money-bill-alt'
  case 'payment_failed':
    return 'fas fa-money-bill-alt'
  }
}

export {statusColors, statusIcons}
