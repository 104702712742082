import { assign } from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import {nextStep} from 'utils/step_utils'
import { ListGroup, ListGroupItem, Button } from 'reactstrap'
import i18n from 'i18n/config'

const stepName  = 'variantsStep'

const fetchVariants = (ctx) => (
  new ApiFetcher({
    key: 'child_products',
    endpoint: ctx.endpoints.variants,
    query: {product_group_id: ctx.product_group_id}
  }).call()
)

const initVariants = assign({variants: (ctx, e) => e.data})
const setError = assign({error: i18n.t('validations:variants_fetch_error')})
const setValidationError = assign({error: i18n.t('validations:at_least_one_ticket')})
const setVariant = assign({variant: (ctx, e) => e.variant})
const cleanError = assign({error: null})

const variantsState = (config) => {
  console.log(nextStep(config, stepName))
  return {
    initial: 'init',
    states: {
      init: {
        on: {
          '': [
            {
              target: 'fetchingTicketTypes',
              actions: [cleanError, 'startSpinner']
            }
          ],
        },
      },
      fetchingTicketTypes: {
        invoke: {
          src: fetchVariants,
          onDone: {
            target: 'waitingVariantPick',
            actions: [initVariants, 'stopSpinner']
          },
          onError: {
            target: '#purchase.error',
            actions: [setError, 'stopSpinner']
          }
        }
      },
      waitingVariantPick: {
        onEntry: []
      }
    },
    on: {
      VARIANT_SELECTION: [
        // actions: [setVariant, cleanError],
        {
          actions: [setVariant, cleanError],
          target: nextStep(config, stepName)
        }

      ]
    }
  }
}

export default variantsState
