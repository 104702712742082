import currency from 'currency.js'
import i18n from 'i18n/config'

const EUR = value => currency(value, {
  symbol: '€',
  pattern: i18n.exists('format:currency.pattern') ? i18n.t('format:currency.pattern') : '#!',
  formatWithSymbol: true,
  separator: i18n.exists('format:currency.separator') ? i18n.t('format:currency.separator') : '.',
  decimal: i18n.exists('format:currency.decimal') ? i18n.t('format:currency.decimal') : ',',
})

const MXN = value => currency(value, {
  symbol: '$',
  pattern: i18n.exists('format:currency.pattern') ? i18n.t('format:currency.pattern') : '!#' ,
  formatWithSymbol: true,
  separator: i18n.exists('format:currency.separator') ? i18n.t('format:currency.separator') : ',',
  decimal: i18n.exists('format:currency.decimal') ? i18n.t('format:currency.decimal') : '.',
})

const currencyDefinition = (amount, cur) => {
  if (cur == 'EUR') {
    return EUR(amount)
  } else if (cur == 'MXN') {
    return MXN(amount)
  } else {
    return EUR(amount)
  }
}

export default currencyDefinition
export {EUR, MXN}
