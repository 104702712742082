import React from 'react'
import {ModulePanel, ModuleHeader, ModuleBody, ModuleFooter} from 'containers/purchase/modules/module_panel'
import CalendarPicker from 'components/calendar_picker'
import styled from 'styled-components'
import moment from 'moment'
import time from 'i18n/time'
import { useTranslation } from 'react-i18next'

const CalendarWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const EventModule = (props) => {
  const {config, machine, status} = props
  const { t, i18n } = useTranslation()
  const [current, send] = machine
  const { initialDate, locale } = config
  const { ready, days_available, day, event } = current.context

  return (
    <ModulePanel>
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send('REOPEN_DAYSTEP') : null}>
        {ready && day ? (
          <React.Fragment>
            <div>
              <i className="far fa-calendar-alt mr-2" aria-hidden="true"></i>
              <span>{time(day).format('LL')}</span>
            </div>
            {event && event.show_time == true &&
              <div>
                <i className="far fa-clock mr-2" aria-hidden="true"></i>
                <span>{event.time}</span>
              </div>
            }
          </React.Fragment>
        ) : (
          i18n.t('day_module.select_a_day')
        )}
      </ModuleHeader>
      <ModuleBody status={status}>
        <CalendarWrapper>
          <CalendarPicker
            initialDay={initialDate}
            onDaySelected={(day) => send({type: 'DAY_SELECTION', day: day})}
            onMonthChange={(date) => send({type: 'MONTH_CHANGE', year_month: moment(date).format('YYYY-MM')})}
            daysAvailable={days_available}
            locale={locale}
          />
        </CalendarWrapper>
      </ModuleBody>
      <ModuleFooter {...props}/>
    </ModulePanel>
  )
}

export default EventModule
