import React from 'react'
import {ModulePanel, ModuleHeader, ModuleBody, ModuleFooter} from 'containers/purchase/modules/module_panel'
import PickupsList from 'containers/purchase/pickups_list'
import { useTranslation } from 'react-i18next'

export const PickupModule = (props) => {
  const {machine, status} = props
  const [current, send] = machine
  const { pickups, pickup } = current.context
  const { t, i18n } = useTranslation()

  return (
    <ModulePanel>
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send('REOPEN_LCTPICKUPSTEP') : null}>
        <span>
          <i className="fa fa-map-marker mr-2" aria-hidden="true"></i>
          {status == 'completed' ? (
            i18n.t('pickup_module.pickup_selected', {pickup: pickup.name})
          ) : (
            i18n.t('pickup_module.select_pickup')
          )}
        </span>
      </ModuleHeader>
      <ModuleBody status={status}>
        {pickups && pickups.length > 0 &&
          <PickupsList
            data={pickups}
            onPickupSelected={(pickup) => send({type: 'PICKUP_SELECTION', pickup: pickup})}
            context="other"
          />
        }
      </ModuleBody>
      <ModuleFooter {...props}/>
    </ModulePanel>
  )
}

export default PickupModule
