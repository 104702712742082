import React, {useState, useEffect} from 'react'
import {ModulePanel, ModuleHeader, ModuleBody, ModuleFooter} from 'containers/purchase/modules/module_panel'
import { useTranslation } from 'react-i18next'
import {currentStep} from 'utils/step_utils'
import {get} from 'lodash'
import cn from 'classnames'
// import {initializeWidget} from './onebox_lib'

export const OneboxIframeModule = (props) => {
  const {machine, status} = props
  const [current, send] = machine
  const {iframeLink} = current.context
  const { t, i18n } = useTranslation()
  const [widgetHeight, setWidgetHeight] = useState('1500px')

  const currentStep = get(current.value, 'iframeStep')
  const productId = get(props, 'config.product.external_api_config.product_id')
  const eventId = get(current.context, 'event.id')
  const token = get(current.context, 'event.params.token')

  useEffect(() => {
    if (eventId) {
      _onebox.moduleLoader.loadWidget('widget-container')

      pubsub.subscribe('/cart/updated', (e) => {
        send('CART_UPDATED', e.data)
      })
    }
  }, [eventId])

  return (
    <ModulePanel>
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send('REOPEN_IFRAMESTEP') : null}>
        <span>
          <i className="fa fa-map-marker mr-2" aria-hidden="true"></i>
          {i18n.t('janto_iframe_module.select_ticket_types')}
        </span>
      </ModuleHeader>
      <ModuleBody status={status} className={cn('border', 'border-top-0', 'rounded-bottom')}>
        <div style={{overflow: 'hidden'}}>
          {eventId &&
            <div id="widget-container"
              className="ob-widget"
              data-module="ob-venue-widget"
              data-version="2"
              data-channel="ociobusiness"
              data-eventid={productId}
              data-sessionid={eventId}
              data-language="es_ES"
              data-activatesales="true"
              data-forceview="1"
              data-token={token}
              data-showsummary="true"
            />
          }
        </div>
      </ModuleBody>
      <ModuleFooter {...props}/>
    </ModulePanel>
  )
}

export default OneboxIframeModule
