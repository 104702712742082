import ApiFetcher from 'lib/api_fetcher'

const addFavorite = (product, successCallback) => {
  PubSub.publish('loading-page')

  return new ApiFetcher({
    key: 'add_favorite',
    method: 'POST',
    endpoint: `/products/${product}/add_favorite`
  }).call()
    .then(() => {
      successCallback()
      // send({type: 'REFRESH'})
      // addToast('Producto añadido a favoritos', {appearance: 'success', autoDismiss: true})
    })
    .finally(() => PubSub.publish('loaded-page'))
}

export default addFavorite
