import styled from 'styled-components'

const PurchaseWrapper = styled.div.attrs({
  id: 'purchase-wrapper'
})`
  ${props => props.mode == 'b2c' && `
    width: 100%;
    margin: auto;
    // max-width: 1140px;
    // padding: 20px 0px;
  `}
`

export default PurchaseWrapper
