import React, {useState} from 'react'
import Page from 'ui/page'
import { useTranslation } from 'react-i18next'
import AsyncTable from 'ui/async_table'
import {ButtonGroup, Button} from 'reactstrap'
import useTableFetch from 'utils/hooks/use_table_fetch'
import tableOptions from './sales_grid_options'
import {currency} from 'i18n/config'
import time from 'i18n/time'
import {assign} from 'lodash'

const Sales = (props) => {
  const {user, urls} = props
  const {i18n} = useTranslation()

  const tableFetch = useTableFetch(urls.grid)
  const {data, doFetch, loading} = tableFetch
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const date_filter = data.filter.find((f) => f.id == 'orders.order_date')

  return (
    <Page {...props} style={{maxWidth: '1750px'}}>
      <div className="d-flex justify-content-between align-items-center my-3">
        <div>
          <h1>
            {i18n.t('sales.title')}
          </h1>
          {!loading &&
            <div className="">
              <span>
                {i18n.t('sales.date_interval', {from: time(date_filter && date_filter.value.from).format('ll'), to: time(date_filter && date_filter.value.to).format('ll')})}
              </span>
              <span  className="mx-3">-</span>
              <span>
                {i18n.t('sales.total_records', {count: data.pagination.total_items})}
              </span>
            </div>
          }
        </div>
        <div>
          <div>
            <ButtonGroup size="sm">
              <Button color="info">
                <input className="text rounded border-0 px-2 py-1" placeholder={i18n.t('sales.search_placeholder')} style={{width: '22em'}}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.persist()
                      doFetch((query) => {
                        return {...assign(query, {search: e.target.value})}
                      })
                    }
                  }}
                />
              </Button>
              <Button color="info" onClick={() => doFetch()}>
                {i18n.t('sales.refresh_table')} <i className="fas fa-sync-alt"/>
              </Button>
              <Button color="info" onClick={() => doFetch({})}>
                {i18n.t('sales.remove_filters')} <i className="fa fa-eraser"/>
              </Button>
            </ButtonGroup>
          </div>
          <div>
            <ul className="list-group list-group-horizontal text-right">
              <li className="list-group-item flex-fill">
                <div>{i18n.t('sales.total_sales')}</div>
                <div className="h5">{data.summary.total_sales ? currency(data.summary.total_sales) : '-'}</div>
              </li>
              <li className="list-group-item flex-fill">
                <div>{i18n.t('sales.tickets_sold')}</div>
                <div className="h5">{data.summary.total_tickets ? data.summary.total_tickets : '-'}</div>
              </li>
              {user.user_data.user.can_see_commissions &&
                <li className="list-group-item flex-fill">
                  <div>{i18n.t('sales.total_commission')}</div>
                  <div className="h5">{data.summary.total_commission ? currency(data.summary.total_commission) : '-'}</div>
                </li>
              }
            </ul>
          </div>
        </div>
      </div>

      <AsyncTable
        {...tableFetch}
        tableOptions={tableOptions}
        params={{
          user: user,
          urls: urls
        }}
      />
    </Page>
  )
}

export default Sales
