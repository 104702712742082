import EventModule from 'containers/purchase/modules/event_module/event_module'
import DayModule from 'containers/purchase/modules/day_module/day_module'
import TicketTypesModule from 'containers/purchase/modules/ticket_types_module/ticket_types_module'
import AccessModule from 'containers/purchase/modules/access_module/access_module'
import eventState from 'containers/purchase/modules/event_module/event_state'
import dayState from 'containers/purchase/modules/day_module/day_state'
import ticketTypesState from 'containers/purchase/modules/ticket_types_module/ticket_types_state'
import accessState from 'containers/purchase/modules/access_module/access_state'

const config = (params) => {
  const mode = params.external_api_config && params.external_api_config.mode
  const filterSessionGroups = params.external_api_config && params.external_api_config.filterSessionGroups

  const reservationMapper = (ctx) => {
    if (mode == 'session') {
      const session_groups = ctx.selection_groups.map((sg) => ({
        id: sg.id,
        access_date: sg.access_date,
        session: sg.session
      })).sort((a, b) => {
        if (a.access_date > b.access_date) {
          return 1
        }
        if (a.access_date < b.access_date) {
          return -1
        }

        return 0
      })

      return {
        event_id: session_groups[0].session,
        ticket_types: ctx.tickets,
        params: {
          session_groups: session_groups
        }
      }
    } else if (mode == 'event') {
      return {
        access_date: ctx.event.time,
        event_id: ctx.event.id,
        ticket_types: ctx.tickets
      }
    } else {
      return {
        event_id: ctx.event.id,
        ticket_types: ctx.tickets
      }
    }
  }

  let steps = {}

  if (mode == 'event') {
    steps['eventStep'] = {
      component: EventModule,
      autoNext: true,
      states: eventState,
      context: {
        year_month: params.initialYearMonth,
        event_groups: [],
        event: null
      }
    }
  } else {
    steps['dayStep'] = {
      component: DayModule,
      autoNext: true,
      states: dayState,
      context: {
        year_month: params.initialYearMonth,
        day: params.initialDate,
        event_groups: [],
        event: null
      }
    }
  }

  steps['ticketsStep'] = {
    component: TicketTypesModule,
    autoNext: false,
    states: ticketTypesState,
    context: {
      tickets: []
    }
  }

  if (mode == 'session') {
    steps['accessStep'] = {
      component: AccessModule,
      autoNext: false,
      states: accessState,
      context: {        
        selection_groups: [],
        filterSessionGroups: filterSessionGroups
      }
    }
  }

  return {
    reservationMapper: reservationMapper,
    steps: steps
  }
}

export default config
