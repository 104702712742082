import React, {useEffect} from 'react'
import { Media } from 'reactstrap'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'

const LodginList = ({data, onLodginSelected}) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    onLodginSelected(data[0])
  }, [])

  const formatOptionLabel = (lodgin, {context}) => {
    if (context == 'menu') {
      return (
        <div className="d-flex justify-content-between">
          <div>
            {lodgin.name}
            {lodgin.distance &&
              <small className="ml-2">
                {lodgin.distance}
                <a className="ml-2" href={lodgin.gmap} target="_blank" rel='noreferrer noopener'>({i18n.t('lodgin_list.map_link')})</a>
              </small>
            }
          </div>
        </div>
      )
    } else {
      return (
        <div className="d-flex justify-content-between">
          <div>{lodgin.name}</div>
          {lodgin.distance &&
            <React.Fragment>
              <div className="pl-2 pr-2"><small>{lodgin.distance}</small></div>
              <div><small><a href={lodgin.gmap} target="_blank" rel='noreferrer noopener'>({i18n.t('lodgin_list.map_link')})</a></small></div>
            </React.Fragment>
          }
        </div>
      )
    }
  }

  return <Select
    onChange={(lodgin) => onLodginSelected(lodgin)}
    formatOptionLabel={formatOptionLabel}
    options={data}
    defaultValue={data[0]}
  />
}

export default LodginList
