import { assign } from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import {nextStep} from 'utils/step_utils'
import {EUR} from 'utils/currency'
import i18n from 'i18n/config'

const stepName  = 'pickupStep'

const fetchPickups = (ctx) => (
  new ApiFetcher({
    key: 'pickups',
    endpoint: ctx.endpoints.pickups,
    query: {event_id: ctx.event.id}
  }).call()
)

const setPickups = assign({pickups: (ctx, e) => e.data})
const setPickup = assign({pickup: (ctx, e) => e.pickup})
const setError = assign({error: i18n.t('validations:pickups_fetch_error')})
const cleanError = assign({error: null})
const setValidationError = assign({error: i18n.t('validations:pickups_required')})
const setPriceSummary = assign({priceSummary: (ctx) => {
  let pickupPrice = 0

  if (ctx.pickup && ctx.pickup.applicable_tickets) {
    pickupPrice = ctx.tickets.filter((ticket) => ticket.qty > 0).map((ticket) => {
      if (ctx.pickup.applicable_tickets.includes(ticket.id)) {
        return ticket.qty * ctx.pickup.price
      } else {
        return 0
      }
    }).reduce((accum, current) => accum + current)
  }

  return Object.assign(ctx.priceSummary, {
    pickupStep: {
      base_price: pickupPrice,
      discount: EUR(0),
      additional_fee: EUR(0),
      total_price: pickupPrice
    }
  })
}})

const emptyPickup = (ctx) => ctx.pickup == null
const pickupSelected = (ctx) => ctx.pickup != null

const pickupsModule = (config) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {
          '': [
            {
              target: 'fetchingPickups',
              cond: emptyPickup,
              actions: [cleanError, 'startSpinner']
            },
            {
              target: 'waitingPickupPick'
            }
          ],
        },
      },
      fetchingPickups: {
        invoke: {
          src: fetchPickups,
          onDone: {
            target: 'waitingPickupPick',
            actions: [setPickups, setPriceSummary, 'stopSpinner']
          },
          onError: {
            target: '#purchase.error',
            actions: [setError, 'stopSpinner']
          }
        }
      },
      waitingPickupPick: {}
    },
    on: {
      PICKUP_SELECTION: {
        actions: [setPickup, setPriceSummary]
      },
      NEXT_STEP_CLICK: [
        {
          target: nextStep(config, stepName),
          guard: pickupSelected
        },
        {
          actions: [setValidationError]
        }
      ]
    }
  }
}

export default pickupsModule
