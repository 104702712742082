import React from 'react'
import { Row, Col, Collapse, Button } from 'reactstrap'
import cn from 'classnames'
import {currentStep} from 'utils/step_utils'
import { useTranslation } from 'react-i18next'

const ModulePanel = (props) => {
  const {children} = props

  return (
    <div className="module-panel mb-3" {...props}>{children}</div>
  )
}

const ModuleHeader = ({status, children, onClick, showIcon = true}) => {
  return (
    <div className="d-flex flex-row" style={{cursor: 'pointer'}} onClick={onClick}>
      <div
        className={cn("d-flex justify-content-between px-3 py-2 w-100", status != 'active' ? 'bg-light' : '')}
        style={{background: '#fff3d0'}}
      >
        {children}
      </div>

      {showIcon &&
        <div className={cn(
          'px-3',
          'py-2',
          status == 'pending' ? 'bg-light' : 'bg-primary',
          {'text-white': status != 'pending'}
        )}>
          <i
            className={cn({
              'fas fa-pencil-alt': status == 'completed' ,
              'fa fa-arrow-down': status == 'active'
            })}
            aria-hidden="true">
          </i>
        </div>
      }
    </div>
  )
}

const ModuleBody = ({status, children, className}) => {
  return (
    <Collapse isOpen={status == 'active'}>
      <div style={{background: 'white'}} className={className ? className : cn('p-3', 'border', 'border-top-0', 'rounded-bottom')}>
        {children}
      </div>
    </Collapse>
  )
}

const ModuleFooter = ({key, machine, status, stepConfig, isLastStep, children, className}) => {
  const [current, send] = machine
  const {next_ready} = current.context
  const {i18n} = useTranslation()

  if (status != 'active') { return null }

  if (stepConfig && !stepConfig.autoNext && !isLastStep) {
    return (
      <Row>
        <Col className="offset-md-9">
          <div className="d-flex justify-content-end py-3">
            <Button
              disabled={!next_ready}
              size={'lg'}
              color='primary'
              className='text-white btn-block'
              onClick={() => send('NEXT_STEP_CLICK', {currentStep: currentStep(current.value)})}
            >
              {i18n.t('module_footer.next_button')}
            </Button>
          </div>
        </Col>
      </Row>
    )
  }

  return null
}

export {ModulePanel, ModuleHeader, ModuleBody, ModuleFooter}
