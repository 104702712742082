import React from 'react'
import {usePartial} from 'i18n/config'
import PageUnlogged from 'ui/page_unlogged'

const PolicyReturn = (props) => {
  const {site_name, client_params} = props

  const PolicyReturnPartial = usePartial('auth/return_policy', {supportTelephone: client_params.support_telephone_label, site_name: site_name})

  return (
    <PageUnlogged {...props}>
      <PolicyReturnPartial/>
    </PageUnlogged>
  )
}

export default PolicyReturn
