import React from 'react'
import {ModulePanel, ModuleHeader, ModuleBody} from 'containers/purchase/modules/module_panel'
import PurchaseWrapper from 'ui/purchase_wrapper'
import {Row, Col, Button} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import time from 'i18n/time'
import {ListGroup, ListGroupItem} from 'reactstrap'
import StatusIcon from 'ui/status_icon'
import {EUR} from 'utils/currency'

const DataList = ({label, value}) => {
  if (!value) {
    return ''
  }

  return (
    <React.Fragment>
      <dt className="col-sm-6 py-1">{label}</dt>
      <dd className="col-sm-6 py-1">{value}</dd>
    </React.Fragment>
  )
}

const ReservationData = ({order_status, order_date, contact_name, code, order_reference, payment_method}) => {
  const { t, i18n } = useTranslation()

  return (
    <ModulePanel>
      <ModuleHeader status="active" showIcon={false}>
        <span>
          <i className="fa fa-bars mr-2" aria-hidden="true"></i>
          {i18n.t('summary.order_data')}
        </span>
      </ModuleHeader>
      <ModuleBody status="active">
        <Row className={'p-2'}>
          <DataList label={i18n.t('summary.order_date')} value={time(order_date).format('lll')}/>
          <DataList label={i18n.t('summary.order_code')} value={code}/>
          <DataList label={i18n.t('summary.status')} value={order_status}/>
          <DataList label={i18n.t('summary.order_reference')} value={order_reference}/>
          <DataList label={i18n.t('summary.payment_method')} value={payment_method}/>
          <DataList label={i18n.t('summary.attended_by')} value={contact_name}/>
        </Row>
      </ModuleBody>
    </ModulePanel>
  )
}

const BuyerData = ({name, email, telephone, room}) => {
  const { t, i18n } = useTranslation()

  return (
    <ModulePanel>
      <ModuleHeader status="active" showIcon={false}>
        <span>
          <i className="fa fa-user mr-2"></i>
          {i18n.t('summary.contact_data')}
        </span>
      </ModuleHeader>
      <ModuleBody status="active">
        <Row className="p-2">
          <DataList label={i18n.t('summary.name')} value={name}/>
          <DataList label={i18n.t('summary.email')} value={email}/>
          <DataList label={i18n.t('summary.telephone')} value={telephone}/>
          <DataList label={i18n.t('summary.room')} value={room}/>
        </Row>
      </ModuleBody>
    </ModulePanel>
  )
}

const SummaryModule = ({reservation}) => {
  const { t, i18n } = useTranslation()
  const {product, event_date, total_price, custom_fields} = reservation

  return (
    <ModulePanel>
      <ModuleHeader status="active" showIcon={false}>
        <span>
          <i className="fa fa-shopping-bag mr-2" aria-hidden="true"></i>
          {i18n.t('summary_module.summary_title')}
        </span>
      </ModuleHeader>
      <ModuleBody status="active" className={{}}>
        {reservation &&
          <ListGroup>
            <ListGroupItem>
              <dl className="row m-0">
                <dt className="col-sm-4 px-0 py-1 m-0">{i18n.t('summary_module.product')}</dt>
                <dd className="col-sm-8 px-0 py-1 m-0">{product.title}</dd>
                <dt className="col-sm-4 px-0 py-1 m-0">{i18n.t('summary_module.event')}</dt>
                <dd className="col-sm-8 px-0 py-1 m-0">{time(event_date).format('LLLL')}</dd>
              </dl>
            </ListGroupItem>
            {reservation.tickets.map((ticket) => (
              <ListGroupItem key={ticket.id} className="d-flex justify-content-between">
                <span>
                  <StatusIcon status={ticket.status}/>
                  {ticket.title}
                </span>
                <span>
                  {EUR(ticket.total_price).format()}
                </span>
              </ListGroupItem>
            ))}
            <ListGroupItem color="primary" className="d-flex justify-content-end">
              <span>
                <strong>
                  {i18n.t('summary_module.amount', {amount: EUR(total_price).format()})}
                </strong>
              </span>
            </ListGroupItem>
          </ListGroup>
        }
      </ModuleBody>
    </ModulePanel>
  )
}

const Order = ({order}) => {
  return (
    <PurchaseWrapper className="container mt-5">
      <Row className="mx-0">
        <Col className="pl-0">
          <ReservationData {...order}/>
          {order.main_customer &&
            <BuyerData {...order.main_customer}/>
          }
        </Col>
        <Col className="pr-0">
          <SummaryModule cart={cart} reservation={order}/>
        </Col>
      </Row>
    </PurchaseWrapper>
  )
}

export default Order
