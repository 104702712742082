import { assign } from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import {nextStep} from 'utils/step_utils'
import {EUR} from 'utils/currency'
import i18n from 'i18n/config'
import time  from 'i18n/time'

const setError = assign({error: i18n.t('validations:fill_all_fields')})
const cleanError = assign({error: null})
const setValidationError = assign({error: i18n.t('validations:fill_all_fields')})
const updateForm = assign({vehicle_rate_form: (ctx, e) => e.form})

const fetchVehicleRates = (ctx) => (
  new ApiFetcher({
    key: 'vehicles',
    endpoint: ctx.endpoints.vehicle_rates,
    method: 'GET',
    query: ctx.car_location_values
  }).call()
)

const submitForm = (ctx, event) => {
  const formName = 'vehicle_rate_form'

  return new Promise((resolve, reject) => {
    ctx[formName].submitForm()
      .then(ctx[formName].validateForm)
      .then(errors => {
        const isValid = Object.keys(errors).length === 0
        if (isValid) {
          resolve(ctx[formName].values)
        } else {
          reject(errors)
        }
      })
  })
}

const vehicleRateState = (steps, params, step) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {'': {
          target: 'fetchVehicleRates',
          actions: cleanError
        }},
      },
      fetchVehicleRates: {
        invoke: {
          src: fetchVehicleRates,
          onDone: [
            {
              target: 'waitingQuotationParams',
              actions: assign({vehicle_rates: (ctx, e) => e.data.data})
            }
          ],
          onError: {
            target: 'waitingQuotationParams',
            actions: [setError]
          }
        }
      },
      waitingQuotationParams: {},
      formValidation: {
        invoke: {
          src: submitForm,
          onDone: [],
          onError: {
            target: 'waitingQuotationParams',
            actions: [setError]
          }
        }
      },
    },
    on: {
      VEHICLE_RATE_FORM_CHANGE: {
        actions: [updateForm]
      },
      NEXT_STEP_CLICK: [
        {
          target: '.formValidation'
        },
        {
          actions: [setValidationError]
        }
      ]
    }
  }
}

export default vehicleRateState
