import React, {useState, useEffect, useContext} from 'react'
import styled from 'styled-components'
import {Card, CardImg, CardBody, CardTitle, CardSubtitle, Col, Row} from 'reactstrap'
import Modal from 'ui/modal'
import AppContext from 'utils/app_context'
import {isMobile, isMobileOnly} from 'react-device-detect'
import {useTranslation} from 'react-i18next'
import ApiFetcher from 'lib/api_fetcher'

const DestinationLabel = styled.span`
  // cursor: pointer;
  // ${props => !props.iframe && `
  //   font-size: 0.7em;
  // `}

  // &:hover {
  //   ${props => props.iframe && `
  //     color: var(--primary);
  //   `}

  //   ${props => !props.iframe && `
  //     color: yellow;
  //   `}
  // }
`

const CityCard = styled(Card).attrs(() => ({
  className: ''
}))`
  cursor: pointer;

  img {
    opacity: 0.85;
  	-webkit-transition: .3s ease-in-out;
  	transition: .3s ease-in-out;

    &:hover {
      opacity: 1
    }
  }

  &:hover {
    color: yellow;
  }
`

const DestinationSelector = ({iframe, url, destination_id, change_destination_link, className, style}) => {
  const [modal, setModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const {i18n} = useTranslation()

  const toggle = () => setModal(!modal)
  const {user} = useContext(AppContext)

  const destinations = user.user_data.user.destinations

  const current = destinations.find((d) => d.id == destination_id || d.slug == destination_id)

  const [searchResults, setSearchResults] = useState(destinations)

  useEffect(() => {
    const results = destinations.filter(d =>
      d.name.toLowerCase().normalize('NFD').includes(searchTerm.toLowerCase().normalize('NFD'))
    )
    setSearchResults(results)
  }, [searchTerm])

  const onPressEnter = (event) => {
    if (event.key === 'Enter') {
      if (searchResults.length > 0) {
        window.location.href = url.replace(/ *\:[^)]*\: */g, searchResults[0].slug)
      }
    }
  }

  if (destinations.length <= 1 || !current) {
    return ''
  }

  const changeDestination = (slug) => {
    new ApiFetcher({
      method: 'PUT',
      key: 'ticket_types',
      endpoint: change_destination_link.replace(/ *\:[^)]*\: */g, slug),
    }).call()
      .then((res) => window.location.href = res.redirect_url)
  }

  return (
    <DestinationLabel iframe={iframe} className={className} style={style} onClick={() => toggle()}>
      {current.name} <i className="ml-1 fa fa-arrow-circle-down"/>

      <Modal
        isOpen={modal}
        toggle={toggle}
        header={
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="mr-0 mr-md-3 pb-lg-0 align-self-md-center">{i18n.t('destination_selector.title_modal')}</div>
            <div className="mx-0 mx-md-4 align-self-md-center">
              <input
                type="text"
                placeholder={i18n.t('destination_selector.input_placeholder')}
                className="form-control d-inline mt-3 mt-sm-0"
                style={{width: isMobile ? '100%' : '15em'}}
                value={searchTerm}
                onChange={() => setSearchTerm(event.target.value)}
                onKeyDown={onPressEnter}
              />
            </div>
          </div>

        }
        bodyStyle={{
          padding: '20px'
        }}
      >
        <Row>
          {searchResults.map(({name, slug, thumbnail_url}) => {
            return (
              <Col key={slug} xs="6" md="3" className="mt-3" onClick={() => changeDestination(slug)}>
                <CityCard>
                  <CardImg top width="100%" height={isMobileOnly ? '100' : '120'} src={thumbnail_url}/>
                  <CardBody className="p-2 bg-primary">
                    <CardTitle className="text-white text-center m-0">
                      <strong>
                        {name}
                      </strong>
                    </CardTitle>
                  </CardBody>
                </CityCard>
              </Col>
            )
          })}
        </Row>
      </Modal>
    </DestinationLabel>
  )
}

export default DestinationSelector
