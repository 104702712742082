import React from 'react'
import {ModulePanel, ModuleHeader, ModuleBody} from 'containers/purchase/modules/module_panel'
import {ListGroup, ListGroupItem} from 'reactstrap'
import { useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import time from 'i18n/time'

const PaymentModule = ({...props}) => {
  const { t, i18n } = useTranslation()
  const [field, meta] = useField(props)
  const {setFieldValue} = useFormikContext()
  const {payment_methods} = props.user
  const {expires_at} = props.cart

  // TODO: Dependencia del orden en el cliente. Se debe trasladar al backend

  const paymentMethodsOrder = [1, 2, 13, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const sortedPaymentMethods = paymentMethodsOrder.map(id => payment_methods.find(item => item.id === id)).filter(item => item !== undefined);

  return (
    <ModulePanel>
      <ModuleHeader status="active" showIcon={false}>
        <span>
          <i className="fa fa-credit-card mr-2" aria-hidden="true"></i>
          {i18n.t('payment_module.payment_title')}
        </span>
      </ModuleHeader>
      <ModuleBody status="active" className={{}}>
        <ListGroup>
          {sortedPaymentMethods.map(({code, title, label, label_es, label_en}) =>
            <ListGroupItem
              key={code}
              color={field.value == code ? 'warning' : ''}
              onClick={() => setFieldValue(field.name, code)}
              className="flex-column align-items-start rounded-0"
              style={{cursor: 'pointer'}}
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">
                  <i className="fa fa-arrow-right mr-2" style={{visibility: field.value != code ? 'hidden' : ''}}></i>
                  {i18n.language == 'es' ? label_es : label_en}

                  {code == 'amex' &&
                    <i className='ml-2 fab fa-cc-amex'/>
                  }

                  {code == 'generic_credit_card' &&
                    <React.Fragment>
                      <i className='ml-2 fab fa-cc-visa'/>
                      <i className='ml-2 fab fa-cc-mastercard'/>
                      <i className='ml-2 fab fa-cc-amex'/>
                    </React.Fragment>
                  }

                  {['safe_credit_card', 'paygold'].includes(code) &&
                    <React.Fragment>
                      <i className='ml-2 fab fa-cc-visa'/>
                      <i className='ml-2 fab fa-cc-mastercard'/>
                      <i className='ml-2 fab fa-cc-amex'/>
                    </React.Fragment>
                  }

                  {code == 'paygold' &&
                    <div className="pl-4">
                      <small><i>{i18n.t('payment_module.paygold_expiration_alert', {expiration: time(expires_at).format('LLL')})}</i></small>
                    </div>
                  }
                </h5>
              </div>
            </ListGroupItem>
          )}
        </ListGroup>
      </ModuleBody>
    </ModulePanel>
  )
}

export default PaymentModule
