import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import {ListGroup, ListGroupItem, Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, Button} from 'reactstrap'
import { Scrollbars } from 'react-custom-scrollbars'
import ApiFetcher from 'lib/api_fetcher'
import { useTranslation } from 'react-i18next'
import ComponentSpinner from 'ui/component_spinner'
import useDebounce from 'utils/hooks/use_debounce'
import {currency} from 'i18n/config'
import {scrollIntoView} from 'scroll-js'
import {isMobile} from 'react-device-detect'
import {get} from 'lodash'

const SearchResultsWrapper = styled.div.attrs((props) => ({
  className: [
    'text-dark rounded shadow'
  ]
}))`
  position: absolute;
  background: transparent !important;
  width: 100%;
  top: 38px;
  right: 0;
  z-index: 99999;

  @media (min-width: 768px) {
    width: 145% !important;
  }
`

const SearchBoxWrapper = styled.div`
  position: relative;
  transition: all 0.25s;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  align-items: center;
  padding: 2px 3px;

  &:hover {
    background: rgba(255, 255, 255, 1);

    .input-button:after {
      content: "${props => props.search_text}";
      margin-left: 3px;
    }

    .input-button {
      width: 90px !important;
    }
  }

  input {
    background: transparent;
    flex: 1;
    border: 0;
    outline: none;
    padding: 3px 20px;
    font-size: 1em;
    color: #000;
  }

  .input-button {
    transition: all 1.2s;
    transition-delay: -0.28s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    border: 0;
    border-radius: 5px;
    width: 33px;
    height: 33px;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    width: 16.5em !important;
  }

  @media (min-width: 992px) {
    width: 24.5em !important;
  }
`

const SearchBox = ({productLink, user}) => {
  const [loading, setLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('a')
  const [response, setResponse] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [showBox, setShowBox] = useState(false)
  const { t, i18n } = useTranslation()

  const sale_channel = user.user_data.user.sale_channel

  const debouncedSearchTerm = useDebounce(() => searchTerm, 500)

  const node = useRef()

  useEffect(() => {
    if (searchTerm && searchTerm.length >= 3) {
      setLoading(true)

      return new ApiFetcher({
        key: 'search',
        method: 'GET',
        endpoint: '/search',
        query: {
          term: searchTerm
        }
      }).call()
        .then((res) => {
          setSearchResults(res.products)
          setResponse(res)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [debouncedSearchTerm])

  const handleClick = e => {
    if (node.current && node.current.contains(e.target)) {
      // setShowBox(true)
    } else {
      setShowBox(false)
    }
  }

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  const ProductCard = (props) => {
    const {id, slug, title, cover_image, state_name, city_name, supplier_name, featured_price} = props

    const url = productLink.replace(/ *\:[^)]*\: */g, slug)

    return (
      <ListGroupItem className="p-0 search-box-result-item">
        <Card className="border-0 d-flex flex-row">
          <div className="p-1 d-flex">
            <img width="130" height="85" className="rounded border align-self-center" src={cover_image}/>
          </div>

          <CardBody className="pl-2 pr-3 py-3">
            <CardTitle tag="h5" className="search-box-result-item-title">
              <a href={url}>{title}</a>
            </CardTitle>
            <CardSubtitle tag="h6" className="mb-0 text-muted d-flex justify-content-between search-box-result-subtitle">
              {sale_channel == 'b2b' ? (
                city_name && supplier_name && (
                  <span>{city_name} ● {supplier_name}</span>
                )
              ) : (
                state_name && city_name && (
                  <span>
                    {state_name == city_name ? (
                      state_name
                    ) : (
                      <React.Fragment>{state_name} ● {city_name}</React.Fragment>
                    )}
                  </span>
                )
              )}

              {featured_price &&
                <span>{i18n.t('search_box.featured_price', {amount: currency(featured_price)})}</span>
              }
            </CardSubtitle>
          </CardBody>
        </Card>
      </ListGroupItem>
    )
  }

  const SearchResults = () => {
    const products = response && response.products
    const productsCount = response && response.products && response.products.length
    const otherProducts = response && response.other_destinations

    const ResultsTitle = () => {
      let title

      if (loading) {
        title = i18n.t('search_results.loading')
      } else if (searchTerm.length < 3) {
        title = i18n.t('search_results.write_a_term')
      } else if (otherProducts) {
        title = i18n.t('search_results.results_count_with_destination', {count: productsCount, destination: response.destination.name})
      } else {
        title = i18n.t('search_results.results_count', {count: productsCount})
      }

      return (
        <strong>{title}</strong>
      )
    }

    return (
      <SearchResultsWrapper>
        <div style={{paddingTop: '10px', backgroundColor: 'transparent'}}>
          <ListGroup style={{background: 'white'}}>
            <ListGroupItem className="bg-primary text-white">
              <ResultsTitle/>
            </ListGroupItem>
            <Scrollbars
              autoHeight
              autoHeightMin={120}
              autoHeightMax={380}
            >
              {loading &&
                <ComponentSpinner/>
              }
              {products && products.length > 0 ? (
                products.map((p) => <ProductCard key={p.id} {...p}/>)
              ) : (
                <ListGroupItem className="py-4 text-center muted">
                  {i18n.t('search_results.no_results')}
                </ListGroupItem>
              )}
            </Scrollbars>

            {false &&
              <ListGroupItem className="d-flex justify-content-center">
                <strong className="text-center ">
                  <i className="mr-2 fa fa-angle-double-down"/>
                    Mostrar más resultados
                  <i className="ml-2 fa fa-angle-double-down"/>
                </strong>
              </ListGroupItem>
            }
          </ListGroup>

          {otherProducts && otherProducts.length > 0 &&
            <ListGroup style={{background: 'white'}}s>
              <ListGroupItem className="bg-primary text-white">
                <strong>{i18n.t('search_results.other_destinations_title', {count: otherProducts.length})}</strong>
              </ListGroupItem>
              <Scrollbars
                autoHeight
                autoHeightMin={120}
                autoHeightMax={300}
              >
                {loading &&
                  <ComponentSpinner/>
                }

                {otherProducts.map((p) => <ProductCard key={p.id} {...p}/>)}
              </Scrollbars>
            </ListGroup>
          }
        </div>
      </SearchResultsWrapper>
    )
  }

  return (
    <SearchBoxWrapper
      search_text={i18n.t('format:actions.search')}
      ref={node}
      className="search-box rounded-lg shadow-lg"
      onClick={() => {
        if (isMobile) {
          var myElement = document.body.getElementsByClassName('search-box-content')[0]
          scrollIntoView(myElement, document.body, { behavior: 'smooth' })
        }

        // if (searchResults && searchResults.length > 0) {
        setShowBox(true)
        // }
      }}
    >
      {showBox &&
        <SearchResults/>
      }
      <input type="text" placeholder={i18n.t('search_box.input_placeholder')} onChange={() => {
        setSearchTerm(event.target.value)
      }}/>
      <div className="input-button search-box-button" onClick={(e) => {
        e.stopPropagation()
        setShowBox(!showBox)
      }}>
        <i className="fa fa-search search-box-button-icon"/>
      </div>
    </SearchBoxWrapper>

  )
}

export default SearchBox
