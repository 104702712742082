import React from 'react'
import {ModulePanel, ModuleHeader, ModuleBody, ModuleFooter} from 'containers/purchase/modules/module_panel'
import TicketTypesList from 'containers/purchase/modules/ticket_types_module/ticket_types_list'
import { useTranslation } from 'react-i18next'

const SupplementsModule = (props) => {
  const {machine, status, type = 'supplement'} = props
  const { t, i18n } = useTranslation()
  const [current, send] = machine
  const { supplements } = current.context

  const qtyTickets = () => {
    if (supplements.length > 0) {
      return supplements.map((ticket) => ticket.qty).reduce((accum, current) => accum + current)
    } else {
      return 0
    }
  }

  const HeaderText = () => {
    if (status != 'completed') {
      return i18n.t('supplements_module.select_supplements')
    }

    if (supplements.length > 0) {
      return i18n.t('supplements_module.selected_supplements', {qty: qtyTickets()})
    } else {
      return i18n.t('supplements_module.product_without_supplements')
    }
  }

  return (
    <ModulePanel>
      <ModuleHeader status={status} onClick={() => status == 'completed' ? send('REOPEN_SUPPLEMENTSSTEP') : null}>
        <span>
          <i className="fa fa-star mr-2" aria-hidden="true"></i>
          <HeaderText/>
        </span>
      </ModuleHeader>
      <ModuleBody status={status} className={' '}>
        <TicketTypesList
          type={type}
          data={supplements}
          onTicketQtyChanged={(tickets) => send({type: 'TICKETS_SELECTION', tickets: tickets})}
        />
      </ModuleBody>
      <ModuleFooter {...props}/>
    </ModulePanel>
  )
}

export default SupplementsModule
