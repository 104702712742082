import React, {useState} from 'react'
import moment from 'moment'
import i18n from 'i18n/config'
import {get} from 'lodash'
import DateRangeFilter from 'ui/tables/filters/date_range_filter'
import SelectFilter from 'ui/tables/filters/select_filter'
import {statusIcons} from './classes'
import currency from 'i18n/currency'
import OrderModal from './order_modal'

const getColumns = (data, params) => [
  {
    Header: '',
    accessor: 'row',
    Cell: ({state, row}) => state.pageSize * (state.pageIndex) + (row.index + 1),
    sortable: false,
    hideInSlider: true,
    justifyContent: 'center',
    width: 55
  },
  {
    Header: i18n.t('sales_grid_options.order_date'),
    id: 'orders.order_date',
    accessor: 'order_date',
    disableFilters: false,
    Filter: DateRangeFilter,
    filterOptionsName: 'products',
    justifyContent: 'center',
    width: 100,
    Cell: ({value}) => {
      return moment(value).format('DD/MM/YYYY')
    }
  },
  {
    Header: i18n.t('sales_grid_options.hour'),
    id: 'orders.hour',
    accessor: 'order_date',
    justifyContent: 'center',
    width: 60,
    Cell: ({value}) => {
      return moment(value).format('HH:mm')
    }
  },
  {
    Header: i18n.t('sales_grid_options.order_status'),
    id: 'orders.order_status',
    accessor: 'order_status',
    disableFilters: false,
    Filter: SelectFilter,
    filterOptionsName: 'order_statuses',
    justifyContent: 'center',
    width: 100,
    Cell: ({row, value}) => {
      return (
        <i title={row.original.order_status_label} className={statusIcons(value)}/>
      )
    }
  },
  {
    Header: i18n.t('sales_grid_options.client_and_user'),
    id: 'orders.user_id',
    accessor: 'client_name',
    disableFilters: false,
    Filter: SelectFilter,
    filterOptionsName: 'users',
    width: 150,
  },
  {
    Header: i18n.t('sales_grid_options.customer'),
    id: 'customers.id',
    accessor: 'main_customer.name',
    width: 180,
    Cell: ({value, row}) => {
      const name = get(row, 'original.main_customer.name', '')
      const surname = get(row, 'original.main_customer.surname', '')
      const room = get(row, 'original.main_customer.room')

      if (room) {
        return `${name} ${surname} (${room})`
      } else {
        return name ? `${name} ${surname}` : ''
      }
    }
  },
  {
    Header: i18n.t('sales_grid_options.product'),
    id: 'orders.product_id',
    accessor: 'product.title',
    disableFilters: false,
    Filter: SelectFilter,
    filterOptionsName: 'products',
    width: 270,
  },
  {
    Header: i18n.t('sales_grid_options.contact_name'),
    id: 'orders.contact_name',
    accessor: 'contact_name',
    disableFilters: false,
    Filter: SelectFilter,
    filterOptionsName: 'attendants',
    width: 130,
    Cell: ({value}) => {
      return value ? value : (<i>{i18n.t('sales_grid_options.no_attended_by')}</i>)
    }
  },
  {
    Header: i18n.t('sales_grid_options.collection_reference'),
    id: 'orders.collection_reference',
    accessor: 'collection_reference',
    justifyContent: 'center',
    width: 130,
  },
  {
    Header: () => <span style={{whiteSpace: 'pre-line'}}>{i18n.t('sales_grid_options.payment_method')}</span>,
    id: 'orders.payment_method_id',
    accessor: 'payment_method_label',
    disableFilters: false,
    Filter: SelectFilter,
    filterOptionsName: 'payment_methods',
    justifyContent: 'center',
    width: 120,
  },
  {
    Header: () => <span style={{whiteSpace: 'pre-line'}}>{i18n.t('sales_grid_options.tickets_count')}</span>,
    id: 'orders.tickets_count',
    accessor: 'tickets_count',
    justifyContent: 'flex-end',
    width: 80,
  },
  {
    Header: () => <span style={{whiteSpace: 'pre-line'}}>{i18n.t('sales_grid_options.total_price')}</span>,
    id: 'orders.total_price',
    accessor: 'total_price',
    justifyContent: 'flex-end',
    width: 75,
    Cell: ({value, row}) => {
      return currency(value, row.original.currency).format()
    }
  },
  {
    Header: () => <span style={{whiteSpace: 'pre-line'}}>{i18n.t('sales_grid_options.commission_type')}</span>,
    id: 'orders.commission_type',
    accessor: 'commission_type',
    justifyContent: 'flex-end',
    width: 75,
    Cell: ({value}) => value ? value : '-'
  },
  {
    Header: () => <span style={{whiteSpace: 'pre-line'}}>{i18n.t('sales_grid_options.total_commission')}</span>,
    id: 'orders.total_commission',
    accessor: 'total_commission',
    justifyContent: 'flex-end',
    width: 75,
    Cell: ({value, row}) => value ? currency(value, row.original.currency).format() : '-'
  },
  {
    id: 'orders.see',
    accessor: 'see',
    justifyContent: 'center',
    width: 40,
    style: {
      background: 'var(--info)',
    },
    Cell: (props) => OrderModal({
      order: props.row.original,
      urls: props.params.urls
    })
  },
  {
    id: 'orders.voucher',
    accessor: 'voucher',
    justifyContent: 'center',
    width: 40,
    style: ({row}) => {
      let color = 'var(--light)'
      if (row.original.order_status == 'confirmed' && row.original.uuid) {
        color = 'var(--success)'
      }

      return ({
        background: color
      })
    },
    Cell: ({row}) => {
      const voucherUrl = params.urls.voucher.replace(/ *\:uuid:*/g, row.original.uuid).replace(/ *\:order_code:*/g, row.original.code)

      return (
        <React.Fragment>
          {row.original.order_status == 'confirmed' && row.original.uuid &&
            <a href={voucherUrl} target={'_blank'} rel="noreferrer"><i className="fa fa fa-download text-white"/></a>
          }
        </React.Fragment>
      )
    }
  }
]

const tableOptions = (data, params) => {
  const can_see_commissions = params.user.user_data.user.can_see_commissions
  const can_see_users = params.user.user_data.user.can_see_users

  return {
    columns: getColumns(data, params).filter((c) => {
      let hiddenColumns = []

      if (!can_see_commissions) {
        hiddenColumns.push('commission_type')
        hiddenColumns.push('total_commission')
      }

      if (!can_see_users) { hiddenColumns.push('client_name') }

      return !hiddenColumns.includes(c.accessor)
    })
  }
}

export default tableOptions
