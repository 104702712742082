import React, {useState} from 'react'
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import { useField } from 'formik'
import cn from 'classnames'
import styled from 'styled-components'

const InputStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.18);
  border-radius: 3px;

  &:focus {
    background: red;
  }

  button {
    padding: .7rem;
    width: 100%;
    min-width: 3rem;
    font-size: 1rem;
    font-weight: bold;
    border: 0 solid #dbdbdb;
    text-align: center;
    text-shadow: 0 1px 0 rgba(#fff, .6);

    cursor: pointer;

    &:hover {
      background: darken(#f3f3f3, 10%);
      color: darken(#888, 20%)
    }

    &.left {
      border-radius: 3px 0 0 3px;
    }

    &.right {
      border-radius: 0 3px 3px 0;
    }
  }

  input {
    width: 4rem;
    padding: .7rem;
    font-size: 1rem;

    border: 0;
    border-top: 0 solid #dbdbdb;
    border-bottom: 0 solid #dbdbdb;
    text-align: center;
  }
`

const NumberInput = ({label, hideError, ...props }) => {
  const name = (props.field && props.field.name) || props.name
  const [field, meta, helpers] = useField(name)

  return (
    <FormGroup>
      {label &&
        <Label className='control-label' htmlFor={props.id || props.name}>
          {label}
          {props.required &&
            <span className="text-danger ml-1">*</span>
          }
        </Label>
      }

      <InputStyles className="quantity-input">
        <button
          className="left bg-light"
          onClick={(e) => {
            e.preventDefault()
            helpers.setValue(field.value - 1)
          }}
        >
          &mdash;
        </button>
        <input type="text" value={field.value} readOnly/>
        <button
          className="right bg-light"
          onClick={(e) => {
            e.preventDefault()
            helpers.setValue(field.value + 1)
          }}
        >
          &#xff0b;
        </button>
      </InputStyles>

      {!hideError && meta.touched && meta.error ? (
        <FormFeedback className={'help-block'}>{meta.error}</FormFeedback>
      ) : null}
    </FormGroup>
  )
}

export default NumberInput
