import React, {useState, useEffect, useRef} from 'react'
import { Formik, Field } from 'formik'
import { Row, Col, Form, FormGroup, Alert, Card, CardHeader, CardBody, ButtonGroup, Button, ListGroupItem, ListGroup } from 'reactstrap'
import { ReactstrapInput } from 'reactstrap-formik'
import QtyInput from 'ui/forms/qty_input'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import {get} from 'lodash'
import Select from 'ui/forms/select'
import TextInput from 'ui/forms/text_input'
import InlineDatetimePicker from 'ui/forms/inline_date_picker'
import Checkbox from 'ui/forms/checkbox'
import moment from 'moment'
import styled from 'styled-components'
import cn from 'classnames'
import RadioButtons from 'ui/forms/radio_buttons'
import time from 'i18n/time'

const Styles = styled.div`
  align-self: center !important;

  input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: white;
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: var(--primary);
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid var(--primary);
    border-radius: 50%;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
  }

  input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--primary);
    /* Windows High Contrast Mode */
    background-color: red;
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }

  input[type="radio"]:focus {
    outline: max(2px, 0.15em) solid var(--primary);
    outline-offset: max(2px, 0.15em);
  }
`

const destinationsMap = {
  barcelona: '71801',
  madrid: '60000',
  sevilla: '51003',
  valencia: '03216',
  'costa-dorada': '04104'
}

const RouteForm = (props) => {
  const { t, i18n } = useTranslation()

  const {config, machine} = props
  const stations = machine[0].context.train_resources.stations.map(({name, uicStationCode}) => ({value: uicStationCode, label: name}))

  return (
    <Formik
      initialValues={{
        // origin: props.destination && destinationsMap[props.destination] ? destinationsMap[props.destination] : null,
        origin: '',
        destination: '',
        departureDate: '',
        returnDate: '',
        adults: 1,
        children: 0,
        infants: 0,
        return: true
      }}
      validationSchema={
        Yup.object().shape({
          origin: Yup.string().required(i18n.t('validations:required')),
          destination: Yup.string().required(i18n.t('validations:required')),
          adults: Yup.number().min(1),
          departureDate: Yup.string().required(i18n.t('validations:required'))
        })
      }
      onSubmit={() => {}}
    >
      {(form) => {
        const {values, errors, touched, setFieldValue} = form

        useEffect(() => {
          props.setForm(form)
        }, [values, errors, touched])

        useEffect(() => {
          if (values.departureDate > values.returnDate) {
            setFieldValue('returnDate', values.departureDate)
          }
        }, [values.departureDate])

        useEffect(() => {
          if (values.returnDate < values.departureDate) {
            setFieldValue('departureDate', values.returnDate)
          }
        }, [values.returnDate])

        useEffect(() => {
          if (values.destination) {
            const validRoute = machine[0].context.train_resources.routes
              .find(({originUicStationCode, destinationUicStationCode, salesPeriods}) => {

                const isValidPeriod = salesPeriods
                  .map(({startDate, endDate}) => time().isBetween(startDate, endDate))
                  .some((validPeriod) => validPeriod)

                return originUicStationCode == values.origin &&
                  destinationUicStationCode == values.destination &&
                  isValidPeriod
              })

            if (!validRoute) {
              setFieldValue('destination', '')
            }
          }
        }, [values.origin, values.destination])

        const originStations = stations.filter((s) => s.value != values.destination)
        const destinationStations = stations.filter((s) => s.value != values.origin).filter((s) => {
          const routes = machine[0].context.train_resources.routes

          if (values.origin) {
            return routes
              .filter((r) => r.originUicStationCode == values.origin)
              .map((r) => r.destinationUicStationCode)
              .includes(s.value)
          } else {
            return true
          }
        })

        function getDates (startDate, endDate) {
          const dates = []
          let currentDate = startDate
          const addDays = function (days) {
            const date = new Date(this.valueOf())
            date.setDate(date.getDate() + days)
            return date
          }
          while (currentDate <= endDate) {
            dates.push(currentDate)
            currentDate = addDays.call(currentDate, 1)
          }
          return dates
        }

        const trainRoutes = get(machine[0], 'context.train_resources.routes', [])

        const validRoute = trainRoutes
          .find(({originUicStationCode, destinationUicStationCode}) => originUicStationCode == values.origin && destinationUicStationCode == values.destination)

        const validPeriodDays = validRoute && validRoute.circulationPeriods.map((period) => {
          let daysArray = period.weekdays.split(',')

          daysArray.push(daysArray.shift())

          const weekdays = daysArray.map((available, i) => available == true ? i + 1 : null)

          const periodDays = getDates(time(period.startDate).toDate(), time(period.endDate).toDate())
          const periodDaysWeekdaysFiltered = periodDays.filter((day) => weekdays.includes(time(day).isoWeekday()))

          return periodDaysWeekdaysFiltered
        })

        const dayAvailable = (date) => {
          // const routes = machine[0].context.train_resources.routes
          if (!values.origin || !values.destination) {
            return true
          }

          return validPeriodDays.map((period) => period.some((day) => time(day).isSame(time(date), 'day'))).some((dayInPeriod) => dayInPeriod == true)
        }

        return (
          <Form>
            <Row className="justify-content-md-center">
              <Col xs="12" md="6">
                <Row>
                  <Col xs="12" md="6">
                    <Select
                      name="origin"
                      label={i18n.t('iryo_route_form.origin_label')}
                      defaultValue={null}
                      values={originStations}
                      value={originStations.find(({value}) => value == values.origin) || ''}
                      placeholder={i18n.t('iryo_route_form.origin_placeholder')}
                      required
                    />
                  </Col>
                  <Col xs="12" md="6">
                    <Select
                      isDisabled={!values.origin}
                      name="destination"
                      label={i18n.t('iryo_route_form.destination_label')}
                      defaultValue={null}
                      values={destinationStations}
                      value={destinationStations.find(({value}) => value == values.destination) || ''}
                      placeholder={i18n.t('iryo_route_form.destination_placeholder')}
                      required
                    />
                  </Col>
                </Row>
                <FormGroup>
                  <Row className="d-flex align-self-center">
                    <Col xs="12" md="6" className="mt-2">
                      <RadioButtons
                        btnBlock
                        values={[{value: true, label: i18n.t('iryo_route_form.round_trip')}, {value: false, label: i18n.t('iryo_route_form.one_way')}]}
                        name={'return'}
                      />
                    </Col>
                    <Col xs="12" md="3">
                      <InlineDatetimePicker
                        selected={values.departureDate}
                        name={'departureDate'}
                        label={i18n.t('iryo_route_form.departureDate')}
                        inline={false}
                        dateFormat="P"
                        showTimeSelect={false}
                        timezone={config.product.local_timezone}
                        machine={machine}
                        initialDate={moment().toDate()}
                        filterDate={dayAvailable}
                      />
                    </Col>
                    {values.return &&
                      <Col xs="12" md="3">
                        <InlineDatetimePicker
                          selected={values.returnDate}
                          name={'returnDate'}
                          label={i18n.t('iryo_route_form.returnDate')}
                          inline={false}
                          dateFormat="P"
                          showTimeSelect={false}
                          timezone={config.product.local_timezone}
                          machine={machine}
                          initialDate={moment().toDate()}
                          filterDate={dayAvailable}
                        />
                      </Col>
                    }
                  </Row>
                </FormGroup>
              </Col>
              <Col xs="12" md="6"className="d-flex align-self-center justify-content-center">
                <Row>
                  <Col>
                    <QtyInput
                      label={i18n.t('iryo_route_form.adults')}
                      id={'adults'}
                      value={values.adults}
                      onIncrement={() => {
                        setFieldValue('adults', values.adults + 1)
                      }}
                      onDecrement={() => {
                        setFieldValue('adults', values.adults == 1 ? values.adults : values.adults - 1)
                      }}
                    />
                  </Col>
                  <Col>
                    <QtyInput
                      label={i18n.t('iryo_route_form.children') + ' (4-13)'}
                      id={'children'}
                      value={values.children}
                      onIncrement={() => {
                        setFieldValue('children', values.children + 1)
                      }}
                      onDecrement={() => {
                        setFieldValue('children', values.children == 0 ? values.children : values.children - 1)
                      }}
                    />
                  </Col>
                  <Col>
                    <QtyInput
                      label={i18n.t('iryo_route_form.infants') + ' (0-3)'}
                      id={'infants'}
                      value={values.infants}
                      onIncrement={() => {
                        setFieldValue('infants', values.infants + 1)
                      }}
                      onDecrement={() => {
                        setFieldValue('infants', values.infants == 0 ? values.infants : values.infants - 1)
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}

export default RouteForm
