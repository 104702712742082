import React from 'react'

const OurProducts = (props) => {
  return (
    <div>
      <p>Our on-line sales channel allows us <strong>to carry out the sale management and the personalization of the catalog for each hotel</strong></p>

      <p>The products and services that we commercialize are, among others:</p>

      <p className="text-center">
        Restaurants ● Shows ● Museums ● Dinner Shows ● Nightclubs ● Party Room ● Theaters ● Music Bars ● Amusement Parks ● Theme Parks ● Tourist Attractions ● Concerts ● Leisure Activities
      </p>

      <p>We have some products that we sell in <strong>exclusive</strong>.</p>
    </div>
  )
}

export default OurProducts
