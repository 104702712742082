import React from 'react'
import Select from 'react-select'
import {find, get} from 'lodash'

const customStyles = {
  container: base => ({
    ...base,
    width: '100%'
  }),
  control: base => ({
    ...base,
    minHeight: 30
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: 0
  }),
  clearIndicator: base => ({
    ...base,
    padding: 0
  }),
  multiValue: base => ({
    ...base
  }),
  valueContainer: base => ({
    ...base,
    padding: '0px 4px'
  }),
  option: base => ({
    ...base,
    textAlign: 'left'
  }),
  input: base => ({
    ...base,
    margin: 0,
    padding: 0
  })
}

const SelectFilter = (props) => {
  const {doFetch, serverResponse, column, state, data} = props
  const filterMode = column.filterMode || 'eq'

  const options = get(serverResponse.filters, column.filterOptionsName, [])
  const param = column.filterParam || column.id
  const value = get(find(serverResponse.filter, {id: param}), `value.${filterMode}`, '')

  return (
    <Select
      styles={customStyles}
      isClearable={true}
      value={find(options, {value: value})}
      options={options}
      placeholder={'...'}
      onChange={(val)=> {
        const filterValue = val ? {[filterMode]: val.value} : {}

        const newfilters = state.filters
          .filter((filter) => filter.id != param)
          .concat([{id: param, value: filterValue}])

        doFetch((query) => {
          return {...query, page: 1, filter: JSON.stringify(newfilters)}
        })
      }}
    />
  )
}

export default SelectFilter
