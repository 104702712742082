import React from 'react'

const EventItem = ({active, hour, availability, showAvailability, eventRenderMode, eventData, onEventSelected}) => {
  const { show_time, description } = eventData

  const OnlyTime = () => {
    return (
      <React.Fragment>
        <strong className="text-white mr-2">
          <i className="far fa-clock mr-1" aria-hidden="true"></i>
          {show_time == true ? (
            <React.Fragment>
              {hour}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {description}
            </React.Fragment>
          )}
        </strong>
        {showAvailability != 'no_show_availability' &&
          <span className="badge badge-light bg-white text-primary">
            <i className="fas fa-ticket-alt mr-1" aria-hidden="true"></i>
            {availability}
          </span>
        }
      </React.Fragment>
    )
  }

  const WithDescription = () => {
    return (
      <span className="d-flex justify-content-between">
        {description &&
          <span className="text-white">
            {description}
          </span>
        }
        <strong className="text-white mr-6">
          <i className="far fa-clock mr-1" aria-hidden="true"></i>
          <span className="mr-2">{hour}</span>
          {showAvailability != 'no_show_availability' &&
            <span className="badge badge-light bg-white text-primary">
              <i className="fas fa-ticket-alt mr-1" aria-hidden="true"></i>
              {availability}
            </span>
          }
        </strong>
      </span>
    )
  }

  const activeClass = active ? 'active' : ''
  return (
    <div className="p-2">
      <button
        onClick={() => onEventSelected(eventData)}
        type="button"
        className={'btn btn-primary btn-block' + activeClass}
        disabled={availability > 0 ? false : true}
      >
        {eventRenderMode == 'description' ? (
          <WithDescription/>
        ) : (
          <OnlyTime/>
        )}
      </button>
    </div>
  )
}

export default EventItem
